import React, { useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { createService, getAllServices, importService, processImportFile, updateService } from "../../../api/service";
import "./../services.scss";
import DataGrid from "../../shared/data-grid/data-grid";
import BasicHeader from "../../shared/data-grid/custom-headers/basic-header";
import BasicCell from "../../shared/data-grid/custom-cells/basic-cell";
import ClickableCell from "../../shared/data-grid/custom-cells/clicable-cell";
import PopUpModal from "../../shared/popup-modal/popUpModal";
import StaffAllocationAction from "../../shared/data-grid/custom-cells/staffAssign";
import * as XLSX from "sheetjs-style";
import { saveAs } from "file-saver";
import StaffAllocation from "./staff-allocation";
import ConfirmNewService from "./confirm";
import ServiceCreateUpdate from "./../service-create/create";
import { updateStaffAllocation } from "../../../api/clientService";
import { getAllUsersList } from "../../../api/user";
import { clone, pullAllBy } from "lodash";

export const ImportServices = () => {

    /*
    * Supported file formats : https://pkg.go.dev/github.com/xuri/excelize/v2
    * XLAM / XLSM / XLSX / XLTM / XLTX
    * */
    const fileTypes = ["XLSX"];

    const columns = [
      {
        Header: () => <BasicHeader header={"Service Code"} />,
        accessor: "service_code",
        width: 150,
        Cell: (row) => {
          return <BasicCell value={row.value} index={row.index} />;
        }
      },
      {
        Header: () => <BasicHeader header={"Service Name"} />,
        accessor: "service_name",
        Cell: (row) => {
          return <BasicCell value={row.value} index={row.index} />;
        }
      },
      {
        Header: () => <BasicHeader header={"Unit Rate"} />,
        accessor: "unit_cost",
        width: 150,
        Cell: (row) => <BasicCell value={row.value} index={row.index} />
      },
      {
        Header: "",
        accessor: "icon",
        Cell: (row) => (
          <ClickableCell
            actionId={row.original}
            onClick={onServiceAdd}
            icon={"/images/plus.svg"}
          />
        ),
        width: 50
      }
    ];

    const newServicesColumn = [
      {
        Header: () => <BasicHeader header={"Service Code"} />,
        accessor: "service_code",
        width: 120,
        Cell: (row) => {
          return <BasicCell value={row.value} index={row.index} />;
        }
      },
      {
        Header: () => <BasicHeader header={"Service Name"} />,
        accessor: "service_name",
        Cell: (row) => {
          return <BasicCell value={row.value} index={row.index} />;
        }
      },
      {
        Header: () => <BasicHeader header={"Client Name"} />,
        accessor: "individual_name",
        Cell: (row) => <BasicCell value={row.value} index={row.index} />
      },
      {
        Header: () => <BasicHeader header={"Recipient ID"} />,
        accessor: "recipient_id",
        width: 120,
        Cell: (row) => <BasicCell value={row.value} index={row.index} />
      },
      {
        Header: () => <BasicHeader header={"Start Date"} />,
        accessor: "service_start_date",
        width: 100,
        Cell: (row) => <BasicCell value={row.value} index={row.index} />
      },
      {
        Header: () => <BasicHeader header={"End Date"} />,
        accessor: "service_end_date",
        width: 100,
        Cell: (row) => <BasicCell value={row.value} index={row.index} />
      },
      {
        Header: () => <BasicHeader header={"Unit Rate"} />,
        accessor: "unit_cost",
        width: 100,
        Cell: (row) => <BasicCell value={row.value} index={row.index} />
      },
      {
        Header: () => <BasicHeader header={"Units Authorized"} />,
        accessor: "units_authorized",
        width: 100,
        Cell: (row) => <BasicCell value={row.value} index={row.index} />
      },
      // {
      //   Header: "",
      //   accessor: "icon",
      //   Cell: (row) => (
      //     <StaffAllocationAction
      //       actionId={row.original}
      //       onClick={() => {
      //         onStaffAllocation(row.original);
      //       }}
      //       value={"Assign"}
      //     />
      //   ),
      //   width: 50
      // },
      {
        Header: "",
        accessor: "icon",
        Cell: (row) => (
          <ClickableCell
            actionId={row.original}
            onClick={() => {
              onStaffAllocation(row.original);
            }}
            icon={"/images/check-green.svg"}
          />
        ),
        width: 50
      }
    ];

    const updatedServicesColumn = [
      {
        Header: () => <BasicHeader header={"Service Code"} />,
        accessor: "service_code",
        width: 150,
        Cell: (row) => {
          return <BasicCell value={row.value} index={row.index} />;
        }
      },
      {
        Header: () => <BasicHeader header={"Service Name"} />,
        accessor: "service_name",
        Cell: (row) => {
          return <BasicCell value={row.value} index={row.index} />;
        }
      },
      {
        Header: () => <BasicHeader header={"Client Name"} />,
        accessor: "individual_name",
        Cell: (row) => <BasicCell value={row.value} index={row.index} />
      },
      {
        Header: () => <BasicHeader header={"Recipient ID"} />,
        accessor: "recipient_id",
        width: 150,
        Cell: (row) => <BasicCell value={row.value} index={row.index} />
      },
      {
        Header: () => <BasicHeader header={"Start Date"} />,
        accessor: "service_start_date",
        width: 150,
        Cell: (row) => <BasicCell value={row.value} index={row.index} />
      },
      {
        Header: () => <BasicHeader header={"End Date"} />,
        accessor: "service_end_date",
        width: 150,
        Cell: (row) => <BasicCell value={row.value} index={row.index} />
      },
      {
        Header: () => <BasicHeader header={"Unit Rate"} />,
        accessor: "unit_cost",
        width: 150,
        Cell: (row) => <BasicCell value={row.value} index={row.index} />
      },
      {
        Header: () => <BasicHeader header={"Date Authorized"} />,
        accessor: "date_last_authorized",
        width: 150,
        Cell: (row) => <BasicCell value={row.value} index={row.index} />
      },
      {
        Header: "",
        accessor: "icon",
        Cell: (row) => (
          <ClickableCell
            actionId={row.original.id}
            onClick={() => {
            }}
            icon={"/images/check-green.svg"}
          />
        ),
        width: 50
      }
    ];


    const [usersList, setUsersList] = useState([]);
    const [allServices, setAllServices] = useState([]);
    const [file, setFile] = useState(null);
    const [missingClients, setMissingClients] = useState([]);
    const [missingServices, setMissingServices] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [newServices, setNewServives] = useState([]);
    const [updatedServices, setUpdatedServives] = useState([]);
    const [reload, setReload] = useState(false);
    const [openAllocationModal, setOpenAllocationModal] = useState(false);
    const [none, setNone] = useState(false);

    const [openConfirmModal, setOpenConfirmModal] = useState(false);

    const [serviceInitialData, setServiceInitialData] = useState({
      serviceName: "",
      serviceCode: "",
      serviceDescription: "",
      serviceUnitRate: 0,
      serviceUnitLength: "",
      isDegreeRequired: false,
      isOverlappable: false
    });
    const [staffAllocationData, setStaffAllocationData] = useState({
      id: 0,
      recipient_id: "",
      serviceName: "",
      serviceCode: "",
      serviceStartDate: "",
      serviceEndDate: "",
      assignedStaff: []
    });

    const [importedData, setImportedData] = useState({});

    useEffect(() => {
      getAllUsersList().then((res) => {
        setUsersList(res);
      });
      getAllServices(1, 100, "recent", "", 0).then((res) => {
        setAllServices(res.data);
      });
    }, []);

    const exportToExcel = () => {
      // Define your data
      const data = missingClients;

      // Define custom headers
      const headers = [
        { v: "Individual Name", s: { font: { bold: true }, alignment: { horizontal: "center" } } },
        { v: "Recipient ID", s: { font: { bold: true }, alignment: { horizontal: "center" } } },
        { v: "ICD-10 Diagnosis Code", s: { font: { bold: true }, alignment: { horizontal: "center" } } },
        { v: "Funding Source", s: { font: { bold: true }, alignment: { horizontal: "center" } } }
      ];

      // Convert data to worksheet and add headers
      const worksheet = XLSX.utils.json_to_sheet([]);
      XLSX.utils.sheet_add_aoa(worksheet, [headers.map(h => h.v)], { origin: "A1" });
      XLSX.utils.sheet_add_json(worksheet, data, { origin: "A2", skipHeader: true });

      // Apply styles to headers
      headers.forEach((header, index) => {
        const cellAddress = XLSX.utils.encode_cell({ c: index, r: 0 });
        worksheet[cellAddress].s = header.s;
      });

      // Set custom column widths
      worksheet["!cols"] = [
        { wpx: 150 }, // Individual Name
        { wpx: 100 }, // Recipient ID
        { wpx: 150 }, // ICD-10 Diagnosis Code
        { wpx: 175 } // Funding Source
      ];

      // Create a new workbook
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

      // Write the workbook to a binary string
      const wbout = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "binary"
      });

      // Convert the binary string to a Blob
      const buf = new ArrayBuffer(wbout.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i < wbout.length; i++) {
        view[i] = wbout.charCodeAt(i) & 0xFF;
      }
      const blob = new Blob([buf], { type: "application/octet-stream" });

      // Save the file using FileSaver
      saveAs(blob, "Missing Client List.xlsx");
    };

    const handleChange = (file) => {
      setFile(file);
    };

    const processFile = () => {
      if (file !== null) {
        // alert(`Are sure you want to process the file: ${file.name}`);
        processImportFile(file).then((res) => {
          splitData(res);
        });
      } else {
        alert("Please select a file to proceed.");
      }
    };

    const splitData = (data) => {
      const mc = data.missing_clients ? data.missing_clients : [];
      setMissingClients(mc);
      const ms = data.missing_services ? data.missing_services : [];
      setMissingServices(ms);
      const ns = data.new_services ? data.new_services : [];
      setNewServives(ns);
      const us = data.updated_services ? data.updated_services : [];
      setUpdatedServives(us);
      if (ms.length > 0) {
        setReload(true);
      }

      if (mc.length === 0 && ms.length === 0 && ns.length === 0 && us.length === 0) {
        setNone(true);
      } else {
        setNone(false);
      }

    };

    const handleConfirmImport = (service) => {
      console.log(service);
      importService(service).then((res) => {
        cleanServiceData();
        processFile();
        setOpenConfirmModal(false);
      });
    };

    const resetServiceDetails = () => {
      setOpenConfirmModal(false);
      setStaffAllocationData({
        id: 0,
        recipient_id: "",
        serviceName: "",
        serviceCode: "",
        serviceStartDate: "",
        serviceEndDate: "",
        assignedStaff: []
      });
      setImportedData({});
    };

    const goBack = () => {
      setOpenConfirmModal(false);
      setOpenAllocationModal(true);
    };

    const onServiceAdd = (row) => {
      setServiceInitialData({
        ...serviceInitialData,
        serviceName: row.service_name,
        serviceCode: row.service_code,
        serviceUnitRate: parseFloat(parseFloat(row.unit_cost.replace("$", "")))
      });
      setOpenModal(true);
    };

    const handleNewServices = (service) => {

      const req = {
        name: service.serviceName,
        code: service.serviceCode,
        description: service.serviceDescription,
        unitRate: parseFloat(service.serviceUnitRate),
        unitLength: parseFloat(service.serviceUnitLength),
        isDegreeRequired: service.isDegreeRequired,
        isOverlappable: service.isOverlappable
      };

      createService(req).then((res) => {
        const filteredData = missingServices.filter(item => item.service_code !== service.serviceCode);
        setMissingServices(filteredData);
        setOpenModal(false);
        setServiceInitialData({
          serviceName: "",
          serviceCode: "",
          serviceDescription: "",
          serviceUnitRate: 0,
          serviceUnitLength: "",
          isDegreeRequired: false,
          isOverlappable: false
        });
      });

    };

    const onStaffAllocation = (service) => {
      setOpenAllocationModal(true);
      const serv = {
        id: service.id,
        recipient_id: service.recipient_id,
        serviceName: service.service_code,
        serviceCode: service.service_code,
        serviceStartDate: service.service_start_date,
        serviceEndDate: service.service_end_date,
        assignedStaff: []
      };
      setStaffAllocationData(serv);
      setImportedData(service);
    };

    const onStaffAllocationUpdate = (service, staff) => {
      setStaffAllocationData({
        ...staffAllocationData,
        assignedStaff: staff
      });
      setOpenAllocationModal(false);
      setOpenConfirmModal(true);
    };

    const cleanServiceData = () => {
      setOpenAllocationModal(false);
      setStaffAllocationData({
        id: 0,
        recipient_id: "",
        serviceName: "",
        serviceCode: "",
        serviceStartDate: "",
        serviceEndDate: "",
        assignedStaff: []
      });
    };

    return (
      <>
        <div className="page-content">
          <div className="row upload-wrapper">
            <div className="col-lg-10 col-sm-12">
              {file == null && (
                <center>
                  <FileUploader
                    multiple={false}
                    handleChange={handleChange}
                    name="file"
                    types={fileTypes}
                  />
                </center>
              )}
              {file &&
                (<>
                    <div className="row file-upload">
                      <div className="col-lg-10 col-sm-12 text">
                        <img src={"/images/file-text.svg"} alt="" className={"img"} />
                        Uploaded succesfully!
                      </div>
                    </div>
                  </>
                )}
            </div>

            <div className="col-lg-2 col-sm-12">
              <button className="button submit" onClick={processFile}>
                <span className="text">Process</span>
              </button>
            </div>
          </div>

          {none && (
            <div className="row import-none">
              <div className="col-lg-10 col-sm-8 text">
                <img src={"/images/check-grey.svg"} alt="" className={"img"} />
                No new changes has been detected.
              </div>
            </div>
          )}


          {missingClients.length > 0 && (
            <div className="row missing-clients">
              <div className="col-lg-10 col-sm-8 text">
                <img src={"/images/alert-triangle.svg"} alt="" className={"img"} />
                REQUIRES ACTION : Missing records of {missingClients.length} clients.
              </div>
              <div className="col-lg-2 col-sm-4">
              <span
                className="text"
                onClick={exportToExcel}
              >
                <img src={"/images/download-cloud.svg"} alt="" className={"img"} />
                Download
              </span>
              </div>
            </div>
          )}

          {(missingServices.length > 0 || (missingServices.length === 0 && reload)) && (
            <div className="row missing-services">
              <div className="col-lg-10 col-sm-8 text">
                <img src={"/images/tool.svg"} alt="" className={"img"} />
                {missingServices.length === 0 ? "Missing Services has been added. Please Reload the data"
                  : `REQUIRES ACTION : Missing records of ${missingServices.length} services.`}
              </div>
              <div className="col-lg-2 col-sm-4">
                {missingServices.length === 0 && reload &&
                  <span
                    className="text"
                    onClick={processFile}
                  >
                  <img src={"/images/refresh.svg"} alt="" className={"img"} />
                  Reload Data
                </span>
                }
              </div>
              <div className="col-lg-12 col-sm-12" style={{ marginTop: 20 }}>
                <DataGrid
                  data={missingServices}
                  columns={columns}
                  onRowClick={(rowInfo) => {
                    // this.onDetails(get(rowInfo.original, "id", ""));
                  }}
                  total={missingServices.length}
                  paginationLabel={""}
                  showPagination={false}
                  pageSize={missingServices.length}
                  pageNumber={1}
                />
              </div>
            </div>
          )}

        </div>

        {newServices.length > 0 && (
          <div className="page-content">
          <span
            className="col-lg-2 col-sm-12 title"
            style={{ cursor: "default" }}
          >
            New Services
          </span>
            <div className="row">
              <div className="col-12">
                <DataGrid
                  data={newServices}
                  columns={newServicesColumn}
                  onRowClick={(rowInfo) => {
                    // this.onDetails(get(rowInfo.original, "id", ""));
                  }}
                  total={newServices.length}
                  paginationLabel={""}
                  showPagination={false}
                  pageSize={newServices.length}
                  pageNumber={1}
                />
              </div>
            </div>
          </div>
        )}

        {updatedServices.length > 0 && (
          <div className="page-content">
          <span
            className="col-lg-2 col-sm-12 title"
            style={{ cursor: "default" }}
          >
            Updated Services
          </span>
            <div className="row">
              <div className="col-12">
                <DataGrid
                  data={updatedServices}
                  columns={updatedServicesColumn}
                  onRowClick={(rowInfo) => {
                    // this.onDetails(get(rowInfo.original, "id", ""));
                  }}
                  total={newServices.length}
                  paginationLabel={""}
                  showPagination={false}
                  pageSize={newServices.length}
                  pageNumber={1}
                />
              </div>
            </div>
          </div>
        )}

        {openModal && (
          <PopUpModal
            show={openModal}
            onClose={() => {
              setOpenModal(false);
            }}
            title={"Create New Service"}
            icon={"/images/clipboard.png"}
            size={"lg"}
          >
            <ServiceCreateUpdate
              onSubmit={handleNewServices}
              initialValues={serviceInitialData}
              isEdit={false}
              onCloseModal={() => {
                setOpenModal(false);
              }}
              isOverlappable={serviceInitialData.isOverlappable}
              onOverlapable={(val) => {
                setServiceInitialData(
                  { ...serviceInitialData, isOverlappable: val }
                );
              }}
              isDegreeRequired={serviceInitialData.isDegreeRequired}
              onDegreeRequired={(value) => {
                setServiceInitialData(
                  { ...serviceInitialData, isDegreeRequired: value }
                );
              }}

            />


          </PopUpModal>
        )}

        {openAllocationModal && (
          <PopUpModal
            show={openAllocationModal}
            onClose={() => {
              setOpenAllocationModal(false);
            }}
            title={"Staff Allocation"}
            icon={"/images/staff-allocation.svg"}
            size={"lg"}
          >
            <StaffAllocation
              service={staffAllocationData}
              usersList={usersList}
              onUpdate={onStaffAllocationUpdate}
              onCloseModal={() => {
                setOpenAllocationModal(false);
              }}
            />
          </PopUpModal>
        )}

        {openConfirmModal && (
          <PopUpModal
            show={openConfirmModal}
            onClose={() => {
              resetServiceDetails();
            }}
            title={"Confirm Import Service"}
            icon={"/images/clipboard.png"}
            size={"lg"}
          >
            <ConfirmNewService
              service={importedData}
              allServices={allServices}
              staffAllocationData={staffAllocationData}
              onConfirm={handleConfirmImport}
              onCloseModal={resetServiceDetails}
              goBack={goBack} />
          </PopUpModal>
        )}


      </>);
  }
;
