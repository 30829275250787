import React, { useState, useEffect } from "react";
import DataGrid from "../../shared/data-grid/data-grid";
import Table from "../../shared/data-grid/table";
import { getAuthData } from "../../../util/auth";
import { withRouter } from "react-router-dom";
import moment from "moment";
import "./staffReports.scss";
import { generateReport, getStaffPayReport } from "../../../api/report";
import {
  DATEFILTEROPTIONS,
  REPORT_TYPES
} from "../../../constants/appConstants";
import { isMobileDevice } from "../../../util/deviceType";
import StaffPayCard from "../../staff/staffPayCard";
import { map } from "lodash";
import Pagination from "../../shared/data-grid/pagination/pagination";
import DatePickerComponent from "../../shared/date-picker/datePickerComponent";

function StaffReports() {
  const [value, setValue] = useState([null, null]);
  const [state, setState] = useState({
    value: [null, null],
    reportType: REPORT_TYPES.STAFF_PAY,
    reviewStatus: "all",
    pageNumber: 0,
    pageSize: 10,
    dateRangeOption: DATEFILTEROPTIONS.CREATED,
    fromDate: new Date(new Date().setDate(new Date().getDate() - 7)).setHours(
      0,
      0,
      0,
      0
    ),
    toDate: new Date().setHours(0, 0, 0, 0),
    clientId: -1,
    serviceId: -1,
    staffId: "",
    showCalender: false,
    showChart: false,
    data: [],
    recordCount: 0,
    stats: null,
    buttonBackground: "rgb(183, 182, 182)"
  });

  useEffect(() => {
    const staffId = JSON.parse(getAuthData()).userId;
    if (staffId != null) {
      setState((obj) => {
        return { ...obj, staffId: staffId };
      });
    }
  }, []);

  useEffect(() => {
    onGenerateReport(0, 10);
  }, [state.staffId, state.fromDate, state.toDate]);

  function onGenerateReport(offset, limit) {
    const fromDate = moment.utc(state.fromDate).format("YYYY-MM-DD HH:mm:ss");
    const toDate = moment.utc(state.toDate).format("YYYY-MM-DD HH:mm:ss");

    console.log(">>> state.fromDate: ", fromDate);
    console.log(">>> state.toDate: ", toDate);


    if (state.fromDate !== null && state.toDate !== null) {
      getStaffPayReport(
        state.reportType,
        state.reviewStatus,
        fromDate,
        toDate,
        state.dateRangeOption,
        state.clientId,
        state.staffId,
        state.serviceId,
        offset,
        limit
      )
        .then((res) => {
          if (res.data.data !== null) {
            setState((values) => {
              return {
                ...values,
                data: res.data.data,
                stats: res.data.stats,
                recordCount: res.recordCount
              };
            });
          } else {
            setState({ ...state, data: [] });
          }
        })
        .catch((e) => {
          console.log("staff_Pay error", e);
        });
    }
  }

  function onPageChange(pageNumber) {
    let limit = parseInt(state.pageSize);
    let offset = limit * pageNumber;
    onGenerateReport(offset, limit);
    setState((values) => {
      return { ...values, pageNumber: pageNumber };
    });
  }

  function onPageSizeChange(pageSize) {
    setState({
      ...state,
      pageNumber: 0,
      pageSize: pageSize
    });
    onGenerateReport(0, parseInt(pageSize));
  }

  const onFromDateChange = (date) => {
    setState((values) => {
      return { ...values, fromDate: date };
    });
  };

  const onToDateChange = (date) => {
    setState((values) => {
      return { ...values, toDate: date };
    });
  };

  return (
    <div className="table-layout">
      <div className="row report-type-selector">
        <div className="col-lg-2 col-md-2 col-sm-3">
          <div className="page-title">Reports</div>
        </div>
      </div>

      <div className="body-container" style={{ margin: "20px 0" }}>
        <div className="list-container">
          <div>
            <div className="list-header">Staff Pay Summary</div>
          </div>
        </div>

        <hr className="body-line" />
        <div className="data-grid-container">
          <div>

            <div className="row" style={{ marginBottom: 0 }}>
              <div className="col-lg-2 col-sm-12 input-box" style={{ marginBottom: 0 }}>
                <div className="label">From Date</div>
                <DatePickerComponent
                  selected={state.fromDate}
                  onChange={onFromDateChange}
                  dateFormat={"MM/DD/YYYY"}
                  className={"box text-box calendar-icon"}
                  maxDate={new Date()}
                  style={{ marginBottom: 0 }}
                />
              </div>
              <div className="col-lg-2 col-sm-12 input-box" style={{ marginBottom: 0 }}>
                <div className="label">To Date</div>
                <DatePickerComponent
                  selected={state.toDate}
                  onChange={onToDateChange}
                  dateFormat={"MM/DD/YYYY"}
                  className={"box text-box calendar-icon"}
                  minDate={state.fromDate}
                  maxDate={new Date()}
                  style={{ marginBottom: 0 }}
                />
              </div>
            </div>

            {isMobileDevice() && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button
                  className="  col-sm-3  button submit"
                  style={{
                    height: 38,
                    marginTop: 5,
                    width: "50%",
                    backgroundColor: state.buttonBackground
                  }}
                  onClick={() => {
                    onGenerateReport(0, state.pageSize);
                  }}
                >
                  VIEW
                </button>
              </div>
            )}
          </div>
          {state.stats !== null && (
            <div style={{ margin: 10 }}>
              <div className=" pay-label">
                <p className="text">
                  {`Total to pay for this week: ${state.stats.totalNet.toFixed(
                    2
                  )}`}
                </p>
              </div>
            </div>
          )}
          {!isMobileDevice() &&
            (state.data.length > 0 ? (
              <Table
                data={state.data}
                showPagination={true}
                pageNumber={state.pageNumber}
                pageSize={state.pageSize}
                onPageSizeChange={(pageSize) => onPageSizeChange(pageSize)}
                onPageNumberChange={(pageNumber) => onPageChange(pageNumber)}
                paginationLabel={"Staff pay per page"}
                total={state.recordCount}
              />
            ) : (
              <DataGrid
                data={[]}
                columns={[]}
                onRowClick={(rowInfo) => {
                }}
                paginationLabel={"Staff pay per page"}
                showPagination={false}
              />
            ))}
          {isMobileDevice() && state.data.length > 0 && (
            <div className="row" style={{ margin: 10 }}>
              {map(state.data, (data, index) => {
                return <StaffPayCard key={`staff-${index}`} data={data} />;
              })}
              <div className="row pagination pagination-align">
                <div className="col-12">
                  <Pagination
                    onPageSizeChange={onPageSizeChange}
                    label={"Staff pay per page"}
                    onPageNumberChange={onPageChange}
                    total={state.recordCount}
                    pageSize={state.pageSize}
                    pageNumber={state.pageNumber}
                  />
                </div>
              </div>
            </div>
          )}

          {isMobileDevice() && state.data.length === 0 && (
            <div className="row">
              <div className="col-12 text-center" style={{ margin: 10 }}>
                <span>No rows found </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
    ;
}

export default withRouter(StaffReports);
