import * as React from "react";
import CreateClientForm from "./create-client-form/createClientForm";
import "./createClient.scss";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  createClient,
  updateClient,
} from "../../../redux/clients/clientActions";
import { getQueryParameter } from "../../../util/url";
import {
  clone,
  get,
  isEmpty,
  isEqual,
  isNull,
  isUndefined,
  remove,
} from "lodash";
import { USER_TYPES } from "../../../constants/user";
import { getAllUsersByType, getSCList } from "../../../api/user";
import { NotificationManager } from "react-notifications";
import {
  ALERT_TYPES,
  COUNTIES,
  DATE_FORMAT,
  FUNDING_SOURCE_TYPE,
  WAIVER_TYPES,
} from "../../../constants/appConstants";
import { getClientById } from "../../../api/client";
import { reset } from "redux-form";
import moment from "moment";

class CreateClient extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clientInfoAuthorized: false,
      isServiceInfoAuthorized: false,
      isDegreeRequired: false,
      isConfirmedDates: false,
      isAllowedNegativeUnits: false,
      initialValues: null,
      isEditMode: false,
      selectedClientId: "",
      supportCoordinatorsList: [],
      behavioralSpecialistsList: [],
      countryList: [],
      waiwer: "",
      fundingSource: "",
      supportCoordinator: 0,
      behavioralSpecialist: 0,
      country: "",
      routeToClient: false,
      fileList: [],
      serviceFileList: [],
      phoneNumber: "",
      latitude: 43.0,
      longitude: -89.5,
      billingState: "PA",
      billingCountry: "USA",
      status: 4,
    };
  }

  componentDidMount() {
    getAllUsersByType(USER_TYPES.BEHAVIORAL_SPECIALIST, "").then((res) => {
      this.setState({
        behavioralSpecialistsList: res,
      });
    });
    this.setState({ countryList: COUNTIES });
    getSCList().then((res) => {
      this.setState({
        supportCoordinatorsList: res,
      });
    });

    const clientId = getQueryParameter("id");
    if (!isEmpty(clientId) && !isUndefined(clientId)) {
      getClientById(clientId).then((res) => {
        this.setState(
          {
            waiwer: this.checkOtherWaiverType(res.waiwer),
            fundingSource: this.checkOtherFundingSourceType(res.fundingSource),
          },
          () => {
            const client = this.mapClient(res);
            client.dob = client.dob
              ? new Date(moment(client.dob).utc().format(DATE_FORMAT))
              : null;
            this.setState({
              initialValues: client,
              isEditMode: true,
              selectedClientId: clientId,
              isAllowedNegativeUnits: res.serviceIsNegetiveUnitsAllowed,
              isServiceInfoAuthorized: res.serviceIsAuthorized,
              isDegreeRequired: res.isDegreeRequired,
              isConfirmedDates: res.isDatesConfirmed,
              fundingSource: res.fundingSource,
              supportCoordinator: res.supportCoordinatorId,
              behavioralSpecialist: res.behavioralSpecialistId,
              country: res.country,
              fileList: res.files ? res.files : [],
              phoneNumber: res.phone,
              longitude: res.longitude,
              latitude: res.latitude,
              billingState: client.billingState,
              status: res.status,
            });
          }
        );
      });
    }
  }

  getAllUsersByType = (type, searchParams) => {
    this.props.dispatch(getAllUsersByType(type, searchParams));
  };

  createClient = (values, routeToClient) => {
    this.setState({
      routeToClient: routeToClient,
    });
    values["status"] = this.state.status;
    let client = this.mapClient(values);
    client.serviceStartDate = client.serviceStartDate
      .toISOString()
      .slice(0, 10);
    client.serviceEndDate = client.serviceEndDate.toISOString().slice(0, 10);
    client.dob = client.dob
      ? moment(new Date(client.dob)).format("YYYY-MM-DD")
      : null;
    client.files = this.state.fileList;
    client.longitude = this.state.longitude;
    client.latitude = this.state.latitude;
    client.billingState = this.state.billingState;
    if (!this.state.isEditMode) {
      this.props.dispatch(createClient(client));
    } else {
      client["clientId"] = parseInt(this.state.selectedClientId);
      this.props.dispatch(updateClient(client));
    }
  };

  componentWillReceiveProps = (nextProps) => {
    const { history } = this.props;
    if (
      !isNull(get(nextProps, "createClientSuccess")) &&
      !isEqual(nextProps.createClientSuccess, this.props.createClientSuccess)
    ) {
      this.createNotification(ALERT_TYPES.SUCCESS, "Client create successful");
      if (this.state.routeToClient) {
        history.push(
          `/admin/client-details?id=${nextProps.createClientSuccess.clientId}`
        );
      } else {
        this.props.dispatch(reset("addClient"));
      }
    }

    if (
      !isNull(get(nextProps, "createClientFail")) &&
      !isEqual(nextProps.createClientFail, this.props.createClientFail)
    ) {
      this.createNotification(ALERT_TYPES.ERROR, "Client create failed");
    }

    if (
      !isNull(get(nextProps, "updateClientSuccess")) &&
      !isEqual(nextProps.updateClientSuccess, this.props.updateClientSuccess)
    ) {
      this.createNotification(ALERT_TYPES.SUCCESS, "Client update successful");
      history.push(`/admin/client-details?id=${this.state.selectedClientId}`);
    }

    if (
      !isNull(get(nextProps, "updateClientFail")) &&
      !isEqual(nextProps.updateClientFail, this.props.updateClientFail)
    ) {
      this.createNotification(ALERT_TYPES.ERROR, "Client update failed");
    }
  };

  createNotification(alertType, alertMessage) {
    switch (alertType) {
      case ALERT_TYPES.INFO:
        NotificationManager.info(alertMessage, "", 2000);
        break;
      case ALERT_TYPES.SUCCESS:
        NotificationManager.success(alertMessage, "", 2000);
        break;
      case ALERT_TYPES.WARNING:
        NotificationManager.warning(alertMessage, "", 2000);
        break;
      case ALERT_TYPES.ERROR:
        NotificationManager.error(alertMessage, "", 2000);
        break;
      default:
        break;
    }
  }

  onWaiwerChange = (event) => {
    const type = event.target.value;
    this.setState({
      waiwer: type,
    });
  };

  onFundingSourceChange = (event) => {
    const type = event.target.value;
    this.setState({
      fundingSource: type,
    });
  };

  onSupportCoordinatorSelect = (value) => {
    this.setState({
      supportCoordinator: parseInt(value),
    });
  };

  onBehavioralSpecialistSelect = (value) => {
    this.setState({ behavioralSpecialist: parseInt(value) });
  };
  onCountrySelect = (value) => {
    this.setState({
      country: value,
    });
  };
  onBillingStateChange = (event) => {
    const type = event.target.value;
    this.setState({
      billingState: type,
    });
  };

  mapClient = (clientData) => {
    let client = {
      firstName: get(clientData, "firstName", ""),
      lastName: get(clientData, "lastName", ""),
      middleName: get(clientData, "middleName", ""),
      mci: get(clientData, "mci", ""),
      mpi: get(clientData, "mpi", ""),
      diagnosticCode: get(clientData, "diagnosticCode", ""),
      supportCoordinatorId: this.state.supportCoordinator,
      behavioralSpecialistId: this.state.behavioralSpecialist,
      country: this.state.country,
      goalsOutcomes: get(clientData, "goalsOutcomes", ""),
      phone: get(clientData, "phone", ""),
      familyContactEmail: get(clientData, "familyContactEmail", ""),
      waiwer:
        this.state.waiwer === "Other" ? clientData.waiwer : this.state.waiwer,
      fundingSource:
        this.state.fundingSource === "Other"
          ? clientData.fundingSource
          : this.state.fundingSource,
      isAuthorized: this.state.clientInfoAuthorized,
      billingStreet: get(clientData, "billingStreet", ""),
      billingCity: get(clientData, "billingCity", ""),
      billingState: get(clientData, "billingState", ""),
      billingZIP: get(clientData, "billingZIP", ""),
      billingCountry: get(clientData, "billingCountry", ""),
      serviceName: get(clientData, "serviceName", ""),
      serviceAccount: get(clientData, "serviceAccount", ""),
      serviceDescription: get(clientData, "serviceDescription", ""),
      serviceUnitRate: parseInt(get(clientData, "serviceUnitRate", 0)),
      serviceUnitLength: parseInt(get(clientData, "serviceUnitLength", 0)),
      dob: get(clientData, "dob", null),
      memo: get(clientData, "memo", ""),
      serviceStartDate: new Date(
        get(clientData, "serviceStartDate", new Date())
      ),
      serviceEndDate: new Date(get(clientData, "serviceEndDate", new Date())),
      serviceUnitsAuthorized: parseInt(
        get(clientData, "serviceUnitsAuthorized", "")
      ),
      serviceIsNegetiveUnitsAllowed: this.state.isAllowedNegativeUnits,
      serviceIsAuthorized: this.state.isServiceInfoAuthorized,
      isDegreeRequired: this.state.isDegreeRequired,
      isDatesConfirmed: this.state.isConfirmedDates,
      deleted: false,
      status: parseInt(this.state.status),
    };
    return client;
  };

  checkOtherWaiverType = (waiver) => {
    let isSetWaiverType = false;
    WAIVER_TYPES.map((w) => {
      if (
        waiver &&
        w.type.toLowerCase() === waiver.toLowerCase() &&
        waiver.toLowerCase() !== "other"
      ) {
        isSetWaiverType = true;
      }
    });
    if (!isSetWaiverType) {
      return "Other";
    } else {
      return waiver;
    }
  };

  checkOtherFundingSourceType = (fundingSource) => {
    let isSetFundingSource = false;
    FUNDING_SOURCE_TYPE.map((w) => {
      if (
        fundingSource &&
        w.type.toLowerCase() === fundingSource.toLowerCase() &&
        fundingSource.toLowerCase() !== "other"
      ) {
        isSetFundingSource = true;
      }
    });
    if (!isSetFundingSource) {
      return "Other";
    } else {
      return fundingSource;
    }
  };

  render() {
    return (
      <div className="client-client">
        <div className="row">
          <div
            className="row col-lg-2 col-md-2 col-sm-4"
            onClick={() => {
              this.props.history.goBack();
            }}
          >
            <img src="./images/chevron-left-green.svg" alt="" />
            <span
              style={{
                fontFamily: "Muli",
                color: "#333333",
                fontSize: 15,
                padding: 10,
              }}
            >
              Back
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="page-title">
              {!this.state.isEditMode ? "Create New Client" : "Edit Client"}
            </div>
          </div>
        </div>
        <div className="row">
          <CreateClientForm
            onSubmit={this.createClient}
            initialValues={this.state.initialValues}
            onWaiwerChange={this.onWaiwerChange}
            waiwer={this.state.waiwer}
            onFundingSourceChange={this.onFundingSourceChange}
            onSupportCoordinatorSelect={this.onSupportCoordinatorSelect}
            onBehavioralSpecialistSelect={this.onBehavioralSpecialistSelect}
            supportCoordinatorsList={this.state.supportCoordinatorsList}
            behavioralSpecialistsList={this.state.behavioralSpecialistsList}
            country={this.state.country}
            countryList={this.state.countryList}
            onCountrySelect={this.onCountrySelect}
            fundingSource={this.state.fundingSource}
            behavioralSpecialist={this.state.behavioralSpecialist}
            supportCoordinator={this.state.supportCoordinator}
            clientInfoAuthorized={this.state.clientInfoAuthorized}
            isConfirmedDates={this.state.isConfirmedDates}
            isDegreeRequired={this.state.isDegreeRequired}
            isAllowedNegativeUnits={this.state.isAllowedNegativeUnits}
            isServiceInfoAuthorized={this.state.isServiceInfoAuthorized}
            phoneNumber={this.state.phoneNumber}
            onClientInfoAuthorized={(isAuthorized) => {
              this.setState({ clientInfoAuthorized: isAuthorized });
            }}
            onConfirmDates={(value) => {
              this.setState({ isConfirmedDates: value });
            }}
            AllowNegativeUnits={(value) => {
              this.setState({ isAllowedNegativeUnits: value });
            }}
            onDegreeRequired={(value) => {
              this.setState({ isDegreeRequired: value });
            }}
            onServiceInfoAuthorized={(value) => {
              this.setState({ isServiceInfoAuthorized: value });
            }}
            fileList={this.state.fileList}
            onFileUpload={(value, success) => {
              if (success) {
                let files = clone(this.state.fileList);
                files.push(value);
                this.createNotification(ALERT_TYPES.INFO, "File uploaded.");
                this.setState({
                  fileList: files,
                });
              } else {
                this.createNotification(
                  ALERT_TYPES.WARNING,
                  "File upload fail."
                );
              }
            }}
            onRemoveImage={(documentKey) => {
              let files = clone(this.state.fileList);
              remove(files, function (o) {
                return o.documentKey == documentKey;
              });
              this.setState({
                fileList: files,
              });
            }}
            onLatLongChange={(coords) => {
              this.setState({
                latitude: coords.lat,
                longitude: coords.lng,
              });
            }}
            latitude={this.state.latitude}
            longitude={this.state.longitude}
            billingState={this.state.billingState}
            billingCountry={this.state.billingCountry}
            onBillingStateChange={this.onBillingStateChange}
            clientStatus={this.state.status}
            onStatusChange={(status) => {
              this.setState({ status: status });
            }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  createClientSuccess: state.client.createSuccess,
  createClientFail: state.client.createFail,
  updateClientSuccess: state.client.updateSuccess,
  updateClientFail: state.client.updateFail,
  clients: state.client.getAllSuccess,
});

export default withRouter(connect(mapStateToProps)(CreateClient));
