import { ServiceSummary } from "../../reports/report-views/serviceSummary/serviceSummary";
import staffReports from "../../reports/report-views/staffReports";

export default [
  {
    name: "STAFF PAY SUMMARY",
    url: "staff/reports",
    component: staffReports,
    isSideBarNavigation: true,
    authUsers: [2],
    icon: "./images/dollar-sign.svg",
    selectedIcon: "./images/dollar-sign-selected.svg",
  },
  {
    name: "SERVICE SUMMARY",
    url: "service/reports",
    component: ServiceSummary,
    isSideBarNavigation: true,
    authUsers: [2],
    icon: "./images/users.svg",
    selectedIcon: "./images/users-selected.svg",
  },
];
