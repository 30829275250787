import * as React from 'react';
import ReactTable from 'react-table';
import Pagination from './pagination/pagination';
import './data-grid.scss';

class DataGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      pageSize: 10
    };
  }

  onPageSizeChange = (pageSize) => {
    this.setState({
      pageSize: parseInt(pageSize)
    });
    this.props.onPageSizeChange(pageSize);
  };

  render() {
    return (
      <div>
        <div>
          <ReactTable
            getTdProps={(state, rowInfo) => {
              return {
                onClick: () => {
                  this.props.onRowClick(rowInfo);
                },
                style: {
                  backgroundColor:
                    rowInfo.index === 0 || rowInfo.index % 2 === 0 ? '#FAFAFA' : '#FFFFFF'
                }
              };
            }}
            data={this.props.data}
            columns={this.props.columns}
            defaultPageSize={2}
            pageSize={this.props.data.length}
            showPagination={false}
            resizable={false}
            sortable={false}
            getNoDataProps={() => {
              return {
                style: {
                  backgroundColor: '#FAFBFC',
                  margin: 10,
                  // height: '100%',
                  width: '100%',
                  color: '#B0B9C9',
                  fontSize: 19,
                  textAlign: 'center',
                  fontFamily: 'Muli',
                  zIndex: 0
                }
              };
            }}
          />
        </div>
        <div className="row pagination pagination-align">
          <div className="col-12">
            {/* todo - refactor this pagination visibility option */}
            {this.props.showPagination && (
              <Pagination
                onPageSizeChange={this.onPageSizeChange}
                label={this.props.paginationLabel}
                onPageNumberChange={this.props.onPageNumberChange}
                total={this.props.total}
                pageNumber={this.props.pageNumber}
                pageSize={this.props.pageSize}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default DataGrid;
