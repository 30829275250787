import React from "react";
import {GoogleApiWrapper, Map, Marker} from "google-maps-react";
import {geoCodeAddress} from "../../../api/maps";
import {GOOGLE} from "../../../constants/appConstants";
import {get, isEmpty} from "lodash";

class MapContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lat: 0,
            lng: 0,
        };
    }

    componentDidMount() {
        if (this.props.address) {
            const address = encodeURI(this.props.address);
            geoCodeAddress(address)
                .then((res) => {
                    const geoLocation = !isEmpty(res)
                        ? get(res[0], "geometry.location", null)
                        : null;
                    if (geoLocation) {
                        this.setState({
                            lat: geoLocation.lat,
                            lng: geoLocation.lng,
                        });
                    }
                })
                .catch((err) => {
                    console.log("Geo coding error ", err);
                });
        } else if (this.props.coordinates) {
            this.setState({
                lat: this.props.coordinates.lat,
                lng: this.props.coordinates.lng,
            });
        }
    }

    render() {
        return (
            <Map
                google={this.props.google}
                zoom={8}
                initialCenter={{
                    lat: 43.0,
                    lng: -89.5,
                }}
                center={{
                    lat: this.state.lat,
                    lng: this.state.lng,
                }}
                zoom={5}
                onClick={() => {
                }}
                disableDefaultUI={true}
            >
                <Marker
                    name={"Your position"}
                    position={{lat: this.state.lat, lng: this.state.lng}}
                    icon={{url: "./images/house.png"}}
                />
            </Map>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: GOOGLE.MAPS_API_KEY,
})(MapContainer);
