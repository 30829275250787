import React from "react";
import {formatDateTime} from "../../../util/util";
import * as classNames from "classnames";

class StartVisit extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const {client, service} = this.props
        return (
            <div>
                <div
                    className={classNames("modal fade", {show: this.props.show})}
                    id={this.props.id ? this.props.id : 'exampleModal'}
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div className={"modal-dialog modal-xl"} role="document">
                        <div className="modal-content" style={{padding: 20}}>
                            <div className="row col-12 modal-header">
                                <div className="col-lg-8 col-sm-12 offset-lg-2 offset-sm-0  text-center">
                                    <h5 className="col-12 modal-title text-center" id="exampleModalLabel">
                                        <span className="my-auto">
                                          <img src={"/images/lock.svg"} alt="" style={{marginRight: 10}}/>
                                        </span>
                                        <span style={{marginLeft: "5px"}}>Start Visit</span>
                                    </h5>
                                </div>
                            </div>
                            <div className="modal-body">
                                <div className='row'>
                                    <div className="col-lg-12 col-sm-12 visit-notes-details">
                                        <div className="row">
                                            <div className="col-lg-3 col-sm-6 text-center">
                                                <img src="./images/user.svg" alt="" style={{marginRight: 2}}/>
                                                <span className="label">Client Name</span>
                                                <div className="value">
                                                    {client.lastName + " " + client.firstName + " " + client.middleName}
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-sm-6 text-center">
                                                <img src="./images/user.svg" alt="" style={{marginRight: 2}}/>
                                                <span className="label">Service Name</span>
                                                <div className="value">
                                                    {service.serviceName}
                                                </div>
                                            </div>
                                            {/*<div className="col-lg-3 col-sm-6 text-center">*/}
                                            {/*    <img src="./images/user.svg" alt="" style={{marginRight: 2}}/>*/}
                                            {/*    <span className="label">Service Code</span>*/}
                                            {/*    <div className="value">*/}
                                            {/*        {service.serviceCode}*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                            <div className="col-lg-3 col-sm-6 text-center">
                                                <img src="./images/user.svg" alt="" style={{marginRight: 2}}/>
                                                <span className="label">Service Unit Length</span>
                                                <div className="value">
                                                    {service.serviceUnitLength + " Hrs"}
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-sm-6 text-center">
                                                <img src="./images/calendar-green.svg" alt=""/>
                                                <span className="label">Clock In Time</span>
                                                <div className="value">
                                                    {formatDateTime(new Date(), "MMMM Do YYYY, h:mm a")}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/*<div className="col-lg-12 col-sm-12 abandon-visit">*/}
                                    {/*    <div className='col-10 offset-1 text-left abandon-date-time'>*/}
                                    {/*        <img src="./images/calendar.svg" alt="" className='calendar'/>*/}
                                    {/*        <span className="label" style={{marginRight: 10}}>Clock In Time</span>*/}
                                    {/*        <span className='date-text'>{moment().format("LLLL")}</span>*/}
                                    {/*    </div>*/}
                                    {/*    <div className="col-lg-3 col-sm-6 abandon-visit">*/}
                                    {/*<img*/}
                                    {/*    src="./images/calendar-green.svg"*/}
                                    {/*    alt=""*/}
                                    {/*    style={{marginRight: 2}}*/}
                                    {/*/>*/}
                                    {/*<span className="label">Clock In Time</span>*/}
                                    {/*<div className="value">*/}
                                    {/*    {formatDateTime(new Date(), "MMMM Do YYYY, h:mm a")}*/}
                                    {/*</div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <div className="col-12 notes-content row">
                                        <span className="col-12 label text-left">Notes</span>
                                        <textarea className={"notes"}
                                                  placeholder={"Add note"}
                                                  value={this.state.notes} onChange={(e) => {
                                            this.setState({notes: e.target.value})
                                        }}></textarea>
                                    </div>
                                    <div className="col-lg-6 offset-lg-3 col-sm-12 offset-sm-0">
                                        <div className='row' style={{marginBottom: 20, marginTop: 20}}>
                                            <div className="col-6">
                                                <button
                                                    className="button basic"
                                                    onClick={(event) => {
                                                        this.props.onCloseModal();
                                                    }}
                                                >
                                                    CANCEL
                                                </button>
                                            </div>
                                            <div className={"col-6"}>
                                                <button className='button submit' onClick={() => {
                                                    this.props.handleSubmit(this.state.notes)
                                                }}>
                                                    <span
                                                        className='text'>START VISIT</span>
                                                </button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default StartVisit;
