import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./datePickerComponent.scss";

class TimePickerComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: new Date(),
        };
    }

    handleChange = (date) => {
        this.setState({
            startDate: date,
        });
        this.props.onChange(date);
    };

    render() {
        const currentDate = new Date();
        currentDate.setUTCHours(0, 0, 0, 0);
        return (
            <DatePicker
                selected={this.props.selected}
                onChange={this.props.onChange}
                placeholderText={"Time"}
                className={this.props.className}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={1}
                timeCaption="Time"
                dateFormat="h:mm aa"
                minDate={this.props.minDate}
                maxDate={this.props.maxDate}
                maxTime={this.props.maxTime}
                minTime={this.props.minTime}
                disabled={this.props.disabled}
            />
        );
    }
}

export default TimePickerComponent;
