import React from "react";
import "./confirmation-box.scss";

class ConfirmationBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div>
                {/* <div className="row modal-description-body">
          <span className="col-sm-12">{this.props.description}</span>
        </div> */}
                <div
                    className="modal-footer confirmation-footer"
                    style={{paddingTop: 10}}
                >
                    <div
                        className="col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-sm-10 offset-sm-1 confirmation-footer">
                        <div className="row confirmation-footer">
                            <div className="col-6">
                                <button
                                    type="button"
                                    className="button submit"
                                    onClick={(event) => {
                                        this.props.onConfirm();
                                    }}
                                >
                                    YES
                                </button>
                            </div>
                            <div className="col-6">
                                <button
                                    className="button basic"
                                    onClick={(event) => {
                                        this.props.onCloseModal();
                                    }}
                                >
                                    NO
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ConfirmationBox;
