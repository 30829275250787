import React from 'react';
import DataGrid from "../../shared/data-grid/data-grid";
import {map, uniqBy} from 'lodash';
import moment from 'moment';
import {DATE_FORMAT, DATE_TIME_FORMAT, DATEFILTEROPTIONS, REPORT_TYPES} from '../../../constants/appConstants';
import ReportCell from '../../shared/data-grid/custom-cells/report-cell';
import Pagination from "../../shared/data-grid/pagination/pagination";
import DataTableReportHeader from "../../shared/data-grid/custom-headers/report-header";

class StaffAllocationReportView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            reportData: [],
            isClientView : true,
        }
    }

    componentWillReceiveProps(nextProps) {
        this.mapRecords(nextProps.reportData, nextProps.reportOption)
    }

    componentDidMount() {
        this.mapRecords(this.props.reportData, this.props.reportOption)
    }

    mapRecords(reports, reportOption) {
        const reportData = [];
        const option = reportOption === DATEFILTEROPTIONS.CLIENT_ALLOCATION ? "serviceRequestId" : reportOption === DATEFILTEROPTIONS.STAFF_ALLOCATION ? "staffId" : "serviceRequestId"
        const ids = this.getUniqueIds(reports, option);
        map(ids, id => {
            const clientData = []
            map(reports, (data => {
                if (data[option] === id) {
                    const report = {
                        staffName: data.staffName,
                        clientName: data.clientName,
                        serviceName: data.serviceName,
                        serviceRequestId: data.serviceRequestId,
                        beginDate: moment(data.serviceStartDate).utc().format(DATE_FORMAT),
                        endDate: moment(data.serviceEndDate).utc().format(DATE_FORMAT),
                    }
                    clientData.push(report)
                }
            }));
            reportData.push(clientData)    
        })
        option==='staffId'? 
        reportData.sort((a, b) => a[0].staffName.localeCompare(b[0].staffName)):
        reportData.sort((a, b) => a[0].clientName.localeCompare(b[0].clientName));
        reportData.forEach(element => {
            element.sort(
                (a, b) => {
                    return a.serviceName.localeCompare(b.serviceName)==0?
                    a.clientName.localeCompare(b.clientName):
                    a.serviceName.localeCompare(b.serviceName) })
        });
        this.setState({
            reportData: reportData,
            isClientView: option === "serviceRequestId",
        })
    }


    getUniqueIds = (reportData, option) => {
        const clientIds = [];
        map(reportData, (data => {
                clientIds.push(data[option])
            })
        );
        const uClientIds = uniqBy(clientIds, Math.floor)
        return uClientIds;
    }


    render() {
        const clientColumns = [
            {
                Header: () => <DataTableReportHeader header={"Staff Name"} fontSize={12}/>,
                accessor: "staffName",
                Cell: (row) => {
                    return <ReportCell value={row.value} index={row.index}/>;
                },
            }
        ];
        const staffColumns = [
            {
                Header: () => <DataTableReportHeader header={"Service Name"} fontSize={12}/>,
                accessor: "serviceName",
                Cell: (row) => {
                    return <ReportCell value={row.value} index={row.index}/>;
                },
            },
            {
                Header: () => <DataTableReportHeader header={"Client Name"} fontSize={12}/>,
                accessor: "clientName",
                Cell: (row) => {
                    return <ReportCell value={row.value} index={row.index}/>;
                },
            }, {
                Header: () => <DataTableReportHeader header={"Service Begin Date"} fontSize={12}/>,
                accessor: "beginDate",
                Cell: (row) => {
                    return <ReportCell value={row.value} index={row.index}/>;
                },
            },
            {
                Header: () => <DataTableReportHeader header={"Service End Date"} fontSize={12}/>,
                accessor: "endDate",
                Cell: (row) => {
                    return <ReportCell value={row.value} index={row.index}/>;
                },
            },
        ];
        return (
            <div>
                {
                    this.state.reportData.length > 0 ? map(this.state.reportData, (data, index) => {
                            return (
                                <div key={index}>
                                    {
                                        data.length > 0 && this.state.isClientView &&
                                        <div className={"report-details-header col-sm-12 col-md-12 col-lg-12 row"}>
                                            <div className="col-sm-6 col-md-4 col-lg-3 title-value-container">
                                                <span className={"title-text"}>{`Client Name : `}</span>
                                                <span className={"value-text"}>{`${data[0].clientName}`}</span>
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-3 title-value-container">
                                                <span className={"title-text"}>{`Service Name : `}</span>
                                                <span className={"value-text"}>{`${data[0].serviceName}`}</span>
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-3 title-value-container">
                                                <span className={"title-text"}>{`Service Begin Date : `}</span>
                                                <span className={"value-text"}>{`${data[0].beginDate}`}</span>
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-3 title-value-container">
                                                <span className={"title-text"}>{`Service End Date : `}</span>
                                                <span className={"value-text"}>{`${data[0].endDate}`}</span>
                                            </div>
                                        </div>

                                    }
                                    {
                                        data.length > 0 && !this.state.isClientView &&
                                        <div className={"report-details-header col-sm-12 col-md-12 col-lg-12 row"}>
                                            <div className="col-sm-6 col-md-4 col-lg-3 title-value-container">
                                                <span className={"title-text"}>{`Staff Name : `}</span>
                                                <span className={"value-text"}>{`${data[0].staffName}`}</span>
                                            </div>
                                        </div>

                                    }
                                    <DataGrid
                                        data={data}
                                        columns={this.state.isClientView ? clientColumns : staffColumns}
                                        onRowClick={(rowInfo) => {
                                            // this.onDetails(get(rowInfo.original, "id", ""));
                                        }}
                                        onPageSizeChange={this.props.onPageSizeChange}
                                        onPageNumberChange={this.props.onPageNumberChange}
                                        total={this.props.total}
                                        paginationLabel={""}
                                        showPagination={false}
                                    />
                                </div>

                            )

                        })
                        :
                        <DataGrid
                            data={[]}
                            columns={this.state.isClientView ? clientColumns : staffColumns}
                            onRowClick={(rowInfo) => {
                                // this.onDetails(get(rowInfo.original, "id", ""));
                            }}
                            onPageSizeChange={this.props.onPageSizeChange}
                            onPageNumberChange={this.props.onPageNumberChange}
                            total={this.props.total}
                            paginationLabel={"Organizations per page"}
                            showPagination={false}
                        />
                }
                <div className="row pagination pagination-align">
                    <div className="col-12">
                        <Pagination label={"Organizations per page"}
                                    onPageNumberChange={this.props.onPageNumberChange}
                                    onPageSizeChange={this.props.onPageSizeChange}
                                    total={this.props.total}
                                    pageSize={this.props.pageSize}
                                    pageNumber={this.props.pageNumber}/>
                    </div>
                </div>
            </div>


        );
    }
}

export default StaffAllocationReportView;
