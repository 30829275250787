import { all } from "redux-saga/effects";
import {
  watchChangePassword,
  watchConfirmEmail,
  watchLogin,
  watchTrustBrowserApiCall,
  watchVerifyOtp,
  watchVerifyResendOtp,
  watchVerifyToken,
} from "./auth/authSaga";
import {
  watchCreateClient,
  watchGetAllClient,
  watchUpdateClient,
} from "./clients/clientSaga";
import {
  watchGetAllUser,
  watchCreateUser,
  watchUpdateUser,
  watchGetAllSC,
  watchUpdateSC,
  watchCreateSC,
} from "./users/userSaga";
import {
  watchCreateClientService,
  watchUpdateClientService,
} from "./services/serviceSaga";
import {
  watchCreateWorkEntry,
  watchUpdateWorkEntry,
  watchGetAllWorkEntry,
  watchGetAllWorkEntryByUserId,
} from "./workEntries/workEntrySaga";
import {
  watchGenerateReport,
  watchGenerateStaffReport,
  watchGenerateWorkEntryReport,
} from "./reports/reportSaga";

export default function* rootSaga() {
  yield all([
    watchLogin(),
    watchTrustBrowserApiCall(),
    watchVerifyResendOtp(),
    watchVerifyOtp(),
    watchCreateClient(),
    watchGetAllClient(),
    watchUpdateClient(),
    watchVerifyToken(),
    watchConfirmEmail(),
    watchChangePassword(),
    watchGetAllUser(),
    watchCreateUser(),
    watchUpdateUser(),
    watchCreateClientService(),
    watchUpdateClientService(),
    watchCreateWorkEntry(),
    watchUpdateWorkEntry(),
    watchGetAllWorkEntry(),
    watchGetAllWorkEntryByUserId(),
    watchGenerateReport(),
    watchGetAllSC(),
    watchUpdateSC(),
    watchCreateSC(),
    watchGenerateStaffReport(),
    watchGenerateWorkEntryReport(),
  ]);
}
