import { post, get, put } from "../util/httpService";
import {CLIENT_URL, VISIT_URL} from "../constants/appConstants";
import * as _ from "lodash";
import { getToken, setAuthData } from "../util/auth";

export async function startVisit(visit) {
    const authToken = getToken();
    const headers = { Authorization: `bearer ${authToken}` };
    try {
        const response = await post(`${VISIT_URL}/start`, visit, { headers: headers });
        return _.get(response, "data", {});
    } catch (e) {
        throw _.get(e, "response.data", {});
    }
}

export async function abandonVisit(visit) {
    const authToken = getToken();
    const headers = { Authorization: `bearer ${authToken}` };
    try {
        const response = await post(`${VISIT_URL}/abandon`, visit, { headers: headers });
        return _.get(response, "data", {});
    } catch (e) {
        throw _.get(e, "response.data", {});
    }
}

export async function completeVisit(visit) {
    const authToken = getToken();
    const headers = { Authorization: `bearer ${authToken}` };
    try {
        const response = await post(`${VISIT_URL}/complete`, visit, { headers: headers });
        return _.get(response, "data", {});
    } catch (e) {
        throw _.get(e, "response.data", {});
    }
}

export async function getInProgressVisits() {
    const authToken = getToken();
    const headers = { Authorization: `bearer ${authToken}` };
    try {
        const response = await get(`${VISIT_URL}/inprogress`, {
            headers: headers,
        });
        return response;
    } catch (e) {
        throw e;
    }
}

export async function updateVisitNote(visit) {
    const authToken = getToken();
    const headers = { Authorization: `bearer ${authToken}` };
    try {
        const response = await post(`${VISIT_URL}/update`, visit, { headers: headers });
        return _.get(response, "data", {});
    } catch (e) {
        throw _.get(e, "response.data", {});
    }
}
