import React from "react";

class WECard extends React.Component {
  render() {
    const we = this.props.we;
    return (
      <div
        className="col-12 visit-card"
        onClick={() => {
          this.props.onDetails(we.id);
        }}
      >
        <div className="row">
          <div className="col-6">
            <div className="col-12 label">Work Entry</div>
            <div className="col-12 value">{we.name}</div>
          </div>
          <div className="col-6">
            <div className="col-12 label">Staff Name</div>
            <div className="col-12 value">{we.staffName}</div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div className="col-12 label">Client Name</div>
            <div className="col-12 value">{we.clientName}</div>
          </div>
          <div className="col-6">
            <div className="col-12 label">Service Name</div>
            <div className="col-12 value">{we.service}</div>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-6">
            <div className="col-12 label">Elapsed Time (Hrs)</div>
            <div className="col-12 value">{we.elapsedTime}</div>
          </div>
          <div className="col-6">
            <div className="col-12 label">Used Units</div>
            <div className="col-12 value">{we.usedUnits}</div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div className="col-12 label">Gross ($)</div>
            <div className="col-12 value">{we.gross}</div>
          </div>
          <div className="col-6">
            <div className="col-12 label">Net ($)</div>
            <div className="col-12 value">{we.net}</div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div className="col-12 label">Start Time</div>
            <div className="col-12 value">{we.startTime}</div>
          </div>
          <div className="col-6">
            <div className="col-12 label">End Time</div>
            <div className="col-12 value">{we.endTime}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default WECard;
