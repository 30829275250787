import React from "react";
import DataGrid from "../../shared/data-grid/data-grid";
import {map, uniqBy} from "lodash";
import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/appConstants";
import ReportCell from "../../shared/data-grid/custom-cells/report-cell";
import Pagination from "../../shared/data-grid/pagination/pagination";
import DataTableReportHeader from "../../shared/data-grid/custom-headers/report-header";

class StaffBillingReportView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            reportData: [],
            reportStats: {},
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            reportStats: nextProps.reportData.stats,
        });
        this.mapRecords(nextProps.reportData.data);
    }

    componentDidMount() {
        this.setState({
            reportStats: this.props.reportData.stats,
        });
        this.mapRecords(this.props.reportData.data);
    }

    mapRecords = (records) => {
        const staffIds = this.getUniqueIds(records, "staffId")
        let report = []
        map(staffIds, (staffId) => {
            let arr = []
            map(records, (r) => {
                if (r.staffId === staffId) {
                    arr.push(r)
                }
            })
            report.push(...this.groupByService(arr))
        })
        this.setState({
            reportData: report,
        });
    }

    groupByService = (records) => {
        const serviceIds = this.getUniqueIds(records, "serviceRequestId")
        let report = []
        map(serviceIds, (serviceId) => {
            const arr = []
            map(records, (r) => {
                if (r.serviceRequestId === serviceId) {
                    arr.push({
                        wName: r.entryName,
                        createdDate: moment(r.createdDate).format(DATE_TIME_FORMAT),
                        sTime: moment(r.serviceStartTime).format(DATE_TIME_FORMAT),
                        eTime: moment(r.serviceEndTime).format(DATE_TIME_FORMAT),
                        eHours: parseFloat(r.elapsedTime).toFixed(2),
                        units: r.unitsUsed,
                        gValue: parseFloat(r.grossAmount).toFixed(2),
                        nValue: parseFloat(r.netAmount).toFixed(2),
                        staffName: r.staffName,
                        serviceName: r.serviceName,
                    })
                }
            })
            report.push(arr)
        })
        return report
    }

    getUniqueIds = (records, key) => {
        const ids = [];
        map(records, (r) => {
            ids.push(r[key]);
        })
        return uniqBy(ids, Math.floor);
    }

    render() {
        const columns = [
            {
                Header: () => (
                    <DataTableReportHeader header={"Work Entry Name"} fontSize={12}/>
                ),
                accessor: "wName",
                Cell: (row) => {
                    return <ReportCell value={row.value} index={row.index}/>;
                },
            },
            {
                Header: () => (
                    <DataTableReportHeader header={"Created Date"} fontSize={12}/>
                ),
                accessor: "createdDate",
                Cell: (row) => {
                    return <ReportCell value={row.value} index={row.index}/>;
                },
            },
            {
                Header: () => (
                    <DataTableReportHeader header={"Start Time"} fontSize={12}/>
                ),
                accessor: "sTime",
                Cell: (row) => <ReportCell value={row.value} index={row.index}/>,
            },
            {
                Header: () => (
                    <DataTableReportHeader header={"End Time"} fontSize={12}/>
                ),
                accessor: "eTime",
                Cell: (row) => <ReportCell value={row.value} index={row.index}/>,
            },
            {
                Header: () => (
                    <DataTableReportHeader header={"Elapsed Hours"} fontSize={12}/>
                ),
                accessor: "eHours",
                Cell: (row) => <ReportCell value={row.value} index={row.index}/>,
            },

            {
                Header: () => (
                    <DataTableReportHeader header={"Units Used"} fontSize={12}/>
                ),
                accessor: "units",
                Cell: (row) => <ReportCell value={row.value} index={row.index}/>,
            },
            {
                Header: () => (
                    <DataTableReportHeader header={"Gross Value ($)"} fontSize={12}/>
                ),
                accessor: "gValue",
                Cell: (row) => <ReportCell value={row.value} index={row.index}/>,
            },
            {
                Header: () => (
                    <DataTableReportHeader header={"Net Value ($)"} fontSize={12}/>
                ),
                accessor: "nValue",
                Cell: (row) => <ReportCell value={row.value} index={row.index}/>,
            },
        ];
        return (
            <div>
                <div className=" report-stats col-sm-12 col-md-12 col-lg-12 row">
                    <div className="col-sm-3 col-md-3 col-lg-3 label">
                        <span>{`Total Records : ${this.state.reportStats.recordCount}`}</span>
                    </div>
                    <div className="col-sm-3 col-md-3 col-lg-3 label">
            <span>
              {`Total Units Used : ${this.state.reportStats.totalUnits}`}{" "}
            </span>
                    </div>
                    <div className="col-sm-3 col-md-3 col-lg-3 label">
            <span>
              {`Total Gross ($) : ${parseFloat(
                  this.state.reportStats.totalGross
              ).toFixed(2)}`}{" "}
            </span>
                    </div>
                    <div className="col-sm-3 col-md-3 col-lg-3 label">
            <span>
              {`Total Net ($) : ${parseFloat(
                  this.state.reportStats.totalNet
              ).toFixed(2)}`}{" "}
            </span>
                    </div>
                </div>
                {this.state.reportData.length > 0 ? (
                    map(this.state.reportData, (data, index) => {
                        return (
                            <div key={index}>
                                {data.length > 0 && (
                                    <div
                                        className={
                                            "report-details-header col-sm-12 col-md-12 col-lg-12 row"
                                        }
                                    >
                                        <div className="col-sm-6 col-md-4 col-lg-6 title-value-container">
                                            <span className={"title-text"}>{`Staff Name : `}</span>
                                            <span
                                                className={"value-text"}
                                            >{`${data[0].staffName}`}</span>
                                        </div>
                                        <div className="col-sm-6 col-md-4 col-lg-6 title-value-container">
                                            <span className={"title-text"}>{`Service : `}</span>
                                            <span
                                                className={"value-text"}
                                            >{`${data[0].serviceName}`}</span>
                                        </div>
                                    </div>
                                )}
                                <DataGrid
                                    data={data}
                                    columns={columns}
                                    onRowClick={(rowInfo) => {
                                        // this.onDetails(get(rowInfo.original, "id", ""));
                                    }}
                                    onPageSizeChange={this.props.onPageSizeChange}
                                    onPageNumberChange={this.props.onPageNumberChange}
                                    total={this.props.total}
                                    paginationLabel={""}
                                    showPagination={false}
                                />
                            </div>
                        );
                    })
                ) : (
                    <DataGrid
                        data={[]}
                        columns={columns}
                        onRowClick={(rowInfo) => {
                            // this.onDetails(get(rowInfo.original, "id", ""));
                        }}
                        onPageSizeChange={this.props.onPageSizeChange}
                        onPageNumberChange={this.props.onPageNumberChange}
                        total={this.props.total}
                        paginationLabel={"Organizations per page"}
                        showPagination={false}
                    />
                )}
                <div className="row pagination pagination-align">
                    <div className="col-12">
                        <Pagination
                            label={"Organizations per page"}
                            onPageNumberChange={this.props.onPageNumberChange}
                            onPageSizeChange={this.props.onPageSizeChange}
                            total={this.props.total}
                            pageSize={this.props.pageSize}
                            pageNumber={this.props.pageNumber}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default StaffBillingReportView;
