import * as React from 'react';

class ReportCell extends React.Component {
    render() {
        return (
            <div className='cell basic-cell' style={{fontSize: 12}}>
                <div className='overflow'>{this.props.value}</div>
            </div>
        );
    }
}

export default ReportCell;
