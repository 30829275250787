import * as React from "react";
import "./staff.scss";
import DataGrid from "../shared/data-grid/data-grid";
import BasicCell from "../shared/data-grid/custom-cells/basic-cell";
import BasicHeader from "../shared/data-grid/custom-headers/basic-header";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getAllUsers } from "../../redux/users/userActions";
import { get, isEqual, isNull, map, startCase } from "lodash";
import Actions from "../shared/data-grid/custom-cells/actions";
import View from "../shared/data-grid/custom-cells/view";
import { isMobileDevice } from "../../util/deviceType";
import StaffCard from "./staffCard";
import Pagination from "../shared/data-grid/pagination/pagination";
import { ALERT_TYPES, RECORD_TYPES } from "../../constants/appConstants";
import ResetPassword from "../shared/data-grid/custom-cells/resetPassword";
import PopUpModal from "../shared/popup-modal/popUpModal";
import ResetPasswordForm from "./ResetPasswordForm";
import { createNotification } from "../../util/notificationManager";
import { resetStaffPassword } from "../../api/user";
import { isEmpty } from "react-select-search/src/Utils";
import { saveStaffSearchChanges } from "../../redux/config/configAction";

class Staff extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      seletedUser: "",
      totalUsers: 0,
      pageNumber: 0,
      searchParam: "",
      filter: "recent",
      pageSize: 10,
      openModal: false,
      status: 0,
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const { users, searchConfig } = this.props;
    const userData = [];
    if (!isEqual(nextProps.users, users) && !isNull(nextProps.users)) {
      map(nextProps.users.data, (user) => {
        userData.push({
          id: user.userId,
          // name: startCase(
          //   `${get(user, "firstName")} ${get(user, "middleName")} ${get(
          //     user,
          //     "lastName"
          //   )}`
          // ),
          name: `${get(user, "lastName")} ${get(user, "firstName")} ${get(
            user,
            "middleName"
          )}`,
          firstName: get(user, "firstName", ""),
          lastName: get(user, "lastName", ""),
          phone: get(user, "phone", ""),
          staffType: this.getUserType(parseInt(get(user, "userType", ""))),
        });
      });
      this.setState({
        users: userData,
        totalUsers: nextProps.users.recordCount,
      });
    }

    if (
      !isNull(nextProps.searchConfig) &&
      !isEmpty(nextProps.searchConfig) &&
      !isEqual(nextProps.searchConfig, searchConfig)
    ) {
      this.setState({
        pageNumber: nextProps.searchConfig.pageNumber,
        pageSize: nextProps.searchConfig.pageSize,
        filter: nextProps.searchConfig.filter,
        searchParam: nextProps.searchConfig.searchParam,
        status: nextProps.searchConfig.status,
      });
      this.getAllUsers(
        nextProps.searchConfig.pageNumber,
        nextProps.searchConfig.pageSize,
        nextProps.searchConfig.filter,
        nextProps.searchConfig.searchParam,
        nextProps.searchConfig.status
      );
    }
  }

  componentDidMount() {
    const { searchConfig } = this.props;
    if (searchConfig) {
      this.setState({
        pageNumber: searchConfig.pageNumber,
        pageSize: searchConfig.pageSize,
        filter: searchConfig.filter,
        searchParam: searchConfig.searchParam,
      });
      this.getAllUsers(
        searchConfig.pageNumber,
        searchConfig.pageSize,
        searchConfig.filter,
        searchConfig.searchParam,
        searchConfig.status
      );
    } else {
      this.getAllUsers(
        this.state.pageNumber,
        this.state.pageSize,
        this.state.filter,
        this.state.searchParam,
        this.state.status
      );
    }
  }

  getAllUsers = (pageNumber, pageSize, filter, searchParams, status) => {
    this.props.dispatch(
      getAllUsers(pageNumber, pageSize, filter, searchParams, "user", status)
    );
  };

  onCreateNewStaff = () => {
    this.props.history.push("/admin/staff-type");
  };

  onEdit = (userId) => {
    this.props.history.push(`/admin/edit-staff?id=${userId}`);
  };

  onResetPassword = (userId) => {
    this.setState({
      openModal: true,
      selectedUser: userId,
    });
  };

  onDetails = (userId) => {
    this.props.history.push(`/admin/view-staff?id=${userId}`);
  };

  onPageChange = (pageNumber) => {
    this.props.dispatch(
      saveStaffSearchChanges(
        pageNumber,
        this.state.pageSize,
        this.state.filter,
        this.state.searchParam,
        this.state.status
      )
    );
  };

  onPageSizeChange = (pageSize) => {
    this.setState({
      pageSize: pageSize,
    });
    this.props.dispatch(
      saveStaffSearchChanges(
        0,
        pageSize,
        this.state.filter,
        this.state.searchParam,
        this.state.status
      )
    );
  };

  onSearch = (event) => {
    const searchParam = event.target.value;
    if (searchParam.length >= 3 || searchParam.length == 0) {
      this.props.dispatch(
        saveStaffSearchChanges(
          0,
          this.state.pageSize,
          "recent",
          searchParam,
          this.state.status
        )
      );
    }
    this.setState({
      searchParam,
      pageNumber: 0,
      filter: "recent",
    });
  };

  onPressEnter = (event) => {
    if (event.keyCode == 13) {
      this.props.dispatch(
        saveStaffSearchChanges(
          0,
          this.state.pageSize,
          this.state.filter,
          this.state.searchParam,
          this.state.status
        )
      );
    }
  };

  onFilterChange = (event) => {
    const filter = event.target.value;
    this.setState({
      filter,
    });
    this.props.dispatch(
      saveStaffSearchChanges(
        0,
        this.state.pageSize,
        filter,
        this.state.searchParam,
        this.state.status
      )
    );
  };

  onStatusChange = (event) => {
    const status = event.target.value;
    this.setState({
      status,
    });
    this.props.dispatch(
      saveStaffSearchChanges(
        0,
        this.state.pageSize,
        this.state.filter,
        this.state.searchParam,
        status
      )
    );
  };

  getUserType = (userType) => {
    let recordType = null;
    map(RECORD_TYPES, (record) => {
      if (record.value === userType) {
        recordType = startCase(record.type);
      }
    });
    return recordType;
  };

  resetPassword = (newPassword, userId) => {
    resetStaffPassword(newPassword, userId).then((res) => {
      if (res.status) {
        this.setState({
          openModal: false,
        });
        createNotification(ALERT_TYPES.SUCCESS, res.message);
      } else {
        createNotification(ALERT_TYPES.ERROR, res.message);
      }
    });
  };

  render() {
    const columns = [
      {
        Header: () => <BasicHeader header={"Name"} />,
        accessor: "name",
        Cell: (row) => <BasicCell value={row.value} index={row.index} />,
      },
      {
        Header: () => <BasicHeader header={"Last Name"} />,
        accessor: "lastName",
        Cell: (row) => {
          return <BasicCell value={row.value} index={row.index} />;
        },
      },
      {
        Header: () => <BasicHeader header={"First Name"} />,
        accessor: "firstName",
        Cell: (row) => <BasicCell value={row.value} index={row.index} />,
      },
      //   {
      //     Header: () => <BasicHeader header={"Client Name"} />,
      //     accessor: "cname",
      //     Cell: row => <BasicCell value={row.value} index={row.index} />
      //   },
      {
        Header: () => <BasicHeader header={"Staff Type"} />,
        accessor: "staffType",
        Cell: (row) => <BasicCell value={row.value} index={row.index} />,
      },
      {
        Header: () => <BasicHeader header={"Phone"} />,
        accessor: "phone",
        Cell: (row) => <BasicCell value={row.value} index={row.index} />,
      },
      {
        Header: "",
        accessor: "icon",
        Cell: (row) => (
          <View
            actionId={row.original.id}
            onClick={this.onDetails.bind(row.original.id)}
          />
        ),
        width: 50,
      },
      {
        Header: "",
        accessor: "icon",
        Cell: (row) => (
          <Actions
            actionId={row.original.id}
            onEdit={this.onEdit.bind(row.original.id)}
          />
        ),
        width: 50,
      },
      {
        Header: "",
        accessor: "icon",
        Cell: (row) => (
          <ResetPassword
            actionId={row.original.id}
            onClick={this.onResetPassword.bind(row.original.id)}
          />
        ),
        width: 50,
      },
    ];

    return (
      <div className="table-layout">
        <div className="row col-12 header-container">
          <div className="row col-lg-10 col-sm-12">
            <span className="col-lg-2 col-sm-12 title">Staff</span>
            <span className="col-lg-1 col-sm-2 sub-title">Search</span>
            <div className="col-lg-4 col-sm-10 input-box">
              <input
                type="text"
                className="box text-box search-icon"
                value={this.state.searchParam}
                onChange={this.onSearch}
                onKeyDown={this.onPressEnter}
                placeholder={"Search Staff Name"}
                autoComplete={"off"}
              />
            </div>
          </div>
          <div className="col-lg-2 col-sm-12">
            <button className="button submit" onClick={this.onCreateNewStaff}>
              <span className="text">CREATE NEW</span>
            </button>
          </div>
        </div>

        <div className="body-container">
          <div className="row list-container">
            <div className="col-lg-3 col-md-4 col-sm-6">
              <span className="list-header">Staff</span>
            </div>
            <div className="col-lg-3 offset-lg-3 col-md-4 col-sm-6">
              <select
                className="select-box list-dropdown"
                onChange={this.onStatusChange}
                value={this.state.status}
              >
                <option value="0">All Staff</option>
                <option value="4">Active Staff</option>
                <option value="5">Inactive Staff</option>
              </select>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <select
                className="select-box list-dropdown"
                onChange={this.onFilterChange}
                value={this.state.filter}
              >
                <option value="all">All Staff</option>
                <option value="recent">Recently Added</option>
                <option value="name_asc">Name A &#8594; Z</option>
                <option value="name_desc">Name Z &#8594; A</option>
                <option value="city_asc">City A &#8594; Z</option>
                <option value="city_desc">City Z &#8594; A</option>
              </select>
            </div>
          </div>

          <div className="data-grid-container">
            {!isMobileDevice() && (
              <div className="row">
                <div className="col-12">
                  <DataGrid
                    data={this.state.users}
                    columns={columns}
                    onRowClick={(rowInfo) => {
                      this.setState({
                        seletedUser: get(rowInfo.original, "id", ""),
                      });
                    }}
                    onPageSizeChange={this.onPageSizeChange}
                    onPageNumberChange={this.onPageChange}
                    total={this.state.totalUsers}
                    paginationLabel={"Users per page"}
                    showPagination={true}
                    pageSize={this.state.pageSize}
                    pageNumber={this.state.pageNumber}
                  />
                </div>
              </div>
            )}

            {isMobileDevice() && (
              <div className="row" style={{ margin: 10 }}>
                {map(this.state.users, (user) => {
                  return (
                    <StaffCard
                      user={user}
                      onDetails={(id) => {
                        this.onDetails(id);
                      }}
                    />
                  );
                })}
                <div className="row pagination pagination-align">
                  <div className="col-12">
                    <Pagination
                      onPageSizeChange={this.onPageSizeChange}
                      label={"Users per page"}
                      onPageNumberChange={this.onPageChange}
                      total={this.state.totalUsers}
                      pageSize={this.state.pageSize}
                      pageNumber={this.state.pageNumber}
                    />
                  </div>
                </div>
              </div>
            )}

            {isMobileDevice() && this.state.users.length === 0 && (
              <div className="row">
                <div className="col-12 text-center">
                  <span>No Staff</span>
                </div>
              </div>
            )}
          </div>
        </div>
        {this.state.openModal && (
          <PopUpModal
            show={this.state.openModal}
            onClose={() => {
              this.setState({ openModal: false });
            }}
            title={"Reset User Password"}
            icon={"/images/clipboard.png"}
            size={"lg"}
          >
            <ResetPasswordForm
              userId={this.state.selectedUser}
              handleSubmit={this.resetPassword}
              onCloseModal={() => {
                this.setState({ openModal: false });
              }}
            />
          </PopUpModal>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.user.getAllSuccess,
  usersError: state.user.getAllFail,
  changePasswordSuccess: state.auth.changePasswordSuccess,
  changePasswordFail: state.auth.changePasswordFail,
  searchConfig: state.config.staffSearch,
});

export default withRouter(connect(mapStateToProps)(Staff));
