import React from "react";

class StaffCard extends React.Component {
  render() {
    const user = this.props.user;
    return (
      <div
        className="col-12 visit-card"
        onClick={() => {
          this.props.onDetails(user.id);
        }}
      >
        <div className="row">
          <div className="col-6">
            <div className="col-12 label">First Name</div>
            <div className="col-12 value">{user.firstName}</div>
          </div>
          <div className="col-6">
            <div className="col-12 label">Last Name</div>
            <div className="col-12 value">{user.lastName}</div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div className="col-12 label">Phone</div>
            <div className="col-12 value">{user.phone}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default StaffCard;
