import * as React from "react";
import "./faq.scss";
import { withRouter } from "react-router-dom";
import { getAuth } from "../../util/auth";
import { map } from "lodash";

class FAQBox extends React.Component {
  constructor(props) {
    super(props);
    let userData = getAuth();
    this.state = {
      isOpen: false,
    };
  }

  render() {
    return (
      <div className="faq col-lg-10 offset-lg-1 col-md-10 offset-md-1 col-sm-12">
        <div
          className="question-container"
          onClick={() => {
            this.setState({ isOpen: !this.state.isOpen });
          }}
        >
          <div className="question">{this.props.question}</div>

          <div>
            {this.state.isOpen ? (
              <img
                src="./images/chevron-up-green.svg"
                className="show-hide"
                alt=""
              />
            ) : (
              <img
                src="./images/chevron-down-green.svg"
                className="show-hide"
                alt=""
              />
            )}
          </div>
        </div>
        {this.state.isOpen && this.props.isTextAnswer && (
          <div className="answer-container">
            <span className="answer">{this.props.answer}</span>
          </div>
        )}
        {this.state.isOpen && !this.props.isTextAnswer && (
          <div className="answer-container">
            <ul>
              {map(this.props.answer, (answer, key) => {
                return <li>{answer}</li>;
              })}
            </ul>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(FAQBox);
