import * as React from "react";
import { Field, Form, reduxForm } from "redux-form";
import {
  inputField,
  renderDatePicker,
  textField,
} from "../../shared/form-fields/formFields";
import { withRouter } from "react-router-dom";
import {
  validateClientServiceCode,
  validateClientServiceEndDate,
  validateClientServiceName,
  validateClientServiceStartDate,
  validateClientServiceUnitLength,
  validateClientServiceUnitRate,
  validateClientServiceUnitsAuthorized,
} from "../create-client/create-client-form/clientValidator";
import Files from "../../staff/create-staff/create-staff-form/files";
import moment from "moment";

class ClientServiceInfo extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { handleSubmit } = this.props;

    return (
      <Form style={{ width: "100%" }} onSubmit={handleSubmit} noValidate>
        <div style={{ margin: 30, marginTop: 0 }}>
          <div className="row">
            <div className="col-6 input-box text-left">
              <div className="label">Service Name</div>
              <Field
                name="serviceName"
                component={inputField}
                type="text"
                placeholder={"Service Name"}
                required={true}
                validate={validateClientServiceName}
              />
            </div>
            <div className="col-6 input-box text-left">
              <div className="label">Service Code</div>
              <Field
                name="serviceCode"
                component={inputField}
                type="text"
                placeholder={"Service Code"}
                required={true}
                validate={validateClientServiceCode}
              />
            </div>
            <div className="col-12 input-box text-left">
              <div className="label">Description</div>
              <Field
                name="serviceDescription"
                component={textField}
                type="text"
                placeholder={"Description"}
                required={true}
                //   validate={validateEmail}
              />
            </div>
            <div className="col-6 input-box text-left">
              <div className="label">Unit Rate</div>
              <Field
                name="serviceUnitRate"
                component={inputField}
                type="number"
                placeholder={"Unit Rate"}
                required={true}
                minVal={0}
                validate={validateClientServiceUnitRate}
              />
            </div>
            <div className="col-6 input-box text-left">
              <div className="label">Unit Length (hrs.)</div>
              <Field
                name="serviceUnitLength"
                component={inputField}
                type="number"
                placeholder={"Unit Length"}
                required={true}
                minVal={0}
                validate={validateClientServiceUnitLength}
              />
              {/*<select*/}
              {/*    className="select-box"*/}
              {/*    placeholder="Select Unit Length"*/}
              {/*    value={this.props.unitLength}*/}
              {/*    onChange={this.props.onUnitLengthChange}*/}
              {/*>*/}
              {/*    {unitLengthList.map((record, i) => {*/}
              {/*        return (*/}
              {/*            <option key={i} value={record}>*/}
              {/*                {record}*/}
              {/*            </option>*/}
              {/*        );*/}
              {/*    })}*/}
              {/*</select>*/}
            </div>
            <div className="col-6 input-box text-left">
              <div className="label">Date Services Begin</div>
              <Field
                name="serviceStartDate"
                component={renderDatePicker}
                type="text"
                required={true}
                validate={validateClientServiceStartDate}
              />
            </div>
            <div className="col-6 input-box text-left my-auto">
              <input
                type="checkbox"
                name="authorized"
                value="authorized"
                checked={this.props.isServiceInfoAuthorized}
                onChange={(event) => {
                  this.props.onServiceInfoAuthorized(event.target.checked);
                }}
                style={{ marginRight: 5 }}
              />
              <span className="label">Authorized</span>
            </div>
            <div className="col-6 input-box text-left">
              <div className="label">Date Services End</div>
              <Field
                name="serviceEndDate"
                component={renderDatePicker}
                type="text"
                required={true}
                validate={validateClientServiceEndDate}
              />
            </div>
            <div className="col-6 input-box text-left my-auto">
              <input
                type="checkbox"
                name="degreeRequired"
                value="degreeRequired"
                checked={this.props.isDegreeRequired}
                onChange={(event) => {
                  this.props.onDegreeRequired(event.target.checked);
                }}
                style={{ marginRight: 5 }}
              />
              <span className="label">Degree Required</span>
            </div>
            <div className="col-6 input-box text-left">
              <div className="label">Units Authorized</div>
              <Field
                name="serviceUnitsAuthorized"
                component={inputField}
                type="number"
                // placeholder={'Unit Length'}
                required={true}
                validate={validateClientServiceUnitsAuthorized}
                style={{ marginRight: 5 }}
                minVal={0}
              />
            </div>
            <div className="col-6 input-box text-left my-auto">
              <input
                type="checkbox"
                name="degreeRequired"
                value="degreeRequired"
                checked={this.props.isAllowedNegativeUnits}
                onChange={(event) => {
                  this.props.AllowNegativeUnits(event.target.checked);
                }}
                style={{ marginRight: 5 }}
              />
              <span className="label">Allow Negative Units Remaining</span>
            </div>
            {this.props.initialValues != null && (
              <div className="col-6 input-box text-left">
                <div className="label">Total Worth</div>
                <Field
                  name="totalWorth"
                  component={inputField}
                  type="text"
                  disabled={true}
                  // placeholder={'Unit Length'}
                  required={true}
                  //   validate={validateEmail}
                />
              </div>
            )}
            {this.props.initialValues != null && (
              <div className="col-6 input-box text-left">
                <div className="label">Units Utilized</div>
                <Field
                  name="unitsUtilized"
                  component={inputField}
                  type="text"
                  disabled={true}
                  // placeholder={'Unit Length'}
                  required={true}
                  //   validate={validateEmail}
                />
              </div>
            )}
            {this.props.initialValues != null && (
              <div className="col-6 input-box text-left">
                <div className="label">Units Remaining</div>
                <Field
                  name="unitsRemaining"
                  component={inputField}
                  type="text"
                  disabled={true}
                  // placeholder={'Unit Length'}
                  required={true}
                  //   validate={validateEmail}
                />
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-6 input-box text-left my-auto">
              <input
                type="checkbox"
                name="degreeRequired"
                value="degreeRequired"
                checked={this.props.isConfirmedDates}
                onChange={(event) => {
                  this.props.onConfirmDates(event.target.checked);
                }}
                style={{ marginRight: 5 }}
              />
              <span className="label">Confirm Dates</span>
            </div>
            <div className="col-6 input-box text-left my-auto">
              <input
                type="checkbox"
                name="degreeRequired"
                value="degreeRequired"
                checked={this.props.isOverlappable}
                onChange={(event) => {
                  this.props.onOverlapable(event.target.checked);
                }}
                style={{ marginRight: 5 }}
              />
              <span className="label">Overlappable Service</span>
            </div>
          </div>
          <div className="row">
            <div className="col-12" style={{ padding: 10 }}>
              <Files
                onFileUpload={this.props.onFileUpload}
                fileList={this.props.fileList}
                onRemoveImage={this.props.onRemoveImage}
                directoryName={"service"}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-sm-10 offset-sm-1">
              <div className="row">
                <div className="col-6">
                  <button type="submit" className="button submit">
                    {this.props.initialValues == null ? "SAVE" : "UPDATE"}
                  </button>
                </div>
                <div className="col-6">
                  <button
                    className="button basic"
                    onClick={(event) => {
                      this.props.onCloseModal();
                    }}
                  >
                    CANCEL
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
    );
  }
}

const serviceForm = reduxForm({
  form: "addService",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(ClientServiceInfo);

export default withRouter(serviceForm);
// export default ClientServiceInfo;
