import * as React from 'react';

class LinkCell extends React.Component {

    onEdit = (event) => {
        this.props.onEdit(this.props.actionId, event)
    };

    render() {
        return (
            <div className='cell link-cell' onClick={this.onEdit}>
                { this.props.value && (
                    <div className='overflow link-text'>{this.props.value}</div>
                )}
                { !this.props.value && (
                    <div className='overflow link-text-disabled'>Add a Note..</div>
                )}
            </div>
        );
    }
}

export default LinkCell;
