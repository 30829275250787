import { SERVICE } from "../../constants/actionTypes";

export function createClientService(service) {
  return {
    type: SERVICE.CREATE_CLIENT_SERVICE,
    service,
  };
}

export function createClientServiceSuccess(response) {
  return {
    type: SERVICE.CREATE_CLIENT_SERVICE_SUCCESS,
    response,
  };
}

export function createClientServiceFail(error) {
  return {
    type: SERVICE.CREATE_CLIENT_SERVICE_FAIL,
    error,
  };
}

export function resetCreateClientService() {
  return {
    type: SERVICE.RESET_CREATE_CLIENT_SERVICE,
  };
}

export function updateClientService(service) {
  return {
    type: SERVICE.UPDATE_CLIENT_SERVICE,
    service,
  };
}

export function updateClientServiceSuccess(response) {
  return {
    type: SERVICE.UPDATE_CLIENT_SERVICE_SUCCESS,
    response,
  };
}

export function updateClientServiceFail(error) {
  return {
    type: SERVICE.UPDATE_CLIENT_SERVICE_FAIL,
    error,
  };
}

export function resetUpdateClientService() {
  return {
    type: SERVICE.RESET_UPDATE_CLIENT_SERVICE,
  };
}
