import { USER } from "../../constants/actionTypes";

export function login(username, password, loginToken) {
  return {
    type: USER.LOGIN,
    username,
    password,
    loginToken,
  };
}

export function loginSuccess(auth) {
  return {
    type: USER.LOGIN_SUCCESS,
    auth,
  };
}

export function loginFail(error) {
  return {
    type: USER.LOGIN_FAIL,
    error,
  };
}

export function loginReset() {
  return {
    type: USER.LOGIN_RESET,
  };
}

export function confirmEmail(confirmEmail) {
  return {
    type: USER.CONFIRM_EMAIL,
    confirmEmail,
  };
}

export function confirmEmailSuccess(response) {
  return {
    type: USER.CONFIRM_EMAIL_SUCCESS,
    response,
  };
}

export function confirmEmailFail(error) {
  return {
    type: USER.CONFIRM_EMAIL_FAIL,
    error,
  };
}

export function resetConfirmEmail() {
  return {
    type: USER.RESET_CONFIRM_EMAIL,
  };
}
export function changePassword(changePassword) {
  return {
    type: USER.CHANGE_PASSWORD,
    changePassword,
  };
}

export function changePasswordSuccess(response) {
  return {
    type: USER.CHANGE_PASSWORD_SUCCESS,
    response,
  };
}

export function changePasswordFail(error) {
  return {
    type: USER.CHANGE_PASSWORD_FAIL,
    error,
  };
}

export function resetChangePassword() {
  return {
    type: USER.RESET_CHANGE_PASSWORD,
  };
}

export function verifyToken(token) {
  return {
    type: USER.VERIFY_TOKEN,
    token,
  };
}

export function verifyTokenSuccess(response) {
  return {
    type: USER.VERIFY_TOKEN_SUCCESS,
    response,
  };
}

export function verifyTokenFail(error) {
  return {
    type: USER.VERIFY_TOKEN_FAIL,
    error,
  };
}

export function saveVerifyToken(token) {
  return {
    type: USER.SAVE_VERIFY_TOKEN,
    token,
  };
}

export function resetVerifyToken() {
  return {
    type: USER.RESET_VERIFY_TOKEN,
  };
}

export function verifyOtp(otpEntered, email) {
  return {
    type: USER.VERIFY_OTP,
    otpEntered,
    email,
  };
}

export function resendOtp(email) {
  return {
    type: USER.RESEND_OTP,
    email,
  };
}

export function resendOtpRes(data) {
  return {
    type: USER.RESEND_OTP_RES,
    data,
  };
}

export function otpVerificationSucess(auth) {
  return {
    type: USER.OTP_VERIFICATION_SUCESS,
    auth,
  };
}

export function otpVerificationFailure() {
  return {
    type: USER.OTP_VERIFICATION_FAILURE,
  };
}

export function trustBrowserApiCall(
  browserId,
  userEmail,
  browser,
  channel,
  userId
) {
  return {
    type: USER.TRUST_BROWSER,
    browserId: browserId,
    userEmail: userEmail,
    browser: browser,
    channel: channel,
    userId: userId,
  };
}

export function resetOtpReset() {
  return {
    type: USER.RESET_OTP_RESENT,
  };
}

export function saveOtpMail(email) {
  return {
    type: USER.SAVE_OTP_EMAIL,
    email,
  };
}
