import * as React from "react";
import "./clients.scss";
import DataGrid from "../shared/data-grid/data-grid";
import BasicCell from "../shared/data-grid/custom-cells/basic-cell";
import BasicHeader from "../shared/data-grid/custom-headers/basic-header";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getAllClients } from "../../redux/clients/clientActions";
import { get, isEmpty, isEqual, isNull, map, startCase } from "lodash";
import View from "../shared/data-grid/custom-cells/view";
import { isMobileDevice } from "../../util/deviceType";
import ClientCard from "./clientCard";
import Pagination from "../shared/data-grid/pagination/pagination";
import { saveClientSearchChanges } from "../../redux/config/configAction";

class Clients extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clients: [],
      selectedClient: "",
      totalClients: 0,
      pageNumber: 0,
      searchParam: "",
      filter: "recent",
      pageSize: 10,
      status: 0,
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const { clients, searchConfig } = this.props;
    const clientData = [];
    if (!isEqual(nextProps.clients, clients) && !isNull(nextProps.clients)) {
      map(nextProps.clients.data, (client) => {
        clientData.push({
          id: client.clientId,
          // name: startCase(
          //     `${get(client, "firstName")} ${get(client, "middleName")} ${get(client, "lastName")}`
          // ),
          name: `${get(client, "lastName")} ${get(client, "firstName")} ${get(
            client,
            "middleName"
          )}`,
          city: startCase(get(client, "billingCity", "")),
          phone: get(client, "phone", ""),
        });
      });
      this.setState({
        clients: clientData,
        totalClients: nextProps.clients.recordCount,
      });
    }

    if (
      !isNull(nextProps.searchConfig) &&
      !isEmpty(nextProps.searchConfig) &&
      !isEqual(nextProps.searchConfig, searchConfig)
    ) {
      this.setState({
        pageNumber: nextProps.searchConfig.pageNumber,
        pageSize: nextProps.searchConfig.pageSize,
        filter: nextProps.searchConfig.filter,
        searchParam: nextProps.searchConfig.searchParam,
        status: nextProps.searchConfig.status,
      });
      this.getAllClients(
        nextProps.searchConfig.pageNumber,
        nextProps.searchConfig.pageSize,
        nextProps.searchConfig.filter,
        nextProps.searchConfig.searchParam,
        nextProps.searchConfig.status
      );
    }
  }

  componentDidMount() {
    const { searchConfig } = this.props;
    if (searchConfig) {
      this.setState({
        pageNumber: searchConfig.pageNumber,
        pageSize: searchConfig.pageSize,
        filter: searchConfig.filter,
        searchParam: searchConfig.searchParam,
        status: searchConfig.status,
      });
      this.getAllClients(
        searchConfig.pageNumber,
        searchConfig.pageSize,
        searchConfig.filter,
        searchConfig.searchParam,
        searchConfig.status
      );
    } else {
      this.getAllClients(
        this.state.pageNumber,
        this.state.pageSize,
        this.state.filter,
        this.state.searchParam,
        this.state.status
      );
    }
  }

  getAllClients = (pageNumber, pageSize, filter, searchParams, status) => {
    this.props.dispatch(
      getAllClients(pageNumber, pageSize, filter, searchParams, status)
    );
  };

  onCreateNewClient = () => {
    this.props.history.push("/admin/create-client");
  };

  onEdit = (clientId) => {
    this.props.history.push(`/admin/edit-client?id=${clientId}`);
  };
  onDetails = (clientId) => {
    this.props.history.push(`/admin/client-details?id=${clientId}`);
  };

  onPageChange = (pageNumber) => {
    this.props.dispatch(
      saveClientSearchChanges(
        pageNumber,
        this.state.pageSize,
        this.state.filter,
        this.state.searchParam,
        this.state.status
      )
    );
  };

  onPageSizeChange = (pageSize) => {
    this.props.dispatch(
      saveClientSearchChanges(
        0,
        pageSize,
        this.state.filter,
        this.state.searchParam,
        this.state.status
      )
    );
  };

  onSearch = (event) => {
    const searchParam = event.target.value;
    if (searchParam.length >= 3 || searchParam.length === 0) {
      this.props.dispatch(
        saveClientSearchChanges(
          0,
          this.state.pageSize,
          "recent",
          searchParam,
          this.state.status
        )
      );
    }
    this.setState({
      searchParam,
      pageNumber: 0,
      filter: "recent",
    });
  };

  onPressEnter = (event) => {
    if (event.keyCode === 13) {
      this.props.dispatch(
        saveClientSearchChanges(
          0,
          this.state.pageSize,
          this.state.filter,
          this.state.searchParam,
          this.state.status
        )
      );
    }
  };

  onFilterChange = (event) => {
    const filter = event.target.value;
    this.setState({
      filter,
    });
    this.props.dispatch(
      saveClientSearchChanges(
        0,
        this.state.pageSize,
        filter,
        this.state.searchParam,
        this.state.status
      )
    );
  };

  onStatusChange = (event) => {
    const status = event.target.value;
    this.setState({
      status,
    });
    this.props.dispatch(
      saveClientSearchChanges(
        0,
        this.state.pageSize,
        this.state.filter,
        this.state.searchParam,
        status
      )
    );
  };

  render() {
    const columns = [
      {
        Header: () => <BasicHeader header={"Client Name"} />,
        accessor: "name",
        Cell: (row) => {
          return <BasicCell value={row.value} index={row.index} />;
        },
      },
      {
        Header: () => <BasicHeader header={"Billing City"} />,
        accessor: "city",
        Cell: (row) => {
          return <BasicCell value={row.value} index={row.index} />;
        },
      },
      {
        Header: () => <BasicHeader header={"Phone"} />,
        accessor: "phone",
        Cell: (row) => <BasicCell value={row.value} index={row.index} />,
      },
      {
        Header: () => <BasicHeader header={""} />,
        accessor: "icon",
        Cell: (row) => (
          <View
            actionId={row.original.id}
            onClick={this.onDetails.bind(row.original.id)}
          />
        ),
        width: 100,
      },
      // {
      //   Header: "",
      //   accessor: "icon",
      //   Cell: (row) => (
      //     <Actions
      //       actionId={row.original.id}
      //       onEdit={this.onEdit.bind(row.original.id)}
      //     />
      //   ),
      //   width: 100,
      // },
    ];

    // const data = [
    //     {
    //         name: 'Tom Martinez',
    //         city: 'Moenborough',
    //         phone: '759-601-8674'
    //     },
    //     {
    //         name: 'Arthur Hubbard',
    //         city: 'Estellaton',
    //         phone: '089-433-3326'
    //     }
    // ];
    return (
      <div className="table-layout">
        <div className="row col-12 header-container">
          <div className="row col-lg-10 col-sm-12">
            <span
              className="col-lg-2 col-sm-12 title"
              style={{ cursor: "default" }}
            >
              Clients
            </span>
            <span className="col-lg-1 col-sm-2 sub-title">Search</span>
            <div className="col-lg-4 col-sm-10 input-box">
              <input
                type="text"
                className="box text-box search-icon"
                value={this.state.searchParam}
                onChange={this.onSearch}
                onKeyDown={this.onPressEnter}
                placeholder={"Client Name"}
              />
            </div>
          </div>
          <div className="col-lg-2 col-sm-12">
            <button className="button submit" onClick={this.onCreateNewClient}>
              <span className="text">CREATE NEW</span>
            </button>
          </div>
        </div>

        <div className="body-container">
          <div className="row list-container">
            <div className="col-lg-3 col-md-4 col-sm-6">
              <span className="list-header">Clients</span>
            </div>
            <div className="col-lg-3 offset-lg-3 col-md-4 col-12">
              <select
                className="select-box list-dropdown"
                onChange={this.onStatusChange}
                value={this.state.status}
              >
                <option value="0">All Clients</option>
                <option value="4">Active Clients</option>
                <option value="5">Inactive Clients</option>
              </select>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <select
                className="select-box list-dropdown"
                onChange={this.onFilterChange}
                value={this.state.filter}
              >
                <option value="all">All Clients</option>
                <option value="recent">Recently Added</option>
                <option value="name_asc">Name A &#8594; Z</option>
                <option value="name_desc">Name Z &#8594; A</option>
                <option value="city_asc">Billing City A &#8594; Z</option>
                <option value="city_desc">Billing City Z &#8594; A</option>
              </select>
            </div>
          </div>

          <div className="data-grid-container">
            {!isMobileDevice() && (
              <div className="row">
                <div className="col-12">
                  <DataGrid
                    data={this.state.clients}
                    columns={columns}
                    onRowClick={(rowInfo) => {
                      // this.onDetails(get(rowInfo.original, "id", ""));
                    }}
                    onPageSizeChange={this.onPageSizeChange}
                    onPageNumberChange={this.onPageChange}
                    total={this.state.totalClients}
                    paginationLabel={"Clients per page"}
                    showPagination={true}
                    pageSize={this.state.pageSize}
                    pageNumber={this.state.pageNumber}
                  />
                </div>
              </div>
            )}

            {isMobileDevice() && (
              <div className="row" style={{ margin: 10 }}>
                {map(this.state.clients, (client) => {
                  return (
                    <ClientCard
                      client={client}
                      onDetails={(id) => {
                        this.onDetails(id);
                      }}
                    />
                  );
                })}
                <div className="row pagination pagination-align">
                  <div className="col-12">
                    <Pagination
                      onPageSizeChange={this.onPageSizeChange}
                      label={"Clients per page"}
                      onPageNumberChange={this.onPageChange}
                      total={this.state.totalClients}
                      pageSize={this.state.pageSize}
                      pageNumber={this.state.pageNumber}
                    />
                  </div>
                </div>
              </div>
            )}

            {isMobileDevice() && this.state.clients.length === 0 && (
              <div className="row">
                <div className="col-12 text-center">
                  <span>No Clients</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  clients: state.client.getAllSuccess,
  clientsError: state.client.getAllFail,
  searchConfig: state.config.clientSearch,
});

export default withRouter(connect(mapStateToProps)(Clients));
