import React from "react";
import "./dual-list-box.scss";
import * as classNames from "classnames";
import { clone, pullAll, remove } from "lodash";

class DualListBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allOptions: ["MS", "LPN", "MA", "LPC", "MSW", "LSW", "BSC"],
      availableOptions: [],
      selectedOptions: [],
      markedAvailableOptions: [],
      markedSelectedOptions: [],
    };
  }

  componentDidMount = () => {
    this.setState({
      availableOptions: clone(this.state.allOptions),
    });
  };

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.selectedOptions !== this.props.selectedOptions) {
      if (nextProps.selectedOptions) {
        let availableOptions = clone(this.state.allOptions);
        let selectedOptions = nextProps.selectedOptions;
        pullAll(availableOptions, selectedOptions);
        this.setState({
          availableOptions: availableOptions,
          selectedOptions: selectedOptions,
        });
      }
    }
  };

  onSelectAvailableOption = (option) => {
    let markedOptions = this.state.markedAvailableOptions;
    if (markedOptions.includes(option)) {
      remove(markedOptions, function (o) {
        return o == option;
      });
    } else {
      markedOptions.push(option);
    }
    this.setState({
      markedAvailableOptions: markedOptions,
    });
  };

  onSelectSelectedOption = (option) => {
    let markedOptions = this.state.markedSelectedOptions;
    if (markedOptions.includes(option)) {
      remove(markedOptions, function (o) {
        return o == option;
      });
    } else {
      markedOptions.push(option);
    }
    this.setState({
      markedSelectedOptions: markedOptions,
    });
  };

  onOptionSelect = () => {
    let availableOptions = clone(this.state.allOptions);
    let selectedOptions = this.state.selectedOptions;
    let markedOptions = this.state.markedAvailableOptions;
    markedOptions.forEach((element) => {
      selectedOptions.push(element);
    });
    pullAll(availableOptions, selectedOptions);
    this.setState({
      availableOptions: availableOptions,
      selectedOptions: selectedOptions,
      markedAvailableOptions: [],
    });
    this.props.onOptionsSelect(selectedOptions);
  };

  onOptionDeselect = () => {
    let availableOptions = clone(this.state.allOptions);
    let selectedOptions = this.state.selectedOptions;
    let markedOptions = this.state.markedSelectedOptions;
    pullAll(selectedOptions, markedOptions);
    pullAll(availableOptions, selectedOptions);
    this.setState({
      availableOptions: availableOptions,
      selectedOptions: selectedOptions,
      markedSelectedOptions: [],
    });
    this.props.onOptionsSelect(selectedOptions);
  };

  render() {
    return (
      <div className="row dual-list-box">
        <div className="col-5 list-box text-center">
          <div className="box-header">Available</div>
          {this.state.availableOptions.map((option) => {
            return (
              <div
                className={classNames("list-item", {
                  active: this.state.markedAvailableOptions.includes(option),
                })}
                onClick={() => {
                  this.onSelectAvailableOption(option);
                }}
              >
                {option}
              </div>
            );
          })}
        </div>
        <div className="col-2 my-auto">
          <div
            className="list-box-arrow text-center"
            onClick={this.onOptionSelect}
          >
            <span>
              <img src="./images/chevron-right-grey.svg" alt="" />
            </span>
          </div>
          <div
            className="list-box-arrow text-center"
            onClick={this.onOptionDeselect}
          >
            <span>
              <img src="./images/chevron-left-grey.svg" alt="" />
            </span>
          </div>
        </div>
        <div className="col-5 list-box text-center">
          <div className="box-header">Chosen</div>
          {this.state.selectedOptions.map((option) => {
            return (
              <div
                className={classNames("list-item", {
                  active: this.state.markedSelectedOptions.includes(option),
                })}
                onClick={() => {
                  this.onSelectSelectedOption(option);
                }}
              >
                {option}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default DualListBox;
