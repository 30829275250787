import React from "react";
import { isEqual, isNull } from "lodash";
import "./index.scss";
import { isEmpty } from "lodash/lang";

class SearchableDropdown extends React.Component {
  state = {
    dropdownOpen: false,
    selected: false,
    selectedValue: "",
    filteredOptions: [],
  };

  componentWillReceiveProps(nextProps, nextContext) {
    if (!isEqual(nextProps, this.props) && !isNull(nextProps)) {
      const { options, valueIdentifier, keyIdentifier, value, defaultValue } =
        nextProps;
      let selectedOption = "";
      if (value != defaultValue && (!isNull(options) || !isEmpty(options))) {
        options.map((option) => {
          if (option[keyIdentifier] == value) {
            selectedOption = option[valueIdentifier];
          }
        });
      }
      this.setState({
        selected: selectedOption != "",
        selectedValue: selectedOption,
        filteredOptions: this.filterOptions(
          options,
          valueIdentifier,
          selectedOption
        ),
      });
    }
  }

  onChange = (event) => {
    const { options, valueIdentifier } = this.props;
    const searchParam = event.target.value;
    this.setState({
      selectedValue: searchParam,
      filteredOptions: this.filterOptions(
        options,
        valueIdentifier,
        searchParam
      ),
    });
  };

  onOptionSelect = (option) => {
    const { keyIdentifier, valueIdentifier } = this.props;
    this.setState({
      selectedValue: option[valueIdentifier],
      selected: true,
      dropdownOpen: false,
    });
    this.props.onChange(option[keyIdentifier]);
  };

  onDropAction = (selectedValue, selected, dropdownOpen) => {
    this.input.focus();
    const { options, valueIdentifier } = this.props;
    this.setState({
      selectedValue,
      selected,
      dropdownOpen,
      filteredOptions: this.filterOptions(
        options,
        valueIdentifier,
        selectedValue
      ),
    });
  };

  filterOptions = (options, valueIdentifier, selectedValue) => {
    selectedValue = selectedValue.trim();
    if (isNull(selectedValue) || isEmpty(selectedValue)) {
      return options;
    }
    return options.filter(
      (option) =>
        option[valueIdentifier]
          .toLowerCase()
          .replaceAll(",", "")
          .includes(selectedValue.toLowerCase()) ||
        option[valueIdentifier].toLowerCase().replaceAll(",", "") ==
          selectedValue.toLowerCase()
    );
  };

  render() {
    const { selectedValue, selected, dropdownOpen, filteredOptions } =
      this.state;
    const { valueIdentifier, placeholder, defaultValue } = this.props;

    return (
      <div
        className={"searchable-dropdown-container"}
        onBlur={() => {
          this.setState({ dropdownOpen: false });
        }}
      >
        <input
          type="text"
          className={"input-field"}
          value={selectedValue}
          onChange={this.onChange}
          placeholder={placeholder}
          onFocus={() => {
            this.onDropAction(selectedValue, selected, true);
          }}
          ref={(ip) => (this.input = ip)}
        />

        {!selected && !dropdownOpen && (
          <i
            className={"action-button"}
            onClick={() => {
              this.onDropAction(selectedValue, selected, true);
            }}
          >
            <img src="./images/chevron-down-grey.svg" alt="" />
          </i>
        )}

        {dropdownOpen && (
          <i
            className={"action-button"}
            onClick={() => {
              this.onDropAction(selectedValue, selected, false);
            }}
          >
            <img src="./images/chevron-up-grey.svg" alt="" />
          </i>
        )}

        {selected && !dropdownOpen && (
          <i
            className={"action-button"}
            onClick={() => {
              this.props.onChange(defaultValue);
              this.onDropAction("", false, true);
            }}
          >
            <img src="./images/x-grey.svg" alt="" />
          </i>
        )}

        <div
          className={dropdownOpen ? "drop-down-list" : "drop-down-list-hidden"}
        >
          {filteredOptions &&
            filteredOptions.map((option, index) => (
              <span
                key={index}
                onMouseDown={() => {
                  this.onOptionSelect(option);
                }}
              >
                {option[valueIdentifier]}
              </span>
            ))}
          {!isNull(filteredOptions) && filteredOptions.length == 0 && (
            <span>No result found</span>
          )}
        </div>
      </div>
    );
  }
}

export default SearchableDropdown;
