import * as React from "react";
import * as classNames from "classnames";
import "./popUpModal.scss";

class PopUpModal extends React.Component {
    render() {

        const modalClassName = this.props.size === '' ? 'modal-dialog modal-md' : 'modal-dialog modal-' + this.props.size
        return (
            <div>
                <div
                    className={classNames("modal fade", {show: this.props.show})}
                    id={this.props.id ? this.props.id : 'exampleModal'}
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div className={modalClassName} role="document">
                        <div className="modal-content">
                            <div className="modal-header text-center">
                                <div className="col-12 text-center">
                                    <button
                                        type="button"
                                        className="close"
                                        onClick={this.props.onClose}
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                    <h5
                                        className="modal-title text-center"
                                        id="exampleModalLabel"
                                    >
                    <span className="my-auto">
                      <img src={this.props.icon} alt="" style={{padding: 20}}/>
                    </span>
                                        <span style={{marginLeft: "5px"}}>
                      {this.props.title}
                    </span>
                                    </h5>
                                </div>
                            </div>
                            <div className="modal-body">{this.props.children}</div>
                            {/*<div className="modal-footer">*/}
                            {/*    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>*/}
                            {/*    <button type="button" className="btn btn-primary">C</button>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PopUpModal;
