import { isEmpty } from "lodash";

const phoneNumberRegEx =
  /^(1s?)?((([0-9]{3}))|[0-9]{3})[s-]?[\0-9]{3}[s-]?[0-9]{4}$/;
const zipRegEx = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
const specialCharRegEx = /[!~`@#$%^&*()_+\=\[\]{};':"\\|,.<>\/?]+/;

function hasSpecialCharacters(value) {
  return specialCharRegEx.test(value)
    ? "Special characters not allowed"
    : undefined;
}

export function validateFirstName(value) {
  return isEmpty(value)
    ? "First name is required"
    : hasSpecialCharacters(value);
}

export function validateLastName(value) {
  return isEmpty(value) ? "Last name is required" : hasSpecialCharacters(value);
}

export function validatePhoneNumber(value) {
  // if (isEmpty(value)) {
  //   return "Phone Number is required";
  // }

  if (!phoneNumberRegEx.test(value)) {
    return "Phone Number is invalid";
  }
  return undefined;
}

export function validateZIPCode(zip) {
  if (!isEmpty(zip) && !zipRegEx.test(zip)) {
    return "Invalid ZIP Code";
  }
  return undefined;
}
