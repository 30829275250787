import React from 'react';
import DataGrid from "../../shared/data-grid/data-grid";
import BasicHeader from "../../shared/data-grid/custom-headers/basic-header";
import {get, map, startCase} from 'lodash';
import ReportCell from '../../shared/data-grid/custom-cells/report-cell';

class AllContactsReportView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            reportData: []
        }
    }

    componentWillReceiveProps(nextProps) {
        this.mapRecords(nextProps.reportData)
    }

    componentDidMount() {
        this.mapRecords(this.props.reportData)
    }

    mapRecords(reports) {
        const reportData = [];
        map(reports, (data => {
            const report = {
                name: (`${get(data, "lastName")} ${get(data, "firstName")} ${get(data, "middleName")}`),
                phone: data.phone,
                email: data.familyContactEmail,
                city: (data.billingCity),
            }
            reportData.push(report)
        }));
        this.setState({
            reportData: reportData
        })
    }


    render() {
        const columns = [
            {
                Header: () => <BasicHeader header={"Name"} fontSize={12}/>,
                accessor: "name",
                Cell: (row) => {
                    return <ReportCell value={row.value} index={row.index}/>;
                },
            },
            {
                Header: () => <BasicHeader header={"Phone Number"} fontSize={12}/>,
                accessor: "phone",
                Cell: (row) => {
                    return <ReportCell value={row.value} index={row.index}/>;
                },
            },
            {
                Header: () => <BasicHeader header={"Email"} fontSize={12}/>,
                accessor: "email",
                Cell: (row) => <ReportCell value={row.value} index={row.index}/>,
            },
            {
                Header: () => <BasicHeader header={"City"} fontSize={12}/>,
                accessor: "city",
                Cell: (row) => <ReportCell value={row.value} index={row.index}/>,
            }
        ];
        return (
            <DataGrid
                data={this.state.reportData}
                columns={columns}
                onRowClick={(rowInfo) => {
                    // this.onDetails(get(rowInfo.original, "id", ""));
                }}
                onPageSizeChange={this.props.onPageSizeChange}
                onPageNumberChange={this.props.onPageNumberChange}
                total={this.props.total}
                paginationLabel={"Organizations per page"}
                showPagination={true}
                pageSize={this.props.pageSize}
                pageNumber={this.props.pageNumber}
            />
        );
    }
}

export default AllContactsReportView;
