import React from "react";
import "./billingReport.scss";
import { DATEFILTEROPTIONS, REPORT_TYPES } from "../../constants/appConstants";
import moment from "moment";
import WorkEntryFilters from "./filters/workEntryFilter";
import StaffFilter from "./filters/staffFilter";
import ContactsReport from "./filters/contactsFilter";
import SCFilter from "./filters/SCFilter";
import { getAllClientsList } from "../../api/client";
import { getAllUsersList } from "../../api/user";
import {
  generateReport,
  generateWorkEntryReport,
  getReportData,
  getWorkEntryReport,
} from "../../api/report";
import ReportView from "./report-views/reportView";
import { connect } from "react-redux";
import StaffAllocationFilter from "./filters/staffAllocationFilter";

class BillingReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reportType: REPORT_TYPES.NOTES_REPORT,
      recordStatus: "all",
      reviewStatus: "all",
      clients: [],
      services: [],
      staff: [],
      selectedClient: -1,
      selectedService: -1,
      selectedStaff: -1,
      showViewer: false,
      reportData: [],
      fromDate: new Date(new Date().setDate(new Date().getDate() - 7)).setHours(
        0,
        0,
        0,
        0
      ),
      toDate: new Date().setHours(0, 0, 0, 0),
      dateRangeOption: DATEFILTEROPTIONS.CREATED,
      pageSize: 10,
      pageNumber: 0,
    };
  }

  componentDidMount() {
    this.getAllClients();
    // this.getAllServices();
    this.getAllStaff();
    this.onGenerateReport(0, 10);
  }

  onReportSelect = (e) => {
    const reportType = e.target.value;
    let fromDate;
    let dateRangeOption = DATEFILTEROPTIONS.CREATED
    if (
      reportType === REPORT_TYPES.SC_SUMMARY ||
      reportType === REPORT_TYPES.CLIENT_SUMMARY ||
      reportType === REPORT_TYPES.STAFF_SUMMARY
    ) {
      fromDate = new Date(new Date().getUTCFullYear(), 0, 1, 0, 0, 0, 0);
    } else {
      if (reportType == REPORT_TYPES.STAFF_ALLOCATION_SUMMARY){
        dateRangeOption = DATEFILTEROPTIONS.STAFF_ALLOCATION
      }
      fromDate = new Date(
        new Date().setDate(new Date().getDate() - 7)
      ).setHours(0, 0, 0, 0);
    }
    this.setState(
      {
        reportType: reportType,
        dateRangeOption: dateRangeOption,
        showViewer: false,
        fromDate: fromDate,
        toDate: new Date().setHours(0, 0, 0, 0),
      },
      () => {
        this.onGenerateReport(0, this.state.pageSize);
      }
    );
  };

  onStatusChange = (e) => {
    const status = e.target.value;
    this.setState(
      {
        recordStatus: status,
        pageNumber: 0,
      },
      () => {
        this.onGenerateReport(0, this.state.pageSize);
      }
    );
  };

  onReviewStatusChange = (e) => {
    const reviewStatus = e.target.value;
    this.setState(
      {
        reviewStatus: reviewStatus,
      },
      () => {
        this.onGenerateReport(0, this.state.pageSize);
      }
    );
  };

  onDateRangeChange = (e) => {
    const dateRangeOption = e.target.value;
    this.setState(
      {
        dateRangeOption: parseInt(dateRangeOption),
      },
      () => {
        this.onGenerateReport(0, this.state.pageSize);
      }
    );
  };

  onFromDateChange = (date) => {
    this.setState(
      {
        fromDate: date,
      },
      () => {
        this.onGenerateReport(0, this.state.pageSize);
      }
    );
  };

  onToDateChange = (date) => {
    this.setState(
      {
        toDate: date,
      },
      () => {
        this.onGenerateReport(0, this.state.pageSize);
      }
    );
  };

  onGenerateReport = (offset, limit) => {
    let fromDate = moment
      .utc(this.state.fromDate)
      .format("YYYY-MM-DD HH:mm:ss");
    let toDate = moment.utc(this.state.toDate).format("YYYY-MM-DD HH:mm:ss");
    if (
      this.state.reportType === REPORT_TYPES.SC_SUMMARY ||
      this.state.reportType === REPORT_TYPES.CLIENT_SUMMARY ||
      this.state.reportType === REPORT_TYPES.STAFF_SUMMARY
    ) {
      getReportData(
        this.state.reportType,
        fromDate,
        null,
        offset,
        limit,
        this.state.recordStatus
      )
        .then((res) => {
          this.setState({
            showViewer: true,
            reportData: res,
          });
        })
        .catch((e) => {
          console.log("getReportData error", e);
        });
    } else {
      getWorkEntryReport(
        this.state.reportType,
        this.state.reviewStatus,
        fromDate,
        toDate,
        this.state.dateRangeOption,
        this.state.selectedClient,
        this.state.selectedStaff,
        this.state.selectedService,
        offset,
        limit
      )
        .then((res) => {
          this.setState({
            showViewer: true,
            reportData: res,
          });
        })
        .catch((e) => {
          console.log("getWorkEntryReport error", e);
        });
    }
  };

  getAllClients() {
    getAllClientsList()
      .then((res) => {
        this.setState({
          clients: res,
        });
      })
      .catch((err) => {
        console.log("get all staff error ", err);
      });
  }

  getAllStaff() {
    getAllUsersList()
      .then((res) => {
        this.setState({
          staff: res,
        });
      })
      .catch((err) => {
        console.log("get all staff error ", err);
      });
  }

  onStaffChange = (value) => {
    this.setState(
      {
        selectedStaff: parseInt(value),
      },
      () => {
        this.onGenerateReport(0, this.state.pageSize);
      }
    );
  };

  onServiceChange = (value) => {
    this.setState(
      {
        selectedService: parseInt(value),
      },
      () => {
        this.onGenerateReport(0, this.state.pageSize);
      }
    );
  };

  onClientChange = (value) => {
    this.setState(
      {
        selectedClient: parseInt(value),
      },
      () => {
        this.onGenerateReport(0, this.state.pageSize);
      }
    );
  };

  onFileDownload = () => {
    let fromDate = moment
      .utc(this.state.fromDate)
      .format("YYYY-MM-DD HH:mm:ss");
    let toDate = moment.utc(this.state.toDate).format("YYYY-MM-DD HH:mm:ss");
    if (
      this.state.reportType === REPORT_TYPES.SC_SUMMARY ||
      this.state.reportType === REPORT_TYPES.CLIENT_SUMMARY ||
      this.state.reportType === REPORT_TYPES.STAFF_SUMMARY
    ) {
      generateReport(
        this.state.reportType,
        fromDate,
        null,
        this.state.recordStatus
      );
    } else {
      generateWorkEntryReport(
        this.state.reportType,
        this.state.reviewStatus,
        fromDate,
        toDate,
        this.state.dateRangeOption,
        this.state.selectedClient,
        this.state.selectedStaff,
        this.state.selectedService
      );
    }
  };

  render() {
    const isWorkEntryReport =
      this.state.reportType === REPORT_TYPES.PROMISE_BILLING_REPORT ||
      this.state.reportType === REPORT_TYPES.NOTES_REPORT ||
      this.state.reportType === REPORT_TYPES.STAFF_REPORT;
    return (
      <div className="report-body">
        <div className="row report-type-selector" >
          <div className="col-lg-2 col-md-2 col-sm-3">
            <div className="page-title">Reports</div>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-3">
            <div className="page-title">
              <span className="title">Select&nbsp;Type</span>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-6">
            <div className="">
              <div className="col-12 input-box text-left" style={{}}>
                <div>
                  <select
                    className="select-box"
                    onChange={this.onReportSelect}
                    value={this.state.reportType}
                  >
                    <option value={REPORT_TYPES.NOTES_REPORT}>
                      Notes Report
                    </option>
                    <option value={REPORT_TYPES.STAFF_REPORT}>
                      Staff Payroll Report
                    </option>
                    <option value={REPORT_TYPES.PROMISE_BILLING_REPORT}>
                      Promise Billing Report
                    </option>
                    <option value={REPORT_TYPES.CLIENT_SUMMARY}>
                      Client Summary
                    </option>
                    <option value={REPORT_TYPES.SC_SUMMARY}>
                      Supports Coordinators
                    </option>
                    <option value={REPORT_TYPES.STAFF_SUMMARY}>
                      Staff Summary
                    </option>
                    <option value={REPORT_TYPES.STAFF_ALLOCATION_SUMMARY}>
                      Staff Allocation Summary
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="row"
          style={{
            backgroundColor: "#FFF",
            border: "1px solid #E0E1E2",
            margin: 20,
          }}
        >
          <div className="col-lg-12 col-md-12 col-sm-12" style={{ padding: 20 }}>
            <div
              className="col-12"
              style={{ paddingLeft: 0, marginBottom: 20 }}
            >
              <span
                style={{
                  fontFamily: "Muli",
                  fontSize: "19px",
                  color: "#333333",
                }}
              >
                Filters
              </span>
            </div>
            {isWorkEntryReport && (
              <WorkEntryFilters
                fromDate={this.state.fromDate}
                toDate={this.state.toDate}
                onFromDateChange={this.onFromDateChange}
                onToDateChange={this.onToDateChange}
                onReviewStatusChange={this.onReviewStatusChange}
                dateRangeOption={this.state.dateRangeOption}
                onDateRangeChange={this.onDateRangeChange}
                clients={this.state.clients}
                // services={this.state.services}
                staff={this.state.staff}
                onClientChange={this.onClientChange}
                selectedClient={this.state.selectedClient}
                onServiceChange={this.onServiceChange}
                selectedService={this.state.selectedService}
                onStaffChange={this.onStaffChange}
                selectedStaff={this.state.selectedStaff}
              />
            )}
            {this.state.reportType === REPORT_TYPES.STAFF_SUMMARY && (
              <StaffFilter
                onStatusChange={this.onStatusChange}
                startDate={this.state.fromDate}
                onStartDateChange={this.onFromDateChange}
              />
            )}
            {this.state.reportType === REPORT_TYPES.CLIENT_SUMMARY && (
              <ContactsReport
                onStatusChange={this.onStatusChange}
                startDate={this.state.fromDate}
                onStartDateChange={this.onFromDateChange}
              />
            )}
            {this.state.reportType === REPORT_TYPES.SC_SUMMARY && (
              <SCFilter
                onStatusChange={this.onStatusChange}
                startDate={this.state.fromDate}
                onStartDateChange={this.onFromDateChange}
              />
            )}
            {this.state.reportType ===
              REPORT_TYPES.STAFF_ALLOCATION_SUMMARY && (
              <StaffAllocationFilter
                dateRangeOption={this.state.dateRangeOption}
                onDateRangeChange={this.onDateRangeChange}
                fromDate={this.state.fromDate}
                toDate={this.state.toDate}
                onFromDateChange={this.onFromDateChange}
                onToDateChange={this.onToDateChange}
                clients={this.state.clients}
                onClientChange={this.onClientChange}
                selectedClient={this.state.selectedClient}
                staff={this.state.staff}
                onStaffChange={this.onStaffChange}
                selectedStaff={this.state.selectedStaff}
              />
            )}

            {/* <div className="row col-4 offset-8"> */}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
              className="btn-section"
            >
              <div style={{ flex: 1 }}></div>
              {/* <div className="col-6"> */}
              <div style={{ margin: 1, flex: 0.5 }}>
                <button
                  className="button submit no-border"
                  onClick={() => {
                    let limit = parseInt(this.state.pageSize);
                    // let offset = parseInt(this.state.pageSize * this.state.pageNumber);
                    let offset = parseInt(0);
                    this.onGenerateReport(offset, limit);
                  }}
                >
                  <span
                    className="text"
                    style={{ fontFamily: "Muli", fontSize: 14 }}
                  >
                    Generate Report
                  </span>
                </button>
              </div>
              {/* </div> */}
              {/* <div className="col-6"> */}
              <div style={{ margin: 1, flex: 0.5 }}>
                <button
                  className="button basic only-border"
                  onClick={this.onFileDownload}
                  // style={{backgroundColor: '#A9A5A5'}}
                >
                  <span
                    className="text"
                    style={{
                      fontFamily: "Muli",
                      fontSize: 14,
                      color: "#5B6576",
                    }}
                  >
                    Download
                  </span>
                </button>
              </div>
              {/* </div> */}
            </div>
          </div>
        </div>

        {
          <div
            className="row"
            style={{
              backgroundColor: "#FFF",
              border: "1px solid #E0E1E2",
              margin: 20,
            }}
          >
            {this.state.showViewer && (
              <ReportView
                reportType={this.state.reportType}
                reportOption={this.state.dateRangeOption}
                reportData={this.state.reportData}
                // onPageSizeChange={this.props.onPageSizeChange}
                onPageSizeChange={(recordCount) => {
                  this.setState({
                    pageSize: recordCount,
                    pageNumber: 0,
                  });
                  this.onGenerateReport(0, parseInt(recordCount));
                }}
                onPageNumberChange={(pageNumber) => {
                  this.setState({
                    pageNumber: pageNumber,
                  });
                  let limit = parseInt(this.state.pageSize);
                  let offset = limit * pageNumber;
                  this.onGenerateReport(offset, limit);
                }}
                pageSize={this.state.pageSize}
                pageNumber={this.state.pageNumber}
              />
            )}
          </div>
        }
      </div>
    );
  }
}

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(BillingReport);
