import React from "react";
import moment from "moment";
import { DATE_TIME_FORMAT } from "../../constants/appConstants";

const style = {
  color: "#5B6576",
  fontFamily: "Muli",
  fontSize: 15,
  fontWeight: "bold",
  letterSpacing: 0,

  lineHeight: 2,
};
const contentStyle = {
  color: "#333333",
  fontFamily: "Muli",
  fontSize: 13,
  letterSpacing: 0,
  lineHeight: 1,
};

function StaffPayCard(props) {
  const row = props.data;
  return (
    <div className="col-12 visit-card">
      <div className="row">
        <div className="col-6">
          <div className="col-12" style={style}>
            Date
          </div>
          <div
            className="col-12 "
            style={{ wordBreak: "break-word", ...contentStyle }}
          >
            {moment(row.createdDate).format(DATE_TIME_FORMAT)}
          </div>
        </div>
        <div className="col-6">
          <div className="col-12" style={style}>
            Client
          </div>
          <div
            className="col-12 "
            style={{ wordBreak: "break-word", ...contentStyle }}
          >
            {row.clientName}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-6">
          <div className="col-12 " style={style}>
            Service Code
          </div>
          <div
            className="col-12 "
            style={{ wordBreak: "break-word", ...contentStyle }}
          >
            {row.serviceCode}
          </div>
        </div>
        <div className="col-6">
          <div className="col-12" style={style}>
            Start
          </div>
          <div
            className="col-12 "
            style={{ wordBreak: "break-word", ...contentStyle }}
          >
            {moment(row.startTime).format(DATE_TIME_FORMAT)}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <div className="col-12" style={style}>
            End
          </div>
          <div
            className="col-12 "
            style={{ wordBreak: "break-word", ...contentStyle }}
          >
            {moment(row.endTime).format(DATE_TIME_FORMAT)}
          </div>
        </div>
        <div className="col-6">
          <div className="col-12" style={style}>
            Pay
          </div>
          <div
            className="col-12 "
            style={{ wordBreak: "break-word", ...contentStyle }}
          >
            {row.netAmount}
          </div>
        </div>
      </div>
      <hr className="body-line" />
      <div className="row">
        <div
          className="col-12"
          style={{ ...style, marginLeft: 10, marginTop: 5 }}
        >
          Notes
        </div>

        <div
          className="col-12 "
          style={{
            wordBreak: "break-word",
            margin: 10,
            marginTop: 1,
            ...contentStyle,
          }}
        >
          {row.notes}
        </div>
      </div>
    </div>
  );
}

export default StaffPayCard;
