import {get, post, put} from "../util/httpService";
import {CLIENT_URL} from "../constants/appConstants";
import * as _ from "lodash";
import {getToken, setAuthData} from "../util/auth";

export async function createClient(client) {
    const authToken = getToken();
    const headers = {Authorization: `bearer ${authToken}`};
    try {
        const response = await post(CLIENT_URL, client, {headers: headers});
        return _.get(response, "data", {});
    } catch (e) {
        throw _.get(e, "response.data", {});
    }
}

export async function updateClient(client) {
    const authToken = getToken();
    const headers = {Authorization: `bearer ${authToken}`};
    try {
        const response = await put(CLIENT_URL, client, {headers: headers});
        return _.get(response, "data", {});
    } catch (e) {
        throw _.get(e, "response.data", {});
    }
}

export async function getAllClients(
    pageNumber,
    pageSize,
    filter,
    searchParams,
    status
) {
    const authToken = getToken();
    const headers = {Authorization: `bearer ${authToken}`};
    try {
        const response = await get(
            `${CLIENT_URL}?offset=${pageNumber}&limit=${pageSize}&filter=${filter}&name=${searchParams}&status=${status}`,
            {headers: headers}
        );
        return _.get(response, "data", {});
    } catch (e) {
        if (e.response.status === 401) {
            setAuthData(null);
        }
        throw _.get(e, "response.data", {});
    }
}

export async function getClientById(clientId) {
    const authToken = getToken();
    const headers = {Authorization: `bearer ${authToken}`};
    try {
        const response = await get(`/clientbyId?client_id=${clientId}`, {
            headers: headers,
        });
        return _.get(response, "data", {});
    } catch (e) {
        throw _.get(e, "response.data", {});
    }
}

export async function getAllClientsList() {
    const authToken = getToken();
    const headers = {Authorization: `bearer ${authToken}`};
    try {
        const response = await get(`${CLIENT_URL}/client_list`, {
            headers: headers,
        });
        return _.get(response, "data", {});
    } catch (e) {
        throw _.get(e, "response.data", {});
    }
}

export async function getActiveClientsList() {
    const authToken = getToken();
    const headers = {Authorization: `bearer ${authToken}`};
    try {
        const response = await get(`${CLIENT_URL}/client_list/active`, {
            headers: headers,
        });
        return _.get(response, "data", {});
    } catch (e) {
        throw _.get(e, "response.data", {});
    }
}

export async function getAssignedClientsList() {
    const authToken = getToken();
    const headers = {Authorization: `bearer ${authToken}`};
    try {
        const response = await get(`${CLIENT_URL}/client_list/assigned`, {
            headers: headers,
        });
        return _.get(response, "data", {});
    } catch (e) {
        throw _.get(e, "response.data", {});
    }
}