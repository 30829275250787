import { isEmpty } from "lodash";

export function validateEmail(email) {
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (isEmpty(email)) {
    return "Email is required";
  }

  if (!emailRegex.test(email)) {
    return "Email is invalid";
  }
  return undefined;
}

export function validateEmailSC(email) {
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (isEmpty(email)) {
    return;
  }

  if (!emailRegex.test(email)) {
    return "Email is invalid";
  }
  return undefined;
}

export function validatePassword(password) {
  if (isEmpty(password)) {
    return "Password is required";
  }
  return undefined;
}

export function validateChangePassword(password) {
  if (isEmpty(password)) {
    return "Password is required";
  }

  if (password.length < 8) {
    return "Password must be at least 8 characters.";
  }
  return undefined;
}

export function validateConfirmPassword(password) {
  if (isEmpty(password)) {
    return "Confirm new Password is required";
  }

  return undefined;
}

export function validateToken(token) {
  if (isEmpty(token)) {
    return "Token is required";
  }

  return undefined;
}

export function validatePasswordFormat(password) {
  const passwordRegex =
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
  if (!passwordRegex.test(password)) {
    return "Please provide a password that contain a special character, lowercase letter, uppercase letter and a number.";
  }

  return undefined;
}

export function validateSandataId(id) {
  const regex = /^(([a-zA-Z0-9]{6})|(0000\\d{5}))$/;
  if (!regex.test(id)) {
    return "Please provide a sandata id that matches the format";
  }

  return undefined;
}

export function validateZipCode(code) {
  const regex = /^\\d{5}(\\d{4})?$/;
  if (!regex.test(code)) {
    return "Please provide a valid postal zip code";
  }

  return undefined;
}

export function validateSSN(ssn) {
  if (isEmpty(ssn)) {
    return "SSN Cannot be blank";
  }
  if (ssn && ssn.length !== 11) {
    return "Please provide a valid SSN";
  }
  return undefined;
}

export function validateMobileNumber(number) {
  if (isEmpty(number)) {
    return "Mobile number cannot be blank";
  }
  if (number) {
    return "Please provide a valid Mobile number";
  }
  return undefined;
}
