import * as React from 'react';
import '../data-grid.scss';

class Actions extends React.Component {

    onEdit = (event) => {
        this.props.onEdit(this.props.actionId, event)
    };

    render() {
        return (

            <div className='cell action-cell' >
                <div className='col-12 text-center' onClick={this.onEdit}>
                    <img src="/images/edit-green.svg" alt="Edit"/>
                </div>
            </div>
        );
    }
}

export default Actions;
