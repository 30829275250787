import React from "react";
import { VISIT_STATUS } from "../../constants/appConstants";
import * as ClassNames from "classnames";
import LinkCell from "../shared/data-grid/custom-cells/link-cell";

const style = { lineHeight: 1, marginBottom: 4 };

class VisitCard extends React.Component {
  render() {
    const visit = this.props.visit;
    return (
      <div className="col-12 visit-card">
        <div className="row">
          {visit.status === VISIT_STATUS.ABANDONED && (
            <div
              className={ClassNames("abandoned-status col-6")}
              style={{ backgroundColor: "transparent" }}
            >
              <img src="./images/abandoned.svg" alt="" />
              &nbsp;
              <span>Abandoned</span>
            </div>
          )}

          {visit.status === VISIT_STATUS.COMPLETED && (
            <div
              className={ClassNames("completed-status col-6")}
              style={{ backgroundColor: "transparent" }}
            >
              <img src="./images/completed-circle.svg" alt="" />
              &nbsp;
              <span>Completed</span>
            </div>
          )}

          {visit.status === VISIT_STATUS.IN_PROGRESS && (
            <div
              className={ClassNames("progress-status col-6")}
              style={{ backgroundColor: "transparent" }}
            >
              <img src="./images/inprogress.svg" alt="" />
              &nbsp;
              <span>In Progress</span>
            </div>
          )}
        </div>
        <div className="row">
          <div className="col-6">
            <div className="col-12 label" style={style}>
              Client Name
            </div>
          </div>
          <div className="col-6">
            <div className="col-12 label">Service Name</div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div className="col-12 value">{visit.clientName}</div>
          </div>
          <div className="col-6">
            <div className="col-12 value">{visit.service}</div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div className="col-12 label">Clock In</div>
            <div className="col-12 value">{visit.clockIn}</div>
          </div>
          <div className="col-6">
            <div className="col-12 label">Clock Out</div>

            <div className="col-12 value">
              {visit.status === VISIT_STATUS.IN_PROGRESS ? "-" : visit.clockOut}
            </div>
          </div>
          <div className="col-6">
            <div className="col-12 label">Date In</div>
            <div className="col-12 value">{visit.clockInDate}</div>
          </div>
          <div className="col-6">
            <div className="col-12 label">Date Out</div>
            <div className="col-12 value">
              {visit.status === VISIT_STATUS.IN_PROGRESS
                ? "-"
                : visit.clockOutDate}
            </div>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-12">
            <div className="col-12 label">Notes</div>
          </div>
          <div className="col-12">
            <LinkCell
              value={visit.notes}
              index={visit.id}
              actionId={visit.id}
              onEdit={this.props.onEdit.bind(visit.id)}
            />
            {/*<div className='col-12 notes-content'>*/}
            {/*    {visit.notes}*/}
            {/*</LinkCelldiv>*/}
          </div>
        </div>
      </div>
    );
  }
}

export default VisitCard;
