import React from "react";

function SupportCard(props) {
  const user = props.user;
  return (
    <div
      className="col-12 visit-card"
      onClick={() => {
        props.onDetails(user.id);
      }}
    >
      <div className="row">
        <div className="col-6">
          <div className="col-12 label"> Name</div>
          <div className="col-12 value" style={{ wordBreak: "break-word" }}>
            {user.name}
          </div>
        </div>
        <div className="col-6">
          <div className="col-12 label">Company Name</div>
          <div className="col-12 value" style={{ wordBreak: "break-word" }}>
            {user.companyName}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <div className="col-12 label">Phone</div>
          <div className="col-12 value" style={{ wordBreak: "break-word" }}>
            {user.phone}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <div className="col-12 label">City</div>
          <div className="col-12 value" style={{ wordBreak: "break-word" }}>
            {user.city}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SupportCard;
