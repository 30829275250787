import React from "react";
import "./serviceSummary.scss";

export const ServiceSummaryMobile = ({ data }) => {
  return (
    <div>
      {data.map((item, i) => {
        return (
          <div className="card-sm">
            <div>
              <div className="titles-sm">Service Code</div>
              <div className="text-sm">{item.serviceCode}</div>
            </div>

            <div>
              <div className="titles-sm">Service End Date</div>
              <div className="text-sm">{item.serviceEndDate}</div>
            </div>

            <div />

            <div>
              <div className="titles-sm">Unit Rate</div>
              <div className="text-sm">{item.serviceUnitRate}</div>
            </div>

            <div>
              <div className="titles-sm">Unit Length</div>
              <div className="text-sm">{item.serviceUnitLength}</div>
            </div>

            <div>
              <div className="titles-sm">Units Authorized</div>
              <div className="text-sm">{item.serviceUnitsAuthorized}</div>
            </div>

            <div>
              <div className="titles-sm">Units Utilized</div>
              <div className="text-sm">{item.unitsUtilized}</div>
            </div>

            <div>
              <div className="titles-sm">Units Remaining</div>
              <div className="text-sm">{item.unitsRemaining}</div>
            </div>

            <div>
              <div className="titles-sm">Allow Negative U.</div>
              <div className="text-sm">{item.serviceUnitsAuthorized}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
