import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getSCById } from "../../../api/user";
import { getQueryParameter } from "../../../util/url";
import {
  get,
  isArray,
  isEmpty,
  isEqual,
  isNull,
  isUndefined,
  map,
  startCase,
  toNumber,
} from "lodash";
import { validatePassword } from "../../login/loginValidator";
import { changePassword } from "../../../redux/auth/authAction";
import { ALERT_TYPES, RECORD_TYPES } from "../../../constants/appConstants";
import { NotificationManager } from "react-notifications";
import { getUserStatus } from "../../../util/util";

class SCDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      staffDetails: null,
      selectedStaffId: null,
      credentials: [],
      fileList: [],
      password: "*********",
      showPasswordError: false,
    };
  }

  componentDidMount() {
    const userId = getQueryParameter("id");
    getSCById(userId).then((res) => {
      const user = this.mapUser(res);
      this.setState({
        staffDetails: user,
        selectedStaffId: userId,
        credentials: isEmpty(res.credentials) ? [] : res.credentials.split(","),
        fileList: isArray(res.files) ? res.files : [],
      });
    });
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (
      !isEqual(
        nextProps.changePasswordSuccess,
        this.props.changePasswordSuccess
      ) &&
      !isNull(nextProps.changePasswordSuccess)
    ) {
      this.createNotification(
        ALERT_TYPES.SUCCESS,
        "Password change successful"
      );
    }
    if (
      !isEqual(nextProps.changePasswordFail, this.props.changePasswordFail) &&
      !isNull(nextProps.changePasswordFail)
    ) {
      this.createNotification(ALERT_TYPES.ERROR, "Fail to change password");
    }
  }

  mapUser = (userData) => {
    let client = {
      firstName: get(userData, "firstName", ""),
      middleName: get(userData, "middleName", ""),
      lastName: get(userData, "lastName", ""),
      email: get(userData, "email", ""),
      password: get(userData, "password", ""),
      userType: get(userData, "userType"),
      reportTo: 0,
      salutation: get(userData, "salutation", ""),
      suffix: get(userData, "suffix", ""),
      dob: new Date(get(userData, "dob", "")),
      title: get(userData, "title", ""),
      ssn_tin: get(userData, "ssn_tin", ""),
      phone: get(userData, "phone", ""),
      memo: get(userData, "memo", ""),
      mobile: get(userData, "mobile", ""),
      hasRelevantDegree: this.state.hasRelevantDegree,
      credentials: this.state.credentials.toLocaleString(),
      percentagePaid: parseFloat(get(userData, "percentagePaid", 0)),
      billingStreet: get(userData, "billingStreet", ""),
      billingCity: get(userData, "billingCity", ""),
      billingState: get(userData, "billingState", ""),
      billingZIP: get(userData, "billingZIP", ""),
      billingCountry: get(userData, "billingCountry", ""),
      orgAuthentication: get(userData, "orgAuthentication", ""),
      company: get(userData, "company", ""),
      entity: get(userData, "entity", ""),
      status: getUserStatus(parseInt(userData.status)),
      isAuthorized: get(userData, "isAuthorized", false),
    };
    return client;
  };

  onEdit = () => {
    this.props.history.push(
      `/admin/edit-sc?id=${this.state.selectedStaffId}&staffType=4`
    );
  };

  getFileIcon = (value) => {
    switch (value.toLowerCase()) {
      case "pdf":
        return "/images/pdf.png";
      case "docx":
      case "doc":
        return "/images/doc.png";
      case "ppt":
      case "pptx":
        return "/images/doc.png";
      case "xls":
      case "xlsm":
      case "xlsx":
        return "/images/excel.png";
      case "png":
        return "/images/doc.png";
      case "jpeg":
        return "/images/doc.png";
      default:
        return "/images/doc.png";
    }
  };

  getFileType = (value) => {
    return value.toUpperCase();
  };

  getFileName = (value) => {
    if (value.length > 10) {
      value = value.substring(0, 10) + "...";
    }
    return value;
  };

  onChangePassword = () => {
    const password = this.state.password;
    if (isUndefined(validatePassword(password))) {
      this.props.dispatch(changePassword(password));
    } else {
      this.setState({
        showPasswordError: true,
      });
    }
  };

  createNotification(alertType, alertMessage) {
    switch (alertType) {
      case ALERT_TYPES.INFO:
        NotificationManager.info(alertMessage, "", 2000);
        break;
      case ALERT_TYPES.SUCCESS:
        NotificationManager.success(alertMessage, "", 2000);
        break;
      case ALERT_TYPES.WARNING:
        NotificationManager.warning(alertMessage, "", 2000);
        break;
      case ALERT_TYPES.ERROR:
        NotificationManager.error(alertMessage, "", 2000);
        break;
      default:
        break;
    }
  }

  getUserType = () => {
    let recordType = null;
    map(RECORD_TYPES, (record) => {
      if (record.value === toNumber(get(this.state.staffDetails, "userType"))) {
        recordType = startCase(record.type);
      }
    });
    return recordType;
  };

  render() {
    const { staffDetails } = this.state;
    return (
      <div className="col-lg-10 col-md-10 col-sm-10 offset-lg-1 offset-lg-1 offset-lg-1">
        <div className="row">
          <div
            className="row col-lg-2 col-md-2 col-sm-4"
            onClick={() => {
              this.props.history.goBack();
            }}
          >
            <img src="./images/chevron-left-green.svg" alt="" />
            <span
              style={{
                fontFamily: "Muli",
                color: "#333333",
                fontSize: 15,
                padding: 10,
              }}
            >
              Back
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="client-details">Supports Coordinator Details</div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            {/* <button className="button btn_delete" size="sm">
              DELETE
            </button> */}
            <div className="row">
              <div className="col-3 offset-9">
                <button className="button btn_edit" onClick={this.onEdit}>
                  EDIT
                </button>
              </div>
            </div>
          </div>
        </div>
        {/*<div className="row" style={{background: "#FFFFFF", marginTop:10}}>*/}
        {/*    <div className="col-lg-12 col-md-12 col-sm-12">*/}
        {/*        <img*/}
        {/*            className="banner"*/}
        {/*            src="./images/banner.png"*/}
        {/*            alt=""*/}
        {/*        />*/}
        {/*    </div>*/}
        {/*    <div className="col-lg-12 col-md-12 col-sm-12 text-center">*/}
        {/*        <div className="client-details"*/}
        {/*             style={{fontSize: 21}}>{`${get(staffDetails, 'firstName', "")} ${get(staffDetails, 'lastName', "")}`}</div>*/}
        {/*    </div>*/}
        {/*</div>*/}
        <div className="row">
          <div
            className="page-content"
            style={{ width: "100%", marginTop: 20 }}
          >
            <div className="row">
              <div className="col-12">
                <span className="form-title">
                  Supports Coordinator Information
                </span>
              </div>
            </div>
            <hr />
            <div className="row" style={{ width: "100%" }}>
              <div className="col-6">
                <div className="header_text">Name</div>
                <div className="title_text">
                  {`${get(staffDetails, "firstName", "")} ${get(
                    staffDetails,
                    "lastName",
                    ""
                  )}`}
                </div>
              </div>
              <div className="col-6">
                <div className="header_text">Company Name</div>
                <div className="title_text">
                  {get(staffDetails, "company", "")}
                </div>
              </div>
            </div>
            <div className="row" style={{ width: "100%" }}>
              <div className="col-6">
                <div className="header_text">First Name</div>
                <div className="title_text">
                  {get(staffDetails, "firstName", "-")}
                </div>
              </div>
              {/*<div className="col-6">*/}
              {/*    <div className="header_text">Middle Name</div>*/}
              {/*    <div className="title_text">*/}
              {/*        {get(staffDetails, 'middleName', "-")}*/}
              {/*    </div>*/}
              {/*</div>*/}
              <div className="col-6">
                <div className="header_text">Last Name</div>
                <div className="title_text">
                  {get(staffDetails, "lastName", "-")}
                </div>
              </div>
            </div>
            <div className="row" style={{ width: "100%" }}>
              {/*<div className="col-6">*/}
              {/*    <div className="header_text">Last Name</div>*/}
              {/*    <div className="title_text">*/}
              {/*        {get(staffDetails, 'lastName', "-")}*/}
              {/*    </div>*/}
              {/*</div>*/}
              <div className="col-6">
                <div className="header_text">Suffix</div>
                <div className="title_text">
                  {get(staffDetails, "suffix", "-")}
                </div>
              </div>
            </div>
            <div className="row" style={{ width: "100%" }}>
              {/*<div className="col-6">*/}
              {/*    <div className="header_text">Birthday</div>*/}
              {/*    <div className="title_text">*/}
              {/*        {isNull(get(staffDetails, 'dob', null)) ? "" : moment(staffDetails.dob).format("DD/MM/YYYY")}*/}
              {/*    </div>*/}
              {/*</div>*/}
              <div className="col-6">
                <div className="header_text">Status</div>
                <div className="title_text">
                  {get(staffDetails, "status", "")}
                </div>
              </div>
            </div>
            <div className="row" style={{ width: "100%" }}>
              <div className="col-6">
                <div className="header_text">Title</div>
                <div className="title_text">
                  {get(staffDetails, "title", "")}
                </div>
              </div>
              <div className="col-6">
                <div className="header_text">Phone</div>
                <div className="title_text">
                  {get(staffDetails, "phone", "")}
                </div>
              </div>
            </div>
            <div className="row" style={{ width: "100%" }}>
              <div className="col-6">
                <div className="header_text">Email</div>
                <div className="title_text">
                  {get(staffDetails, "email", "")}
                </div>
              </div>
              <div className="col-6">
                <div className="header_text">Mobile</div>
                <div className="title_text">
                  {get(staffDetails, "mobile", "")}
                </div>
              </div>
            </div>
            {/*<hr/>*/}
            {/*<div className="row page-content-grey" style={{width: "100%", marginLeft: 10}}>*/}
            {/*  <div className="col-4 input-box">*/}
            {/*    <div className="header_text">Password</div>*/}
            {/*    <input*/}
            {/*      type="password"*/}
            {/*      name="hasRelevantDegree"*/}
            {/*      value={this.state.password}*/}
            {/*      style={{marginRight: 5}}*/}
            {/*      onChange={(e)=>{this.setState({*/}
            {/*        password:e.target.value*/}
            {/*      })}}*/}
            {/*      className='box text-box'*/}
            {/*    />*/}
            {/*    {this.state.showPasswordError && <p className='error-message text-left'>*/}
            {/*      <i><img src="./images/alert-circle.svg" alt=""/></i>Invalid Password</p>}*/}
            {/*  </div>*/}
            {/*  <div className="col-4 offset-4 text-right" style={{marginTop: 25}}>*/}
            {/*    <div className='change-password'>CHANGE PASSWORD</div>*/}
            {/*  </div>*/}
            {/*</div>*/}
            <div className="row" style={{ width: "100%" }}>
              <div className="col-12">
                <div className="header_text">Internal Memo</div>
                <div className="title_text">
                  {get(staffDetails, "memo", "")}
                </div>
              </div>
            </div>
          </div>
          <div className="page-content" style={{ width: "100%" }}>
            <div className="row">
              <div className="col-12">
                <span className="form-title">Address Information</span>
              </div>
            </div>
            <hr />
            <div className="row" style={{ width: "100%" }}>
              <div className="col-6">
                <div className="header_text">Mailing Street Address</div>
                <div className="title_text">
                  {get(staffDetails, "billingStreet", "")}
                </div>
              </div>
              <div className="col-6">
                <div className="header_text">Mailing City</div>
                <div className="title_text">
                  {get(staffDetails, "billingCity", "")}
                </div>
              </div>
            </div>
            <div className="row" style={{ width: "100%" }}>
              <div className="col-6">
                <div className="header_text">Mailing State/Province</div>
                <div className="title_text">
                  {get(staffDetails, "billingState", "")}
                </div>
              </div>
              <div className="col-6">
                <div className="header_text">Mailing Zip/Postal Code</div>
                <div className="title_text">
                  {get(staffDetails, "billingZIP", "")}
                </div>
              </div>
            </div>
            <div className="row" style={{ width: "100%" }}>
              <div className="col-6">
                <div className="header_text">Mailing Country</div>
                <div className="title_text">
                  {get(staffDetails, "billingCountry", "")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  changePasswordSuccess: state.auth.changePasswordSuccess,
  changePasswordFail: state.auth.changePasswordFail,
});

export default withRouter(connect(mapStateToProps)(SCDetails));
