import React from "react";
import {ADMIN_VERIFICATION, DATEFILTEROPTIONS} from "../../../constants/appConstants";
import DatePickerComponent from "../../shared/date-picker/datePickerComponent";
import '../billingReport.scss'
import SearchableDropdown from "../../shared/searchable-dropdown";

export default class StaffAllocationFilter extends React.Component {

    render() {
        return (
            <div className='row' style={{marginBottom: 0}}>
                <div className='col-md-2 col-12 input-box text-left' style={{marginBottom: 0}}>
                    <div className="label">Group</div>
                    <div>
                        <select
                            className="select-box box"
                            onChange={this.props.onDateRangeChange}
                            value={this.props.dateRangeOption}
                        >
                            <option value={DATEFILTEROPTIONS.CLIENT_ALLOCATION}>Client</option>
                            <option value={DATEFILTEROPTIONS.STAFF_ALLOCATION}>Staff</option>
                        </select>
                    </div>
                </div>
                <div className='col-md-2 col-12 input-box text-left' style={{marginBottom: 0}}>
                    <div className="label">Start Date</div>
                    <DatePickerComponent
                        selected={this.props.fromDate}
                        onChange={this.props.onFromDateChange}
                        dateFormat={"MM/DD/YYYY"}
                        className={'box text-box calendar-icon'}
                    />
                </div>
                <div className="col-md-2 col-12 input-box" style={{marginBottom: 0}}>
                    <div className="label">End Date</div>
                    <DatePickerComponent
                        selected={this.props.toDate}
                        onChange={this.props.onToDateChange}
                        dateFormat={"MM/DD/YYYY"}
                        className={'box text-box calendar-icon'}
                        minDate={this.props.startDate}
                    />
                </div>
                <div className="col-md-2 col-12 input-box" style={{ marginBottom: 20 }}>
                    <div className="label">Staff Person</div>
                    <div className={"searchable-dropdown"}>
                        <SearchableDropdown options={this.props.staff} value={this.props.selectedStaff}
                                            defaultValue={-1}
                                            keyIdentifier="userId" valueIdentifier="name"
                                            placeholder="Search Staff Person"
                                            onChange={this.props.onStaffChange}/>
                    </div>
                </div>
                <div className="col-md-2 col-12 input-box" style={{ marginBottom: 10 }}>
                    <div className="label">Client</div>
                    <div className={"searchable-dropdown"}>
                        <SearchableDropdown options={this.props.clients} value={this.props.selectedClient}
                                            defaultValue={-1}
                                            keyIdentifier="clientId" valueIdentifier="name"
                                            placeholder="Search Client"
                                            onChange={this.props.onClientChange}/>
                    </div>
                </div>
            </div>
        );
    }
}
