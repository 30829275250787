import {call, put, takeEvery} from "redux-saga/effects";
import {USER} from "../../constants/actionTypes";
import {createSC, createUser, getAllUsers, getSCs, updateSC, updateUser} from "../../api/user";
import {
    createSCFail,
    createSCSuccess,
    createUserFail,
    createUserSuccess,
    getAllUsersByType,
    getAllUsersByTypeFail,
    getAllUsersByTypeSuccess,
    getAllUsersFail,
    getAllUsersSuccess,
    getSCsFail,
    getSCsSuccess,
    resetCreateSC,
    resetCreateUser,
    resetGetAllUsers,
    resetGetAllUsersByType,
    resetGetSCs,
    resetUpdateSC,
    resetUpdateUser,
    updateSCFail,
    updateSCSuccess,
    updateUserFail,
    updateUserSuccess
} from "./userActions";

export function* watchCreateUser() {
    yield takeEvery(USER.CREATE_USER, function* (userData) {
        const {user} = userData;
        yield call(handleCreateUser, user);
    });
}

function* handleCreateUser(user) {
    yield put(resetCreateUser());
    try {
        const userData = yield call(createUser, user);
        yield put(createUserSuccess(userData));
    } catch (e) {
        yield put(createUserFail(e));
    }
}

export function* watchUpdateUser() {
    yield takeEvery(USER.UPDATE_USER, function* (userData) {
        const {user} = userData;
        yield call(handleUpdateUser, user);
    });
}

function* handleUpdateUser(user) {
    yield put(resetUpdateUser());
    try {
        const userData = yield call(updateUser, user);
        yield put(updateUserSuccess(userData));
    } catch (e) {
        yield put(updateUserFail(e));
    }
}

export function* watchGetAllUser() {
    yield takeEvery(USER.GET_USERS, function* (userData) {
        const {pageNumber, pageSize, filter, searchParams, userType, status} = userData;
        yield call(handleGetAllUser, pageNumber, pageSize, filter, searchParams, userType, status);
    });
}

function* handleGetAllUser(pageNumber, pageSize, filter, searchParams, userType, status) {
    yield put(resetGetAllUsers());
    try {
        pageNumber = pageNumber * pageSize;
        const users = yield call(
            getAllUsers,
            pageNumber,
            pageSize,
            filter,
            searchParams,
            userType,
            status
        );
        yield put(getAllUsersSuccess(users));
    } catch (e) {
        yield put(getAllUsersFail(e));
    }
}

export function* watchGetAllUserByType() {
    yield takeEvery(USER.GET_USERS_BY_TYPE, function* (userData) {
        const {type, searchParams} = userData;
        yield call(handleGetAllUserByType, type, searchParams);
    });
}

function* handleGetAllUserByType(type, searchParams) {
    yield put(resetGetAllUsersByType());
    try {
        const users = yield call(getAllUsersByType, type, searchParams);
        yield put(getAllUsersByTypeSuccess(users));
    } catch (e) {
        yield put(getAllUsersByTypeFail(e));
    }
}

export function* watchCreateSC() {
    yield takeEvery(USER.CREATE_SC, function* (userData) {
        const {user} = userData;
        yield call(handleCreateSC, user);
    });
}

function* handleCreateSC(user) {
    yield put(resetCreateSC());
    try {
        const userData = yield call(createSC, user);
        yield put(createSCSuccess(userData));
    } catch (e) {
        yield put(createSCFail(e));
    }
}

export function* watchUpdateSC() {
    yield takeEvery(USER.UPDATE_SC, function* (userData) {
        const {user} = userData;
        yield call(handleUpdateSC, user);
    });
}

function* handleUpdateSC(user) {
    yield put(resetUpdateSC());
    try {
        const userData = yield call(updateSC, user);
        yield put(updateSCSuccess(userData));
    } catch (e) {
        yield put(updateSCFail(e));
    }
}

export function* watchGetAllSC() {
    yield takeEvery(USER.GET_SCS, function* (userData) {
        const {pageNumber, pageSize, filter, searchParams, status} = userData;
        yield call(handleGetAllSCs, pageNumber, pageSize, filter, searchParams, status);
    });
}

function* handleGetAllSCs(pageNumber, pageSize, filter, searchParams, status) {
    yield put(resetGetSCs());
    try {
        pageNumber = pageNumber * pageSize;
        const users = yield call(
            getSCs,
            pageNumber,
            pageSize,
            filter,
            searchParams,
            status
        );
        yield put(getSCsSuccess(users));
    } catch (e) {
        yield put(getSCsFail(e));
    }
}
