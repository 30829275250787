import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getQueryParameter } from "../../../util/url";
import { get, isArray, isEmpty, isUndefined } from "lodash";
import "./clientDetails.scss";
import BasicInformation from "./basicInformation";
import ServiceDetails from "./serviceDetails";
import { getClientById } from "../../../api/client";
import { getUserStatus } from "../../../util/util";
import moment from "moment";
import { DATE_FORMAT } from "../../../constants/appConstants";

class clientDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedClientId: "",
      activeTabIndex: 0,
      initialValues: null,
      openModal: false,
      fileList: [],
    };
  }

  componentDidMount() {
    const clientId = getQueryParameter("id");
    if (!isEmpty(clientId) && !isUndefined(clientId)) {
      getClientById(clientId).then((res) => {
        const client = this.mapClient(res);
        this.setState({
          selectedClientId: clientId,
          initialValues: client,
          fileList: isArray(res.files) ? res.files : [],
          clientServices: client.clientServices,
        });
      });
    }
  }

  mapClient = (clientData) => {
    let client = {
      firstName: get(clientData, "firstName", ""),
      middleName: get(clientData, "middleName", ""),
      lastName: get(clientData, "lastName", ""),
      mci: get(clientData, "mci", ""),
      mpi: get(clientData, "mpi", ""),
      diagnosticCode: get(clientData, "diagnosticCode", ""),
      supportCoordinator: get(clientData, "supportCoordinator", null),
      behavioralSpecialist: get(clientData, "behavioralSpecialist", null),
      country: get(clientData, "country", ""),
      goalsOutcomes: get(clientData, "goalsOutcomes", ""),
      phone: get(clientData, "phone", ""),
      familyContactEmail: get(clientData, "familyContactEmail", ""),
      waiwer: get(clientData, "waiwer", ""),
      fundingSource: get(clientData, "fundingSource", ""),
      isAuthorized: this.state.clientInfoAuthorized,
      billingStreet: get(clientData, "billingStreet", ""),
      billingCity: get(clientData, "billingCity", ""),
      billingState: get(clientData, "billingState", ""),
      billingZIP: get(clientData, "billingZIP", ""),
      billingCountry: get(clientData, "billingCountry", ""),
      clientServices: get(clientData, "clientServices", ""),
      dob: get(clientData, "dob", ""),
      status: getUserStatus(parseInt(clientData.status)),
    };
    if (client.clientServices) {
      client.clientServices.forEach(function (service) {
        service.serviceStartDate = new Date(service.serviceStartDate);
        service.serviceEndDate = new Date(service.serviceEndDate);
        service.serviceStartDateDisplay = moment(service.serviceStartDate)
          .utc()
          .format("MM-DD-YYYY");
        service.serviceEndDateDisplay = moment(service.serviceEndDate)
          .utc()
          .format("MM-DD-YYYY");
      });
    }
    return client;
  };

  onEdit = () => {
    this.props.history.push(
      `/admin/edit-client?id=${this.state.selectedClientId}`
    );
  };

  onClientServicesUpdate = (clientServices) => {
    this.setState({
      clientServices: clientServices,
    });
  };

  handleTabStateChanged(position) {
    this.setState({
      activeTabIndex: position,
    });
  }

  render() {
    return (
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className="row">
          <div
            className="row col-lg-2 col-md-2 col-sm-4"
            onClick={() => {
              this.props.history.goBack();
            }}
          >
            <img src="./images/chevron-left-green.svg" alt="" />
            <span
              style={{
                fontFamily: "Muli",
                color: "#333333",
                fontSize: 15,
                padding: 10,
              }}
            >
              Back
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="client-details" style={{ cursor: "default" }}>
              Client Details
            </div>
          </div>
        </div>
        <div className="row">
          <div
            className=""
            style={{
              width: "100%",
              zIndex: 1,
              paddingTop: 10,
              marginTop: 20,
              // background: "#FFFFFF",
            }}
          >
            <div className="col-md-11 offset-md-1 col-lg-11 offset-lg-1 col-sm-12">
              <div
                style={{ display: "inline-block" }}
                className={"col-sm-3 col-lg-3 text-center"}
                onClick={this.handleTabStateChanged.bind(this, 0)}
              >
                <div
                  style={{ display: "flex", justifyContent: "center" }}
                  className={
                    this.state.activeTabIndex === 0
                      ? "tab_text_style active_tabs"
                      : "tab_text_style inactive_tabs"
                  }
                >
                  Basic information
                  <span
                    onClick={this.onEdit}
                    style={{ paddingLeft: 10, cursor: "pointer" }}
                    alt="Edit Client"
                  >
                    <img src="/images/edit-green.svg" alt="" />
                  </span>
                </div>
              </div>
              <div
                style={{ display: "inline-block", marginLeft: "0px" }}
                className={"col-sm-2 col-lg-2 text-center"}
                onClick={this.handleTabStateChanged.bind(this, 1)}
              >
                <div
                  className={
                    this.state.activeTabIndex === 1
                      ? "tab_text_style active_tabs"
                      : "tab_text_style inactive_tabs"
                  }
                >
                  Services
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-0"></div>
          </div>

          <div className="col-md-11 offset-md-1">
            {/* <div className="col-lg-1 col-md-1 col-sm-0 text-right"></div> */}
            {/* <div className="col-lg-12 col-md-12 col-sm-12"> */}
            {this.state.activeTabIndex === 0 ? (
              <div className="col-md-11 col-12" style={{ marginTop: 30 }}>
                <BasicInformation
                  initialValues={this.state.initialValues}
                  fileList={this.state.fileList}
                />
              </div>
            ) : (
              <div
                className="col-md-11 col-12"
                style={{
                  backgroundColor: "#FFF",
                  padding: 20,
                  marginTop: 30,
                }}
              >
                <ServiceDetails
                  clientServices={this.state.clientServices}
                  clientId={this.state.selectedClientId}
                  onClientServicesUpdate={this.onClientServicesUpdate}
                  onOpenModal={() => {
                    this.setState({
                      openModal: true,
                    });
                  }}
                />
              </div>
            )}
            {/* </div> */}
            {/* <div className="col-lg-1 col-md-1 col-sm-0"></div> */}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  createClientSuccess: state.client.createClientSuccess,
  createClientFail: state.client.createClientFail,
  updateClientSuccess: state.client.updateClientSuccess,
  updateClientFail: state.client.updateClientFail,
  clients: state.client.getAllSuccess,
});

export default withRouter(connect(mapStateToProps)(clientDetails));
