import * as React from 'react';

class BasicHeader extends React.Component {

    render() {
        const fontSize = this.props.fontSize ? this.props.fontSize : 16
        return (
            <div className='custom-header overflow' style={{fontSize: fontSize}}>
                <span>{this.props.header}</span>
            </div>
        );
    }

}

export default BasicHeader;
