import React from "react";
import { Field } from "redux-form";
import {
  inputField,
  phoneNumberField,
  textField,
} from "../../../shared/form-fields/formFields";
import { validateFirstName, validateLastName } from "./staffValidator";
import { validateEmailSC } from "../../../login/loginValidator";

class MemberInformation extends React.Component {
  render() {
    const recordTypes = [
      {
        type: "Staff",
        value: 2,
      },
      {
        type: "Behavioral Specialist",
        value: 3,
      },
      {
        type: "Support Coordinator",
        value: 4,
      },
      {
        type: "Customer",
        value: 5,
      },
      {
        type: "Family Contact",
        value: 6,
      },
      {
        type: "Other",
        value: 7,
      },
    ];
    return (
      <div>
        <div className="row">
          <span className="form-title">Staff Information</span>
        </div>
        <div className="row">
          {/* <div className="col-6 input-box text-left">
            <div className="label">Name</div>
            <Field
              name="name"
              component={searchField}
              type="text"
              placeholder={"Search"}
              required={true}
              //   validate={validateEmail}
            />
          </div> */}
          <div className="col-6 input-box text-left">
            <div className="label">Staff Record Type</div>
            {/* <Field
              name="staffType"
              component={inputField}
              type="text
              placeholder={"Staff"}
              required={true}
              value={"staff"}
              disabled={true}
              //   validate={validateEmail}
            /> */}
            <select
              className="select-box"
              placeholder="Select Staff Record Type"
              disabled
              value={this.props.userType}
            >
              {recordTypes.map((record) => {
                return <option value={record.value}>{record.type}</option>;
              })}
            </select>
          </div>
          {/* <div className="col-6 input-box text-left">
            <div className="label">Report to</div>
            <Field
              name="reportTo"
              component={inputField}
              type="text"
              placeholder={"Select"}
              required={true}
            />
          </div> */}
          <div className="col-6 input-box text-left">
            <div className="label">Salutation</div>
            <Field
              name="salutation"
              component={inputField}
              type="text"
              placeholder={"Select"}
              required={true}
              //   validate={validateEmail}
            />
          </div>
          <div className="col-6 input-box text-left">
            <div className="label">First Name</div>
            <Field
              name="firstName"
              component={inputField}
              type="text"
              placeholder={"First Name"}
              required={true}
              validate={validateFirstName}
            />
          </div>
          {/*<div className="col-6 input-box text-left">*/}
          {/*    <div className="label">Middle Name</div>*/}
          {/*    <Field*/}
          {/*        name="middleName"*/}
          {/*        component={inputField}*/}
          {/*        type="text"*/}
          {/*        placeholder={"Middle Name"}*/}
          {/*        required={true}*/}
          {/*        //   validate={validateEmail}*/}
          {/*    />*/}
          {/*</div>*/}
          <div className="col-6 input-box text-left">
            <div className="label">Last Name</div>
            <Field
              name="lastName"
              component={inputField}
              type="text"
              placeholder={"Last Name"}
              required={true}
              validate={validateLastName}
            />
          </div>
          <div className="col-6 input-box text-left">
            <div className="label">Suffix</div>
            <Field
              name="suffix"
              component={inputField}
              type="text"
              placeholder={"Suffix"}
              required={true}
              //   validate={validateEmail}
            />
          </div>
          {/*<div className="col-6 input-box text-left">*/}
          {/*    <div className="label">Birth Date</div>*/}
          {/*    <Field*/}
          {/*        name="dob"*/}
          {/*        component={renderDatePicker}*/}
          {/*        type="text"*/}
          {/*        placeholder={"Select"}*/}
          {/*        required={true}*/}
          {/*        //   validate={validateEmail}*/}
          {/*    />*/}
          {/*</div>*/}
          <div className="col-6 input-box text-left">
            <div className="label">Status</div>
            {/*<Field*/}
            {/*  name="title"*/}
            {/*  component={inputField}*/}
            {/*  type="text"*/}
            {/*  placeholder={"Title"}*/}
            {/*  required={true}*/}
            {/*  //   validate={validateEmail}*/}
            {/*/>*/}
            <select
              className="select-box"
              placeholder="Select Staff Status"
              value={this.props.staffStatus}
              onChange={(e) => {
                this.props.onStatusChange(e.target.value);
              }}
            >
              <option value={4}>Active</option>
              <option value={5}>Inactive</option>
            </select>
          </div>
          <div className="col-6 input-box text-left">
            <div className="label">Title</div>
            <Field
              name="title"
              component={inputField}
              type="text"
              placeholder={"Title"}
              required={true}
              //   validate={validateEmail}
            />
          </div>
          <div className="col-6 input-box text-left">
            <div className="label">Phone</div>
            <Field
              name="phone"
              component={phoneNumberField}
              type="text"
              placeholder={"Phone"}
              required={false}
            />
          </div>
          <div className="col-6 input-box text-left">
            <div className="label">Mobile</div>
            <Field
              name="mobile"
              component={phoneNumberField}
              type="text"
              placeholder={"Mobile"}
              required={true}
            />
          </div>
          <div className="col-6 input-box text-left">
            <div className="label">Company Name</div>
            <Field
              name="company"
              component={inputField}
              type="text"
              placeholder={"Company Name"}
              required={true}
            />
          </div>
          <div className="col-6 input-box text-left">
            <div className="label">Email</div>
            <Field
              name="email"
              component={inputField}
              type="text"
              placeholder={"Email"}
              required={false}
              autofilled={false}
              validate={validateEmailSC}
            />
          </div>
        </div>
        <div className="col-12 input-box text-left">
          <div className="label">Internal Memo</div>
          <Field
            name="memo"
            component={textField}
            type="text"
            placeholder={"Internal Memo"}
            required={false}
          />
        </div>
      </div>
    );
  }
}

export default MemberInformation;
