import React from "react";

class ServiceCard extends React.Component {
  render() {
    const client = this.props.client;
    return (
      <div className='col-12 visit-card' onClick={() => {
        this.props.onDetails(client.id)
      }}>
        <div className='row'>
          <div className='col-6'>
            <div className='col-12 label'>
              Client Name
            </div>
            <div className='col-12 value'>
              {client.name}
            </div>
          </div>
          <div className='col-6'>
            <div className='col-12 label'>
              City
            </div>
            <div className='col-12 value'>
              {client.city}
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-6'>
            <div className='col-12 label'>
              Phone
            </div>
            <div className='col-12 value'>
              {client.phone}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ServiceCard