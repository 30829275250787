import React from "react";
import { Field } from "redux-form";
import {
  inputField,
  phoneNumberField,
  renderDatePicker,
  SSNTNField,
  textField,
} from "../../../shared/form-fields/formFields";
import DualListBox from "../../../shared/dual-list-box/dual-list-box";
import {
  validateFirstName,
  validateLastName,
  validateMiddleName,
  validateSalutation,
  validateSuffix,
  validateMobileNumber,
  validateUserPercentage,
} from "./staffValidator";
import {
  validateEmail,
  validatePasswordFormat,
  validateSSN,
} from "../../../login/loginValidator";
import { getAuth } from "../../../../util/auth";
import { USER_TYPES } from "../../../../constants/user";

class MemberInformation extends React.Component {
  render() {
    const recordTypes = [
      {
        type: "Super Admin",
        value: 0,
      },
      {
        type: "Admin",
        value: 1,
      },
      {
        type: "Staff",
        value: 2,
      },
      {
        type: "Behavioral Specialist",
        value: 3,
      },
      {
        type: "Support Coordinator",
        value: 4,
      },
      {
        type: "Customer",
        value: 5,
      },
      {
        type: "Family Contact",
        value: 6,
      },
      {
        type: "Other",
        value: 7,
      },
    ];
    let showEmail = this.props.isEditMode
      ? getAuth().userType == USER_TYPES.SUPER_ADMIN
      : true;
    return (
      <div>
        <div className="row">
          <span className="form-title">Staff Member Information</span>
        </div>
        <div className="row">
          {/* <div className="col-6 input-box text-left">
            <div className="label">Name</div>
            <Field
              name="name"
              component={searchField}
              type="text"
              placeholder={"Search"}
              required={true}
              //   validate={validateEmail}
            />
          </div> */}
          <div className="col-6 input-box text-left">
            <div className="label">Staff Record Type</div>
            {/* <Field
              name="staffType"
              component={inputField}
              type="text"
              placeholder={"Staff"}
              required={true}
              value={"staff"}
              disabled={true}
              //   validate={validateEmail}
            /> */}
            <select
              className="select-box"
              placeholder="Select Staff Record Type"
              disabled
              value={this.props.userType}
            >
              {recordTypes.map((record) => {
                return <option value={record.value}>{record.type}</option>;
              })}
            </select>
          </div>
          {/* <div className="col-6 input-box text-left">
            <div className="label">Report to</div>
            <Field
              name="reportTo"
              component={inputField}
              type="text"
              placeholder={"Select"}
              required={true}
            />
          </div> */}
          <div className="col-6 input-box text-left">
            <div className="label">Salutation</div>
            <Field
              name="salutation"
              component={inputField}
              type="text"
              placeholder={"Select"}
              required={true}
              validate={validateSalutation}
            />
          </div>
          <div className="col-6 input-box text-left">
            <div className="label">First Name</div>
            <Field
              name="firstName"
              component={inputField}
              type="text"
              placeholder={"First Name"}
              required={true}
              validate={validateFirstName}
            />
          </div>
          <div className="col-6 input-box text-left">
            <div className="label">Middle Name</div>
            <Field
              name="middleName"
              component={inputField}
              type="text"
              placeholder={"Middle Name"}
              required={true}
              validate={validateMiddleName}
            />
          </div>
          <div className="col-6 input-box text-left">
            <div className="label">Last Name</div>
            <Field
              name="lastName"
              component={inputField}
              type="text"
              placeholder={"Last Name"}
              required={true}
              validate={validateLastName}
            />
          </div>
          <div className="col-6 input-box text-left">
            <div className="label">Suffix</div>
            <Field
              name="suffix"
              component={inputField}
              type="text"
              placeholder={"Suffix"}
              required={true}
              validate={validateSuffix}
            />
          </div>
          <div className="col-6 input-box text-left">
            <div className="label">Birth Date</div>
            <Field
              name="dob"
              component={renderDatePicker}
              type="text"
              maxDate={new Date()}
              placeholder={"Select"}
              required={true}
              //   validate={validateEmail}
            />
          </div>
          <div className="col-6 input-box text-left">
            <div className="label">Status</div>
            {/*<Field*/}
            {/*  name="title"*/}
            {/*  component={inputField}*/}
            {/*  type="text"*/}
            {/*  placeholder={"Title"}*/}
            {/*  required={true}*/}
            {/*  //   validate={validateEmail}*/}
            {/*/>*/}
            <select
              className="select-box"
              placeholder="Select Staff Status"
              value={this.props.staffStatus}
              onChange={(e) => {
                this.props.onStatusChange(e.target.value);
              }}
            >
              <option value={4}>Active</option>
              <option value={5}>Inactive</option>
            </select>
          </div>
          <div className="col-6 input-box text-left">
            <div className="label">SSN/TIN</div>
            <Field
              name="ssn_tin"
              component={SSNTNField}
              type="text"
              placeholder={"SSN/TIN"}
              required={true}
              validate={validateSSN}
            />
          </div>
          <div className="col-6 input-box text-left">
            <div className="label">Hire Date</div>
            <Field
              name="hireDate"
              component={renderDatePicker}
              type="text"
              maxDate={new Date()}
              placeholder={"Select"}
              required={true}
              // disabled={this.props.isEditMode}
              //   validate={validateEmail}
            />
          </div>
          <div className="col-6 input-box text-left">
            <div className="label">Mobile</div>
            <Field
              name="mobile"
              component={phoneNumberField}
              type="text"
              placeholder={"Mobile"}
              required={false}
              validate={validateMobileNumber}
            />
          </div>
          <div className="col-6 input-box text-left">
            <div className="label">Phone</div>
            <Field
              name="phone"
              component={phoneNumberField}
              type="text"
              placeholder={"Phone"}
              required={false}
              // validate={validatePhoneNumber}
            />
          </div>

          <div className="col-xs-12 col-md-6 input-box text-left">
            <div className="label">Staff</div>

            <DualListBox
              selectedOptions={this.props.credentials}
              onOptionsSelect={this.props.onCredentialsSelect}
            />
          </div>
          <div className="col-6 input-box text-left">
            <div className="row">
              <div className="col-12 input-box">
                <div className="label">Email</div>

                <Field
                  name="email"
                  component={inputField}
                  type="text"
                  placeholder={"Email"}
                  required={true}
                  validate={validateEmail}
                  disabled={!showEmail}
                  autocomplete="new-password"
                />
              </div>
            </div>
            {!this.props.isEditMode && (
              <div className="row">
                <div className="col-12 input-box">
                  <div className="label">Password</div>
                  <Field
                    name="password"
                    component={inputField}
                    type="password"
                    placeholder={"Password"}
                    required={true}
                    validate={validatePasswordFormat}
                    autocomplete="new-password"
                  />
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-12 input-box">
                <div className="label">Percent Paid</div>
                <Field
                  name="percentagePaid"
                  component={inputField}
                  type="number"
                  placeholder={"Percent Paid"}
                  required={true}
                  validate={validateUserPercentage}
                />
              </div>
            </div>
            <div
              className="col-6 input-box text-left"
              style={{ marginBottom: 20 }}
            >
              <input
                type="checkbox"
                name="hasRelevantDegree"
                value="authorized"
                checked={this.props.hasRelevantDegree}
                style={{ marginRight: 5 }}
                onChange={(event) => {
                  this.props.onHasRelevantDegree(event.target.checked);
                }}
              />
              <span className="label">Has Relevant Degree</span>
            </div>
          </div>

          <div className="col-12 input-box text-left">
            <div className="label">Internal Memo</div>
            <Field
              name="memo"
              component={textField}
              type="text"
              placeholder={"Internal Memo"}
              required={false}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default MemberInformation;
