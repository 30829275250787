import {call, put, takeEvery} from "redux-saga/effects";
import {WORK_ENTRY} from "../../constants/actionTypes";
import {createWorkEntry, getAllWorkEntries, getAllWorkEntriesByUserId, updateWorkEntry,} from "../../api/workEntry";
import {
    createWorkEntryFail,
    createWorkEntrySuccess,
    getAllWorkEntriesFail,
    getAllWorkEntriesSuccess,
    resetCreateWorkEntry,
    resetGetAllWorkEntries,
    resetUpdateWorkEntry,
    updateWorkEntryFail,
    updateWorkEntrySuccess,
} from "./workEntryAction";

export function* watchCreateWorkEntry() {
    yield takeEvery(WORK_ENTRY.CREATE_WORK_ENTRY, function* (workEntryData) {
        const {workEntry} = workEntryData;
        yield call(handleCreateWorkEntry, workEntry);
    });
}

function* handleCreateWorkEntry(workEntry) {
    yield put(resetCreateWorkEntry());
    try {
        const workEntryData = yield call(createWorkEntry, workEntry);
        yield put(createWorkEntrySuccess(workEntryData));
    } catch (e) {
        yield put(createWorkEntryFail(e));
    }
}

export function* watchUpdateWorkEntry() {
    yield takeEvery(WORK_ENTRY.UPDATE_WORK_ENTRY, function* (workEntryData) {
        const {workEntry} = workEntryData;
        yield call(handleUpdateWorkEntry, workEntry);
    });
}

function* handleUpdateWorkEntry(workEntry) {
    yield put(resetUpdateWorkEntry());
    try {
        const workEntryData = yield call(updateWorkEntry, workEntry);
        yield put(updateWorkEntrySuccess(workEntryData));
    } catch (e) {
        yield put(updateWorkEntryFail(e));
    }
}

export function* watchGetAllWorkEntry() {
    yield takeEvery(WORK_ENTRY.GET_WORK_ENTRIES, function* (workEntryData) {
        const {
            pageNumber,
            pageSize,
            filter,
            searchParams,
            entryType,
            staff,
            service,
            fromDate,
            toDate,
            dateRangeOption,
        } = workEntryData;
        yield call(
            handleGetAllWorkEntry,
            pageNumber,
            pageSize,
            filter,
            searchParams,
            entryType,
            staff,
            service,
            fromDate,
            toDate,
            dateRangeOption,
        );
    });
}

function* handleGetAllWorkEntry(
    pageNumber,
    pageSize,
    filter,
    searchParams,
    entryType,
    staff,
    service,
    fromDate,
    toDate,
    dateRangeOption,
) {
    yield put(resetGetAllWorkEntries());
    try {
        pageNumber = pageNumber * pageSize;
        const workEntries = yield call(
            getAllWorkEntries,
            pageNumber,
            pageSize,
            filter,
            searchParams,
            entryType,
            staff,
            service,
            fromDate,
            toDate,
            dateRangeOption,
        );
        yield put(getAllWorkEntriesSuccess(workEntries));
    } catch (e) {
        yield put(getAllWorkEntriesFail(e));
    }
}

export function* watchGetAllWorkEntryByUserId() {
    yield takeEvery(WORK_ENTRY.GET_WORK_ENTRIES_BY_USER_ID, function* (
        workEntryData
    ) {
        const {
            userId,
            pageNumber,
            pageSize,
            filter,
            searchParams,
        } = workEntryData;
        yield call(
            handleGetAllWorkEntryByUserId,
            userId,
            pageNumber,
            pageSize,
            filter,
            searchParams
        );
    });
}

function* handleGetAllWorkEntryByUserId(
    userId,
    pageNumber,
    pageSize,
    filter,
    searchParams
) {
    yield put(resetGetAllWorkEntries());
    try {
        pageNumber = pageNumber * pageSize;
        const workEntries = yield call(
            getAllWorkEntriesByUserId,
            userId,
            pageNumber,
            pageSize,
            filter,
            searchParams
        );
        yield put(getAllWorkEntriesSuccess(workEntries));
    } catch (e) {
        yield put(getAllWorkEntriesFail(e));
    }
}
