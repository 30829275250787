import { USER } from "../../constants/actionTypes";

export const authReducer = (state = {}, action) => {
  switch (action.type) {
    case USER.LOGIN_SUCCESS:
      return { ...state, data: action.auth, otpVerified: null };
    case USER.LOGIN_FAIL:
      return { ...state, error: action.error };
    case USER.LOGIN_RESET:
      return { ...state, error: null, data: null };
    case USER.CONFIRM_EMAIL:
      return { ...state, confirmEmail: action.confirmEmail.email };
    case USER.CONFIRM_EMAIL_SUCCESS:
      return { ...state, confirmEmailSuccess: action.response };
    case USER.CONFIRM_EMAIL_FAIL:
      return { ...state, confirmEmailFail: action.error };
    case USER.RESET_CONFIRM_EMAIL:
      return { ...state, confirmEmailFail: null, confirmEmailSuccess: null };
    case USER.VERIFY_TOKEN_SUCCESS:
      return { ...state, verifyTokenSuccess: action.response };
    case USER.VERIFY_TOKEN_FAIL:
      return { ...state, verifyTokenFail: action.error };
    case USER.RESET_VERIFY_TOKEN:
      return { ...state, verifyTokenFail: null, verifyTokenSuccess: null };
    case USER.CHANGE_PASSWORD_SUCCESS:
      return { ...state, changePasswordSuccess: action.response };
    case USER.CHANGE_PASSWORD_FAIL:
      return { ...state, changePasswordFail: action.error };
    case USER.SAVE_VERIFY_TOKEN:
      return { ...state, verifyToken: action.token };
    case USER.RESET_CHANGE_PASSWORD:
      return {
        ...state,
        changePasswordSuccess: null,
        changePasswordFail: null,
        verifyToken: null,
      };

    case USER.OTP_VERIFICATION_SUCESS:
      return { ...state, resendOtp: false, otpVerified: true };

    case USER.OTP_VERIFICATION_FAILURE:
      return { ...state, resendOtp: false, otpVerified: false };

    case USER.RESEND_OTP_RES:
      return { ...state, otpVerified: false, resendOtp: action.data };

    case USER.RESET_OTP_RESENT:
      return { ...state, otpVerified: null, resendOtp: null };

    case USER.SAVE_OTP_EMAIL:
      return { ...state, otpEmail: action.email };

    default:
      return state;
  }
};
