import React from "react";
import "./profile.scss";
import PopUpModal from "../shared/popup-modal/popUpModal";
import ChangePassword from "./change-password/changePassword";
import { getAuth, getAuthData, setAuthData } from "../../util/auth";
import { updateUserPassword } from "../../api/user";
import { createNotification } from "../../util/notificationManager";
import { ALERT_TYPES } from "../../constants/appConstants";
import { Value } from "sass";

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      userData: {},
      initials: "",
      isResetPasswordError: false,
      isSamePasswordError:false
    };
  }

  componentDidMount = () => {
    let userData = getAuth();
    let initials = "";
    if (userData != null) {
      initials = (
        userData.firstName.charAt(0) + userData.lastName.charAt(0)
      ).toUpperCase();
    }
    this.setState({
      userData: userData,
      initials: initials,
    });
  };

  getUserType = () => {
    switch (this.state.userData.userType) {
      case 0:
        return "SUPER ADMIN";
      case 1:
        return "ADMIN";
      case 2:
        return "STAFF";
      case 3:
        return "";

      default:
        return "USER";
    }
  };

  handleSubmit = (values) => {
    if (values.newPassword != values.currentPassword) {
      if (values.confirmPassword === values.newPassword) {
      this.setState({ isSamePasswordError: false });
        const userData = JSON.parse(getAuthData());
        updateUserPassword(
          values.currentPassword,
          values.newPassword,
          userData.userId
        ).then((res) => {
          if (res.status) {
            this.setState({
              openModal: false,
            });
            createNotification(ALERT_TYPES.SUCCESS, res.message);
            setAuthData({});
            this.props.history.push("/login");
          } else {
            createNotification(ALERT_TYPES.ERROR, res.message);
          }
        });
      } else {
        this.setState({ isResetPasswordError: true });
      }
    } else {
      this.setState({ isSamePasswordError: true });
    }
  };

  onPasswordValueChange = () => {
    this.setState({ isResetPasswordError: false });
  };
  samePasswordValueChange = () => {
    this.setState({ isSamePasswordError: false });
  };
  render() {
    return (
      <div className="profile" style={{ display: "grid" }}>
        <div className="col-lg-8 offset-lg-2 col-md-8 offset-md-2 col-sm-12 offset-sm-0">
          {/*<div className='col-12'>*/}
          <img
            src="/images/banner.png"
            alt=""
            style={{ width: "100%", minHeight: 150 }}
          />
          <div style={{ display: "block", marginTop: -140 }}>
            <center>
              <svg viewBox="0 0 120 100">
                <path
                  d="M38,2
           L82,2
           A12,12 0 0,1 94,10
           L112,44
           A12,12 0 0,1 112,56
           L94,90
           A12,12 0 0,1 82,98
           L38,98
           A12,12 0 0,1 26,90
           L8,56
           A12,12 0 0,1 8,44
           L26,10
           A12,12 0 0,1 38,2"
                />
                <text x="40" y="60" className="initials">
                  {this.state.initials}
                </text>
              </svg>
            </center>
          </div>
          <div
            className="col-12"
            style={{
              display: "grid",
              padding: 20,
              marginTop: -130,
              backgroundColor: "white",
              zIndex: 0,
            }}
          >
            <div className="col-12 text-center" style={{ marginTop: 120 }}>
              <span style={{ fontSize: "21px" }}>
                {this.state.userData.firstName +
                  " " +
                  this.state.userData.lastName}
              </span>
            </div>
            <div
              className="col-lg-2 col-md-22 col-sm-4 offset-lg-5 offset-md-5 role text-center"
              style={{ marginTop: "5px" }}
            >
              <span className="text">{this.getUserType()}</span>
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 page-content">
            <div className=" personal-info" style={{ padding: 20 }}>
              Personal Information
            </div>
            <hr />
            <div className="row" style={{ padding: 20 }}>
              <div className="col-lg-4 col-md-4 col-sm-12">
                <div className="key">Name</div>
                <div className="value">{this.state.userData.firstName}</div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12">
                <div className="key">Last Name</div>
                <div className="value">{this.state.userData.lastName}</div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12">
                <div className="key">Email</div>
                <div className="value">{this.state.userData.email}</div>
              </div>
            </div>
            <hr />
            <div className="row">
              <div
                className=" row col-lg-12 col-md-12 col-sm-12"
                style={{
                  borderRadius: 8,
                  backgroundColor: "#F9F9F9",
                  margin: 10,
                }}
              >
                <div
                  className="col-lg-6 col-md-6 col-sm-12"
                  style={{
                    padding: 10,
                    paddingBottom: 0,
                    margin: 0,
                    paddingLeft: 30,
                  }}
                >
                  <div className="key">Password</div>
                  <div className="value">*********</div>
                </div>
                <div
                  className="col-lg-5 col-md-5 col-sm-12 offset-lg-1 offset-md-1"
                  style={{ padding: 20, paddingTop: 20 }}
                >
                  <button
                    // className="actionButton"
                    style={{
                      backgroundColor: " rgba(53,204,98,0.08)",
                      borderRadius: 4,
                      border: 0,
                    }}
                    onClick={() => {
                      this.setState({ openModal: true });
                    }}
                  >
                    <span
                      className=""
                      style={{
                        fontSize: 14,
                        fontWeight: 800,
                        textAlign: "center",
                        fontFamily: "",
                        color: "#35CC62",
                      }}
                    >
                      CHANGE PASSWORD
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.openModal && (
          <PopUpModal
            show={this.state.openModal}
            onClose={() => {
              this.setState({ openModal: false });
            }}
            title={"Change Password"}
            size={"lg"}
          >
            <ChangePassword
              onSubmit={this.handleSubmit}
              isResetPasswordError={this.state.isResetPasswordError}
              onPasswordValueChange={this.onPasswordValueChange}
              isSamePasswordError={this.state.isSamePasswordError}
              samePasswordValueChange={this.samePasswordValueChange}
            />
          </PopUpModal>
        )}
      </div>
    );
  }
}

export default Profile;
