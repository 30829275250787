import { get, post, put } from "../util/httpService";
import { SERVICE_URL } from "../constants/appConstants";
import * as _ from "lodash";
import { getToken, setAuthData } from "../util/auth";
import * as axios from "../util/httpService";

export async function createService(service) {
  const authToken = getToken();
  const headers = { Authorization: `bearer ${authToken}` };
  try {
    const response = await post(SERVICE_URL, service, { headers: headers });
    return _.get(response, "data", {});
  } catch (e) {
    throw _.get(e, "response.data", {});
  }
}

export async function updateService(service) {
  const authToken = getToken();
  const headers = { Authorization: `bearer ${authToken}` };
  try {
    const response = await put(SERVICE_URL, service, { headers: headers });
    return _.get(response, "data", {});
  } catch (e) {
    throw _.get(e, "response.data", {});
  }
}

export async function getAllServices(
  pageNumber,
  pageSize,
  filter,
  searchParams,
  status
) {
  const authToken = getToken();
  const headers = { Authorization: `bearer ${authToken}` };
  try {
    const response = await get(
      `${SERVICE_URL}?offset=${pageNumber}&limit=${pageSize}&filter=${filter}&name=${searchParams}&status=${status}`,
      { headers: headers }
    );
    return _.get(response, "data", {});
  } catch (e) {
    if (e.response.status === 401) {
      setAuthData(null);
    }
    throw _.get(e, "response.data", {});
  }
}

export async function getServiceById(serviceId) {
  const authToken = getToken();
  const headers = { Authorization: `bearer ${authToken}` };
  try {
    const response = await get(`/serviceById?service_id=${serviceId}`, {
      headers: headers
    });
    return _.get(response, "data", {});
  } catch (e) {
    throw _.get(e, "response.data", {});
  }
}

export async function getAllServicesList() {
  const authToken = getToken();
  const headers = { Authorization: `bearer ${authToken}` };
  try {
    const response = await get(`${SERVICE_URL}/service_list`, {
      headers: headers
    });
    return _.get(response, "data", {});
  } catch (e) {
    throw _.get(e, "response.data", {});
  }
}


export async function processImportFile(file) {
  const formData = new FormData();
  formData.append("file", file);
  const authToken = getToken();
  const headers = { Authorization: `bearer ${authToken}`, "Content-Type": "multipart/form-data" };
  try {
    const response = await post(`/services/process`, formData, { headers: headers });
    return _.get(response, "data", {});
  } catch (e) {
    throw _.get(e, "response.data", {});
  }
}

export async function importService(service) {
  const authToken = getToken();
  const headers = { Authorization: `bearer ${authToken}` };
  try {
    const response = await post('/services/import', service, { headers: headers });
    return _.get(response, "data", {});
  } catch (e) {
    throw _.get(e, "response.data", {});
  }
}