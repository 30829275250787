import React from "react";
import { Field, Form, reduxForm } from "redux-form";
import { inputField } from "../../shared/form-fields/formFields";
import { validatePasswordFormat } from "../../login/loginValidator";

class ChangePassword extends React.Component {
  state = {
    currentPasswordShow: false,
    newPasswordShow: false,
    confirmPasswordShow: false,
  };

  validatePassword = (values) => {
    const { isResetPasswordError, isSamePasswordError } = this.props;
    if (isResetPasswordError) {
      this.props.onPasswordValueChange();
    } 
     if (isSamePasswordError) {
      this.props.samePasswordValueChange();
    }
    return validatePasswordFormat(values);
  };

  render() {
    const {
      handleSubmit,
      initialValues,
      isResetPasswordError,
      isSamePasswordError,
    } = this.props;
    return (
      <Form style={{ width: "100%" }} onSubmit={handleSubmit} noValidate>
        <div className="row">
          <div className="col-lg-8 offset-lg-2 col-md-12 offset-md-0 col-sm-12 offset-sm-0">
            <div className="form">
              <div className="col-12 input-box text-left">
                <div className="label">Current Password</div>
                <Field
                  name="currentPassword"
                  component={inputField}
                  type={this.state.currentPasswordShow ? "text" : "password"}
                  placeholder={"Current Password"}
                  required={true}
                  validate={this.validatePassword}
                />
                <i
                  style={{ position: "absolute", top: 40, right: 20 }}
                  onClick={() => {
                    this.setState({
                      currentPasswordShow: !this.state.currentPasswordShow,
                    });
                  }}
                >
                  {this.state.currentPasswordShow && (
                    <img src="./images/eye-off.svg" alt="" />
                  )}
                  {!this.state.currentPasswordShow && (
                    <img src="./images/eye.svg" alt="" />
                  )}
                </i>
              </div>
              <div className="col-12 input-box text-left">
                <div className="label">New Password</div>
                <Field
                  name="newPassword"
                  component={inputField}
                  type={this.state.newPasswordShow ? "text" : "password"}
                  placeholder={"New Password"}
                  required={true}
                  validate={this.validatePassword}
                />
                <i
                  style={{ position: "absolute", top: 40, right: 20 }}
                  onClick={() => {
                    this.setState({
                      newPasswordShow: !this.state.newPasswordShow,
                    });
                  }}
                >
                  {this.state.newPasswordShow && (
                    <img src="./images/eye-off.svg" alt="" />
                  )}
                  {!this.state.newPasswordShow && (
                    <img src="./images/eye.svg" alt="" />
                  )}
                </i>
              </div>
              <div className="col-12 input-box text-left">
                <div className="label">Confirm Password</div>
                <Field
                  name="confirmPassword"
                  component={inputField}
                  type={this.state.confirmPasswordShow ? "text" : "password"}
                  placeholder={"Confirm Password"}
                  required={true}
                  validate={this.validatePassword}
                />
                <i
                  style={{ position: "absolute", top: 40, right: 20 }}
                  onClick={() => {
                    this.setState({
                      confirmPasswordShow: !this.state.confirmPasswordShow,
                    });
                  }}
                >
                  {this.state.confirmPasswordShow && (
                    <img src="./images/eye-off.svg" alt="" />
                  )}
                  {!this.state.confirmPasswordShow && (
                    <img src="./images/eye.svg" alt="" />
                  )}
                </i>
                {isResetPasswordError && (
                  <div className="col-12">
                    <p className="error-message text-right">
                      <i>
                        <img src="./images/alert-circle.svg" alt="" />
                      </i>
                      &nbsp;{"New Password and Confirm Password do not match"}
                    </p>
                  </div>
                )}{" "}
                {isSamePasswordError && (
                  <div className="col-12">
                    <p className="error-message text-right">
                      <i>
                        <img src="./images/alert-circle.svg" alt="" />
                      </i>
                      &nbsp;
                      {
                        "New Password cannot be the same as the Current Password"
                      }
                    </p>
                  </div>
                )}
              </div>
              <div className="row">
                <div className="col-6 offset-3">
                  <button type="submit" className="button submit">
                    <span className="text">OK</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
    );
  }
}

const changePasswordForm = reduxForm({
  form: "passwordChange",
})(ChangePassword);

export default changePasswordForm;
