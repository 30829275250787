import { put, call, takeEvery } from "redux-saga/effects";
import {CLIENT, REPORTS} from "../../constants/actionTypes";
import {
  generateReport, generateStaffReport, generateWorkEntryReport
} from "../../api/report";
import {createClientFail, createClientSuccess, resetCreateClient} from "../clients/clientActions";
import {createClient} from "../../api/client";

export function* watchGenerateReport() {
  yield takeEvery(REPORTS.GENERATE_REPORT,function* (reportData) {
    const {reportType,startDate,endDate} = reportData;
    yield call(handleGenerateReport,reportType,startDate,endDate);
  });
}

export function* watchGenerateStaffReport() {
  yield takeEvery(REPORTS.GENERATE_STAFF_REPORT,function* (reportData) {
    const {reportType,status} = reportData;
    yield call(handleGenerateStaffReport,reportType,status);
  });
}

export function* watchGenerateWorkEntryReport() {
  yield takeEvery(REPORTS.GENERATE_WORK_ENTRY_REPORT,function* (reportData) {
    const {reportType, adminVerification, startDate, endDate,client,staff,service} = reportData;
    yield call(handleGenerateWorkEntryReport,reportType,adminVerification,startDate,endDate,client,staff,service);
  });
}

function* handleGenerateReport(reportType,startDate,endDate){
  // yield put(resetCreateClient())
  try {
    const clientData = yield call(generateReport,reportType,startDate,endDate);
    // yield put(ge(clientData));
  }catch (e) {
    // yield put(createClientFail(e));
  }
}

function* handleGenerateStaffReport(reportType,status){
  // yield put(resetCreateClient())
  try {
    const clientData = yield call(generateStaffReport,reportType,status);
    // yield put(ge(clientData));
  }catch (e) {
    // yield put(createClientFail(e));
  }
}

function* handleGenerateWorkEntryReport(reportType,adminVerification,startDate,endDate,client,staff,service){
  // yield put(resetCreateClient())
  try {
    const clientData = yield call(generateWorkEntryReport,reportType,adminVerification,startDate,endDate,client,staff,service);
    // yield put(ge(clientData));
  }catch (e) {
    // yield put(createClientFail(e));
  }
}
