import React from 'react';
import {REPORT_TYPES} from '../../../constants/appConstants';
import AllContactsReportView from './allContactReport';
import NoteReportView from './notesReport';
import PromiseBillingReportView from './promiseBillingReport';
import SCSummaryReportView from './scSummaryReports';
import StaffBillingReportView from './staffBillingReport';
import StaffSummaryReportView from './staffSummaryReport';
import StaffAllocationReportView from './staffAllocationSummary';


class ReportView extends React.Component {

    render() {
        const {reportType, reportData} = this.props
        return (
            <div id="excel-viewer" className='col-12'>
                {
                    reportType === REPORT_TYPES.NOTES_REPORT &&
                    <NoteReportView
                        reportData={reportData.data}
                        onPageSizeChange={(pageSize) => {
                            this.props.onPageSizeChange(pageSize)
                        }}
                        onPageNumberChange={(pageNumber) => {
                            this.props.onPageNumberChange(pageNumber)
                        }}
                        total={reportData.recordCount}
                        pageSize={this.props.pageSize}
                        pageNumber={this.props.pageNumber}
                    />
                }

                {
                    reportType === REPORT_TYPES.STAFF_REPORT &&
                    <StaffBillingReportView
                        reportData={reportData.data}
                        onPageSizeChange={(pageSize) => {
                            this.props.onPageSizeChange(pageSize)
                        }}
                        onPageNumberChange={(pageNumber) => {
                            this.props.onPageNumberChange(pageNumber)
                        }}
                        total={reportData.recordCount}
                        pageSize={this.props.pageSize}
                        pageNumber={this.props.pageNumber}
                    />
                }

                {
                    reportType === REPORT_TYPES.PROMISE_BILLING_REPORT &&
                    <PromiseBillingReportView
                        reportData={reportData.data}
                        onPageSizeChange={(pageSize) => {
                            this.props.onPageSizeChange(pageSize)
                        }}
                        onPageNumberChange={(pageNumber) => {
                            this.props.onPageNumberChange(pageNumber)
                        }}
                        total={reportData.recordCount}
                        pageSize={this.props.pageSize}
                        pageNumber={this.props.pageNumber}
                    />
                }

                {
                    reportType === REPORT_TYPES.CLIENT_SUMMARY &&
                    <AllContactsReportView
                        reportData={reportData.data}
                        onPageSizeChange={(pageSize) => {
                            this.props.onPageSizeChange(pageSize)
                        }}
                        onPageNumberChange={(pageNumber) => {
                            this.props.onPageNumberChange(pageNumber)
                        }}
                        total={reportData.recordCount}
                        pageSize={this.props.pageSize}
                        pageNumber={this.props.pageNumber}
                    />
                }

                {
                    reportType === REPORT_TYPES.SC_SUMMARY &&
                    <SCSummaryReportView
                        reportData={reportData.data}
                        onPageSizeChange={(pageSize) => {
                            this.props.onPageSizeChange(pageSize)
                        }}
                        onPageNumberChange={(pageNumber) => {
                            this.props.onPageNumberChange(pageNumber)
                        }}
                        total={reportData.recordCount}
                        pageSize={this.props.pageSize}
                        pageNumber={this.props.pageNumber}
                    />
                }

                {
                    reportType === REPORT_TYPES.STAFF_SUMMARY &&
                    <StaffSummaryReportView
                        reportData={reportData.data}
                        onPageSizeChange={(pageSize) => {
                            this.props.onPageSizeChange(pageSize)
                        }}
                        onPageNumberChange={(pageNumber) => {
                            this.props.onPageNumberChange(pageNumber)
                        }}
                        total={reportData.recordCount}
                        pageSize={this.props.pageSize}
                        pageNumber={this.props.pageNumber}
                    />
                }

{
                    reportType === REPORT_TYPES.STAFF_ALLOCATION_SUMMARY &&
                    <StaffAllocationReportView
                        reportOption={this.props.reportOption}
                        reportData={reportData.data}
                        onPageSizeChange={(pageSize) => {
                            this.props.onPageSizeChange(pageSize)
                        }}
                        onPageNumberChange={(pageNumber) => {
                            this.props.onPageNumberChange(pageNumber)
                        }}
                        total={reportData.recordCount}
                        pageSize={this.props.pageSize}
                        pageNumber={this.props.pageNumber}
                    />
                }

            </div>
        );
    }
}

export default ReportView;
