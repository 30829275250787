import * as React from 'react';
import '../../login/login.scss';
import '../../shared/form-fields/formFields.scss';
import '../resetPassword.scss';
import {withRouter} from 'react-router-dom';
import {isEqual, isNull, isUndefined,} from 'lodash';
import {validateEmail} from "../../login/loginValidator";
import VerifyForm from "./verifyEmailForm";
import Footer from "../../shared/footer/footer";
import {getHostName, getQueryParameter} from "../../../util/url";
import {connect} from 'react-redux';
import {confirmEmail, saveVerifyToken, verifyToken} from "../../../redux/auth/authAction";
import {ALERT_TYPES} from "../../../constants/appConstants";
import {createNotification} from "../../../util/notificationManager";


class VerifyEmail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            confirmationEmail: '',
            isLinkSent: false,
            resetToken: '',
            emailInvalid: false,
            tokenInvalid: false,
            emailInvalidError: '',
            verifyToken: ''
        }
    }


    componentDidMount() {
        const isVerifyToken = getQueryParameter('verifyToken');

        if (!isNull(isVerifyToken) && !isUndefined(isVerifyToken) && !isUndefined(this.props.confirmEmailSuccess)) {
            this.setState({
                isLinkSent: true
            })
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (!isEqual(nextProps.confirmEmailSuccess, this.props.confirmEmailSuccess) && !isNull(nextProps.confirmEmailSuccess)) {
            this.setState({
                isLinkSent: true
            })
        } else if (!isEqual(this.props.confirmEmailFail, nextProps.confirmEmailFail) && !isNull(nextProps.confirmEmailFail)) {
            createNotification(ALERT_TYPES.ERROR, 'The email address entered did not match our records, Please try again.');
        }

        if (!isEqual(nextProps.verifyTokenSuccess, this.props.verifyTokenSuccess) && !isNull(nextProps.verifyTokenSuccess)) {
            this.props.dispatch(saveVerifyToken(this.state.verifyToken));
            this.props.history.push('/change-password');
        } else if (!isEqual(this.props.verifyTokenFail, nextProps.verifyTokenFail) && !isNull(nextProps.verifyTokenFail)) {
            createNotification(ALERT_TYPES.ERROR, 'Invalid Token');
        }
    }

    validateEmail = () => {

        const email = this.state.confirmationEmail;
        const isInvalid = validateEmail(email);
        if (!isUndefined(isInvalid)) {
            this.setState({
                emailInvalidError: isInvalid,
                emailInvalid: true
            })
        } else {
            this.setState({
                emailInvalidError: isInvalid,
                emailInvalid: false
            })
        }
    };

    resendLink = () => {
        this.setState({
            isLinkSent: false
        })
    };

    emailVerify = (values) => {
        if (!isUndefined(values.email) && isUndefined(values.token)) {
            const verifyEmail = {
                email: values.email,
                reset_url: `${getHostName()}/verify-email?verifyToken=true`
            }
            this.props.dispatch(confirmEmail(verifyEmail));
        } else {
            const token = {
                email: values.email,
                token: values.token
            };

            this.props.dispatch(verifyToken(token));
            this.setState({
                verifyToken: values.token
            });
        }

    };


    render() {
        const {isLinkSent} = this.state;
        return (
            <div>
                <nav
                    className="navbar navbar-expand navbar-white navbar-light main-nav"
                    style={{position: 'fixed', top: 0, right: 0, left: 0}}
                >
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <img src="./images/logo.svg" alt=""/>
                            <a className="text-center">
                                <span className="navigation-title title">HCBS</span>
                            </a>
                        </li>
                    </ul>
                </nav>
                <div className='login'>
                    <div className='row'>
                        <div className='col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-12 text-center title'>
                            <span>Reset Password</span>
                        </div>
                    </div>
                    <VerifyForm
                        onSubmit={this.emailVerify}
                        isLinkSent={isLinkSent}
                        resendLink={this.resendLink}
                    />
                </div>
                <Footer/>
            </div>
        );
    }

}

const mapStateToProps = (state) => ({
    confirmEmailSuccess: state.auth.confirmEmailSuccess,
    confirmEmailFail: state.auth.confirmEmailFail,
    verifyTokenSuccess: state.auth.verifyTokenSuccess,
    verifyTokenFail: state.auth.verifyTokenFail
});

export default withRouter(connect(mapStateToProps)(VerifyEmail));