import {CONFIG} from "../../constants/actionTypes";

export function saveClientSearchChanges(pageNumber, pageSize, filter, searchParam, status) {
    let config = {
        pageNumber: pageNumber,
        pageSize: pageSize,
        filter: filter,
        searchParam: searchParam,
        status: status,
    }
    return {
        type: CONFIG.SAVE_CLIENT_SEARCH,
        config
    }
}

export function saveServicesSearchChanges(pageNumber, pageSize, filter, searchParam, status) {
    let config = {
        pageNumber: pageNumber,
        pageSize: pageSize,
        filter: filter,
        searchParam: searchParam,
        status: status,
    }
    return {
        type: CONFIG.SAVE_SERVICE_SEARCH,
        config
    }
}

export function saveStaffSearchChanges(pageNumber, pageSize, filter, searchParam, status) {
    let config = {
        pageNumber: pageNumber,
        pageSize: pageSize,
        filter: filter,
        searchParam: searchParam,
        status: status
    }
    return {
        type: CONFIG.SAVE_STAFF_SEARCH,
        config
    }
}

export function saveSCSearchChanges(pageNumber, pageSize, filter, searchParam, status) {
    let config = {
        pageNumber: pageNumber,
        pageSize: pageSize,
        filter: filter,
        searchParam: searchParam,
        status: status,
    }
    return {
        type: CONFIG.SAVE_SC_SEARCH,
        config
    }
}

export function saveWESearchChanges(pageNumber, pageSize, filter, searchParam, selectedStaff, selectedService, fromDate, toDate, dateRangeOption, activeTabIndex) {
    let config = {
        pageNumber: pageNumber,
        pageSize: pageSize,
        filter: filter,
        searchParam: searchParam,
        selectedStaff: selectedStaff,
        selectedService: selectedService,
        fromDate: fromDate,
        toDate: toDate,
        dateRangeOption: dateRangeOption,
        activeTabIndex: activeTabIndex,
    }
    return {
        type: CONFIG.SAVE_WE_SEARCH,
        config
    }
}

export function saveReportSearchChanges(pageNumber, pageSize, filter, searchParam) {
    let config = {
        pageNumber: pageNumber,
        pageSize: pageSize,
        filter: filter,
        searchParam: searchParam,
    }
    return {
        type: CONFIG.SAVE_REPORT_SEARCH,
        config
    }
}

export function resetSearchConfigs() {
    return {
        type: CONFIG.RESET_SEARCH_CONFIGS
    }
}
