import {CLIENT} from "../../constants/actionTypes";

export const clientReducer = (state = {}, action) => {
    switch (action.type) {
        case CLIENT.CREATE_CLIENT_SUCCESS:
            return {...state, createSuccess: action.response};
        case CLIENT.CREATE_CLIENT_FAIL:
            return {...state, createFail: action.error};
        case CLIENT.RESET_CREATE_CLIENT:
            return {...state, createSuccess: null, createFail: null};
        case CLIENT.UPDATE_CLIENT_SUCCESS:
            return {...state, updateSuccess: action.response};
        case CLIENT.UPDATE_CLIENT_FAIL:
            return {...state, updateFail: action.error};
        case CLIENT.RESET_UPDATE_CLIENT:
            return {...state, updateFail: null, updateSuccess: null};
        case CLIENT.GET_CLIENTS_SUCCESS:
            return {...state, getAllSuccess: action.response};
        case CLIENT.GET_CLIENTS_FAIL:
            return {...state, getAllFail: action.error};
        case CLIENT.RESET_GET_CLIENTS:
            return {...state, getAllSuccess: null, getAllFail: null};
        default:
            return state;
    }
};