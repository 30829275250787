import * as React from 'react';
import './faq.scss';
import {withRouter} from 'react-router-dom';
import Footer from "../shared/footer/footer";
import FAQBox from "./faq-box";
import {map} from "lodash";


class FAQ extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    faqList = [
        {
            question: "Does this count as my EVV entry?",
            isTextAnswer: true,
            answer: "NO.  Before START VISIT, login to the SMC app and start the EVV.  After COMPLETE VISIT, please logout of hcbsbillingsolutions.com and then go into the SMC app to logout of EVV there."
        },
        {
            question: "How do l start a visit? ",
            isTextAnswer: true,
            answer: "Select your client from the drop down list, click CREATE NEW"
        },
        {
            question: "How do I select the service for this visit? ",
            isTextAnswer: true,
            answer: "After clicking CREATE NEW, your client and their authorized service codes should appear.  Select the service by clicking on the dot to the left of the correct code."
        },
        {
            question: "Which code is correct?",
            isTextAnswer: false,
            answer: [
                "W1726 is Companion",
                "W7060 is IHCS 2(1:1)",
                "W7061 is IHCS 2(1:1)- Enhanced",
                "W9862 is Respite 3(1:1)- 15 min",
                "W9798 is Respite 3(1:1)- 24 hours",
                "W5996 is CPS- Community Participation Services",
                "W9794 is Supported Employment",
                "H2023 is Job Finding",
                "T2025 is Nursing",
                "W7095 is Behavioral"
            ]
        },
        {
            question: "How do I enter my Service Notes?",
            isTextAnswer: true,
            answer: "After completing the visit, on your main page, choose the visit you want to add Service Notes to by clicking the green Notes area in the list.  Then click EDIT and begin entering your Service Notes.  Be sure to SAVE them."
        },
        {
            question: "How do I fill in the signature pad? ",
            isTextAnswer: true,
            answer: "Using your mouse or stylus, click and hold while making your signature.  It will be visible.  To redo the signature, please click CLEAR under the signature box."
        },
        {
            question: "How do I end the visit?",
            isTextAnswer: false,
            answer: [
                "Click on YOU HAVE A VISIT IN PROGRESS (big blue bar at the top of your screen)",
                "Make the appropriate selections for progress",
                "Enter your notes (can do this after completing the visit)",
                "Sign the signature pad",
                "Check that the start time is correct and that you are finishing the unit (15 minute increments)",
                "Click COMPLETE VISIT - a summary page will open for your review.",
                "Click COMPLETE VISIT.",
            ]
        },
        {
            question: "Can I edit my Notes after completing the visit? ",
            isTextAnswer: true,
            answer: "YES. Select the visit you want to edit and click on the green Notes area. Select EDIT, begin typing, then SAVE"
        },
        {
            question: "What if I made a mistake?",
            isTextAnswer: true,
            answer: " I haven’t allowed for any mistakes, check back in a bit.\n"
        }
    ];

    render() {
        return (
            <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="row">
                    <div className="row col-lg-2 col-md-2 col-sm-4" onClick={() => {
                        this.props.history.goBack()
                    }}>
                        <img src="./images/chevron-left-green.svg" alt=""/>
                        <span style={{fontFamily: "Muli", color: '#333333', fontSize: 15, padding: 10}}>Back</span>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="client-details">Frequently Asked Questions</div>
                    </div>
                </div>
                <div className='row page-content' style={{width: '100%', marginTop: 30}}>
                    {map(this.faqList, (faq, key) => {
                        return (
                            <FAQBox key={key} question={faq.question} isTextAnswer={faq.isTextAnswer} answer={faq.answer}/>
                        )
                    })}
                </div>
            </div>

        );
    }
}

export default withRouter(FAQ);
