import React from "react";
import {reduxForm} from "redux-form";
import ValidationsBox from "../shared/password-validations/validations-box";

class ResetPasswordForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            password: " ",
            isValidPassword: false,
            dirty: false,
            validLength: false,
            validCharacters: false,
            validNumbers: false,
            validSpecialCharacters: false,
        };
    }

    onPasswordChange = (e) => {
        let password = e.target.value;
        let validLength = false;
        let validCharacters = false;
        let validNumbers = false;
        let validSpecialCharacters = false;
        let validPassword = false;

        const lowerCaseRegex = /[a-z]/
        const upperCaseRegex = /[A-Z]/
        const numbersRegex = /^(?=.*?[0-9]).+$/
        const specialCharacterRegex = /^(?=.*?[#?!@$%^&*-]).+$/
        const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/

        validLength = password.length >= 8;
        validCharacters = lowerCaseRegex.test(password) && upperCaseRegex.test(password);
        validNumbers = numbersRegex.test(password);
        validSpecialCharacters = specialCharacterRegex.test(password);
        validPassword = passwordRegex.test(password);

        this.setState({
            dirty: true,
            password: e.target.value,
            validLength: validLength,
            validCharacters: validCharacters,
            validNumbers: validNumbers,
            validSpecialCharacters: validSpecialCharacters,
            isValidPassword: validPassword,
        })

    }

    render() {
        return (
            <div className='row'>
                <div className='col-lg-8 offset-lg-2 col-md-12 offset-md-0 col-sm-12 offset-sm-0'>
                    <div className='form'>
                        <div className='col-12 input-box text-left password-container'>
                            <div className='label' style={{}}>New Password</div>
                            {/*<Field*/}
                            {/*    name="newPassword"*/}
                            {/*    component={inputField}*/}
                            {/*    type="text"*/}
                            {/*    placeholder={'New Password'}*/}
                            {/*    required={true}*/}
                            {/*    validate={this.validatePassword}*/}
                            {/*    onChange={(e) => {*/}
                            {/*        this.setState({password: e.target.value})*/}
                            {/*    }}*/}
                            {/*/>*/}
                            <input type="text"
                                   className={this.state.isValidPassword || !this.state.dirty ? 'box password-text text-box' : 'box password-text text-box error'}
                                   style={{marginBottom: '0px !important'}} placeholder={'New Password'}
                                   onChange={this.onPasswordChange} onFocus={() => {
                                this.setState({
                                    dirty: true
                                })
                            }}/>
                        </div>
                        <div className="col-12">
                            <ValidationsBox dirty={this.state.dirty} validLength={this.state.validLength}
                                            validCharacters={this.state.validCharacters}
                                            validNumbers={this.state.validNumbers}
                                            validSpecialCharacters={this.state.validSpecialCharacters}/>
                        </div>
                        <div className='row' style={{marginBottom: 20}}>
                            <div className="col-6">
                                <button
                                    className="button basic"
                                    onClick={(event) => {
                                        this.props.onCloseModal();
                                    }}
                                >
                                    CANCEL
                                </button>
                            </div>
                            <div className='col-6'>
                                <button
                                    className={this.state.isValidPassword ? 'button submit' : 'button submit disabled'}
                                    disabled={!this.state.isValidPassword} onClick={() => {
                                    if (this.state.isValidPassword) {
                                        this.props.handleSubmit(this.state.password, this.props.userId)
                                    }
                                }}>
                                            <span className='text'>
                                                SAVE CHANGES
                                            </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const ResetPassword = reduxForm({
    form: 'loginForm'
})(ResetPasswordForm);

export default ResetPassword;
