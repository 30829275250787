import React, { useEffect, useState } from "react";
import "./serviceSummary.scss";
import Pagination from "./pagination";
import { isMobileDevice } from "../../../../util/deviceType";
import { ServiceSummaryMobile } from "./serviceSummaryMobile";
import { getServiceSummary } from "../../../../api/report";
import { ServiceDataTable } from "./serviceDataTable";

export const ServiceSummary = () => {
  const [services, setServices] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");

  const itemsPerPage = 3;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const handlePageClick = (event, pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const filteredServices = Object.entries(services).reduce(
    (result, [clientName, servicesArray]) => {
      const filteredArray = servicesArray.filter((service) => {
        return service.clientName
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      });

      if (filteredArray.length > 0) {
        result[clientName] = filteredArray;
      }

      return result;
    },
    {}
  );

  const currentItems = Object.keys(filteredServices).slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const fetch = () => {
    getServiceSummary()
      .then((res) => {
        if (res.data) {
          const temp = {};

          for (const objet of res.data) {
            const clientName = objet.clientName;

            if (!temp[clientName]) {
              temp[clientName] = [];
            }

            temp[clientName].push(objet);
          }

          setServices(temp);
        }
      })
      .catch((e) => {
        console.log("staff_Pay error", e);
      });
  };

  useEffect(() => {
    fetch();
  }, []);

  return (
    <div div className="table-layout">
      <div className="col-lg-6 col-sm-12 page-title">Reports</div>

      <div className="cont-service-title">
        <span>Service Summary Report</span>
        <img
          src="./images/refresh-icon.svg"
          style={{ height: "20px" }}
          onClick={fetch}
        />
      </div>

      <>
        {currentItems.length === 0 ? (
          <div className="row">
            <div className="col-12 text-center" style={{ margin: 10 }}>
              <span>No rows found </span>
            </div>
          </div>
        ) : (
          currentItems.map((clientName) => (
            <>
              <div
                className={
                  isMobileDevice() ? "cont-client-name-sm" : "cont-client-name"
                }
              >
                {clientName}
              </div>

              {!isMobileDevice() ? (
                <ServiceDataTable data={services[clientName]} />
              ) : (
                <ServiceSummaryMobile data={services[clientName]} />
              )}
            </>
          ))
        )}

        <Pagination
          itemsPerPage={itemsPerPage}
          totalItems={Object.keys(filteredServices).length}
          currentPage={currentPage}
          onPageClick={handlePageClick}
        />
      </>
    </div>
  );
};
