import React, { useEffect, useState } from "react";
import { get } from "lodash";
import moment from "moment/moment";
import * as classNames from "classnames";

const ConfirmNewService = ({ service, allServices, staffAllocationData, onConfirm, onCloseModal, goBack }) => {

  const [serviceDetails, setServiceDetails] = useState({});
  const [serviceRequest, setServiceRequest] = useState({
    serviceName: "", // Client Name - Service Code
    serviceCode: "",
    serviceUnitRate: 0,
    serviceUnitLength: 0,
    serviceStartDate: "",
    serviceEndDate: "",
    serviceUnitsAuthorized: 0,
    serviceIsNegetiveUnitsAllowed: false,
    serviceIsAuthorized: false,
    isDegreeRequired: false,
    isDatesConfirmed: false,
    isOverlappable: false,
    authorizedDate: "",
    clientName: "",
    recipientId: "",
    serviceId: 0,
    unitsUtilized: 0,
    assignedStaff: []
  });
  const [isAllowedNegativeUnits, setIsAllowedNegativeUnits] = useState(false);

  useEffect(() => {

    let selectedService = {};
    allServices.map((s) => {
      if (s.code === service.service_code) {
        selectedService = s;
      }
    });
    setServiceDetails(selectedService);

    const servReq = {
      clientName: service.individual_name,
      recipientId: service.recipient_id,
      serviceName: `${toTitleCase(service.individual_name)} - ${service.service_code}`,
      serviceCode: service.service_code,
      serviceStartDate: service.service_start_date,
      serviceEndDate: service.service_end_date,
      authorizedDate: service.date_last_authorized,
      isDegreeRequired: selectedService.isDegreeRequired,
      isOverlappable: selectedService.isOverlappable,
      serviceId: selectedService.serviceId,
      serviceUnitRate: parseFloat(selectedService.unitRate),
      serviceUnitLength: parseFloat(selectedService.unitLength),
      assignedStaff: staffAllocationData.assignedStaff,
      serviceUnitsAuthorized: parseFloat(service.units_authorized),
      unitsUtilized: parseFloat(service.utilized_units)
    };
    setServiceRequest(servReq);
    console.log(servReq);
    console.log(service);
    console.log(staffAllocationData);
    console.log(selectedService);

  }, []);

  const toTitleCase = (name) => {
    return name.toLowerCase().split(" ").map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    }).join(" ");
  };

  const getStartDate = () => {
    return moment(
      get(serviceRequest, "serviceStartDate", new Date())
    ).format("YYYY-MM-DD HH:mm:ss.SSS");
  };

  const getEndDate = () => {
    return moment(
      get(serviceRequest, "serviceEndDate", new Date())
    ).format("YYYY-MM-DD HH:mm:ss.SSS");
  };

  const allowNegativeUnits = (val) => {
    setIsAllowedNegativeUnits(val);
    setServiceRequest({
      ...serviceRequest,
      serviceIsNegetiveUnitsAllowed: val
    });
  };

  return (
    <div className={"col-sm-12 col-lg-12 staff-allocation"}>
      <div
        className="row col-lg-2 col-md-2 col-sm-4"
        onClick={() => {
          goBack();
        }}
      >
        <img src="./images/chevron-left-green.svg" alt="" />
        <span
          style={{
            fontFamily: "Muli",
            color: "#333333",
            fontSize: 15,
            padding: 10
          }}
        >
            Back
          </span>
      </div>
      <div className={"row col-sm-12 col-lg-12"} style={{ marginBottom: 10 }}>
        <div className="col-lg-6 col-sm-12">
          <div className="header_text">Client Name</div>
          <div className="title_text">{get(serviceRequest, "clientName", "-")}</div>
        </div>
        <div className="col-lg-6 col-sm-12">
          <div className="header_text">Service Name</div>
          <div className="title_text">{get(serviceRequest, "serviceName", "-")}</div>
        </div>
      </div>
      <div className={"row col-sm-12 col-lg-12"} style={{ marginBottom: 10 }}>
        <div className="col-lg-3 col-sm-6">
          <div className="header_text">Service Code</div>
          <div className="title_text">{get(serviceRequest, "serviceCode", "-")}</div>
        </div>
        <div className="col-lg-3 col-sm-6">
          <div className="header_text">Authorized Date</div>
          <div className="title_text">{serviceRequest.authorizedDate}</div>
        </div>
        <div className="col-lg-3 col-sm-6">
          <div className="header_text">Begin Date</div>
          <div className="title_text">
            {serviceRequest.serviceStartDate}
          </div>
        </div>
        <div className="col-lg-3 col-sm-6">
          <div className="header_text">End Date</div>
          <div className="title_text">
            {serviceRequest.serviceEndDate}
          </div>
        </div>
      </div>
      <div className={"row col-sm-12 col-lg-12"} style={{ marginBottom: 10 }}>
        <div className="col-lg-3 col-sm-6">
          <div className="header_text">Units Authorized</div>
          <div className="title_text">{get(serviceRequest, "serviceUnitsAuthorized", "-")}</div>
        </div>
        <div className="col-lg-3 col-sm-6">
          <div className="header_text">Unit Rate</div>
          <div className="title_text">{get(serviceRequest, "serviceUnitRate", "-")}</div>
        </div>
        <div className="col-lg-3 col-sm-6">
          <div className="header_text">Unit Length</div>
          <div className="title_text">{get(serviceRequest, "serviceUnitLength", "-")}</div>
        </div>
        {serviceRequest?.unitsUtilized > 0 && (
          <div className="col-lg-3 col-sm-6">
            <div className="header_text">Units Utilized</div>
            <div className="title_text">{get(serviceRequest, "unitsUtilized", "-")}</div>
          </div>
        )}
      </div>
      <div className={"row col-sm-12 col-lg-12"} style={{ marginBottom: 10 }}>
        <div className="col-6 input-box text-left my-auto">
          <input
            type="checkbox"
            name="isAllowedNegativeUnits"
            value="isAllowedNegativeUnits"
            checked={isAllowedNegativeUnits}
            onChange={(event) => {
              allowNegativeUnits(event.target.checked);
            }}
            style={{ marginRight: 5 }}
          />
          <span className="header_text">Allow Negative Units Remaining</span>
        </div>
      </div>
      <div className={"row col-sm-12 col-lg-12"} style={{ marginBottom: 30 }}>
        <div className="col-lg-12 col-sm-12">
          <div className="header_text">Allocated Staff</div>
          <div className="title_text">
            <ul>
              {serviceRequest.assignedStaff.map((staff, index) => (
                <li key={index}>{staff.name}</li>
              ))}
              {serviceRequest?.assignedStaff.length === 0 && (
                <li key={"none"}>No Staff Members have been allocated.</li>
              )}
            </ul>
          </div>
        </div>
      </div>


      <div
        className="row"
        style={{
          marginTop: 30
        }}
      >
        <div className="col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-sm-10 offset-sm-1">
          <div className="row">
            <div className="col-6">
              <button
                type="submit"
                className="button submit"
                onClick={() => {
                  onConfirm(serviceRequest);
                }}
              >
                {"CONFIRM"}
              </button>
            </div>
            <div className="col-6">
              <button
                className="button basic"
                onClick={(event) => {
                  onCloseModal();
                }}
              >
                CANCEL
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmNewService;