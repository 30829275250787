import React from "react";
import * as classNames from "classnames";
import {formatDateTime} from "../../../util/util";
import {get} from "lodash";

class CompleteVisit extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const {client, service, visit, notes} = this.props
        return (
            <div>
                <div
                    className={classNames("modal fade", {show: this.props.show})}
                    id={this.props.id ? this.props.id : 'exampleModal'}
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div className={"modal-dialog modal-xl"} role="document">
                        <div className="modal-content" style={{padding: 20}}>
                            <div className="row col-12 modal-header">
                                <div className="col-lg-8 col-sm-12 offset-lg-2 offset-sm-0  text-center">
                                    <h5 className="col-12 modal-title text-center" id="exampleModalLabel">
                                        <span className="my-auto">
                                          <img src={"/images/lock.svg"} alt="" style={{marginRight: 10}}/>
                                        </span>
                                        <span style={{marginLeft: "5px"}}>Complete Visit</span>
                                    </h5>
                                </div>
                            </div>
                            <div className="modal-body">
                                <div className='row'>
                                    <div className="col-lg-12 col-sm-12 visit-notes-details">
                                        <div className="row">
                                            <div className="col-lg-3 col-sm-6 text-center">
                                                <img src="./images/user.svg" alt="" style={{marginRight: 2}}/>
                                                <span className="label">Client Name</span>
                                                <div className="value">
                                                    {client.lastName + " " + client.firstName + " " + client.middleName}
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-sm-6 text-center">
                                                <img src="./images/user.svg" alt="" style={{marginRight: 2}}/>
                                                <span className="label">Service Name</span>
                                                <div className="value">
                                                    {service.serviceName}
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-sm-6 text-center">
                                                <img src="../images/calendar-green.svg" alt=""
                                                     style={{marginRight: 2}}/>
                                                <span className="label">Clock In Time</span>
                                                <div className="value">
                                                    {formatDateTime(new Date(get(visit, 'serviceStartTime', null)), "MMMM Do YYYY, h:mm a")}
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-sm-6 text-center">
                                                <img src="../images/calendar-green.svg" alt=""
                                                     style={{marginRight: 2}}/>
                                                <span className="label">Clock Out Time</span>
                                                <div className="value">
                                                    {formatDateTime(new Date(), "MMMM Do YYYY, h:mm a")}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/*<div className="col-lg-12 col-sm-12 abandon-visit">*/}
                                    {/*    <div className='col-10 offset-1 text-left abandon-date-time'*/}
                                    {/*         style={{marginBottom: 10}}>*/}
                                    {/*        <img src="./images/calendar.svg" alt="" className='calendar'/>*/}
                                    {/*        <span className="label" style={{marginRight: 10}}>Clock In Time</span>*/}
                                    {/*        <span*/}
                                    {/*            className='date-text'>{moment(visit.serviceStartTime).format("LLLL")}</span>*/}
                                    {/*    </div>*/}
                                    {/*    <div className='col-10 offset-1 text-left abandon-date-time'>*/}
                                    {/*        <img src="./images/calendar.svg" alt="" className='calendar'/>*/}
                                    {/*        <span className="label" style={{marginRight: 10}}>Clock Out Time</span>*/}
                                    {/*        <span className='date-text'>{moment().format("LLLL")}</span>*/}
                                    {/*    </div>*/}
                                    {/*<div className="col-lg-3 col-sm-6 abandon-visit">*/}
                                    {/*<img*/}
                                    {/*    src="./images/calendar-green.svg"*/}
                                    {/*    alt=""*/}
                                    {/*    style={{marginRight: 2}}*/}
                                    {/*/>*/}
                                    {/*<span className="label">Clock In Time</span>*/}
                                    {/*<div className="value">*/}
                                    {/*    {formatDateTime(new Date(), "MMMM Do YYYY, h:mm a")}*/}
                                    {/*</div>*/}
                                    {/*</div>*/}
                                    {/*</div>*/}

                                    <div className='col-lg-12 col-md-12 col-sm-12'>
                                        <div className="client-details" style={{fontSize: 18}}>Progress</div>
                                        <div className="row" style={{padding: 30, backgroundColor: '#FAFBFC'}}>
                                            <div className="col-lg-4 col-md-4 col-sm-12 text-left progress-selection">
                                                <input
                                                    type="radio"
                                                    name="progress"
                                                    value="increased"
                                                    checked={this.props.progress === 1}
                                                    disabled={true}
                                                />
                                                <label className={"radio-text"}>Increased</label>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-12 text-left progress-selection">
                                                <input
                                                    type="radio"
                                                    name="progress"
                                                    value="maintained"
                                                    checked={this.props.progress === 2}
                                                    disabled={true}
                                                />
                                                <label className={"radio-text"}>Maintained</label>
                                            </div>

                                            <div className="col-lg-4 col-md-4 col-sm-12 text-left progress-selection">
                                                <input
                                                    type="radio"
                                                    name="progress"
                                                    value="decreased"
                                                    checked={this.props.progress === 3}
                                                    disabled={true}
                                                />
                                                <label className={"radio-text"}>Decreased</label>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div className="col-lg-12 col-md-12 col-sm-12 notes-content row">
                                        <span className="col-12 label text-left">Notes</span>
                                        {notes && (
                                            <span className={"notes-text"}>
                                            {notes}
                                        </span>
                                        )}
                                        {!notes && (
                                            <span className={"notes-text-disabled"} style={{
                                                textDecoration: "underline",
                                                fontStyle: "italic",
                                                cursor: "pointer"
                                            }} onClick={(event) => {
                                                this.props.onCloseModal();
                                            }}>
                                            {"Add a notes.."}
                                        </span>
                                        )}
                                    </div>
                                    {this.props.signature && (
                                        <div className="col-12 notes-content row">
                                            <span className="col-12 label text-left">Signature</span>
                                            <img className={{}} src={this.props.signature} alt="Signature"/>
                                        </div>
                                    )}
                                    <div className="col-lg-6 offset-lg-3 col-sm-12 offset-sm-0">
                                        <div className='row' style={{marginBottom: 20, marginTop: 20}}>
                                            <div className="col-6">
                                                <button
                                                    className="button basic"
                                                    onClick={(event) => {
                                                        this.props.onCloseModal();
                                                    }}
                                                >
                                                    CANCEL
                                                </button>
                                            </div>
                                            <div className={"col-6"}>
                                                <button className='button submit' onClick={this.props.handleSubmit}>
                                                    <span
                                                        className='text'>COMPLETE VISIT</span>
                                                </button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default CompleteVisit;
