import { get } from "lodash";

export function setAuthData(authData) {
  window.localStorage.setItem("authData", JSON.stringify(authData));
}

export function getAuthData() {
  return window.localStorage.getItem("authData");
}

export function checkAuthStatus(status) {
  if (status === 401 && window.location.pathname != "/login") {
    window.localStorage.setItem("authData", null)
    window.location.href="/login"
  }
}

export function getToken() {
  const authData = JSON.parse(getAuthData());
  return get(authData, "token", null);
}

export function getAuth() {
  const authData = getAuthData();
  return JSON.parse(authData);
}

export function setOtpData(otpData) {
  window.localStorage.setItem("otpData", JSON.stringify(otpData));
}

export function setLoginToken(token) {
  window.localStorage.setItem("loginToken", JSON.stringify(token));
}

export function getLoginToken() {
  const loginToken = window.localStorage.getItem("loginToken");
  return JSON.parse(loginToken);
}

export function setLoginTokenArray(token, email) {
  email = email.trim().toLowerCase();
  let existingLoginTokenArray = getLoginTokenArray();
  if (existingLoginTokenArray != null) {
    let c = 0;
    for (let i = 0; i < existingLoginTokenArray.length; i++) {
      if (existingLoginTokenArray[i].email == email) {
        existingLoginTokenArray[i].token = token;
        c++;
      }
    }
    if (c == 0) {
      existingLoginTokenArray.push({
        email,
        token,
      });
    }
  } else {
    existingLoginTokenArray = [];
    existingLoginTokenArray.push({
      email,
      token,
    });
  }
  window.localStorage.setItem(
    "loginTokenArray",
    JSON.stringify(existingLoginTokenArray)
  );
}

export function getLoginTokenArray() {
  const loginTokenArray = window.localStorage.getItem("loginTokenArray");
  return JSON.parse(loginTokenArray);
}

export function getSpecificLoginToken(email) {
  let existingLoginTokenArray = getLoginTokenArray();

  if (existingLoginTokenArray != null) {
    let tokenToReturn = "";
    for (let i = 0; i < existingLoginTokenArray.length; i++) {
      if (existingLoginTokenArray[i].email == email) {
        tokenToReturn = existingLoginTokenArray[i].token;
      }
    }
    return tokenToReturn;
  } else return null;
}
