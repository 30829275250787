import { post } from "../util/httpService";
import {
  GET_REPORT_URL,
  REPORT_TYPES,
  REPORT_URL,
} from "../constants/appConstants";
import { get } from "lodash";
import { getToken } from "../util/auth";
import FileDownload from "js-file-download";
import moment from "moment";
import { getTimeZone } from "../util/util";

export async function generateReport(reportType, startDate, endDate, status) {
  const authToken = getToken();
  const headers = { Authorization: `bearer ${authToken}` };
  const body = endDate
    ? { fromDate: startDate, endDate: endDate, status: status }
    : {
        fromDate: startDate,
        status: status,
      };
  try {
    const response = await post(`${REPORT_URL}/${reportType}`, body, {
      headers: headers,
      responseType: "blob",
    });
    FileDownload(response.data, getReportName(reportType));
    // return get(response,'data.data',{}),report;
    return response.data;
  } catch (e) {
    throw get(e, "response.data", {});
  }
}

export async function getReportData(
  reportType,
  startDate,
  endDate,
  offset,
  limit,
  status
) {
  const authToken = getToken();
  const headers = { Authorization: `bearer ${authToken}` };
  const body = endDate
    ? {
        fromDate: startDate,
        endDate: endDate,
        offset: offset,
        limit: limit,
        status: status,
      }
    : { fromDate: startDate, offset: offset, limit: limit, status: status };
  try {
    const response = await post(`${GET_REPORT_URL}/${reportType}`, body, {
      headers: headers,
    });
    return response.data;
  } catch (e) {
    throw get(e, "response.data", {});
  }
}

export async function generateStaffReport(reportType, status) {
  const authToken = getToken();
  const headers = { Authorization: `bearer ${authToken}` };
  try {
    const response = await post(
      `${REPORT_URL}/${reportType}?status=${status}`,
      {},
      {
        headers: headers,
        responseType: "blob",
      }
    );
    FileDownload(response.data, getReportName(reportType));
    return response.data;
  } catch (e) {
    throw get(e, "response.data", {});
  }
}

export async function getStaffReportData(reportType, status, offset, limit) {
  const authToken = getToken();
  const headers = { Authorization: `bearer ${authToken}` };
  const body = { offset: offset, limit: limit };
  try {
    const response = await post(
      `${GET_REPORT_URL}/${reportType}?status=${status}`,
      body,
      {
        headers: headers,
      }
    );
    return response.data;
  } catch (e) {
    throw get(e, "response.data", {});
  }
}
export async function getStaffPayReport(
  reportType,
  adminVerification,
  fromDate,
  toDate,
  dateFilterOption,
  clientId,
  staffId,
  serviceId,
  offset,
  limit
) {
  const authToken = getToken();
  const headers = { Authorization: `bearer ${authToken}` };
  const body = {
    adminVerification: adminVerification,
    clientId: clientId,
    staffId: staffId,
    serviceId: serviceId,
    dateFilterOption: dateFilterOption,
    fromDate: moment(fromDate).isValid() ? fromDate : "",
    toDate: moment(toDate).isValid() ? toDate : "",
    timeZone: getTimeZone(),
    offset: offset,
    limit: limit,
  };
  try {
    const response = await post(`${GET_REPORT_URL}/${reportType}`, body, {
      headers: headers,
    });
    return response.data;
  } catch (e) {
    throw get(e, "response.data", {});
  }
}

export async function getServiceSummary() {
  const authToken = getToken();
  const headers = { Authorization: `bearer ${authToken}` };
  const body = {
    timezone: getTimeZone(),
    offset: 0,
    limit: 1000,
  };
  try {
    const response = await post(`${GET_REPORT_URL}/service_summary`, body, {
      headers: headers,
    });
    return response.data;
  } catch (e) {
    throw get(e, "response.data", {});
  }
}

export async function generateWorkEntryReport(
  reportType,
  adminVerification,
  fromDate,
  toDate,
  dateFilterOption,
  clientId,
  staffId,
  serviceId
) {
  const authToken = getToken();
  const headers = { Authorization: `bearer ${authToken}` };
  const body = {
    adminVerification: adminVerification,
    clientId: clientId,
    staffId: staffId,
    serviceId: serviceId,
    dateFilterOption: dateFilterOption,
    fromDate: moment(fromDate).isValid() ? fromDate : "",
    toDate: moment(toDate).isValid() ? toDate : "",
    timeZone: getTimeZone(),
  };
  try {
    const response = await post(`${REPORT_URL}/${reportType}`, body, {
      headers: headers,
      responseType: "blob",
    });
    FileDownload(response.data, getReportName(reportType));
    return response.data;
  } catch (e) {
    throw get(e, "response.data", {});
  }
}

export async function getWorkEntryReport(
  reportType,
  adminVerification,
  fromDate,
  toDate,
  dateFilterOption,
  clientId,
  staffId,
  serviceId,
  offset,
  limit
) {
  const authToken = getToken();
  const headers = { Authorization: `bearer ${authToken}` };
  const body = {
    adminVerification: adminVerification,
    clientId: clientId,
    staffId: staffId,
    serviceId: serviceId,
    dateFilterOption: dateFilterOption,
    fromDate: moment(fromDate).isValid() ? fromDate : "",
    toDate: moment(toDate).isValid() ? toDate : "",
    timeZone: getTimeZone(),
    offset: offset,
    limit: limit,
  };
  try {
    const response = await post(`${GET_REPORT_URL}/${reportType}`, body, {
      headers: headers,
    });
    return response.data;
  } catch (e) {
    throw get(e, "response.data", {});
  }
}

export function getReportName(reportType) {
  switch (reportType) {
    case REPORT_TYPES.NOTES_REPORT:
      return `Notes Report on ${moment().format("MM-DD-YYYY")}.xlsx`;
    case REPORT_TYPES.STAFF_REPORT:
      return `Staff Billing Report on ${moment().format("MM-DD-YYYY")}.xlsx`;
    case REPORT_TYPES.PROMISE_BILLING_REPORT:
      return `Promise Billing Report on ${moment().format("MM-DD-YYYY")}.xlsx`;
    case REPORT_TYPES.CLIENT_SUMMARY:
      return `All Contacts Report on ${moment().format("MM-DD-YYYY")}.xlsx`;
    case REPORT_TYPES.SC_SUMMARY:
      return `Supports Coordinator Report on ${moment().format(
        "MM-DD-YYYY"
      )}.xlsx`;
    case REPORT_TYPES.STAFF_SUMMARY:
      return `Staff Summary Report on ${moment().format("MM-DD-YYYY")}.xlsx`;
    case REPORT_TYPES.STAFF_ALLOCATION_SUMMARY:
      return `Staff Allocation Summary Report on ${moment().format(
        "MM-DD-YYYY"
      )}.xlsx`;
    default:
      return "Invalid Report Name.xlsx";
  }
}
