import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import "./clientDetails.scss";
import SearchableDropdown from "../../shared/searchable-dropdown";
import { clone, get, isNull, map, pullAllBy, remove } from "lodash";
import { getAllUsersList } from "../../../api/user";
import DualListBox from "../../shared/dual-list-box/dual-list-box";
import * as classNames from "classnames";
import { formatDateTime } from "../../../util/util";
import moment from "moment";
import { isEmpty } from "lodash/lang";

class StaffAllocation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allOptions: [],
      availableOptions: [],
      filteredAvailableOptions: [],
      selectedOptions: [],
      filteredSelectedOptions: [],
      markedAvailableOptions: [],
      markedSelectedOptions: [],
      selectedStaff: [],
      chosenFilter: "",
      availableFilter: "",
    };
  }

  componentDidMount() {
    const { service } = this.props;
    getAllUsersList().then((res) => {
      let allOptions = res;
      let availableOptions = clone(allOptions);
      let selectedOptions = service.assignedStaff ? service.assignedStaff : [];
      pullAllBy(availableOptions, selectedOptions, "userId");
      this.setState({
        allOptions: allOptions,
        availableOptions: availableOptions,
        selectedOptions: selectedOptions,
        filteredAvailableOptions: availableOptions,
        filteredSelectedOptions: selectedOptions,
        selectedStaff: selectedOptions,
      });
    });
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.selectedOptions !== this.props.selectedOptions) {
      if (nextProps.selectedOptions) {
        let availableOptions = clone(this.state.allOptions);
        let selectedOptions = nextProps.selectedOptions;
        pullAllBy(availableOptions, selectedOptions, "userId");
        this.setState({
          availableOptions: availableOptions,
          selectedOptions: selectedOptions,
          filteredAvailableOptions: availableOptions,
          filteredSelectedOptions: selectedOptions,
        });
      }
    }
  };

  onSelectAvailableOption = (option) => {
    let markedOptions = this.state.markedAvailableOptions;
    if (markedOptions.includes(option)) {
      remove(markedOptions, function (o) {
        return o == option;
      });
    } else {
      markedOptions.push(option);
    }
    this.setState({
      markedAvailableOptions: markedOptions,
      availableFilter: "",
      filteredAvailableOptions: this.filterOptions(
        this.state.availableOptions,
        "name",
        ""
      ),
    });
  };

  onOptionSelect = () => {
    let availableOptions = clone(this.state.allOptions);
    let selectedOptions = this.state.selectedOptions;
    let markedOptions = this.state.markedAvailableOptions;
    markedOptions.forEach((element) => {
      selectedOptions.push(
        availableOptions.find((option) => option.userId === element.userId)
      );
    });
    pullAllBy(availableOptions, selectedOptions, "userId");
    this.setState({
      availableOptions: availableOptions,
      selectedOptions: selectedOptions,
      selectedStaff: selectedOptions,
      markedAvailableOptions: [],
    });
  };

  onSelectSelectedOption = (option) => {
    let markedOptions = this.state.markedSelectedOptions;
    if (markedOptions.includes(option)) {
      remove(markedOptions, function (o) {
        return o == option;
      });
    } else {
      markedOptions.push(option);
    }
    this.setState({
      markedSelectedOptions: markedOptions,
      chosenFilter: "",
      filteredSelectedOptions: this.filterOptions(
        this.state.selectedOptions,
        "name",
        ""
      ),
    });
  };

  onOptionDeselect = () => {
    let availableOptions = clone(this.state.allOptions);
    let selectedOptions = this.state.selectedOptions;
    let markedOptions = this.state.markedSelectedOptions;
    pullAllBy(selectedOptions, markedOptions, "userId");
    pullAllBy(availableOptions, selectedOptions, "userId");
    this.setState({
      availableOptions: availableOptions,
      selectedOptions: selectedOptions,
      selectedStaff: selectedOptions,
      markedSelectedOptions: [],
    });
  };

  onChosenFilterChange = (e) => {
    const searchParam = e.target.value;
    this.setState({
      chosenFilter: searchParam,
      filteredSelectedOptions: this.filterOptions(
        this.state.selectedOptions,
        "name",
        searchParam
      ),
    });
  };

  onAvailableFilterChange = (e) => {
    const searchParam = e.target.value;
    this.setState({
      availableFilter: searchParam,
      filteredAvailableOptions: this.filterOptions(
        this.state.availableOptions,
        "name",
        searchParam
      ),
    });
  };

  filterOptions = (options, valueIdentifier, selectedValue) => {
    selectedValue = selectedValue.trim();
    if (isNull(selectedValue) || isEmpty(selectedValue)) {
      return options;
    }
    return options.filter(
      (option) =>
        option[valueIdentifier]
          .toLowerCase()
          .replaceAll(",", "")
          .includes(selectedValue.toLowerCase()) ||
        option[valueIdentifier].toLowerCase().replaceAll(",", "") ==
          selectedValue.toLowerCase()
    );
  };

  render() {
    const { service } = this.props;
    return (
      <div className={"col-sm-12 col-lg-12 staff-allocation"}>
        <div className={"row col-sm-12 col-lg-12"} style={{ marginBottom: 30 }}>
          <div className="col-lg-3 col-sm-6">
            <div className="header_text">Service Name</div>
            <div className="title_text">{get(service, "serviceName", "-")}</div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="header_text">Service Code</div>
            <div className="title_text">{get(service, "serviceCode", "-")}</div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="header_text">Begin Date</div>
            <div className="title_text">
              {moment(service.serviceStartDate).utc().format("MM-DD-YYYY")}
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="header_text">End Date</div>
            <div className="title_text">
              {moment(service.serviceEndDate).utc().format("MM-DD-YYYY")}
            </div>
          </div>
        </div>
        <div className="row dual-list-box">
          <div className={"col-5"}>
            <div className="box-header text-center">Available Staff</div>
            <div className="input-box">
              <input
                type="text"
                className="box text-box staff-allocation-search search-icon"
                value={this.state.availableFilter}
                onChange={this.onAvailableFilterChange}
              />
            </div>
            <div className=" list-box text-center">
              {this.state.filteredAvailableOptions.map((option, index) => {
                return (
                  <div
                    key={index}
                    className={classNames("list-ite", {
                      active:
                        this.state.markedAvailableOptions.includes(option),
                    })}
                    onClick={() => {
                      this.onSelectAvailableOption(option);
                    }}
                  >
                    <div className="child-item">{option.name}</div>
                  </div>
                );
              })}
              {this.state.filteredAvailableOptions.length === 0 &&
                this.state.availableFilter != "" && (
                  <div className="box-header text-center">No Matches found</div>
                )}
            </div>
          </div>

          <div className="col-2 my-auto">
            <div
              className="list-box-arrow text-center"
              onClick={this.onOptionSelect}
            >
              <span>
                <img src="./images/chevron-right-grey.svg" alt="" />
              </span>
            </div>
            <div
              className="list-box-arrow text-center"
              onClick={this.onOptionDeselect}
            >
              <span>
                <img src="./images/chevron-left-grey.svg" alt="" />
              </span>
            </div>
          </div>
          <div className="col-5">
            <div className="box-header text-center">Chosen Staff</div>
            <div className="input-box">
              <input
                type="text"
                className="box text-box staff-allocation-search search-icon"
                value={this.state.chosenFilter}
                onChange={this.onChosenFilterChange}
              />
            </div>
            <div className="list-box text-center">
              {this.state.filteredSelectedOptions.map((option, index) => {
                return (
                  <div
                    key={index}
                    className={classNames("list-ite", {
                      active: this.state.markedSelectedOptions.includes(option),
                    })}
                    // style={{
                    //   backgroundColor: "#35cc62",

                    //   margin: 15,
                    //   minHeight: 31,
                    //   justifyContent: "stretch",
                    //   justifyItems: "center",
                    //   borderRadius: 15.5,
                    // }}
                    onClick={() => {
                      this.onSelectSelectedOption(option);
                    }}
                  >
                    <div
                      className="child-item"
                      //   style={{
                      //     backgroundColor: "red",
                      //     wordWrap: "break-word",
                      //     margin: 4,
                      //   }}
                    >
                      {option.name}
                    </div>
                  </div>
                );
              })}
              {this.state.filteredSelectedOptions.length === 0 &&
                this.state.chosenFilter != "" && (
                  <div className="box-header text-center">No Matches found</div>
                )}
            </div>
          </div>
        </div>
        <div
          className="row"
          style={{
            marginTop: 30,
          }}
        >
          <div className="col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-sm-10 offset-sm-1">
            <div className="row">
              <div className="col-6">
                <button
                  type="submit"
                  className="button submit"
                  onClick={() => {
                    this.props.onUpdate(service, this.state.selectedStaff);
                  }}
                >
                  {"UPDATE"}
                </button>
              </div>
              <div className="col-6">
                <button
                  className="button basic"
                  onClick={(event) => {
                    this.props.onCloseModal();
                  }}
                >
                  CANCEL
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default withRouter(connect(mapStateToProps)(StaffAllocation));
