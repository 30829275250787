import * as React from "react";
import "./support-cordinator.scss";
import DataGrid from "../shared/data-grid/data-grid";
import BasicCell from "../shared/data-grid/custom-cells/basic-cell";
import BasicHeader from "../shared/data-grid/custom-headers/basic-header";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getSCs } from "../../redux/users/userActions";
import { get, isEmpty, isEqual, isNull, map, startCase } from "lodash";
import Actions from "../shared/data-grid/custom-cells/actions";
import View from "../shared/data-grid/custom-cells/view";
import { isMobileDevice } from "../../util/deviceType";
import SupportCard from "../staff/supportCard";
import Pagination from "../shared/data-grid/pagination/pagination";
import { saveSCSearchChanges } from "../../redux/config/configAction";

class SupportCoordinator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      seletedUser: "",
      totalUsers: 0,
      pageNumber: 0,
      searchParam: "",
      filter: "recent",
      pageSize: 10,
      status: 0,
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const { users, searchConfig } = this.props;
    const userData = [];
    if (!isEqual(nextProps.users, users) && !isNull(nextProps.users)) {
      map(nextProps.users.data, (user) => {
        userData.push({
          id: user.scId,
          // name: startCase(`${get(user, "firstName")} ${get(user, "lastName")}`),
          name: `${get(user, "lastName")} ${get(user, "firstName")}`,
          city: startCase(get(user, "billingCity", "")),
          companyName: get(user, "company", ""),
          mobile: get(user, "mobile", ""),
        });
      });
      this.setState({
        users: userData,
        totalUsers: nextProps.users.recordCount,
      });
    }

    if (
      !isNull(nextProps.searchConfig) &&
      !isEmpty(nextProps.searchConfig) &&
      !isEqual(nextProps.searchConfig, searchConfig)
    ) {
      this.setState({
        pageNumber: nextProps.searchConfig.pageNumber,
        pageSize: nextProps.searchConfig.pageSize,
        filter: nextProps.searchConfig.filter,
        searchParam: nextProps.searchConfig.searchParam,
        status: nextProps.searchConfig.status,
      });
      this.getAllUsers(
        nextProps.searchConfig.pageNumber,
        nextProps.searchConfig.pageSize,
        nextProps.searchConfig.filter,
        nextProps.searchConfig.searchParam,
        nextProps.searchConfig.status
      );
    }
  }

  componentDidMount() {
    const { searchConfig } = this.props;
    if (searchConfig) {
      this.setState({
        pageNumber: searchConfig.pageNumber,
        pageSize: searchConfig.pageSize,
        filter: searchConfig.filter,
        searchParam: searchConfig.searchParam,
        status: searchConfig.status,
      });
      this.getAllUsers(
        searchConfig.pageNumber,
        searchConfig.pageSize,
        searchConfig.filter,
        searchConfig.searchParam,
        searchConfig.status
      );
    } else {
      this.getAllUsers(
        this.state.pageNumber,
        this.state.pageSize,
        this.state.filter,
        this.state.searchParam,
        this.state.status
      );
    }
  }

  getAllUsers = (pageNumber, pageSize, filter, searchParams, status) => {
    this.props.dispatch(
      getSCs(pageNumber, pageSize, filter, searchParams, status)
    );
  };

  onCreateNewStaff = () => {
    this.props.history.push("/admin/create-sc?staffType=4");
  };

  onEdit = (userId) => {
    this.props.history.push(`/admin/edit-sc?id=${userId}&staffType=4`);
  };

  onDetails = (userId) => {
    this.props.history.push(`/admin/view-sc?id=${userId}`);
  };

  onPageChange = (pageNumber) => {
    this.setState({
      pageNumber: pageNumber,
    });
    this.props.dispatch(
      saveSCSearchChanges(
        pageNumber,
        this.state.pageSize,
        this.state.filter,
        this.state.searchParam,
        this.state.status
      )
    );
  };

  onPageSizeChange = (pageSize) => {
    this.setState({
      pageSize: pageSize,
    });
    this.props.dispatch(
      saveSCSearchChanges(
        0,
        pageSize,
        this.state.filter,
        this.state.searchParam,
        this.state.status
      )
    );
  };

  onSearch = (event) => {
    const searchParam = event.target.value;
    if (searchParam.length >= 3 || searchParam.length === 0) {
      this.props.dispatch(
        saveSCSearchChanges(
          0,
          this.state.pageSize,
          "recent",
          searchParam,
          this.state.status
        )
      );
    }
    this.setState({
      searchParam,
      pageNumber: 0,
      filter: "recent",
    });
  };

  onPressEnter = (event) => {
    if (event.keyCode === 13) {
      this.props.dispatch(
        saveSCSearchChanges(
          0,
          this.state.pageSize,
          this.state.filter,
          this.state.searchParam,
          this.state.status
        )
      );
    }
  };

  onFilterChange = (event) => {
    const filter = event.target.value;
    this.setState({
      filter,
    });
    this.props.dispatch(
      saveSCSearchChanges(
        0,
        this.state.pageSize,
        filter,
        this.state.searchParam,
        this.state.status
      )
    );
  };

  onStatusChange = (event) => {
    const status = event.target.value;
    this.setState({
      status,
    });
    this.props.dispatch(
      saveSCSearchChanges(
        0,
        this.state.pageSize,
        this.state.filter,
        this.state.searchParam,
        status
      )
    );
  };

  render() {
    const columns = [
      {
        Header: () => <BasicHeader header={"Name"} />,
        accessor: "name",
        Cell: (row) => <BasicCell value={row.value} index={row.index} />,
      },
      {
        Header: () => <BasicHeader header={"City"} />,
        accessor: "city",
        Cell: (row) => {
          return <BasicCell value={row.value} index={row.index} />;
        },
      },
      {
        Header: () => <BasicHeader header={"Company Name"} />,
        accessor: "companyName",
        Cell: (row) => <BasicCell value={row.value} index={row.index} />,
      },
      {
        Header: () => <BasicHeader header={"Mobile"} />,
        accessor: "mobile",
        Cell: (row) => <BasicCell value={row.value} index={row.index} />,
      },
      {
        Header: "",
        accessor: "icon",
        Cell: (row) => (
          <View
            actionId={row.original.id}
            onClick={this.onDetails.bind(row.original.id)}
          />
        ),
        width: 50,
      },
      {
        Header: "",
        accessor: "icon",
        Cell: (row) => (
          <Actions
            actionId={row.original.id}
            onEdit={this.onEdit.bind(row.original.id)}
          />
        ),
        width: 50,
      },
    ];

    return (
      <div className="table-layout">
        {isMobileDevice() ? (
          <div
            style={{
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "space-between",
              alignItems: "center",
              padding: 4,
            }}
          >
            <h2 style={{ margin: 1, wordBreak: "break-word", flex: 1 }}>
              Supports Coordinator
            </h2>

            <div style={{ flex: 1 }}>Search</div>
            <div className="input-box" style={{ flex: 1, paddingRight: 20 }}>
              <input
                type="text"
                className="box text-box search-icon"
                value={this.state.searchParam}
                onChange={this.onSearch}
                onKeyDown={this.onPressEnter}
                placeholder={"Supports Coordinator Name"}
              />
            </div>

            <div style={{ flex: 1 }}>
              <button className="button submit" onClick={this.onCreateNewStaff}>
                <span className="text">CREATE NEW</span>
              </button>
            </div>
          </div>
        ) : (
          <div className="row col-12 header-container">
            <div className="row col-lg-10 ">
              <span className="col-lg-5  title">Supports Coordinator</span>
              <span className="col-lg-1  sub-title">Search</span>
              <div className="col-lg-4  input-box">
                <input
                  type="text"
                  className="box text-box search-icon"
                  value={this.state.searchParam}
                  onChange={this.onSearch}
                  onKeyDown={this.onPressEnter}
                  placeholder={"Supports Coordinator Name"}
                />
                {/*<FeatherIcon icon="search"/>*/}
              </div>
            </div>
            <div className="col-lg-2 ">
              <button className="button submit" onClick={this.onCreateNewStaff}>
                <span className="text">CREATE NEW</span>
              </button>
            </div>
          </div>
        )}
        <div className="body-container">
          <div className="row list-container">
            <div className="col-lg-4 col-md-4 col-sm-6">
              <span className="list-header">Supports Coordinators</span>
            </div>
            <div className="col-lg-3 offset-lg-2 col-md-4 col-sm-6">
              <select
                className="select-box list-dropdown"
                onChange={this.onStatusChange}
                value={this.state.status}
              >
                <option value="0">All SC</option>
                <option value="4">Active SC</option>
                <option value="5">Inactive SC</option>
              </select>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <select
                className="select-box list-dropdown"
                onChange={this.onFilterChange}
                value={this.state.filter}
              >
                <option value="all">All Supports Coordinators</option>
                <option value="recent">Recently Added</option>
                <option value="name_asc">Name A &#8594; Z</option>
                <option value="name_desc">Name Z &#8594; A</option>
                <option value="city_asc">Billing City A &#8594; Z</option>
                <option value="city_desc">Billing City Z &#8594; A</option>
              </select>
            </div>
          </div>

          <div className="data-grid-container">
            {!isMobileDevice() && (
              <div className="row">
                <div className="col-12">
                  <DataGrid
                    data={this.state.users}
                    columns={columns}
                    onRowClick={(rowInfo) => {
                      this.setState({
                        seletedUser: get(rowInfo.original, "id", ""),
                      });
                    }}
                    onPageSizeChange={this.onPageSizeChange}
                    onPageNumberChange={this.onPageChange}
                    total={this.state.totalUsers}
                    paginationLabel={"Supports Coordinators per page"}
                    showPagination={true}
                    pageSize={this.state.pageSize}
                    pageNumber={this.state.pageNumber}
                  />
                </div>
              </div>
            )}

            {isMobileDevice() && (
              <div className="row" style={{ margin: 10 }}>
                {map(this.state.users, (user) => {
                  return (
                    <SupportCard
                      user={user}
                      onDetails={(id) => {
                        this.onDetails(id);
                      }}
                    />
                  );
                })}
                <div className="row pagination pagination-align">
                  <div className="col-12">
                    <Pagination
                      onPageSizeChange={this.onPageSizeChange}
                      label={"Supports Coordinators per page"}
                      onPageNumberChange={this.onPageChange}
                      total={this.state.totalUsers}
                      pageSize={this.state.pageSize}
                      pageNumber={this.state.pageNumber}
                    />
                  </div>
                </div>
              </div>
            )}

            {isMobileDevice() && this.state.users.length === 0 && (
              <div className="row">
                <div className="col-12 text-center">
                  <span>No Supports Coordinators</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.user.getSCSuccess,
  usersError: state.user.getSCFail,
  searchConfig: state.config.scSearch,
});

export default withRouter(connect(mapStateToProps)(SupportCoordinator));
