import * as React from "react";
import "./services.scss";
import DataGrid from "../shared/data-grid/data-grid";
import BasicCell from "../shared/data-grid/custom-cells/basic-cell";
import BasicHeader from "../shared/data-grid/custom-headers/basic-header";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { map } from "lodash";
import { isMobileDevice } from "../../util/deviceType";
import Pagination from "../shared/data-grid/pagination/pagination";
import { saveServicesSearchChanges } from "../../redux/config/configAction";
import ServiceCard from "./serviceCard";
import { createService, getAllServices, getServiceById, updateService } from "../../api/service";
import PopUpModal from "../shared/popup-modal/popUpModal";
import ServiceCreateUpdate from "./service-create/create";
import Actions from "../shared/data-grid/custom-cells/actions";

class Services extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      services: [],
      totalServices: 0,
      pageNumber: 0,
      searchParam: "",
      filter: "recent",
      pageSize: 10,
      status: 0,
      openModal: false,
      selectedServiceId: 0,
      isEdit: false,
      initialValues: null,
      isDegreeRequired: false,
      isOverlappable: false
    };
  }

  componentDidMount() {
    const { searchConfig } = this.props;
    if (searchConfig) {
      this.setState({
        pageNumber: searchConfig.pageNumber,
        pageSize: searchConfig.pageSize,
        filter: searchConfig.filter,
        searchParam: searchConfig.searchParam,
        status: searchConfig.status
      });
      this.getAllServices(
        searchConfig.pageNumber,
        searchConfig.pageSize,
        searchConfig.filter,
        searchConfig.searchParam,
        searchConfig.status
      );
    } else {
      this.getAllServices(
        this.state.pageNumber,
        this.state.pageSize,
        this.state.filter,
        this.state.searchParam,
        this.state.status
      );
    }
  }

  getAllServices = (pageNumber, pageSize, filter, searchParams, status) => {
    getAllServices(pageNumber, pageSize, filter, searchParams, status).then((res) => {
      // console.log(res.data)
      this.setState({
        services: res.data ? res.data : []
      });
    });
  };

  onCreateNewService = () => {
    this.setState({
      openModal: true,
      isEdit: false
    });
  };

  onImportServices = () => {
    window.open("/admin/import-services", "_blank");
  };

  onEdit = (serviceId) => {
    getServiceById(serviceId).then((res) => {
      const service = {
        serviceName: res.name,
        serviceCode: res.code,
        serviceDescription: res.description,
        serviceUnitRate: res.unitRate,
        serviceUnitLength: res.unitLength,
        degreeRequired: res.isDegreeRequired,
        isOverlappable: res.isOverlappable
      };
      this.setState({
        selectedServiceId: serviceId,
        openModal: true,
        isEdit: true,
        initialValues: service,
        degreeRequired: res.isDegreeRequired,
        isOverlappable: res.isOverlappable
      });
    });
  };

  handleServiceAddUpdate = (service) => {
    const req = {
      serviceId: this.state.selectedServiceId,
      name: service.serviceName,
      code: service.serviceCode,
      description: service.serviceDescription,
      unitRate: parseFloat(service.serviceUnitRate),
      unitLength: parseFloat(service.serviceUnitLength),
      isDegreeRequired: this.state.isDegreeRequired,
      isOverlappable: this.state.isOverlappable
    };


    if (this.state.isEdit) {
      updateService(req).then((res) => {
        this.setState({
          openModal: false,
          isEdit: false
        });
        this.getAllServices(
          this.state.pageNumber,
          this.state.pageSize,
          this.state.filter,
          this.state.searchParam,
          this.state.status
        );
      });
    } else {
      createService(req).then((res) => {
        this.setState({
          openModal: false,
          isEdit: false
        });
        this.getAllServices(
          this.state.pageNumber,
          this.state.pageSize,
          this.state.filter,
          this.state.searchParam,
          this.state.status
        );
      });
    }
  };

  onPageChange = (pageNumber) => {
    this.props.dispatch(
      saveServicesSearchChanges(
        pageNumber,
        this.state.pageSize,
        this.state.filter,
        this.state.searchParam,
        this.state.status
      )
    );
  };

  onPageSizeChange = (pageSize) => {
    this.props.dispatch(
      saveServicesSearchChanges(
        0,
        pageSize,
        this.state.filter,
        this.state.searchParam,
        this.state.status
      )
    );
  };

  onSearch = (event) => {
    const searchParam = event.target.value;
    if (searchParam.length >= 3 || searchParam.length === 0) {
      this.props.dispatch(
        saveServicesSearchChanges(
          0,
          this.state.pageSize,
          "recent",
          searchParam,
          this.state.status
        )
      );
    }
    this.setState({
      searchParam,
      pageNumber: 0,
      filter: "recent"
    });
  };

  onPressEnter = (event) => {
    if (event.keyCode === 13) {
      this.props.dispatch(
        saveServicesSearchChanges(
          0,
          this.state.pageSize,
          this.state.filter,
          this.state.searchParam,
          this.state.status
        )
      );
    }
  };

  onFilterChange = (event) => {
    const filter = event.target.value;
    this.setState({
      filter
    });
    this.props.dispatch(
      saveServicesSearchChanges(
        0,
        this.state.pageSize,
        filter,
        this.state.searchParam,
        this.state.status
      )
    );
  };

  onStatusChange = (event) => {
    const status = event.target.value;
    this.setState({
      status
    });
    this.props.dispatch(
      saveServicesSearchChanges(
        0,
        this.state.pageSize,
        this.state.filter,
        this.state.searchParam,
        status
      )
    );
  };

  render() {
    const columns = [
      {
        Header: () => <BasicHeader header={"Service Code"} />,
        accessor: "code",
        width: 150,
        Cell: (row) => {
          return <BasicCell value={row.value} index={row.index} />;
        }
      },
      {
        Header: () => <BasicHeader header={"Service Name"} />,
        accessor: "name",
        Cell: (row) => {
          return <BasicCell value={row.value} index={row.index} />;
        }
      },
      {
        Header: () => <BasicHeader header={"Unit Length"} />,
        accessor: "unitLength",
        width: 150,
        Cell: (row) => <BasicCell value={row.value} index={row.index} />
      },
      {
        Header: () => <BasicHeader header={"Unit Rate"} />,
        accessor: "unitRate",
        width: 150,
        Cell: (row) => <BasicCell value={row.value} index={row.index} />
      },
      {
        Header: "",
        accessor: "icon",
        Cell: (row) => (
          <Actions
            actionId={row.original.serviceId}
            onEdit={this.onEdit.bind(row.original.serviceId)}
          />
        ),
        width: 50
      }
    ];

    return (
      <div className="table-layout">
        <div className="row col-12 header-container">
          <div className="row col-lg-8 col-sm-12">
            <span
              className="col-lg-2 col-sm-12 title"
              style={{ cursor: "default" }}
            >
              Services
            </span>
            <span className="col-lg-1 col-sm-2 sub-title">Search</span>
            <div className="col-lg-4 col-sm-10 input-box">
              <input
                type="text"
                className="box text-box search-icon"
                value={this.state.searchParam}
                onChange={this.onSearch}
                onKeyDown={this.onPressEnter}
                placeholder={"Service Name"}
              />
            </div>
          </div>
          <div className="col-lg-2 col-sm-6">
            <button className="button basic" onClick={this.onImportServices}>
              <span className="text">IMPORT SERVICES</span>
            </button>
          </div>
          <div className="col-lg-2 col-sm-6">
            <button className="button submit" onClick={this.onCreateNewService}>
              <span className="text">CREATE NEW</span>
            </button>
          </div>
        </div>

        <div className="body-container">
          <div className="row list-container">
            <div className="col-lg-3 col-md-4 col-sm-6">
              <span className="list-header">Services</span>
            </div>
            <div className="col-lg-3 offset-lg-3 col-md-4 col-12">
              <select
                className="select-box list-dropdown"
                onChange={this.onStatusChange}
                value={this.state.status}
              >
                <option value="0">All Services</option>
                <option value="4">Active Services</option>
                <option value="5">Inactive Services</option>
              </select>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <select
                className="select-box list-dropdown"
                onChange={this.onFilterChange}
                value={this.state.filter}
              >
                <option value="all">All Services</option>
                <option value="recent">Recently Added</option>
              </select>
            </div>
          </div>

          <div className="data-grid-container">
            {!isMobileDevice() && (
              <div className="row">
                <div className="col-12">
                  <DataGrid
                    data={this.state.services}
                    columns={columns}
                    onRowClick={(rowInfo) => {
                      // this.onDetails(get(rowInfo.original, "id", ""));
                    }}
                    onPageSizeChange={this.onPageSizeChange}
                    onPageNumberChange={this.onPageChange}
                    total={this.state.totalServices}
                    paginationLabel={"Clients per page"}
                    showPagination={true}
                    pageSize={this.state.pageSize}
                    pageNumber={this.state.pageNumber}
                  />
                </div>
              </div>
            )}

            {isMobileDevice() && (
              <div className="row" style={{ margin: 10 }}>
                {map(this.state.clients, (client) => {
                  return (
                    <ServiceCard
                      client={client}
                      onDetails={(id) => {
                        this.onDetails(id);
                      }}
                    />
                  );
                })}
                <div className="row pagination pagination-align">
                  <div className="col-12">
                    <Pagination
                      onPageSizeChange={this.onPageSizeChange}
                      label={"Clients per page"}
                      onPageNumberChange={this.onPageChange}
                      total={this.state.totalClients}
                      pageSize={this.state.pageSize}
                      pageNumber={this.state.pageNumber}
                    />
                  </div>
                </div>
              </div>
            )}

            {isMobileDevice() && this.state.clients.length === 0 && (
              <div className="row">
                <div className="col-12 text-center">
                  <span>No Clients</span>
                </div>
              </div>
            )}


          </div>
        </div>

        {this.state.openModal && (
          <PopUpModal
            show={this.state.openModal}
            onClose={() => {
              this.setState({ openModal: false });
            }}
            title={this.state.isEdit ? "Edit Service" : "Create Service"}
            icon={"/images/clipboard.png"}
            size={"lg"}
          >
            <ServiceCreateUpdate
              onSubmit={this.handleServiceAddUpdate}
              initialValues={this.state.initialValues}
              isEdit={this.state.isEdit}
              onCloseModal={() => {
                this.setState({ openModal: false });
              }}
              isOverlappable={this.state.isOverlappable}
              onOverlapable={(val) => {
                this.setState({
                  isOverlappable: val
                });
              }}
              isDegreeRequired={this.state.isDegreeRequired}
              onDegreeRequired={(value) => {
                this.setState({ isDegreeRequired: value });
              }}

            />
          </PopUpModal>
        )}


      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  clients: state.client.getAllSuccess,
  clientsError: state.client.getAllFail,
  searchConfig: state.config.clientSearch
});

export default withRouter(connect(mapStateToProps)(Services));
