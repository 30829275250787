import React from "react";
import BasicHeader from "../shared/data-grid/custom-headers/basic-header";
import BasicCell from "../shared/data-grid/custom-cells/basic-cell";
import DataGrid from "../shared/data-grid/data-grid";
import { isEmpty, isEqual, isNull, map, startCase } from "lodash";
import {
  getAllWorkEntries,
  setVisitDetails,
  setVisitStatus,
} from "../../redux/workEntries/workEntryAction";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import StatusCell from "../shared/data-grid/custom-cells/status-cell";
import "./visits.scss";
import { isMobileDevice } from "../../util/deviceType";
import VisitCard from "./visitCard";
import {
  getActiveClientsList,
  getAssignedClientsList,
  getClientById,
} from "../../api/client";
import ClockInOut from "../shared/data-grid/custom-cells/clockInOut";
import { ALERT_TYPES, VISIT_STATUS } from "../../constants/appConstants";
import { getInProgressVisits, updateVisitNote } from "../../api/visits";
import Pagination from "../shared/data-grid/pagination/pagination";
import UpdateVisit from "./update-visit/update-visit";
import { getWorkEntryById } from "../../api/workEntry";
import LinkCell from "../shared/data-grid/custom-cells/link-cell";
import { createNotification } from "../../util/notificationManager";
import SearchableDropdown from "../shared/searchable-dropdown";

class Visits extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      workEntries: [],
      selectedWorkEntryId: 0,
      pageNumber: 0,
      searchParam: "",
      filter: "recent",
      pageSize: 10,
      totalWorkEntries: 0,
      clients: [],
      selectedClient: null,
      visitInProgress: false,
      selectedVisit: {},
      openModal: false,
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const { workEntries } = this.props;
    const workEntryData = [];
    if (
      !isEqual(nextProps.workEntries, workEntries) &&
      !isNull(nextProps.workEntries)
    ) {
      map(nextProps.workEntries.data, (we) => {
        workEntryData.push({
          workEntry: we,
          id: we.entryId,
          clientName: startCase(we.clientName),
          service: startCase(we.serviceName),
          clockInDate: moment(we.startTime).format("MM-DD-YYYY"),
          clockIn: moment(we.startTime).format("hh:mm A"),
          clockOutDate:
            we.status === VISIT_STATUS.IN_PROGRESS
              ? "-"
              : moment(we.endTime).format("MM-DD-YYYY"),
          clockOut: moment(we.endTime).format("hh:mm A"),
          notes: we.notes,
          status: we.status,
        });
      });
      this.setState({
        workEntries: workEntryData,
        totalWorkEntries: nextProps.workEntries.recordCount,
      });
    }
  }

  componentDidMount() {
    this.getAllWorkEntries(
      this.state.pageNumber,
      this.state.pageSize,
      this.state.filter,
      this.state.searchParam
    );

    getAssignedClientsList().then((res) => {
      this.setState({
        clients: res,
      });
    });

    getInProgressVisits().then((res) => {
      if (!isEmpty(res.data)) {
        this.setState({
          visitInProgress: true,
        });
        this.props.dispatch(
          setVisitStatus(VISIT_STATUS.IN_PROGRESS, res.data.serviceStartTime)
        );
        this.props.dispatch(setVisitDetails(res.data));
      }
    });
  }

  getAllWorkEntries = (pageNumber, pageSize, filter, searchParams) => {
    this.props.dispatch(
      getAllWorkEntries(pageNumber, pageSize, filter, searchParams)
    );
  };

  onCreateNewWorkEntry = () => {
    const selectedClient = this.state.selectedClient;
    this.props.history.push("/staff/start-visit", {
      selectedClient: selectedClient,
    });
  };

  onPageChange = (pageNumber) => {
    this.setState({
      pageNumber: pageNumber,
    });
    this.getAllWorkEntries(
      pageNumber,
      this.state.pageSize,
      this.state.filter,
      this.state.searchParam
    );
  };

  onPageSizeChange = (pageSize) => {
    this.setState({
      pageNumber: 0,
      pageSize: pageSize,
    });
    this.getAllWorkEntries(
      0,
      pageSize,
      this.state.filter,
      this.state.searchParam
    );
  };

  onSearch = (event) => {
    const searchParam = event.target.value;
    if (searchParam.length >= 3 || searchParam.length == 0) {
      this.getAllWorkEntries(
        0,
        this.state.pageSize,
        this.state.filter,
        searchParam
      );
    }
    this.setState({
      pageNumber: 0,
      searchParam: searchParam,
    });
  };

  onFilterChange = (event) => {
    const filter = event.target.value;
    this.setState({
      pageNumber: 0,
      filter: filter,
    });
    this.getAllWorkEntries(
      0,
      this.state.pageSize,
      filter,
      this.state.searchParam
    );
  };

  onDetails = (workEntryId) => {
    this.props.history.push(`/staff/view-timeSheet?id=${workEntryId}`);
  };

  onEdit = (workEntryId) => {
    getWorkEntryById(workEntryId).then((res) => {
      getClientById(res.clientId).then((client) => {
        this.setState({
          selectedWorkEntryId: workEntryId,
          selectedVisit: res,
          selectedClient: client,
          openModal: true,
        });
      });
    });
  };

  updateVisit = (workEntry, notes, progress, signature) => {
    workEntry.notes = notes;
    workEntry.progress = progress;
    workEntry.signature = signature;
    updateVisitNote(workEntry)
      .then((res) => {
        this.setState({
          openModal: false,
        });
        this.getAllWorkEntries(
          this.state.pageNumber,
          this.state.pageSize,
          this.state.filter,
          this.state.searchParam
        );
      })
      .catch((err) => {
        createNotification(ALERT_TYPES.ERROR, err.message);
      });
  };

  onClientChange = (value) => {
    this.setState({
      selectedClient: parseInt(value),
    });
  };

  render() {
    const columns = [
      {
        Header: () => <BasicHeader header={"Client Name"} />,
        accessor: "clientName",
        Cell: (row) => <BasicCell value={row.value} index={row.index} />,
      },
      {
        Header: () => <BasicHeader header={"Service Name"} />,
        accessor: "service",
        Cell: (row) => {
          return <BasicCell value={row.value} index={row.index} />;
        },
      },
      {
        Header: () => <BasicHeader header={"Clock In Date"} />,
        accessor: "clockInDate",
        Cell: (row) => <BasicCell value={row.value} index={row.index} />,
        width: 125,
      },
      {
        Header: () => <BasicHeader header={"Clock In Time"} />,
        accessor: "clockIn",
        Cell: (row) => <BasicCell value={row.value} index={row.index} />,
        width: 125,
      },
      {
        Header: () => <BasicHeader header={"Clock Out Date"} />,
        accessor: "clockOutDate",
        Cell: (row) => <BasicCell value={row.value} index={row.index} />,
        width: 125,
      },
      {
        Header: () => <BasicHeader header={"Clock Out Time"} />,
        accessor: "clockOut",
        Cell: (row) => <ClockInOut value={row} index={row.index} />,
        width: 125,
      },
      {
        Header: () => <BasicHeader header={"Notes"} />,
        accessor: "notes",
        Cell: (row) => (
          <LinkCell
            value={row.value}
            index={row.index}
            actionId={row.original.id}
            onEdit={this.onEdit.bind(row.original.id)}
          />
        ),
      },
      {
        Header: () => <BasicHeader header={"Status"} />,
        accessor: "status",
        Cell: (row) => <StatusCell status={row.value} index={row.index} />,
        width: 150,
      },
      // {
      //     Header: "",
      //     accessor: "icon",
      //     Cell: (row) => (
      //         <Actions
      //             actionId={row.original.id}
      //             onEdit={this.onEdit.bind(row.original.id)}
      //         />
      //     ),
      //     width: 50,
      // },
    ];
    return (
      <div className="table-layout">
        {this.state.visitInProgress && (
          <div
            className="row in-progress-banner"
            style={{ marginBottom: 10 }}
            onClick={() => {
              this.props.history.push("/staff/visit-progress");
            }}
          >
            <div className="col-12 text-left">
              <img src="./images/clock-white.svg" alt="" />
              <span style={{ marginLeft: 10 }}>
                You have a visit in progress
              </span>
              <img
                style={{ marginLeft: 10 }}
                src="./images/chevron-right-white.svg"
                alt=""
              />
            </div>
            {/*<div className="col-lg-2 col-md-3 col-sm-3 offset-lg-7 offset-md-5 offset-sm-4 text-right">*/}
            {/*    /!*<span className="duration">03:046:11</span>*!/*/}
            {/*    <img src="./images/chevron-right-white.svg" alt=""/>*/}
            {/*</div>*/}
          </div>
        )}
        <div className="row col-lg-12 col-sm-12 header-container">
          <div className="row col-lg-8 col-sm-12">
            <span className="col-lg-2 col-sm-12 title">Visits</span>
            <span className="col-lg-1 col-sm-12 sub-title mobile-no-padding">
              Search
            </span>
            <div className="col-lg-4 col-sm-12 input-box  mobile-no-padding">
              <input
                type="text"
                className="box text-box search-icon"
                value={this.state.searchParam}
                onChange={this.onSearch}
                placeholder={"Search Visits"}
              />
              {/*<FeatherIcon icon="search"/>*/}
            </div>
          </div>
          {!this.state.visitInProgress && (
            <div className="col-lg-2 col-md-6 col-sm-6 header-component">
              <SearchableDropdown
                options={this.state.clients}
                value={0}
                defaultValue={0}
                keyIdentifier="clientId"
                valueIdentifier="name"
                placeholder="Search Client"
                onChange={this.onClientChange}
              />
            </div>
          )}
          {!this.state.visitInProgress && (
            <div className="col-lg-2 col-md-6 col-sm-6 header-component">
              <button
                className="button submit"
                onClick={this.onCreateNewWorkEntry}
                disabled={this.state.selectedClient === null}
                style={
                  this.state.selectedClient === null
                    ? { backgroundColor: "#B7B6B6" }
                    : null
                }
              >
                <span className="text">CREATE NEW</span>
              </button>
            </div>
          )}
        </div>

        <div className="body-container">
          <div className="row list-container">
            <div className="col-lg-3 col-md-4 col-sm-6">
              <span className="list-header">Visits</span>
            </div>
            <div className="col-lg-3 offset-lg-6 col-md-4 offset-md-4 col-sm-6 offset-sm-0 list-header-component">
              <select
                className="select-box list-dropdown"
                onChange={this.onFilterChange}
                value={this.state.filter}
              >
                <option value="all">All Visits</option>
                <option value="recent">Recently Added</option>
              </select>
            </div>
          </div>

          <div className="data-grid-container">
            {!isMobileDevice() && (
              <div className="row">
                <div className="col-12">
                  <DataGrid
                    data={this.state.workEntries}
                    columns={columns}
                    onRowClick={(rowInfo) => {
                      // this.viewOrganization(get(rowInfo.original, 'id', ''))
                    }}
                    onPageSizeChange={this.onPageSizeChange}
                    onPageNumberChange={this.onPageChange}
                    total={this.state.totalWorkEntries}
                    paginationLabel={"Visits per page"}
                    showPagination={true}
                    pageNumber={this.state.pageNumber}
                    pageSize={this.state.pageSize}
                  />
                </div>
              </div>
            )}

            {isMobileDevice() && this.state.workEntries.length !== 0 && (
              <div className="row">
                {map(this.state.workEntries, (visit) => {
                  return <VisitCard visit={visit} onEdit={this.onEdit} />;
                })}
                <div className="row pagination pagination-align">
                  <div className="col-12">
                    <Pagination
                      onPageSizeChange={this.onPageSizeChange}
                      label={"Visits per page"}
                      onPageNumberChange={this.onPageChange}
                      total={this.state.totalWorkEntries}
                      pageSize={this.state.pageSize}
                      pageNumber={this.state.pageNumber}
                    />
                  </div>
                </div>
              </div>
            )}

            {isMobileDevice() && this.state.workEntries.length === 0 && (
              <div className="row">
                <div className="col-12 text-center">
                  <span>No Visits</span>
                </div>
              </div>
            )}
          </div>
        </div>

        {this.state.openModal && (
          <UpdateVisit
            show={this.state.openModal}
            onClose={() => {
              this.setState({ openModal: false });
            }}
            title={"Edit Visit Notes"}
            icon={"/images/lock.svg"}
            size={"xl"}
            visit={this.state.selectedVisit}
            client={this.state.selectedClient}
            handleSubmit={this.updateVisit}
            onCloseModal={() => {
              this.setState({ openModal: false });
            }}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  workEntries: state.workEntry.getAllSuccess,
  workEntryError: state.workEntry.getAllFail,
  visitStatus: state.workEntry.visitStatus,
});

export default withRouter(connect(mapStateToProps)(Visits));
