import React from "react";
import { get } from "lodash";
import moment from "moment";
import { formatDateTime } from "../../../util/util";
import * as classNames from "classnames";
import * as ClassNames from "classnames";
import { VISIT_STATUS } from "../../../constants/appConstants";
import SignaturePad from "../../shared/signature-pad";
import isEmpty from "lodash/isEmpty";

class UpdateVisit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notes: "",
      visit: {},
      isEditMode: false,
      signature: "",
      progress: 0,
    };
  }

  componentDidMount() {
    const { visit } = this.props;
    this.setState({
      visit: visit,
      notes: visit.notes == null ? "" : visit.notes,
      progress: visit.progress,
      signature: visit.signature,
    });
  }

  render() {
    let visit = this.props.visit;
    let client = this.props.client;
    let lastEdit = moment(new Date()).diff(
      moment(new Date(visit.updated_on)),
      "days"
    );
    return (
      <div>
        <div
          className={classNames("modal fade", { show: this.props.show })}
          id={this.props.id ? this.props.id : "exampleModal"}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className={"modal-dialog modal-xl"} role="document">
            <div className="modal-content">
              <div className="row col-12 modal-header">
                <div className="col-lg-8 col-sm-12 offset-lg-2 offset-sm-0  text-center">
                  <h5
                    className="col-12 modal-title text-center"
                    id="exampleModalLabel"
                  >
                    <span className="my-auto">
                      <img
                        src={this.props.icon}
                        alt=""
                        style={{ marginRight: 10 }}
                      />
                    </span>
                    <span style={{ marginLeft: "5px" }}>
                      {this.props.title}
                    </span>
                  </h5>
                  <span className={"col-12 text-center last-edit"}>
                    Last Edit {lastEdit} Days Ago
                  </span>
                </div>
                <div className="col-lg-2 col-sm-12">
                  <div className="status-cell text-center">
                    {visit.status === VISIT_STATUS.ABANDONED && (
                      <div className={ClassNames("abandoned-status")}>
                        <img src="./images/abandoned.svg" alt="" />
                        &nbsp;
                        <span>Abandoned</span>
                      </div>
                    )}
                    {visit.status === VISIT_STATUS.COMPLETED && (
                      <div className={ClassNames("completed-status")}>
                        <img src="./images/completed-circle.svg" alt="" />
                        &nbsp;
                        <span>Completed</span>
                      </div>
                    )}
                    {visit.status === VISIT_STATUS.IN_PROGRESS && (
                      <div className={ClassNames("progress-status")}>
                        <img src="./images/inprogress.svg" alt="" />
                        &nbsp;
                        <span>In Progress</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-lg-12 col-sm-12 visit-notes-details">
                    <div className="row">
                      <div className="col-lg-3 col-sm-6 text-center">
                        <img
                          src="./images/user.svg"
                          alt=""
                          style={{ marginRight: 2 }}
                        />
                        <span className="label">Client Name</span>
                        <div className="value">
                          {client.lastName +
                            " " +
                            client.firstName +
                            " " +
                            client.middleName}
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-6 text-center">
                        <img
                          src="./images/map-pin.svg"
                          alt=""
                          style={{ marginRight: 2 }}
                        />
                        <span className="label">Address</span>
                        <div
                          className="value"
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >{`${get(client, "billingStreet", "")} ${get(
                          client,
                          "billingCity",
                          ""
                        )}, ${get(client, "billingState", "")} ${get(
                          client,
                          "billingCountry",
                          ""
                        )}`}</div>
                      </div>
                      <div className="col-lg-3 col-sm-6 text-center">
                        <img
                          src="./images/calendar-green.svg"
                          alt=""
                          style={{ marginRight: 2 }}
                        />
                        <span className="label">Clock In Time</span>
                        <div className="value">
                          {formatDateTime(
                            get(visit, "serviceStartTime", null),
                            "MMMM Do YYYY, h:mm a"
                          )}
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-6 text-center">
                        <img
                          src="./images/calendar-green.svg"
                          alt=""
                          style={{ marginRight: 2 }}
                        />
                        <span className="label">Clock Out Time</span>

                        {visit.status !== VISIT_STATUS.IN_PROGRESS && (
                          <div className="value">
                            {formatDateTime(
                              get(visit, "serviceEndTime", null),
                              "MMMM Do YYYY, h:mm a"
                            )}
                          </div>
                        )}
                        {visit.status === VISIT_STATUS.IN_PROGRESS && (
                          <div className="value">{"-"}</div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-12 col-sm-12 notes-content row">
                    <span className="col-lg-12 col-md-12 col-sm-12 label text-left">
                      Progress
                    </span>
                    {!this.state.isEditMode && (
                      <div
                        className="row col-lg-12 col-md-12 col-sm-12"
                        style={{ padding: 30, backgroundColor: "#FAFBFC" }}
                      >
                        <div className="col-lg-4 col-md-4 col-sm-12 text-left progress-selection">
                          <input
                            type="radio"
                            name="progress"
                            value="increased"
                            checked={this.state.progress === 1}
                            disabled={true}
                            // onChange={(event) => {
                            //     this.setState({progress: 1})
                            // }}
                          />
                          <label className={"radio-text"}>Increased</label>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 text-left progress-selection">
                          <input
                            type="radio"
                            name="progress"
                            value="maintained"
                            checked={this.state.progress === 2}
                            disabled={true}
                            // onChange={(event) => {
                            //     this.setState({progress: 2})
                            // }}
                          />
                          <label className={"radio-text"}>Maintained</label>
                        </div>

                        <div className="col-lg-4 col-md-4 col-sm-12 text-left progress-selection">
                          <input
                            type="radio"
                            name="progress"
                            value="decreased"
                            checked={this.state.progress === 3}
                            disabled={true}
                            // onChange={(event) => {
                            //     this.setState({progress: 3})
                            // }}
                          />
                          <label className={"radio-text"}>Decreased</label>
                        </div>
                      </div>
                    )}
                    {this.state.isEditMode && (
                      <div className="row col-lg-12 col-md-12 col-sm-12">
                        <div className="col-lg-4 col-md-4 col-sm-12 text-left">
                          <input
                            type="radio"
                            name="progress"
                            value="increased"
                            checked={this.state.progress == 1}
                            onChange={(event) => {
                              this.setState({ progress: 1 });
                            }}
                          />{" "}
                          Increased
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 text-left">
                          <input
                            type="radio"
                            name="progress"
                            value="maintained"
                            checked={this.state.progress == 2}
                            onChange={(event) => {
                              this.setState({ progress: 2 });
                            }}
                          />{" "}
                          Maintained
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 text-left">
                          <input
                            type="radio"
                            name="progress"
                            value="decreased"
                            checked={this.state.progress == 3}
                            onChange={(event) => {
                              this.setState({ progress: 3 });
                            }}
                          />{" "}
                          Decreased
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12 notes-content row">
                    <span className="col-12 label text-left">Notes</span>
                    {this.state.notes && (
                      <span
                        className={
                          this.state.isEditMode ? "hidden" : "notes-text"
                        }
                      >
                        {this.state.notes}
                      </span>
                    )}
                    {!this.state.notes && (
                      <span
                        className={
                          this.state.isEditMode
                            ? "hidden"
                            : "notes-text-disabled"
                        }
                      >
                        {"Add a notes.."}
                      </span>
                    )}

                    <textarea
                      className={this.state.isEditMode ? "notes" : "hidden"}
                      placeholder={"Add note"}
                      value={this.state.notes}
                      onChange={(e) => {
                        this.setState({ notes: e.target.value });
                      }}
                    ></textarea>
                  </div>

                  {!this.state.isEditMode && visit.signature && (
                    <div className="col-12 notes-content row">
                      <span className="col-12 label text-left">Signature</span>
                      <img
                        className={{}}
                        src={visit.signature}
                        alt="Signature"
                      />
                    </div>
                  )}

                  <div
                    className="col-12 notes-content row"
                    style={this.state.isEditMode ? {} : { display: "none" }}
                  >
                    <span className="col-12 label text-left">Signature</span>
                    <div className="row">
                      <SignaturePad
                        showSignaturePad={false}
                        signature={this.state.signature}
                        onChange={(signature) => {
                          this.setState({ signature });
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-lg-6 offset-lg-3 col-sm-12 offset-sm-0">
                    <div
                      className="row"
                      style={{ marginBottom: 20, marginTop: 20 }}
                    >
                      <div className="col-6">
                        <button
                          className="button basic"
                          onClick={(event) => {
                            if (!this.state.isEditMode) {
                              this.props.onCloseModal();
                            } else {
                              this.setState({
                                isEditMode: false,
                              });
                            }
                          }}
                        >
                          CANCEL
                        </button>
                      </div>
                      <div className={"col-6"}>
                        <button
                          className="button submit"
                          disabled={isEmpty(this.state.signature) && this.state.isEditMode}
                          style={
                            !this.state.isEditMode &&
                            this.state.progress > 0 ||
                            this.state.signature != ""
                              ? null
                              : { backgroundColor: "#B7B6B6" }
                          }
                          onClick={() => {
                            if (this.state.isEditMode) {
                              this.props.handleSubmit(
                                this.state.visit,
                                this.state.notes,
                                this.state.progress,
                                this.state.signature
                              );
                            } else {
                              this.setState({
                                isEditMode: true,
                              });
                            }
                          }}
                        >
                          <span className="text">
                            {this.state.isEditMode ? "SAVE CHANGES" : "EDIT"}
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UpdateVisit;
