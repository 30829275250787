import React from "react";
import Details from "./details";
import { getWorkEntryById } from "../../../../api/workEntry";
import { getQueryParameter } from "../../../../util/url";
import { get, isEmpty, isEqual, isNull, isUndefined } from "lodash";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getAllUsersList } from "../../../../api/user";
import { getAllClientsList, getClientById } from "../../../../api/client";
import { getClientServiceByClientId } from "../../../../api/clientService";
import {
  createWorkEntry,
  updateWorkEntry,
} from "../../../../redux/workEntries/workEntryAction";
import { ALERT_TYPES } from "../../../../constants/appConstants";
import { NotificationManager } from "react-notifications";
import {getTimeZone} from '../../../../util/util';

class CreateTimeSheetForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: null,
      isEditMode: false,
      selectedWorkEntryId: 0,
      users: [],
      clients: [],
      services: [],
      userId: 0,
      clientId: 0,
      serviceId: 0,
      startDate: "",
      startTime: "",
      endDate: "",
      endTime: "",
      thirdPartyVerification: false,
      staffVerification: false,
      adminVerification: false,
      progress: 0,
      signature: "",
      goalsAndOutcomes: "",
      // startLongitude: 0.0,
      // startLatitude: 0.0,
      // endLongitude: 0.0,
      // endLatitude: 0.0,
    };
  }

  componentDidMount() {
    const workEntryId = getQueryParameter("id");
    if (!isEmpty(workEntryId) && !isUndefined(workEntryId)) {
      getWorkEntryById(workEntryId).then((res) => {
        const we = this.mapWorkEntry(res);
        this.loadClientServices(res.clientId);
        this.loadClient(res.clientId);
        this.setState({
          initialValues: we,
          isEditMode: true,
          selectedWorkEntryId: workEntryId,
          userId: res.userId,
          clientId: res.clientId,
          serviceId: res.serviceRequestId,
          thirdPartyVerification: res.thirdPartyVerification,
          staffVerification: res.staffVerification,
          progress: res.progress,
          signature: res.signature,
          adminVerification: res.adminVerification,
          // startLongitude: res.startLongitude,
          // startLatitude: res.startLatitude,
          // endLongitude: res.endLongitude,
          // endLatitude: res.endLatitude,
        });
      });
    }
    getAllUsersList().then((res) => {
      this.setState({
        users: res,
      });
    });
    getAllClientsList().then((res) => {
      this.setState({
        clients: res,
      });
    });
  }

  onUserChange = (event) => {
    const type = event.target.value;
    this.setState({
      userId: type,
    });
  };

  onClientChange = (event) => {
    const type = event.target.value;
    this.loadClientServices(type);
    this.loadClient(type);
  };

  loadClientServices(clientId) {
    getClientServiceByClientId(clientId).then((res) => {
      this.setState({
        clientId: clientId,
        services: res,
      });
    });
  }

  loadClient(clientId) {
    getClientById(clientId).then((res) => {
      this.setState({
        goalsAndOutcomes: res.goalsOutcomes,
        clientId: clientId,
        services: res.clientServices,
      });
    });
  }

  onServiceChange = (event) => {
    const type = event.target.value;
    this.setState({
      serviceId: type,
    });
  };

  mapWorkEntry = (data) => {
    let we = {
      notes: get(data, "notes", ""),
      userId: get(data, "userId", 0).toString(),
      clientId: get(data, "clientId", 0).toString(),
      serviceId: get(data, "serviceRequestId", 0).toString(),
      serviceStartLongitude: get(data, "serviceStartLongitude", ""),
      serviceStartLatitude: get(data, "serviceStartLatitude", ""),
      serviceEndLongitude: get(data, "serviceEndLongitude", ""),
      serviceEndLatitude: get(data, "serviceEndLatitude", ""),
      startDate: new Date(get(data, "serviceStartTime", new Date())),
      startTime: new Date(get(data, "serviceStartTime", new Date())),
      endDate: new Date(get(data, "serviceEndTime", new Date())),
      endTime: new Date(get(data, "serviceEndTime", new Date())),
    };
    return we;
  };

  componentWillReceiveProps = (nextProps) => {
    const { history } = this.props;
    if (
      !isNull(get(nextProps, "createWorkEntrySuccess")) &&
      !isEqual(
        nextProps.createWorkEntrySuccess,
        this.props.createWorkEntrySuccess
      )
    ) {
      this.createNotification(
        ALERT_TYPES.SUCCESS,
        "Work Entry create successful"
      );
      history.push("/admin/work-entries");
    }

    if (
      !isNull(get(nextProps, "createWorkEntryFail")) &&
      !isEqual(nextProps.createWorkEntryFail, this.props.createWorkEntryFail)
    ) {
      this.createNotification(
        ALERT_TYPES.ERROR,
        nextProps.createWorkEntryFail.message
      );
    }

    if (
      !isNull(get(nextProps, "updateWorkEntrySuccess")) &&
      !isEqual(
        nextProps.updateWorkEntrySuccess,
        this.props.updateWorkEntrySuccess
      )
    ) {
      this.createNotification(
        ALERT_TYPES.SUCCESS,
        "Work Entry update successful"
      );
      this.props.history.goBack();
    }

    if (
      !isNull(get(nextProps, "updateWorkEntryFail")) &&
      !isEqual(nextProps.updateWorkEntryFail, this.props.updateWorkEntryFail)
    ) {
      this.createNotification(
        ALERT_TYPES.ERROR,
        nextProps.updateWorkEntryFail.message
      );
    }
  };

  createNotification(alertType, alertMessage) {
    switch (alertType) {
      case ALERT_TYPES.INFO:
        NotificationManager.info(alertMessage, "", 2000);
        break;
      case ALERT_TYPES.SUCCESS:
        NotificationManager.success(alertMessage, "", 2000);
        break;
      case ALERT_TYPES.WARNING:
        NotificationManager.warning(alertMessage, "", 2000);
        break;
      case ALERT_TYPES.ERROR:
        NotificationManager.error(alertMessage, "", 2000);
        break;
      default:
        break;
    }
  }

  createWorkEntry = (values) => {
    let we = this.mapWorkEntry(values);
    we.signature = this.state.signature;
    we.userId = parseInt(this.state.userId);
    we.clientId = parseInt(this.state.clientId);
    we.serviceRequestId = parseInt(this.state.serviceId);
    we.serviceStartTime = new Date(
      values.startDate.getFullYear(),
      values.startDate.getMonth(),
      values.startDate.getDate(),
      values.startTime.getHours(),
      values.startTime.getMinutes(),
      values.startTime.getSeconds()
    )
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");
    we.serviceEndTime = new Date(
      values.endDate.getFullYear(),
      values.endDate.getMonth(),
      values.endDate.getDate(),
      values.endTime.getHours(),
      values.endTime.getMinutes(),
      values.endTime.getSeconds()
    )
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");
    we.progress = this.state.progress;
    we.staffVerification = this.state.staffVerification;
    we.thirdPartyVerification = this.state.thirdPartyVerification;
    we.adminVerification = this.state.adminVerification;
    we.timeZone = getTimeZone();
    // we.startLongitude = this.state.startLongitude;
    // we.startLatitude = this.state.startLatitude;
    // we.endLongitude = this.state.endLongitude;
    // we.endLatitude = this.state.endLatitude;
    delete we.startDate;
    delete we.startTime;
    delete we.endDate;
    delete we.endTime;
    if (!this.state.isEditMode) {
      this.props.dispatch(createWorkEntry(we));
    } else {
      we["workEntryId"] = parseInt(this.state.selectedWorkEntryId);
      this.props.dispatch(updateWorkEntry(we));
    }
  };

  onCancelClick = () => {
    this.props.history.push("/admin/work-entries");
  };

  onLocationUpdate = (geolocation) => {
    if (geolocation.coords) {
      console.log(geolocation.coords);
      // this.setState({
      //   startLongitude: geolocation.coords.longitude,
      //   startLatitude: geolocation.coords.latitude,
      //   endLongitude: geolocation.coords.longitude,
      //   endLatitude: geolocation.coords.latitude,
      // });
    }
  };

  render() {
    return (
      <div className="page-content">
        <div className="col-lg-8 col-md-10 col-sm-10 offset-lg-2 offset-md-1 offset-sm-0">
          <Details
            initialValues={this.state.initialValues}
            isEditMode={this.state.isEditMode}
            onSubmit={this.createWorkEntry}
            onCancelClick={this.onCancelClick}
            users={this.state.users}
            onUserChange={this.onUserChange}
            clients={this.state.clients}
            onClientChange={this.onClientChange}
            services={this.state.services}
            onServiceChange={this.onServiceChange}
            goalsAndOutcomes={this.state.goalsAndOutcomes}
            userId={this.state.userId}
            clientId={this.state.clientId}
            serviceId={this.state.serviceId}
            thirdPartyVerification={this.state.thirdPartyVerification}
            onThirdPartyVerification={(value) => {
              this.setState({ thirdPartyVerification: value });
            }}
            adminVerification={this.state.adminVerification}
            onAdminVerification={(value) => {
              this.setState({ adminVerification: value });
            }}
            staffVerification={this.state.staffVerification}
            onStaffVerification={(value) => {
              this.setState({ staffVerification: value });
            }}
            progress={this.state.progress}
            onChangeProgress={(value) => {
              this.setState({ progress: value });
            }}
            signature={this.state.signature}
            onSignatureChange={(signature) => {
              this.setState({ signature });
            }}
            // startLongitude={this.state.startLongitude}
            // startLatitude={this.state.startLatitude}
            // endLongitude={this.state.endLongitude}
            // endLatitude={this.state.endLatitude}
          />
        </div>
        {/* <Geolocation
          onSuccess={this.onLocationUpdate}
          render={({
            fetchingPosition,
            position: { coords: { latitude, longitude } = {} } = {},
            error,
            getCurrentPosition,
          }) => (
            <div>
              {error && (
                <div className="input-box">
                  <p className="error-message text-right">
                    <i>
                      <img src="./images/alert-circle.svg" alt="" />
                    </i>
                    &nbsp; {error.message}
                  </p>
                </div>
              )}
            </div>
          )}
        /> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  createWorkEntrySuccess: state.workEntry.createSuccess,
  createWorkEntryFail: state.workEntry.createFail,
  updateWorkEntrySuccess: state.workEntry.updateSuccess,
  updateWorkEntryFail: state.workEntry.updateFail,
});

export default withRouter(connect(mapStateToProps)(CreateTimeSheetForm));
