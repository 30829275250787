import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import { authReducer } from "./auth/authReducer";
import { clientReducer } from "./clients/clientReducer";
import { CLIENT, SERVICE, USER } from "../constants/actionTypes";
import { userReducer } from "./users/userReducer";
import { serviceReducer } from "./services/serviceReducer";
import { workEntryReducer } from "./workEntries/workEntryReducer";
import { configReducer } from "./config/configReducer";

const rootReducer = combineReducers({
  form: formReducer.plugin({
    addClient: (state, action) => {
      switch (action.type) {
        case CLIENT.CREATE_CLIENT_SUCCESS:
          return action.response;
        case USER.CREATE_USER_SUCCESS:
          return action.response;
        case SERVICE.CREATE_CLIENT_SERVICE_SUCCESS:
          return action.response;
        default:
          return state;
      }
    },
  }),
  auth: authReducer,
  client: clientReducer,
  user: userReducer,
  service: serviceReducer,
  workEntry: workEntryReducer,
  config: configReducer,
});

export default rootReducer;
