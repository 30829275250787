import * as React from "react";
import { Form, reduxForm } from "redux-form";
import ClientInfo from "./clientInfo";
import ClientAddressInfo from "./clientAddressInfo";
import { withRouter } from "react-router-dom";
import Files from "../../../staff/create-staff/create-staff-form/files";

class CreateClientForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      servicesList: [],
    };
  }
  componentDidMount() {
    if (!this.props.initialValues) {
      this.props.initialize({ billingCountry: this.props.billingCountry });
    }
  }
  render() {
    const { handleSubmit } = this.props;
    const routeToClient = true;
    return (
      <Form style={{ width: "100%" }}>
        <div className="page-content">
          <div className="col-lg-8 col-md-10 col-sm-10 offset-lg-2 offset-md-1 offset-sm-0">
            <ClientInfo
              clientInfoAuthorized={this.props.clientInfoAuthorized}
              onClientInfoAuthorized={this.props.onClientInfoAuthorized}
              supportCoordinatorsList={this.props.supportCoordinatorsList}
              behavioralSpecialistsList={this.props.behavioralSpecialistsList}
              countryList={this.props.countryList}
              onWaiwerChange={this.props.onWaiwerChange}
              onFundingSourceChange={this.props.onFundingSourceChange}
              onSupportCoordinatorSelect={this.props.onSupportCoordinatorSelect}
              onBehavioralSpecialistSelect={
                this.props.onBehavioralSpecialistSelect
              }
              onCountrySelect={this.props.onCountrySelect}
              waiwer={this.props.waiwer}
              fundingSource={this.props.fundingSource}
              behavioralSpecialist={this.props.behavioralSpecialist}
              supportCoordinator={this.props.supportCoordinator}
              country={this.props.country}
              phoneNumber={this.props.phoneNumber}
              onStatusChange={this.props.onStatusChange}
              clientStatus={this.props.clientStatus}
            />
          </div>
        </div>
        <div className="page-content">
          <div className="col-lg-8 col-md-10 col-sm-10 offset-lg-2 offset-md-1 offset-sm-0">
            <ClientAddressInfo
              onLatLongChange={this.props.onLatLongChange}
              longitude={this.props.longitude}
              latitude={this.props.latitude}
              billingState={this.props.billingState}
              onBillingStateChange={this.props.onBillingStateChange}
              initialValues={this.props.initialValues}
            />
          </div>
        </div>
        <div className="page-content">
          <div className="col-lg-8 col-md-10 col-sm-10 offset-lg-2 offset-md-1 offset-sm-0">
            <Files
              onFileUpload={this.props.onFileUpload}
              fileList={this.props.fileList}
              onRemoveImage={this.props.onRemoveImage}
              directoryName={"client"}
            />
          </div>
        </div>
        {/* <div className="page-content">
          <div className="col-lg-8 col-md-10 col-sm-10 offset-lg-2 offset-md-1 offset-sm-0">
             <ClientServiceInfo
              isDegreeRequired={this.props.isDegreeRequired}
              isConfirmedDates={this.props.isConfirmedDates}
              isAllowedNegativeUnits={this.props.isAllowedNegativeUnits}
              isServiceInfoAuthorized={this.props.isServiceInfoAuthorized}
              onConfirmDates={this.props.onConfirmDates}
              AllowNegativeUnits={this.props.AllowNegativeUnits}
              onDegreeRequired={this.props.onDegreeRequired}
              onServiceInfoAuthorized={this.props.onServiceInfoAuthorized}
            />
            <ServiceDetails clientServices={this.state.servicesList} />
            <div className="row">
              <div
                className="col-lg-12 col-md-12 col-sm-12 btn-new-service text-center"
                onClick={() => {
                  this.setState({ openModal: true });
                }}
              >
                <span className="add-new-service">+ ADD NEW SERVICE</span>
              </div>
            </div>
          </div>
        </div> */}
        <div className="row">
          <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-8 offset-sm-2">
            <div className="row">
              <div className="col-4">
                <button
                  className="button submit"
                  onClick={handleSubmit((values) => {
                    this.props.onSubmit(values, routeToClient);
                    // this.props.history.goBack();
                  })}
                >
                  {this.props.initialValues == null ? "SAVE" : "UPDATE"}
                </button>
              </div>
              {this.props.initialValues == null && (
                <div className="col-4">
                  <button
                    className="button submit-save"
                    onClick={handleSubmit((values) => {
                      this.props.onSubmit(values, !routeToClient);
                    })}
                  >
                    SAVE & NEW
                  </button>
                </div>
              )}
              <div className="col-4">
                <button
                  className="button basic"
                  onClick={(event) => {
                    this.props.history.goBack();
                    event.preventDefault();
                  }}
                >
                  CANCEL
                </button>
              </div>
            </div>
          </div>
        </div>
      </Form>
    );
  }
}

const clientForm = reduxForm({
  form: "addClient",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(CreateClientForm);

export default withRouter(clientForm);
