import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// import { getQueryParameter } from "../../../util/url";
import { get, isNull, map } from "lodash";
import "./clientDetails.scss";
import moment from "moment";
import { DATE_FORMAT } from "../../../constants/appConstants";

class BasicInformation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedClientId: "",
      activeTabIndex: 0,
      fileList: [],
    };
  }

  componentDidMount() {}

  componentWillReceiveProps = (nextProps) => {};

  getFullName = (user) => {
    return `${get(user, "firstName", "")} ${get(user, "middleName", "")} ${get(
      user,
      "lastName",
      ""
    )}`;
  };

  onProfileLinkClick = (id) => {
    this.props.history.push(`/admin/view-staff?id=${id}`);
  };

  onScProfileLink = (id) => {
    this.props.history.push(`/admin/view-sc?id=${id}`);
  };

  getFileIcon = (value) => {
    switch (value.toLowerCase()) {
      case "pdf":
        return "/images/pdf.png";
      case "docx":
      case "doc":
        return "/images/doc.png";
      case "ppt":
      case "pptx":
        return "/images/doc.png";
      case "xls":
      case "xlsm":
      case "xlsx":
        return "/images/excel.png";
      case "png":
        return "/images/doc.png";
      case "jpeg":
        return "/images/doc.png";
      default:
        return "/images/doc.png";
    }
  };

  getFileType = (value) => {
    return value.toUpperCase();
  };

  getFileName = (value) => {
    if (value.length > 10) {
      value = value.substring(0, 10) + "...";
    }
    return value;
  };

  render() {
    const { initialValues, fileList } = this.props;
    return (
      <div>
        <div className="page-content" style={{ width: "100%" }}>
          <div className="row" style={{ width: "100%" }}>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="header_text">Client Name</div>
              <div className="title_text">
                {initialValues ? initialValues.firstName : ""}{" "}
                {initialValues ? initialValues.lastName : ""}
              </div>
            </div>
          </div>
          <div className="row" style={{ width: "100%" }}>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="header_text">First Name</div>
              <div className="title_text">
                {initialValues ? initialValues.firstName : ""}
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="header_text">Middle Name</div>
              <div className="title_text">
                {initialValues ? initialValues.middleName : ""}
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="header_text">Last Name</div>
              <div className="title_text">
                {initialValues ? initialValues.lastName : ""}
              </div>
            </div>
          </div>
          <div className="row" style={{ width: "100%" }}>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="header_text">Phone</div>
              <div className="title_text">
                {initialValues ? initialValues.phone : ""}
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="header_text">Family Contact Email</div>
              <div className="title_text">
                {initialValues ? initialValues.familyContactEmail : ""}
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="header_text">Birth Date</div>
              <div className="title_text">
                {isNull(get(initialValues, "dob", null))
                  ? ""
                  : moment(initialValues.dob).utc().format(DATE_FORMAT)}
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="header_text">Status</div>
              <div className="title_text">
                {get(initialValues, "status", "")}
              </div>
            </div>
          </div>
          <div className="row" style={{ width: "100%" }}>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <hr></hr>
            </div>
          </div>

          <div className="row" style={{ width: "100%" }}>
            <div className="col-lg-3 col-md-4 col-sm-12">
              <div className="header_text">MCI</div>
              <div className="title_text">
                {initialValues ? initialValues.mci : ""}
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-12">
              <div className="header_text">MPI Number</div>
              <div className="title_text">
                {initialValues ? initialValues.mpi : ""}
              </div>
            </div>
          </div>
          <div className="row" style={{ width: "100%" }}>
            <div className="col-lg-3 col-md-4 col-sm-12">
              <div className="header_text">Diagnostic Code</div>
              <div className="title_text">
                {initialValues ? initialValues.diagnosticCode : ""}
              </div>
            </div>
          </div>
          <div className="row" style={{ width: "100%" }}>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <hr></hr>
            </div>
          </div>

          <div className="row" style={{ width: "100%" }}>
            <div className="col-lg-3 col-md-4 col-sm-12">
              <div className="header_text">Supports Coordinator</div>
              <div
                className="title_text_profile_link"
                onClick={() => {
                  this.onScProfileLink(initialValues.supportCoordinator.scId);
                }}
              >
                {!isNull(get(initialValues, "supportCoordinator", null))
                  ? this.getFullName(initialValues.supportCoordinator)
                  : ""}
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-12">
              <div className="header_text">Behavioral Specialist</div>
              <div
                className="title_text_profile_link"
                onClick={() => {
                  this.onProfileLinkClick(
                    initialValues.behavioralSpecialist.userId
                  );
                }}
              >
                {!isNull(get(initialValues, "behavioralSpecialist", null))
                  ? this.getFullName(initialValues.behavioralSpecialist)
                  : ""}
              </div>
            </div>
          </div>

          <div className="row" style={{ width: "100%" }}>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <hr></hr>
            </div>
          </div>

          <div className="row" style={{ width: "100%" }}>
            <div className="col-lg-3 col-md-4 col-sm-12">
              <div className="header_text">Waiver Type</div>
              <div className="title_text">
                {initialValues ? initialValues.waiwer : ""}
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-12">
              <div className="header_text">Funding Source</div>
              <div className="title_text">
                {initialValues ? initialValues.fundingSource : ""}
              </div>
            </div>
          </div>
          <div className="row" style={{ width: "100%" }}>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <hr></hr>
            </div>
          </div>

          <div className="row" style={{ width: "100%" }}>
            <div className="col-lg-6 col-md-8 col-sm-12">
              <div className="header_text">Billing Street Address</div>
              <div className="title_text">
                {initialValues ? initialValues.billingStreet : ""}
              </div>
            </div>
          </div>
          <div className="row" style={{ width: "100%" }}>
            <div className="col-lg-3 col-md-4 col-sm-12">
              <div className="header_text">Billing City</div>
              <div className="title_text">
                {initialValues ? initialValues.billingCity : ""}
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-12">
              <div className="header_text">Billing State/Province</div>
              <div className="title_text">
                {initialValues ? initialValues.billingState : ""}
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-12">
              <div className="header_text">Billing Zip/Postal Code</div>
              <div className="title_text">
                {initialValues ? initialValues.billingZIP : ""}
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-12">
              <div className="header_text">Billing Country</div>
              <div className="title_text">
                {initialValues ? initialValues.billingCountry : ""}
              </div>
            </div>
          </div>

          <div className="row" style={{ width: "100%" }}>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <hr></hr>
            </div>
          </div>

          <div className="row" style={{ width: "100%" }}>
            <div className="col-lg-3 col-md-4 col-sm-12">
              <div className="header_text">County</div>
              <div className="title_text">
                {initialValues ? initialValues.country : ""}
              </div>
            </div>
            <div className="col-lg-9 col-md-4 col-sm-12">
              <div className="header_text">Goals & Outcomes</div>
              <div className="title_text">
                {initialValues ? initialValues.goalsOutcomes : ""}
              </div>
            </div>
          </div>
        </div>

        <div className="page-content" style={{ width: "100%" }}>
          <div className="row">
            <div className="col-12">
              <div className="header_text">Files</div>
            </div>
          </div>
          <hr />
          <div className="row">
            {map(fileList, (file, i) => {
              return (
                <div className="col-lg-3 col-md-3 col-xs-6" key={i}>
                  <div className="col-12 file-component">
                    <a
                      href={file.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="card__corner">
                        <div className="card__corner-triangle"></div>
                      </div>
                      <img
                        src={this.getFileIcon(file.fileType)}
                        alt=""
                        className=" file-icon"
                      />
                      <div className="col-sm-12 document-name">
                        {this.getFileName(file.name)}
                      </div>
                      <div className="col-sm-12 document-type">
                        {this.getFileType(file.fileType)}
                      </div>
                    </a>
                  </div>
                </div>
              );
            })}
            {fileList.length === 0 && (
              <div className="col-12 text-center">No Files Uploaded</div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  createClientSuccess: state.client.createClientSuccess,
  createClientFail: state.client.createClientFail,
  updateClientSuccess: state.client.updateClientSuccess,
  updateClientFail: state.client.updateClientFail,
  clients: state.client.getAllSuccess,
});

export default withRouter(connect(mapStateToProps)(BasicInformation));
