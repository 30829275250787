import React from "react";
import { Field, Form, isDirty, reduxForm } from "redux-form";
import {
  inputField,
  renderDatePicker,
  renderTimePicker,
  selectField,
  textField
} from "../../../shared/form-fields/formFields";
import { isEmpty, isEqual } from "lodash";
import {
  validateClient,
  validateClientServiceCode,
  validateClientServiceEndDate,
  validateClientServiceEndTime,
  validateClientServiceStartDate,
  validateClientServiceStartTime,
  validateStaffMember
} from "./workEntryValidator";
import moment from "moment";
import SignaturePad from "../../../shared/signature-pad";

class Details extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: moment().format("MM/dd/yyyy"),
      startTime: moment().format("MM/dd/yyyy hh:mm:ss"),
      endDate: moment().format("MM/dd/yyyy")
    };
  }


  onStartTimeSet = (e) => {
    const startTime = moment(moment(this.state.startDate).format("MM/dd/yyyy") + " " + moment(e).format("hh:mm:ss")).format("MM/dd/yyyy hh:mm:ss");
    this.setState({
      startTime: startTime
    });
  };

  onStartDateSet = (e) => {
    const startDate = moment(e);
    this.setState({
      startDate: startDate
    });
  };

  onEndDateSet = (e) => {
    const endDate = moment(e);
    this.setState({
      endDate: endDate
    });
  };

  componentWillReceiveProps(nextProps, nextContext) {
    const { initialValues } = nextProps;
    if (
      initialValues != null &&
      !isEqual(initialValues.startDate, this.state.startDate)
    ) {
      this.setState({
        startDate: initialValues.startDate,
        startTime: initialValues.startTime
      });
    }
  }

  render() {

    const { handleSubmit, initialValues, users, clients, services } = this.props;
    let staffOptions = [];
    let clientOptions = [];
    let serviceOptions = [];
    if (users) {
      users.forEach((staff) => {
        staffOptions.push({
          value: staff.userId,
          text: staff.name
        });
      });
    }
    if (clients) {
      clients.forEach((client) => {
        clientOptions.push({
          value: client.clientId,
          text: client.name
        });
      });
    }
    if (services) {
      services.forEach((service) => {
        serviceOptions.push({
          value: service.serviceRequestId,
          text: `${service.serviceCode} (${moment(service.serviceStartDate).utc().format("MM-DD-YYYY")} - ${moment(service.serviceEndDate).utc().format("MM-DD-YYYY")})`
        });
      });
    }
    return (
      <Form style={{ width: "100%" }}>
        <div className="row">
          <div className="col-6 input-box text-left">
            <div className="label">Staff Name</div>

            {/*<select*/}
            {/*    className="select-box"*/}
            {/*    placeholder="Select Staff Member"*/}
            {/*    onChange={this.props.onUserChange}*/}
            {/*    disabled={this.props.initialValues != null}*/}
            {/*    value={this.props.userId}*/}
            {/*>*/}
            {/*    <option value={""}>{"Select Staff Member"}</option>*/}
            {/*    {this.props.users && this.props.users.map((record, index) => {*/}
            {/*        return (*/}
            {/*            <option key={index} value={record.userId}>*/}
            {/*                {record.name}*/}
            {/*            </option>*/}
            {/*        );*/}
            {/*    })}*/}
            {/*</select>*/}
            <Field
              name="userId"
              component={selectField}
              placeholder={"Select Staff Member"}
              required={true}
              onChange={this.props.onUserChange}
              disabled={this.props.initialValues != null}
              options={staffOptions}
              validate={validateStaffMember}
            />
          </div>
          <div className="col-6 input-box text-left">
            <div className="label">Client Name</div>
            {/*<select*/}
            {/*    className="select-box"*/}
            {/*    placeholder="Select Client"*/}
            {/*    onChange={this.props.onClientChange}*/}
            {/*    disabled={this.props.initialValues != null}*/}
            {/*    value={this.props.clientId}*/}
            {/*>*/}
            {/*    <option value={""}>{"Select Client"}</option>*/}
            {/*    {this.props.clients && this.props.clients.map((record, index) => {*/}
            {/*        return (*/}
            {/*            <option key={index} value={record.clientId}>*/}
            {/*                {record.name}*/}
            {/*            </option>*/}
            {/*        );*/}
            {/*    })}*/}
            {/*</select>*/}
            <Field
              name="clientId"
              component={selectField}
              placeholder={"Select Client"}
              required={true}
              onChange={this.props.onClientChange}
              // disabled={this.props.initialValues != null}
              options={clientOptions}
              validate={validateClient}
            />
          </div>
          <div className="col-6 input-box text-left" style={{ paddingTop: 10, paddingBottom: 10 }}>
            <div className="label">Client Service</div>
            {/*<select*/}
            {/*    className="select-box"*/}
            {/*    placeholder="Select Client"*/}
            {/*    onChange={this.props.onServiceChange}*/}
            {/*    disabled={*/}
            {/*        this.props.initialValues != null || isEmpty(this.props.services)*/}
            {/*    }*/}
            {/*    value={this.props.serviceId}*/}
            {/*    required={true}*/}
            {/*>*/}
            {/*    <option value={""}>{"Select Client Service"}</option>*/}
            {/*    {this.props.services && this.props.services.map((record, index) => {*/}
            {/*        return (*/}
            {/*            <option key={index} value={record.serviceRequestId}>*/}
            {/*                {record.serviceCode}*/}
            {/*            </option>*/}
            {/*        );*/}
            {/*    })}*/}
            {/*</select>*/}
            <Field
              name="serviceId"
              component={selectField}
              placeholder={"Select Client Service"}
              required={true}
              onChange={this.props.onServiceChange}
              disabled={isEmpty(this.props.services)}
              options={serviceOptions}
              validate={validateClientServiceCode}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-sm-12 input-box text-left">
            <div className="label">Start Date Time</div>
            <div className="row col-12" style={{ padding: 0 }}>
              <div className="col-6 input-box text-left">
                <Field
                  name="startDate"
                  component={renderDatePicker}
                  type="text"
                  placeholder={"Start Date"}
                  required={true}
                  maxDate={new Date()}
                  validate={validateClientServiceStartDate}
                  onTextValueChange={this.onStartDateSet}
                />
              </div>
              <div className="col-6 input-box text-left">
                <Field
                  name="startTime"
                  component={renderTimePicker}
                  type="text"
                  placeholder={"Start Time"}
                  required={true}
                  onTextValueChange={this.onStartTimeSet}
                  validate={validateClientServiceStartTime}
                  selectedDate={this.state.startDate}
                  maxTime={new Date().getTime()}
                />
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-sm-12 input-box text-left">
            <div className="label">End Date Time</div>
            <div className="row col-12" style={{ padding: 0 }}>
              <div className="col-6 input-box text-left">
                <Field
                  name="endDate"
                  component={renderDatePicker}
                  type="text"
                  placeholder={"End Date"}
                  required={true}
                  minDate={new Date(this.state.startDate)}
                  maxDate={moment(this.state.startDate).format("MM/dd/yyyy") == moment(new Date()).format("MM/dd/yyyy") || initialValues == null ? new Date() : new Date(new Date().setDate(new Date(this.state.startDate).getDate() + 1)).setHours(0, 0, 0, 0)}
                  value={this.state.startDate}
                  validate={validateClientServiceEndDate}
                  onTextValueChange={this.onEndDateSet}
                  // disabled={true}
                />
              </div>
              <div className="col-6 input-box text-left">
                <Field
                  name="endTime"
                  component={renderTimePicker}
                  type="text"
                  placeholder={"End Time"}
                  required={true}
                  validate={validateClientServiceEndTime}
                  onTextValueChange={() => {
                  }}
                  selectedDate={this.state.endDate}
                  // minTime={this.state.startTime}
                  // maxTime={new Date(this.state.startTime).getMilliseconds() < new Date().getMilliseconds() ? new Date() : new Date(new Date().setDate(new Date(this.state.startTime).getDate() + 1)).setHours(0, 0, 0, 0)}
                />
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-sm-12 input-box text-left">
            <div className="label">Start Location</div>
            <div className="row col-12" style={{ padding: 0 }}>
              <div className="col-6 input-box text-left">
                <Field
                  name="serviceStartLongitude"
                  component={inputField}
                  type="text"
                  placeholder={"Start Longitude"}
                  required={false}
                />
              </div>
              <div className="col-6 input-box text-left">
                <Field
                  name="serviceStartLatitude"
                  component={inputField}
                  type="text"
                  placeholder={"Start Latitude"}
                  required={false}
                />
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-sm-12 input-box text-left">
            <div className="label">End Location</div>
            <div className="row col-12" style={{ padding: 0 }}>
              <div className="col-6 input-box text-left">
                <Field
                  name="serviceEndLongitude"
                  component={inputField}
                  type="text"
                  placeholder={"End Longitude"}
                  required={false}
                />
              </div>
              <div className="col-6 input-box text-left">
                <Field
                  name="serviceEndLatitude"
                  component={inputField}
                  type="text"
                  placeholder={"End Latitude"}
                  required={false}
                />
              </div>
            </div>
          </div>

          <div className="col-12 input-box text-left grey-area">
            <div className="title">Goals and outcomes</div>
            <span className="description">{this.props.goalsAndOutcomes}</span>
          </div>
          <div className="col-12 input-box text-left">
            <div className="label">Service Notes</div>
            <Field
              name="notes"
              component={textField}
              type="text"
              placeholder={"Notes"}
              required={true}
              //   validate={validateEmail}
            />
          </div>
          <div className="col-12 grey-area">
            <div className="col-4">
              <div className="label">Progress</div>
            </div>
            <div className="row col-12">
              <div
                className="col-4 text-left"
                style={{ padding: "10px" }}
              >
                <input
                  type="radio"
                  name="progress"
                  value="increased"
                  checked={this.props.progress == 1}
                  onChange={(event) => {
                    this.props.onChangeProgress(1);
                  }}
                />{" "}
                Increased
              </div>
              <div
                className="col-4 text-left"
                style={{ padding: "10px" }}
              >
                <input
                  type="radio"
                  name="progress"
                  value="maintained"
                  checked={this.props.progress == 2}
                  onChange={(event) => {
                    this.props.onChangeProgress(2);
                  }}
                />{" "}
                Maintained
              </div>
              <div
                className="col-4 text-left"
                style={{ padding: "10px" }}
              >
                <input
                  type="radio"
                  name="progress"
                  value="decreased"
                  checked={this.props.progress == 3}
                  onChange={(event) => {
                    this.props.onChangeProgress(3);
                  }}
                />{" "}
                Decreased
              </div>
            </div>
          </div>
          {/*<div className="col-4 text-left input-box my-auto">*/}
          {/*  <input*/}
          {/*    type="checkbox"*/}
          {/*    name="thirdPartyVerification"*/}
          {/*    value="thirdPartyVerification"*/}
          {/*    checked={this.props.thirdPartyVerification}*/}
          {/*    style={{marginRight:5}}*/}
          {/*    onChange={(event) => {*/}
          {/*      this.props.onThirdPartyVerification(event.target.checked);*/}
          {/*    }}*/}
          {/*  />*/}
          {/*  <span className="label">3rd Party Verification</span>*/}
          {/*</div>*/}
          <div className="col-4 text-left input-box my-auto">
            <input
              type="checkbox"
              name="adminVerification"
              value="adminVerification"
              checked={this.props.adminVerification}
              style={{ marginRight: 5 }}
              onChange={(event) => {
                this.props.onAdminVerification(event.target.checked);
              }}
            />
            <span className="label">Admin Verification</span>
          </div>
          {/*<div className="col-4 text-right input-box my-auto">*/}
          {/*  <input*/}
          {/*    type="checkbox"*/}
          {/*    name="staffVerification"*/}
          {/*    value="staffVerification"*/}
          {/*    checked={this.props.staffVerification}*/}
          {/*    style={{marginRight:5}}*/}
          {/*    onChange={(event) => {*/}
          {/*      this.props.onStaffVerification(event.target.checked);*/}
          {/*    }}*/}
          {/*  />*/}
          {/*  <span className="label">Staff Verification</span>*/}
          {/*</div>*/}
          <div className="col-12">
            <hr />
          </div>
          <div className="col-12 text-left input-box">
            <div className="label">
              Signature Pad
            </div>
          </div>
          <SignaturePad
            showSignaturePad={isEmpty(this.props.initialValues)}
            isEditMode={this.props.isEditMode}
            signature={this.props.signature}
            onChange={this.props.onSignatureChange}
          />
        </div>

        <div className="row" style={{ paddingTop: 20 }}>
          <div className="col-lg-6 offset-lg-3 col-md-6 offset-md-3 col-sm-12 offset-sm-0">
            <div className="row">
              <div className="col-6">
                <button
                  type="button"
                  onClick={(event) => {
                    event.preventDefault();
                    this.props.onCancelClick();
                  }}
                  className="button basic buttonOverflow"
                >
                  CANCEL
                </button>
              </div>
              <div className="col-6">
                <button
                  onClick={handleSubmit((values) => {
                    this.props.onSubmit(values);
                  })}
                  className="button submit buttonOverflow"
                >
                  {this.props.initialValues == null ? "SAVE" : "UPDATE"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Form>
    );
  }
}

const timeSheetForm = reduxForm({
  form: "timeSheet",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(Details);

export default timeSheetForm;
