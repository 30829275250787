import * as React from "react";
import "../data-grid.scss";

class StaffAllocationAction extends React.Component {
    onClick = (event) => {
        this.props.onClick(this.props.actionId, event);
    };

    render() {
        return (
            <div className='cell action-cell'>
                <div className="col-12 text-center" onClick={this.onClick}>
                    <img src="/images/staff-allocation.svg" alt=""/>
                </div>
            </div>
        );
    }
}

export default StaffAllocationAction;
