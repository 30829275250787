import {externalAPIGet} from "../util/httpService";
import {GOOGLE} from "../constants/appConstants";
import * as _ from "lodash";


export async function geoCodeAddress(address){
    try {
        const response = await externalAPIGet(`${GOOGLE.MAPS_API}?address=${address}&key=${GOOGLE.MAPS_API_KEY}`,{});
        return _.get(response,'data.results',[])
    }catch (e) {
        throw _.get(e,'response.data',{});
    }
}