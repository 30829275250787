import * as React from "react";
import { Field } from "redux-form";
import {
  inputField,
  phoneNumberField,
  renderDatePicker,
  textField,
} from "../../../shared/form-fields/formFields";
import {
  validateClientFirstName,
  validateClientLastName,
  validateClientMiddleName,
  validateFamilyContactEmail,
  validateMCI,
  validateMobileNumber,
} from "./clientValidator";
import {
  COUNTIES,
  FUNDING_SOURCE_TYPE,
  WAIVER_TYPES,
} from "../../../../constants/appConstants";
import SearchableDropdown from "../../../shared/searchable-dropdown";

class ClientInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthorized: false,
      sc: [],
      bs: [],
      showOtherWaiver: false,
      showOtherFundingSource: false,
      waiverType: "",
      customWaiver: "",
      fundingSource: "",
      customFunding: "",
    };
  }

  componentDidMount() {
    if (this.props.waiwer) {
      this.checkOtherWaiverType(this.props.waiwer);
    }
  }

  checkOtherWaiverType = (waiver) => {
    let isSetWaiverType = false;
    WAIVER_TYPES.map((w) => {
      if (
        w.type.toLowerCase() === waiver.toLowerCase() &&
        waiver.toLowerCase() !== "other"
      ) {
        isSetWaiverType = true;
      }
    });
    if (!isSetWaiverType) {
      this.setState({
        showOtherWaiver: true,
        waiverType: "Other",
      });
    } else {
      this.setState({
        showOtherWaiver: false,
        waiverType: waiver,
      });
    }
  };

  checkOtherFundingSource = (fundingSource) => {
    let isSetFundingSource = false;
    FUNDING_SOURCE_TYPE.map((w) => {
      if (
        w.type.toLowerCase() === fundingSource.toLowerCase() &&
        fundingSource.toLowerCase() !== "other"
      ) {
        isSetFundingSource = true;
      }
    });
    if (!isSetFundingSource) {
      this.setState({
        showOtherFundingSource: true,
        fundingSource: "Other",
      });
    } else {
      this.setState({
        showOtherFundingSource: false,
        fundingSource: fundingSource,
      });
    }
  };

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.waiwer !== this.props.waiwer && nextProps.waiwer) {
      this.checkOtherWaiverType(nextProps.waiwer);
    }
    if (
      nextProps.fundingSource !== this.props.fundingSource &&
      nextProps.fundingSource
    ) {
      this.checkOtherFundingSource(nextProps.fundingSource);
    }
  };

  onWaiverChange = (e) => {
    const type = e.target.value;
    if (type === "Other") {
      this.setState({
        showOtherWaiver: true,
        waiverType: type,
      });
    } else {
      this.setState({
        waiverType: type,
        showOtherWaiver: false,
      });
    }

    this.props.onWaiwerChange(e);
  };

  onFundingSourceChange = (e) => {
    const type = e.target.value;
    if (type === "Other") {
      this.setState({
        showOtherFundingSource: true,
        fundingSource: type,
      });
    } else {
      this.setState({
        showOtherFundingSource: false,
        fundingSource: type,
      });
    }

    this.props.onFundingSourceChange(e);
  };

  validatemiddlename = (variable_to_test) => {
    // const re = /^[a-zA-Z0-9_]+$/;
    // re.test(variable_to_test);
    // if (re.test) {
    //   return variable_to_test;
    // } else {
    //   return undefined;
    // }
    return "yes";
  };

  render() {
    let today = new Date();

    return (
      <div>
        <div className="row">
          <span className="form-title">Client Information</span>
        </div>
        <div className="row">
          <div className="col-6 input-box text-left">
            <div className="label">First Name</div>
            <Field
              name="firstName"
              component={inputField}
              type="text"
              placeholder={"First Name"}
              required={false}
              validate={validateClientFirstName}
            />
          </div>
          <div className="col-6 input-box text-left">
            <div className="label">Middle Name</div>
            <Field
              name="middleName"
              component={inputField}
              type="text"
              placeholder={"Middle Name"}
              required={false}
              pattern="^[a-zA-Z0-9]+$"
              validate={validateClientMiddleName}
            />
          </div>
          <div className="col-6 input-box text-left">
            <div className="label">Last Name</div>
            <Field
              name="lastName"
              component={inputField}
              type="text"
              placeholder={"Last Name"}
              required={false}
              validate={validateClientLastName}
            />
          </div>
          <div className="col-6 input-box text-left">
            <div className="label">Birth Date</div>
            <Field
              name="dob"
              component={renderDatePicker}
              type="text"
              placeholder={"Select"}
              required={true}
              maxDate={new Date()}
              //   validate={validateEmail}
            />
          </div>
          <div className="col-6 input-box text-left">
            <div className="label">MCI</div>
            <Field
              name="mci"
              component={inputField}
              type="text"
              placeholder={"MCI"}
              required={true}
              validate={validateMCI}
            />
          </div>
          <div className="col-6 input-box text-left">
            <div className="label">MPI Number</div>
            <Field
              name="mpi"
              component={inputField}
              type="text"
              placeholder={"MPI Number"}
              required={false}
            />
          </div>
          <div className="col-6 input-box text-left">
            <div className="label">Diagnostic Code</div>
            <Field
              name="diagnosticCode"
              component={inputField}
              type="text"
              placeholder={"Diagnostic Code"}
              required={false}
            />
          </div>
          <div className="col-6 input-box text-left">
            <div className="label">Supports Coordinator</div>
            <SearchableDropdown
              options={this.props.supportCoordinatorsList}
              value={this.props.supportCoordinator}
              defaultValue={0}
              keyIdentifier="scId"
              valueIdentifier="name"
              placeholder="Select Supports Coordinator"
              onChange={this.props.onSupportCoordinatorSelect}
            />
          </div>
          <div className="col-6 input-box text-left">
            <div className="label">Behavioral Specialist</div>
            <SearchableDropdown
              options={this.props.behavioralSpecialistsList}
              value={this.props.behavioralSpecialist}
              defaultValue={0}
              keyIdentifier="userId"
              valueIdentifier="name"
              placeholder="Select Behavioral Specialist"
              onChange={this.props.onBehavioralSpecialistSelect}
            />
          </div>
          <div className="col-6 input-box text-left">
            <div className="label">County</div>
            <SearchableDropdown
              options={COUNTIES}
              value={this.props.country}
              name="country"
              keyIdentifier="type"
              valueIdentifier="value"
              defaultValue={0}
              placeholder={"County"}
              required={true}
              onChange={this.props.onCountrySelect}
            />
          </div>
          <div className="col-12 input-box text-left">
            <div className="label">Goals & Outcomes</div>
            <Field
              name="goalsOutcomes"
              component={textField}
              type="text"
              placeholder={"Goals and Outcomes"}
              required={false}
            />
          </div>
          <div className="col-12 input-box text-left">
            <div className="label">Internal Memo</div>
            <Field
              name="memo"
              component={textField}
              type="text"
              placeholder={"Internal Memo"}
              required={false}
            />
          </div>
          <div className="col-6 input-box text-left">
            <div className="label">Phone</div>
            <Field
              name="phone"
              component={phoneNumberField}
              // type="number"
              value={this.props.phoneNumber}
              // pattern={'[0-9]{10}'}
              placeholder={"Phone"}
              required={true}
              validate={validateMobileNumber}
            />
          </div>
          <div className="col-6 input-box text-left">
            <div className="label">Family Contact Email</div>
            <Field
              name="familyContactEmail"
              component={inputField}
              type="text"
              placeholder={"Family Contact Email"}
              required={false}
              validate={validateFamilyContactEmail}
            />
          </div>
          <div
            className="col-6 input-box text-left"
            style={{ marginBottom: 20 }}
          >
            <div className="label">Waiver</div>
            {/* <Field
              name='waiwer'
              component={inputField}
              type='text'
              placeholder={'Waiver'}
              required={false}
            /> */}
            <select
              className="select-box"
              placeholder="Select Waiwer Type"
              onChange={this.onWaiverChange}
              value={this.state.waiverType}
            >
              {WAIVER_TYPES.map((record) => {
                return <option value={record.value}>{record.type}</option>;
              })}
            </select>
          </div>
          <div
            className="col-6 input-box text-left"
            style={{ marginBottom: 20 }}
          >
            <div className="label">Funding Source</div>
            {/* <Field
              name="fundingSource"
              component={inputField}
              type="text"
              placeholder={"Funding Source"}
              required={false}
            /> */}
            <select
              className="select-box"
              placeholder="Select Funding Source"
              onChange={this.onFundingSourceChange}
              value={this.state.fundingSource}
            >
              {FUNDING_SOURCE_TYPE.map((record) => {
                return <option value={record.value}>{record.type}</option>;
              })}
            </select>
          </div>
          {this.state.showOtherWaiver && (
            <div className="col-6 input-box text-left">
              <div className="label">Specify Waiver type</div>
              <Field
                name="waiwer"
                component={inputField}
                type="text"
                placeholder={"Waiver Type"}
                required={true}
              />
            </div>
          )}

          {this.state.showOtherFundingSource && (
            <div className="col-6 input-box text-left">
              <div className="label">Specify Funding Source</div>
              <Field
                name="fundingSource"
                component={inputField}
                type="text"
                placeholder={"Funding Source"}
                required={true}
              />
            </div>
          )}
          {/*<div className="col-12 input-box text-left">*/}
          {/*  <input*/}
          {/*    type="checkbox"*/}
          {/*    name="authorized"*/}
          {/*    value="authorized"*/}
          {/*    checked={this.props.clientInfoAuthorized}*/}
          {/*    onChange={(event) => {*/}
          {/*      this.props.onClientInfoAuthorized(event.target.checked);*/}
          {/*    }}*/}
          {/*    style={{marginRight:5}}*/}
          {/*  />*/}
          {/*  <span className="label">Authorized</span>*/}
          {/*</div>*/}
          <div className="col-6 input-box text-left">
            <div className="label">Status</div>
            {/*<Field*/}
            {/*  name="title"*/}
            {/*  component={inputField}*/}
            {/*  type="text"*/}
            {/*  placeholder={"Title"}*/}
            {/*  required={true}*/}
            {/*  //   validate={validateEmail}*/}
            {/*/>*/}
            <select
              className="select-box"
              placeholder="Select Staff Status"
              value={this.props.clientStatus}
              onChange={(e) => {
                this.props.onStatusChange(e.target.value);
              }}
            >
              <option value={4}>Active</option>
              <option value={5}>Inactive</option>
            </select>
          </div>
        </div>
      </div>
    );
  }
}

export default ClientInfo;
