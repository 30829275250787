import {ALERT_TYPES} from "../constants/appConstants";
import {NotificationManager} from "react-notifications";

export function createNotification(alertType, alertMessage) {
    switch (alertType) {
        case ALERT_TYPES.INFO:
            NotificationManager.info(alertMessage, '', 2000);
            break;
        case ALERT_TYPES.SUCCESS:
            NotificationManager.success(alertMessage, '', 2000);
            break;
        case ALERT_TYPES.WARNING:
            NotificationManager.warning(alertMessage, '', 2000);
            break;
        case ALERT_TYPES.ERROR:
            NotificationManager.error(alertMessage, '', 2000);
            break;
        default:
            break;
    }
}