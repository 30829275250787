import React from "react";
import { Form, reduxForm } from "redux-form";
import MemberInformation from "./memberInformation";
import StaffAddressInfo from "./staffAddressInfo";
import SystemInfo from "./systemInfo";
import Files from "./files";
import { withRouter } from "react-router-dom";

class CreateStaffForm extends React.Component {
  componentDidMount() {
    if (!this.props.initialValues) {
      this.props.initialize({
        billingState: this.props.billingState,
        billingCountry: this.props.billingCountry,
      });
    }
  }
  render() {
    const { handleSubmit, initialValues } = this.props;
    const routeToStaff = true;
    return (
      <Form style={{ width: "100%" }}>
        <div className="page-content">
          <div className="col-lg-8 col-md-10 col-sm-10 offset-lg-2 offset-md-1 offset-sm-0">
            <MemberInformation
              hasRelevantDegree={this.props.hasRelevantDegree}
              onHasRelevantDegree={this.props.onHasRelevantDegree}
              userType={this.props.userType}
              credentials={this.props.credentials}
              onCredentialsSelect={this.props.onCredentialsSelect}
              isEditMode={this.props.isEditMode}
              onStatusChange={this.props.onStatusChange}
              staffStatus={this.props.staffStatus}
            />
          </div>
        </div>
        <div className="page-content">
          <div className="col-lg-8 col-md-10 col-sm-10 offset-lg-2 offset-md-1 offset-sm-0">
            <StaffAddressInfo />
          </div>
        </div>
        <div className="page-content">
          <div className="col-lg-8 col-md-10 col-sm-10 offset-lg-2 offset-md-1 offset-sm-0">
            <Files
              onFileUpload={this.props.onFileUpload}
              fileList={this.props.fileList}
              onRemoveImage={this.props.onRemoveImage}
              directoryName={"staff"}
            />
          </div>
        </div>
        <div className="page-content">
          <div className="col-lg-6 col-md-8 col-sm-8 offset-lg-2 offset-md-1 offset-sm-0">
            <SystemInfo
              isAuthorized={this.props.isAuthorized}
              onIsAuthorized={this.props.onIsAuthorized}
              roleGroupList={this.props.roleGroupList}
              userRoleGroup={this.props.userRoleGroup}
              onRoleGroupChange={this.props.onRoleGroupChange}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-8 offset-sm-2">
            <div className="row">
              <div className="col-4">
                <button
                  className="button submit"
                  onClick={handleSubmit((values) => {
                    this.props.onSubmit(values, routeToStaff);
                  })}
                >
                  {this.props.initialValues == null ? "SAVE" : "UPDATE"}
                </button>
              </div>
              {this.props.initialValues == null && (
                <div className="col-4">
                  <button
                    className="button submit-save"
                    onClick={handleSubmit((values) => {
                      this.props.onSubmit(values, !routeToStaff);
                    })}
                  >
                    SAVE & NEW
                  </button>
                </div>
              )}
              <div className="col-4">
                <button
                  className="button basic"
                  onClick={(event) => {
                    event.preventDefault();
                    this.props.history.push("/admin/staff");
                  }}
                >
                  CANCEL
                </button>
              </div>
            </div>
          </div>
        </div>
      </Form>
    );
  }
}

const staffForm = reduxForm({
  form: "addStaff",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(CreateStaffForm);

export default withRouter(staffForm);
