import React from "react";
import moment from "moment";

export const ServiceDataTable = ({ data }) => {
  return (
    <div className="table-container">
      <div className="grid">
        <div className="headers">Service Name</div>
        <div className="headers">Service Code</div>
        <div className="headers">Unit Length</div>
        <div className="headers">Service Begin Date</div>
        <div className="headers">Service End Date</div>
        <div className="headers">Units Authorized</div>
        <div className="headers">Allow Negative Units</div>
        <div className="headers">Units Utilized</div>
        <div className="headers">Units Remaining</div>
      </div>

      {data.map((item, i) => {
        return (
          <div>
            <div className="grid" key={i}>
              <div className="content">{item.serviceName}</div>
              <div className="content">{item.serviceCode}</div>
              <div className="content">{item.serviceUnitLength}</div>
              <div className="content">
                {moment(item.serviceStartDate).format("MM/DD/YYYY")}
              </div>
              <div className="content">
                {moment(item.serviceEndDate).format("MM/DD/YYYY")}
              </div>
              <div className="content">{item.serviceUnitsAuthorized}</div>
              <div className="content">
                {item.serviceIsNegetiveUnitsAllowed ? "Yes" : "No"}
              </div>
              <div className="content">{item.unitsUtilized}</div>
              <div className="content">{item.unitsRemaining}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
