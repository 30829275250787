import React from "react";

const Pagination = ({ itemsPerPage, totalItems, currentPage, onPageClick }) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const getPageRange = () => {
    const startPage = Math.max(1, currentPage - Math.floor(itemsPerPage / 2));
    const endPage = Math.min(totalPages, startPage + itemsPerPage - 1);
    const pages = [...Array(endPage - startPage + 1)].map(
      (_, i) => startPage + i
    );

    return pages;
  };

  return (
    <div className="cont-pagination">
      <div className="cont-page">
        <div className="cont-arrows">
          <img
            src={"./images/chevron-left.svg"}
            style={{ height: 24 }}
            onClick={(e) => {
              if (currentPage !== 1) {
                onPageClick(e, currentPage - 1);
              }
            }}
          />
        </div>

        <div className="cont-numbers">
          {getPageRange().map((number, i) => {
            return (
              <div
                className={
                  number === currentPage
                    ? "numbers numbers-selected"
                    : "numbers"
                }
                key={i}
                selected={number === currentPage}
                onClick={(event) => onPageClick(event, number)}
              >
                {number}
              </div>
            );
          })}
        </div>

        <div className="cont-arrows">
          <img
            src={"./images/chevron-right.svg"}
            style={{ height: 24 }}
            onClick={(e) => {
              if (currentPage < totalPages) {
                onPageClick(e, currentPage + 1);
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Pagination;
