import Clients from "../../clients/clients";
import Staff from "../../staff/staff";
import SupportCoordinator from "../../support-cordinator/support-cordinator";
import WorkEntry from "../../work-entries/workEntries";
import Reports from "../../reports/billingReport";
import Services from "../../services/services";

export default [
  {
    name: "CLIENTS",
    url: "admin/clients",
    component: Clients,
    isSideBarNavigation: true,
    authUsers: [1, 0],
    icon: "./images/users.svg",
    selectedIcon: "./images/users-selected.svg"
  },
  {
    name: "SERVICES",
    url: "admin/services",
    component: Services,
    isSideBarNavigation: true,
    authUsers: [1, 0],
    icon: "./images/clipboard-grey.svg",
    selectedIcon: "./images/clipboard-green.svg"
  },
  {
    name: "STAFF",
    url: "admin/staff",
    component: Staff,
    isSideBarNavigation: true,
    authUsers: [1, 0],
    icon: "./images/book.svg",
    selectedIcon: "./images/book-selected.svg"
  },
  {
    name: "SUPPORTS COORDINATOR",
    url: "admin/support-coordinator",
    component: SupportCoordinator,
    isSideBarNavigation: true,
    authUsers: [1, 0],
    icon: "./images/zap-not.svg",
    selectedIcon: "./images/zap.svg"
  },
  {
    name: "WORK ENTRIES",
    url: "admin/work-entries",
    component: WorkEntry,
    isSideBarNavigation: true,
    authUsers: [1, 0],
    icon: "./images/file-plus.svg",
    selectedIcon: "./images/file-plus-selected.svg"
  },
  {
    name: "REPORTS",
    url: "admin/reports",
    component: Reports,
    isSideBarNavigation: true,
    authUsers: [1, 0],
    icon: "./images/reports.svg",
    selectedIcon: "./images/reports-selected.svg"
  },
  {
    name: "VISITS",
    url: "staff/visits",
    component: WorkEntry,
    isSideBarNavigation: true,
    authUsers: [2],
    icon: "./images/file-plus.svg",
    selectedIcon: "./images/file-plus-selected.svg"
  },
  {
    name: "REPORTS",
    url: "staff/reports",
    component: Reports,
    isSideBarNavigation: true,
    authUsers: [2],
    icon: "./images/reports.svg",
    selectedIcon: "./images/reports.svg"
  }
];
