import React from 'react'
import {Field} from 'redux-form'
import {inputField} from '../../../shared/form-fields/formFields'
import {validateZIPCode} from "./staffValidator";

class StaffAddressInfo extends React.Component {
    render() {
        return (
            <div>
                <div className='row'>
                    <span className='form-title'>Address Information</span>
                </div>
                <div className='row'>
                    <div className='col-12 input-box text-left'>
                        <div className='label'>Mailing Street Address</div>
                        <Field
                            name='billingStreet'
                            component={inputField}
                            type='text'
                            placeholder={'Mailing Street Address'}
                            required={true}
                            //   validate={validateEmail}
                        />
                    </div>
                    <div className='col-6 input-box text-left'>
                        <div className='label'>Mailing City</div>
                        <Field
                            name='billingCity'
                            component={inputField}
                            type='text'
                            placeholder={'Mailing City'}
                            required={true}
                            //   validate={validateEmail}
                        />
                    </div>
                    <div className='col-6 input-box text-left'>
                        <div className='label'>Mailing State/Province</div>
                        <Field
                            name='billingState'
                            component={inputField}
                            type='text'
                            placeholder={'Mailing State/Province'}
                            required={true}
                            //   validate={validateEmail}
                        />
                    </div>
                    <div className='col-6 input-box text-left'>
                        <div className='label'>Mailing Zip/Postal Code</div>
                        <Field
                            name='billingZIP'
                            component={inputField}
                            type='text'
                            placeholder={'Mailing Zip/Postal Code'}
                            required={true}
                              validate={validateZIPCode}
                        />
                    </div>
                    <div className='col-6 input-box text-left'>
                        <div className='label'>Mailing Country</div>
                        <Field
                            name='billingCountry'
                            component={inputField}
                            type='text'
                            placeholder={'Mailing Country'}
                            required={true}
                            //   validate={validateEmail}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default StaffAddressInfo
