import * as React from 'react';
import {Field, Form, reduxForm} from "redux-form";
import {inputField} from "../../shared/form-fields/formFields";
import {validateEmail, validateToken} from "../../login/loginValidator";

class VerifyEmailForm extends React.Component {

    render() {
        const {isLinkSent, resendLink, handleSubmit} = this.props;
        return (
            <Form onSubmit={handleSubmit}>
                {
                    !isLinkSent ?
                        <div className='row'>
                            <div
                                className='col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-12 form-container text-center'>
                                <div className='form'>
                                    <div className='col-12 input-box text-left'>
                                        <div className='label'>Email Address</div>
                                        <Field
                                            name="email"
                                            component={inputField}
                                            type="text"
                                            placeholder={'email address'}
                                            required={true}
                                            validate={validateEmail}
                                        />
                                    </div>
                                    <div className='col-lg-10 offset-lg-1 col-md-10 offset-md-1 col-sm-12 offset-sm-0'>
                                        <button type='submit' className='button submit'>
                                            <span className='text'>
                                                Send Reset Password Link
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div> :
                        <div className='row'>
                            <div
                                className='col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-12 form-container text-center'>
                                <div className='col-12 reset-token-note'>
                                    We sent an email with a token to help reset your password. Please enter the token
                                    below.
                                    If needed
                                    <span className='click-here' style={{cursor: 'pointer'}} onClick={resendLink}>
                                        &nbsp;click here&nbsp;
                                    </span>
                                    to email a new reset link.
                                </div>
                                <div className='form'>
                                    <div className='col-12 input-box text-left'>
                                        <div className='label'>Reset Token</div>
                                        <Field
                                            name="token"
                                            component={inputField}
                                            type="text"
                                            placeholder={'reset token'}
                                            required={true}
                                            validate={validateToken}
                                        />
                                    </div>
                                    <div className='col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-12'>
                                        <button type='submit' className='button submit'>
                                            <span className='text'>
                                                Verify
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                }
            </Form>
        );
    }

}

const VerifyForm = reduxForm({
    form: 'verifyEmailForm'
})(VerifyEmailForm);

export default VerifyForm;