import * as React from "react";
import * as classNames from "classnames";

class Pagination extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      recordCount: [10, 25, 50, 100, 200],
    };
  }

  onPageSizeChange = (event) => {
    const pageSize = event.target.value;
    this.props.onPageSizeChange(pageSize);
  };

  checkPreviousDisabled = () => {
    const pageSize = this.props.pageSize;
    const previousPageNumber = this.props.pageNumber;
    return previousPageNumber * pageSize <= pageSize
      ? previousPageNumber <= 0
        ? true
        : false
      : false;
  };

  checkNextDisabled = () => {
    const { pageSize, pageNumber } = this.props;
    return (pageNumber + 1) * pageSize < this.props.total ? false : true;
  };

  onPageChange = (pageNumber) => {
    this.props.onPageNumberChange(pageNumber - 1);
  };

  render() {
    const pageNumber = this.props.pageNumber + 1;
    return (
      <div className="row">
        <div className="col-lg-3 col-sm-1 col-6 record-count record-count-text-container">
          <span className="record-count-text">Record Count</span>
        </div>
        <div className="col-lg-2 col-sm-1 col-6 record-count record-count-list-container">
          <select
            className="select-box record-count-list"
            placeholder="Select Staff Record Type"
            onChange={this.onPageSizeChange}
            value={this.props.pageSize}
          >
            {this.state.recordCount.map((record) => {
              return (
                <option key={record} value={record}>
                  {record}
                </option>
              );
            })}
          </select>
        </div>

        <div className="col-lg-7 col-sm-12 page-number-container">
          <button
            className="page-number"
            onClick={() => {
              this.onPageChange(pageNumber - 1);
            }}
            disabled={this.checkPreviousDisabled()}
          >
            <i>
              <img src="./images/chevron-left.svg" alt="" />
            </i>
          </button>
          {!this.checkPreviousDisabled() && (
            <button
              className={classNames("page-number", {
                active: pageNumber === pageNumber - 1,
              })}
              onClick={() => {
                this.onPageChange(pageNumber - 1);
              }}
            >
              {pageNumber - 1}
            </button>
          )}
          <button
            className={classNames("page-number", {
              active: pageNumber === pageNumber,
            })}
            onClick={() => {
              this.onPageChange(pageNumber);
            }}
          >
            {pageNumber}
          </button>
          {!this.checkNextDisabled() && (
            <button
              className={classNames("page-number", {
                active: pageNumber === pageNumber + 1,
              })}
              onClick={() => {
                this.onPageChange(pageNumber + 1);
              }}
            >
              {pageNumber + 1}
            </button>
          )}
          <button
            className="page-number"
            onClick={() => {
              this.onPageChange(pageNumber + 1);
            }}
            disabled={this.checkNextDisabled()}
          >
            <i>
              <img src="./images/chevron-right.svg" alt="" />
            </i>
          </button>
        </div>
      </div>
    );
  }
}

export default Pagination;
