import { WORK_ENTRY } from "../../constants/actionTypes";

export function createWorkEntry(workEntry) {
  return {
    type: WORK_ENTRY.CREATE_WORK_ENTRY,
    workEntry,
  };
}

export function createWorkEntrySuccess(response) {
  return {
    type: WORK_ENTRY.CREATE_WORK_ENTRY_SUCCESS,
    response,
  };
}

export function createWorkEntryFail(error) {
  return {
    type: WORK_ENTRY.CREATE_WORK_ENTRY_FAIL,
    error,
  };
}

export function resetCreateWorkEntry() {
  return {
    type: WORK_ENTRY.RESET_CREATE_WORK_ENTRY,
  };
}

export function updateWorkEntry(workEntry) {
  return {
    type: WORK_ENTRY.UPDATE_WORK_ENTRY,
    workEntry,
  };
}

export function updateWorkEntrySuccess(response) {
  return {
    type: WORK_ENTRY.UPDATE_WORK_ENTRY_SUCCESS,
    response,
  };
}

export function updateWorkEntryFail(error) {
  return {
    type: WORK_ENTRY.UPDATE_WORK_ENTRY_FAIL,
    error,
  };
}

export function resetUpdateWorkEntry() {
  return {
    type: WORK_ENTRY.RESET_UPDATE_WORK_ENTRY,
  };
}

export function getAllWorkEntries(
  pageNumber,
  pageSize,
  filter,
  searchParams,
  entryType,
  staff,
  service,
  fromDate,
  toDate,
  dateRangeOption
) {
  return {
    type: WORK_ENTRY.GET_WORK_ENTRIES,
    pageNumber,
    pageSize,
    filter,
    searchParams,
    entryType,
    staff,
    service,
    fromDate,
    toDate,
    dateRangeOption
  };
}

export function getAllWorkEntriesSuccess(response) {
  return {
    type: WORK_ENTRY.GET_WORK_ENTRIES_SUCCESS,
    response,
  };
}

export function getAllWorkEntriesFail(error) {
  return {
    type: WORK_ENTRY.GET_WORK_ENTRIES_FAIL,
    error,
  };
}

export function resetGetAllWorkEntries() {
  return {
    type: WORK_ENTRY.RESET_GET_WORK_ENTRIES,
  };
}

export function getAllWorkEntriesByUserId(
  userId,
  pageNumber,
  pageSize,
  filter,
  searchParams
) {
  return {
    type: WORK_ENTRY.GET_WORK_ENTRIES_BY_USER_ID,
    userId,
    pageNumber,
    pageSize,
    filter,
    searchParams,
  };
}

export function getAllWorkEntriesByUserIdSuccess(response) {
  return {
    type: WORK_ENTRY.GET_WORK_ENTRIES_BY_USER_ID_SUCCESS,
    response,
  };
}

export function getAllWorkEntriesByUserIdFail(error) {
  return {
    type: WORK_ENTRY.GET_WORK_ENTRIES_BY_USER_ID_FAIL,
    error,
  };
}

export function resetGetAllWorkEntriesByUserId() {
  return {
    type: WORK_ENTRY.RESET_GET_WORK_ENTRIES_BY_USER_ID,
  };
}

export function setVisitStatus(visitStatus,duration){
  return{
    type:WORK_ENTRY.SET_VISIT_STATUS,
    visitStatus,duration
  }
}

export function setVisitDetails(visit){
  return{
    type:WORK_ENTRY.SET_VISIT_DETAILS,
    visit
  }
}

export function resetVisitDetails(){
  return{
    type:WORK_ENTRY.RESET_VISIT,
  }
}

