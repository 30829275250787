import * as React from "react";
import { Field, Form, reduxForm } from "redux-form";
import { inputField } from "../shared/form-fields/formFields";
import { validateEmail, validatePassword } from "./loginValidator";

class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
    };
  }

  onShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };

  render() {
    const { handleSubmit, loginError, checkIsRememberMe, onForgotPassword } =
      this.props;
    return (
      <Form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-12 form-container text-center">
            <div className="form">
              <div className="col-12 input-box text-left">
                <div className="label">Email</div>
                <Field
                  name="email"
                  component={inputField}
                  type="text"
                  placeholder={"email"}
                  required={true}
                  validate={validateEmail}
                />
              </div>
              <div className="col-12 input-box text-left">
                <div className="label">Password</div>
                <Field
                  name="password"
                  component={inputField}
                  type={this.state.showPassword ? "text" : "password"}
                  placeholder={"password"}
                  required={true}
                  validate={validatePassword}
                />
                <i
                  style={{ position: "absolute", top: 40, right: 20 }}
                  onClick={this.onShowPassword}
                >
                  {this.state.showPassword && (
                    <img src="./images/eye-off.svg" alt="" />
                  )}
                  {!this.state.showPassword && (
                    <img src="./images/eye.svg" alt="" />
                  )}
                </i>
              </div>
              {loginError && (
                <div className="col-12">
                  <p className="error-message text-right">
                    <i>
                      <img src="./images/alert-circle.svg" alt="" />
                    </i>
                    &nbsp;Your email id and password didn’t match our records.
                    Please try again
                  </p>
                </div>
              )}
              <div className="row">
                <div className="col-6 text-left remember-me">
                  <input
                    type="checkbox"
                    className="remember-me-checkbox"
                    name="rememberme"
                    value="rememberme"
                    onClick={checkIsRememberMe}
                  />
                  <span className="remember-me-text">Remember me</span>
                </div>
                {/* <div className="col-6 text-right forgot-password">
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={onForgotPassword}
                  >
                    Forgot Password
                  </span>
                </div> */}
              </div>
              <br />
              <div className="row">
                <div className="col-6 offset-3">
                  <button type="submit" className="button submit">
                    <span className="text">Login</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
    );
  }
}

const authForm = reduxForm({
  form: "loginForm",
})(LoginForm);

export default authForm;
