import { SERVICE } from "../../constants/actionTypes";

export const serviceReducer = (state = {}, action) => {
  switch (action.type) {
    case SERVICE.CREATE_CLIENT_SERVICE_SUCCESS:
      return { ...state, createSuccess: action.response };
    case SERVICE.CREATE_CLIENT_SERVICE_FAIL:
      return { ...state, createFail: action.error };
    case SERVICE.RESET_CREATE_CLIENT_SERVICE:
      return { ...state, createSuccess: null, createFail: null };
    case SERVICE.UPDATE_CLIENT_SERVICE_SUCCESS:
      return { ...state, updateSuccess: action.response };
    case SERVICE.UPDATE_CLIENT_SERVICE_FAIL:
      return { ...state, updateFail: action.error };
    case SERVICE.RESET_UPDATE_CLIENT_SERVICE:
      return { ...state, updateFail: null, updateSuccess: null };
    default:
      return state;
  }
};
