import { USER } from "../../constants/actionTypes";

export const userReducer = (state = {}, action) => {
  switch (action.type) {
    case USER.CREATE_USER_SUCCESS:
      return { ...state, createSuccess: action.response };
    case USER.CREATE_USER_FAIL:
      return { ...state, createFail: action.error };
    case USER.RESET_CREATE_USER:
      return { ...state, createSuccess: null, createFail: null };
    case USER.UPDATE_USER_SUCCESS:
      return { ...state, updateSuccess: action.response };
    case USER.UPDATE_USER_FAIL:
      return { ...state, updateFail: action.error };
    case USER.RESET_UPDATE_USER:
      return { ...state, updateFail: null, updateSuccess: null };
    case USER.GET_USERS_SUCCESS:
      return { ...state, getAllSuccess: action.response };
    case USER.GET_USERS_FAIL:
      return { ...state, getAllFail: action.error };
    case USER.RESET_GET_USERS:
      return { ...state, getAllSuccess: null, getAllFail: null };
    case USER.GET_USERS_BY_TYPE_SUCCESS:
      return { ...state, getAllSuccess: action.response };
    case USER.GET_USERS_BY_TYPE_FAIL:
      return { ...state, getAllFail: action.error };
    case USER.RESET_GET_USERS_BY_TYPE:
      return { ...state, getAllSuccess: null, getAllFail: null };
    case USER.GET_SCS_SUCCESS:
      return { ...state, getSCSuccess: action.response };
    case USER.GET_SCS_FAIL:
      return { ...state, getSCFail: action.error };
    case USER.RESET_SCS:
      return { ...state, getSCSuccess: null, getSCFail: null };
    case USER.UPDATE_SC_SUCCESS:
      return { ...state, updateSCSuccess: action.response };
    case USER.UPDATE_SC_FAIL:
      return { ...state, updateSCFail: action.error };
    case USER.RESET_UPDATE_SC:
      return { ...state, updateSCFail: null, updateSCSuccess: null };
    case USER.CREATE_SC_SUCCESS:
      return { ...state, createSCSuccess: action.response };
    case USER.CREATE_SC_FAIL:
      return { ...state, createSCFail: action.error };
    case USER.RESET_SC_USER:
      return { ...state, createSCSuccess: null, createSCFail: null };
    default:
      return state;
  }
};
