import { isEmpty } from "lodash";

const phoneNumberRegEx =
  /^(1s?)?((([0-9]{3}))|[0-9]{3})[s-]?[\0-9]{3}[s-]?[0-9]{4}$/;
const specialCharRegEx = /[!@#$%^&*()_+|~=`{}\[\]:";'<>?,.\/]/;
const zipRegEx = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
const characterRegEx = /^[A-Za-z]+$/;

export function validateFirstName(value) {
  return isEmpty(value)
    ? "First name is required"
    : hasSpecialCharacters(value);
}

export function validateLastName(value) {
  return isEmpty(value) ? "Last name is required" : hasSpecialCharacters(value);
}

export function validateMiddleName(value) {
  return isEmpty(value) ? undefined : hasSpecialCharacters(value);
}

export function validateSalutation(value) {
  return !isEmpty(value) && !characterRegEx.test(value)
    ? "Can contain only letters."
    : undefined;
}

export function validateSuffix(value) {
  return !isEmpty(value) && !characterRegEx.test(value)
    ? "Can contain only letters."
    : undefined;
}
export function validateMobileNumber(value) {
  return isEmpty(value) ? "Mobile number is required" : undefined;
}

export function validateUserPercentage(value) {
  return parseFloat(value) < 0
    ? "Percentage should be greater than 0"
    : undefined;
}

function hasSpecialCharacters(value) {
  return specialCharRegEx.test(value)
    ? "Can not contain special characters"
    : undefined;
}

export function validateZIPCode(zip) {
  if (!isEmpty(zip) && !zipRegEx.test(zip)) {
    return "Invalid ZIP Code";
  }
  return undefined;
}
