import React from "react";
import "./createTimeSheet.scss";
import CreateTimeSheetForm from "../create-timesheet/create-timesheet-form/createTimeSheetForm";

class CreateTimeSheet extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 0,
        };
    }

    render() {
        return (
            <div className="client-client">
                <div className="row">
                    <div className="row col-lg-2 col-md-2 col-sm-4" onClick={() => {
                        this.props.history.goBack()
                    }}>
                        <img src="./images/chevron-left-green.svg" alt=""/>
                        <span style={{fontFamily: "Muli", color: '#333333', fontSize: 15, padding: 10}}>Back</span>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-12" style={{marginBottom:15}}>
                        <div className="page-title">Timesheets</div>
                    </div>
                    {/* <div className="col-lg-1 offset-lg-7 col-md-2 offset-md-4 col-sm-12">
            <button className="button submit">
              <span className="text">SUBMIT</span>
            </button>
          </div> */}
                </div>
                {/* <div className="row">
          <span
            className={classNames("tabs", {
              active: this.state.activeTab === 0,
            })}
            onClick={() => {
              this.setState({ activeTab: 0 });
            }}
          >
            Details
          </span>
          <span
            className={classNames("tabs", {
              active: this.state.activeTab === 1,
            })}
            onClick={() => {
              this.setState({ activeTab: 1 });
            }}
          >
            Historical Entries Submited by: DMS Admin
          </span>
        </div> */}
                <div className="row">
                    <CreateTimeSheetForm/>
                </div>
            </div>
        );
    }
}

export default CreateTimeSheet;
