import { isEmpty, isUndefined } from "lodash";

const specialCharRegEx = /[!@#$%^&*()_+|~=`{}\[\]:";'<>?,.\/]/;
const zipRegEx = /(^\d{5}$)|(^\d{5}-\d{4}$)/;

export function validateClientName(value) {
  return isEmpty(value) ? "Client Name is required" : undefined;
}

export function validateClientFirstName(value) {
  return isEmpty(value)
    ? "Client First name is required"
    : hasSpecialCharacters(value);
}

export function validateClientMiddleName(value) {
  return hasSpecialCharacters(value);
}

export function validateClientLastName(value) {
  return isEmpty(value)
    ? "Client Last name is required"
    : hasSpecialCharacters(value);
}

export function validateClientServiceName(value) {
  return isEmpty(value) ? "Service Name is required" : undefined;
}

export function validateClientServiceCode(value) {
  return isEmpty(value) ? "Service Code is required" : undefined;
}

export function validateClientServiceStartDate(value) {
  return isUndefined(value) ? "Service Start Date is required" : undefined;
}

export function validateClientServiceEndDate(value) {
  return isUndefined(value) ? "Service End Date is required" : undefined;
}

export function validateClientServiceUnitRate(value) {
  return isUndefined(value)
    ? "Service Unit Rate is required"
    : validateNumericFieldValues(value);
}

export function validateClientServiceUnitsAuthorized(value) {
  return isUndefined(value)
    ? "Service Units Authorized is required"
    : validateNumericFieldValues(value);
}

export function validateClientServiceUnitLength(value) {
  return isUndefined(value)
    ? "Service Units Length is required."
    : (parseFloat(value) > 0 ? undefined : "The value should be greater than zero.");
}

export function validateFamilyContactEmail(email) {
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!isEmpty(email) && !emailRegex.test(email)) {
    return "Email is invalid";
  }
  return undefined;
}

export function validateMobileNumber(value) {
  return isEmpty(value) ? "Phone number is required" : undefined;
}

export function validateRequiredFields(value) {
  return isEmpty(value) ? "Field cannot be empty" : undefined;
}

function hasSpecialCharacters(value) {
  return specialCharRegEx.test(value)
    ? "Can not contain special characters"
    : undefined;
}

export function validateMCI(value) {
  if (isEmpty(value)) {
    return "Field cannot be empty";
  }
  if (value.length > 10) {
    return "MCI should be 10 or less characters long";
  } else {
    return undefined;
  }
}

export function validateZIPCode(zip) {
  if (isEmpty(zip)) {
    return "Field cannot be empty";
  }
  if (!isEmpty(zip) && !zipRegEx.test(zip)) {
    return "Invalid ZIP Code";
  }
  return undefined;
}

export function validateNumericFieldValues(value) {
  return parseFloat(value) < 0 ? "The values can not be negative." : undefined;
}

export function validateOverlapOption(value) {
  return (value==="")
    ? "Overlap Option is required"
    : undefined;
}
