import * as React from "react";
import {withRouter} from "react-router-dom";
import './validations-box.scss'

class ValidationsBox extends React.Component {

    render() {
        return (
            <div className={'validation-container'}>
                <div className="row">
                    <img src={ !this.props.dirty ? '/images/check-grey.svg' : this.props.validLength ? '/images/check-green.svg' : '/images/alert-circle.svg'} alt=""/>
                    <span className={this.props.validLength || !this.props.dirty ? 'text-description': 'text-error'}>At least 8 characters long</span>
                </div>
                <div className="row">
                    <img src={ !this.props.dirty ? '/images/check-grey.svg' : this.props.validLength ? '/images/check-green.svg' : '/images/alert-circle.svg'} alt=""/>
                    <span className={this.props.validCharacters || !this.props.dirty ? 'text-description': 'text-error'}>Contains at least 1 upper case & lower case character</span>
                </div>
                <div className="row">
                    <img src={ !this.props.dirty ? '/images/check-grey.svg' : this.props.validLength ? '/images/check-green.svg' : '/images/alert-circle.svg'} alt=""/>
                    <span className={this.props.validNumbers || !this.props.dirty ? 'text-description': 'text-error'}>Contains at least 1 number</span>
                </div>
                <div className="row">
                    <img src={ !this.props.dirty ? '/images/check-grey.svg' : this.props.validLength ? '/images/check-green.svg' : '/images/alert-circle.svg'} alt=""/>
                    <span className={this.props.validSpecialCharacters || !this.props.dirty ? 'text-description': 'text-error'}>Contains at least 1 special character such as (!@#%&?)</span>
                </div>
            </div>
        )
    }
}

export default withRouter(ValidationsBox);





