export const USER_TYPES = {
  SUPER_ADMIN: 0,
  ADMIN: 1,
  STAFF: 2,
  BEHAVIORAL_SPECIALIST: 3,
  SUPPORT_COORDINATOR: 4,
  CUSTOMER: 5,
  FAMILY_CONTACT: 6,
  OTHER: 7
};
