import * as React from "react";
import { Field, Form, reduxForm } from "redux-form";
import {
  inputField,
  textField
} from "../../shared/form-fields/formFields";
import { withRouter } from "react-router-dom";
import {
  validateClientServiceCode,
  validateClientServiceName,
  validateClientServiceUnitLength,
  validateClientServiceUnitRate
} from "../../clients/create-client/create-client-form/clientValidator";

class ServiceCreateUpdate extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { handleSubmit } = this.props;

    return (
      <Form style={{ width: "100%" }} onSubmit={handleSubmit} noValidate>
        <div style={{ margin: 30, marginTop: 0 }}>
          <div className="row">
            <div className="col-6 input-box text-left">
              <div className="label">Service Name</div>
              <Field
                name="serviceName"
                component={inputField}
                type="text"
                placeholder={"Service Name"}
                required={true}
                validate={validateClientServiceName}
              />
            </div>
            <div className="col-6 input-box text-left">
              <div className="label">Service Code</div>
              <Field
                name="serviceCode"
                component={inputField}
                type="text"
                placeholder={"Service Code"}
                required={true}
                validate={validateClientServiceCode}
              />
            </div>
            <div className="col-12 input-box text-left">
              <div className="label">Description</div>
              <Field
                name="serviceDescription"
                component={textField}
                type="text"
                placeholder={"Description"}
                required={true}
                //   validate={validateEmail}
              />
            </div>
            <div className="col-6 input-box text-left">
              <div className="label">Unit Rate</div>
              <Field
                name="serviceUnitRate"
                component={inputField}
                type="number"
                placeholder={"Unit Rate"}
                required={true}
                minVal={0}
                validate={validateClientServiceUnitRate}
              />
            </div>
            <div className="col-6 input-box text-left">
              <div className="label">Unit Length (hrs.)</div>
              <Field
                name="serviceUnitLength"
                component={inputField}
                type="number"
                placeholder={"Unit Length"}
                required={true}
                minVal={0}
                validate={validateClientServiceUnitLength}
              />

            </div>

            <div className="col-6 input-box text-left my-auto">
              <input
                type="checkbox"
                name="degreeRequired"
                value="degreeRequired"
                checked={this.props.isDegreeRequired}
                onChange={(event) => {
                  this.props.onDegreeRequired(event.target.checked);
                }}
                style={{ marginRight: 5 }}
              />
              <span className="label">Degree Required</span>
            </div>
            <div className="col-6 input-box text-left my-auto">
              <input
                type="checkbox"
                name="degreeRequired"
                value="degreeRequired"
                checked={this.props.isOverlappable}
                onChange={(event) => {
                  this.props.onOverlapable(event.target.checked);
                }}
                style={{ marginRight: 5 }}
              />
              <span className="label">Overlappable Service</span>
            </div>
          </div>

          <div className="row" style={{marginTop:30}}>
            <div className="col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-sm-10 offset-sm-1">
              <div className="row">
                <div className="col-6">
                  <button type="submit" className="button submit">
                    {this.props.isEdit ? "UPDATE" : "SAVE"}
                  </button>
                </div>
                <div className="col-6">
                  <button
                    className="button basic"
                    onClick={(event) => {
                      this.props.onCloseModal();
                    }}
                  >
                    CANCEL
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
    );
  }
}

const serviceCreateUpdateForm = reduxForm({
  form: "createService",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(ServiceCreateUpdate);

export default withRouter(serviceCreateUpdateForm);
// export default ClientServiceInfo;
