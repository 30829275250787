import React from "react";

class SelectCell extends React.Component {

    render() {
        const {value} = this.props;
        return (
            <div className='cell select-cell text-center'>
                {value && <img src="./images/verified.svg" alt={""}/>}
            </div>
        );
    }
}

export default SelectCell;