import * as React from 'react';
import '../data-grid.scss';

class ClickableCell extends React.Component {

    onClick = (event) => {
        this.props.onClick(this.props.actionId, event)
    };

    render() {
        return (
          <div className='cell action-cell'>
              <div className='col-12 text-center' onClick={this.onClick}>
                  <img src={this.props.icon} alt="" />
              </div>
          </div>
        );
    }
}

export default ClickableCell;