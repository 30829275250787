import * as React from 'react';

class ClockInOut extends React.Component {
    render() {
        const value = this.props.value.original && this.props.value.original.status === 6 ? `-` : this.props.value.original.clockOut
        return (
            <div className='cell basic-cell'>
                <div className='overflow'>{value}</div>
            </div>
        );
    }
}

export default ClockInOut;
