import React from 'react';
import DataGrid from "../../shared/data-grid/data-grid";
import BasicHeader from "../../shared/data-grid/custom-headers/basic-header";
import {map} from 'lodash';
import moment from 'moment';
import {DATE_TIME_FORMAT} from '../../../constants/appConstants';
import ReportCell from '../../shared/data-grid/custom-cells/report-cell';

class NoteReportView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            reportData: []
        }
    }

    componentWillReceiveProps(nextProps) {
        this.mapRecords(nextProps.reportData)
    }

    componentDidMount() {
        this.mapRecords(this.props.reportData)
    }

    mapRecords(reports) {
        const reportData = [];
        map(reports, (data => {
            const report = {
                accName: data.clientName,
                staffName: data.staffName,
                wName: data.entryName,
                sName: data.serviceName,
                sTime: moment(data.serviceStartTime).format(DATE_TIME_FORMAT),
                eTime: moment(data.serviceEndTime).format(DATE_TIME_FORMAT),
                state: data.billingState,
                modDate: moment(data.updatedDate).format(DATE_TIME_FORMAT),
                notes: data.notes
            }
            reportData.push(report)
        }));
        this.setState({
            reportData: reportData
        })
    }


    render() {
        const columns = [
            {
                Header: () => <BasicHeader header={"Account Name"} fontSize={12}/>,
                accessor: "accName",
                Cell: (row) => {
                    return <ReportCell value={row.value} index={row.index}/>;
                },
            },
            {
                Header: () => <BasicHeader header={"Staff Name"} fontSize={12}/>,
                accessor: "staffName",
                Cell: (row) => {
                    return <ReportCell value={row.value} index={row.index}/>;
                },
            },
            {
                Header: () => <BasicHeader header={"Work Entry Name"} fontSize={12}/>,
                accessor: "wName",
                Cell: (row) => {
                    return <ReportCell value={row.value} index={row.index}/>;
                },
            },
            {
                Header: () => <BasicHeader header={"Service Name"} fontSize={12}/>,
                accessor: "sName",
                Cell: (row) => <ReportCell value={row.value} index={row.index}/>,
            },
            {
                Header: () => <BasicHeader header={"Start Time"} fontSize={12}/>,
                accessor: "sTime",
                Cell: (row) => <ReportCell value={row.value} index={row.index}/>,
            },
            {
                Header: () => <BasicHeader header={"End Time"} fontSize={12}/>,
                accessor: "eTime",
                Cell: (row) => <ReportCell value={row.value} index={row.index}/>,
            },
            {
                Header: () => <BasicHeader header={"Billing State"} fontSize={12}/>,
                accessor: "state",
                Cell: (row) => <ReportCell value={row.value} index={row.index}/>,
            },
            {
                Header: () => <BasicHeader header={"Last Modified Date"} fontSize={12}/>,
                accessor: "modDate",
                Cell: (row) => <ReportCell value={row.value} index={row.index}/>,
            },
            {
                Header: () => <BasicHeader header={"Notes"} fontSize={12}/>,
                accessor: "notes",
                Cell: (row) => <ReportCell value={row.value} index={row.index}/>,
                width: 300,
            }
        ];
        return (
            <DataGrid
                data={this.state.reportData}
                columns={columns}
                onRowClick={(rowInfo) => {
                    // this.onDetails(get(rowInfo.original, "id", ""));
                }}
                onPageSizeChange={this.props.onPageSizeChange}
                onPageNumberChange={this.props.onPageNumberChange}
                total={this.props.total}
                paginationLabel={"Organizations per page"}
                showPagination={true}
                pageSize={this.props.pageSize}
                pageNumber={this.props.pageNumber}
            />
        );
    }
}

export default NoteReportView;
