import React from "react";
import CreateStaffForm from "./create-sc-form/createSC";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { createSC, updateSC } from "../../../redux/users/userActions";
import { getQueryParameter } from "../../../util/url";
import {
  clone,
  get,
  isEmpty,
  isEqual,
  isNull,
  isUndefined,
  remove,
} from "lodash";
import { ALERT_TYPES } from "../../../constants/appConstants";
import { NotificationManager } from "react-notifications";
import { reset } from "redux-form";

import { getSCById } from "../../../api/user";

class CreateSC extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: null,
      isEditMode: false,
      selectedUserId: "",
      hasRelevantDegree: false,
      isAuthorized: false,
      staffType: 2,
      credentials: [],
      routeToStaff: false,
      fileList: [],
      status: 4,
    };
  }

  componentDidMount() {
    const userId = getQueryParameter("id");
    const userType = getQueryParameter("staffType");
    if (!isEmpty(userType) && !isUndefined(userType)) {
      this.setState({
        staffType: userType,
      });
    }
    if (!isEmpty(userId) && !isUndefined(userId)) {
      getSCById(userId).then((res) => {
        const user = this.mapUser(res);
        this.setState({
          initialValues: user,
          isEditMode: true,
          selectedUserId: userId,
          staffType: res.userType,
          hasRelevantDegree: res.hasRelevantDegree,
          isAuthorized: res.isAuthorized,
          credentials: res.credentials,
          fileList: res.files,
          status: res.status,
        });
      });
    }
  }

  createUser = (values, routeToStaff) => {
    this.setState({
      routeToStaff: routeToStaff,
    });
    values["status"] = this.state.status;
    let user = this.mapUser(values);
    user.dob = user.dob.toISOString().slice(0, 10);
    user.files = this.state.fileList;
    if (!this.state.isEditMode) {
      this.props.dispatch(createSC(user));
    } else {
      user["scId"] = parseInt(this.state.selectedUserId);
      this.props.dispatch(updateSC(user));
    }
  };

  componentWillReceiveProps = (nextProps) => {
    const { history } = this.props;
    if (
      !isNull(get(nextProps, "createUserSuccess")) &&
      !isEqual(nextProps.createUserSuccess, this.props.createUserSuccess)
    ) {
      this.createNotification(ALERT_TYPES.SUCCESS, "Staff create successful");
      if (this.state.routeToStaff) {
        history.push(`/admin/view-sc?id=${nextProps.createUserSuccess.userId}`);
      } else {
        this.props.dispatch(reset("addSC"));
      }
      // history.push(`/view-sc?id=${nextProps.createUserSuccess.scId}`);
    }

    if (
      !isNull(get(nextProps, "createUserFail")) &&
      !isEqual(nextProps.createUserFail, this.props.createUserFail)
    ) {
      this.createNotification(
        ALERT_TYPES.ERROR,
        nextProps.createUserFail.message
      );
    }
    if (
      !isNull(get(nextProps, "updateUserSuccess")) &&
      !isEqual(nextProps.updateUserSuccess, this.props.updateUserSuccess)
    ) {
      this.createNotification(ALERT_TYPES.SUCCESS, "Staff update successful");
      history.push(`/admin/view-sc?id=${this.state.selectedUserId}`);
    }

    if (
      !isNull(get(nextProps, "updateUserFail")) &&
      !isEqual(nextProps.updateUserFail, this.props.updateUserFail)
    ) {
      this.createNotification(ALERT_TYPES.ERROR, "Staff update failed");
    }
  };

  createNotification(alertType, alertMessage) {
    switch (alertType) {
      case ALERT_TYPES.INFO:
        NotificationManager.info(alertMessage, "", 2000);
        break;
      case ALERT_TYPES.SUCCESS:
        NotificationManager.success(alertMessage, "", 2000);
        break;
      case ALERT_TYPES.WARNING:
        NotificationManager.warning(alertMessage, "", 2000);
        break;
      case ALERT_TYPES.ERROR:
        NotificationManager.error(alertMessage, "", 2000);
        break;
      default:
        break;
    }
  }

  mapUser = (userData) => {
    let client = {
      firstName: get(userData, "firstName", ""),
      middleName: get(userData, "middleName", ""),
      lastName: get(userData, "lastName", ""),
      email: isEmpty(get(userData, "email", "")) ? null : userData.email,
      password: get(userData, "password", ""),
      userType: parseInt(this.state.staffType),
      reportTo: 0,
      salutation: get(userData, "salutation", ""),
      suffix: get(userData, "suffix", ""),
      dob: new Date(get(userData, "dob", new Date())),
      title: get(userData, "title", ""),
      phone: get(userData, "phone", ""),
      mobile: get(userData, "mobile", ""),
      memo: get(userData, "memo", ""),
      percentagePaid: parseFloat(get(userData, "percentagePaid", 0)),
      billingStreet: get(userData, "billingStreet", ""),
      billingCity: get(userData, "billingCity", ""),
      billingState: get(userData, "billingState", ""),
      billingZIP: get(userData, "billingZIP", ""),
      billingCountry: get(userData, "billingCountry", ""),
      company: get(userData, "company", ""),
      entity: get(userData, "entity", ""),
      orgAuthentication: get(userData, "orgAuthentication", ""),
      isAuthorized: this.state.isAuthorized,
      status: parseInt(this.state.status),
    };
    return client;
  };

  render() {
    return (
      <div className="client-client">
        <div className="row">
          <div
            className="row col-lg-2 col-md-2 col-sm-4"
            onClick={() => {
              this.props.history.goBack();
            }}
          >
            <img src="./images/chevron-left-green.svg" alt="" />
            <span
              style={{
                fontFamily: "Muli",
                color: "#333333",
                fontSize: 15,
                padding: 10,
              }}
            >
              Back
            </span>
          </div>
        </div>
        <div className="row" style={{ marginBottom: 40 }}>
          <div
            className="col-lg-6 col-md-8 col-sm-12 page-title"
            style={{ wordBreak: "break-word" }}
          >
            {!this.state.isEditMode
              ? "Create Supports Coordinator"
              : "Edit Supports Coordinator"}
          </div>
        </div>
        <div className="row">
          <CreateStaffForm
            onSubmit={this.createUser}
            userType={this.state.staffType}
            credentials={this.state.credentials}
            initialValues={this.state.initialValues}
            hasRelevantDegree={this.state.hasRelevantDegree}
            isEditMode={this.state.isEditMode}
            staffStatus={this.state.status}
            isAuthorized={this.state.isAuthorized}
            onHasRelevantDegree={(value) => {
              this.setState({ hasRelevantDegree: value });
            }}
            onIsAuthorized={(value) => {
              this.setState({ isAuthorized: value });
            }}
            onCredentialsSelect={(value) => {
              this.setState({ credentials: value });
            }}
            onStatusChange={(status) => {
              this.setState({ status: status });
            }}
            onChange={this.handleFileChange}
            fileList={this.state.fileList}
            onFileUpload={(value, success) => {
              if (success) {
                let files = clone(this.state.fileList);
                files.push(value);
                this.createNotification(ALERT_TYPES.INFO, "File uploaded.");
                this.setState({
                  fileList: files,
                });
              } else {
                this.createNotification(
                  ALERT_TYPES.WARNING,
                  "File upload fail."
                );
              }
            }}
            onRemoveImage={(documentKey) => {
              let files = clone(this.state.fileList);
              remove(files, function (o) {
                return o.documentKey == documentKey;
              });
              this.setState({
                fileList: files,
              });
            }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  createUserSuccess: state.user.createSCSuccess,
  createUserFail: state.user.createSCFail,
  updateUserSuccess: state.user.updateSCSuccess,
  updateUserFail: state.user.updateSCFail,
  users: state.user.getSCSuccess,
});

export default withRouter(connect(mapStateToProps)(CreateSC));
