import * as React from 'react';

class BasicCell extends React.Component {
    render() {
        // const value = this.props.maxLength ? `${this.props.value.split()}`
        return (
            <div className='cell basic-cell'>
                <div className='overflow'>{this.props.value}</div>
            </div>
        );
    }
}

export default BasicCell;
