import React from "react";
import moment from "moment";
import * as _ from "lodash";
import { STAFF_STATUS } from "../../../constants/appConstants";

export default class SCFilter extends React.Component {
  render() {
    return (
      <div className="row">
        <div className="col-md-2 col-12 input-box" style={{ marginBottom: 10 }}>
          <div className="label">Status</div>
          <div>
            <select
              className="select-box"
              onChange={this.props.onStatusChange}
              // value={this.state.staffStatus}
            >
              <option value={STAFF_STATUS.ALL}>All</option>
              <option value={STAFF_STATUS.ACTIVE}>Active</option>
              <option value={STAFF_STATUS.INACTIVE}>Inactive</option>
            </select>
          </div>
        </div>
      </div>
    );
  }
}
