import mobileDetect from 'mobile-detect';
import * as _ from 'lodash';


const md = new mobileDetect(window.navigator.userAgent);
export function isMobileDevice() {
    return !_.isNull(md.phone());
}

export function isTablet(){
    return !_.isNull(md.tablet())
}
