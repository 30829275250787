// // todo-done : remove this file and do the implementation on verify-otp.jsx file
// // todo-done : the email must be stored on redux store not local store!!
// // todo-done : if the user email is not in the redux store the page should be redirected into the login page!
// // todo-done : do not use the notification toast for validation messages!!! refer the invision design - ex: invalid token

import * as React from "react";
import Footer from "../shared/footer/footer";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { Form, reduxForm } from "redux-form";
import OtpInput from "react-otp-input";
import {
  resendOtp,
  resetOtpReset,
  verifyOtp,
} from "../../redux/auth/authAction";
import { NotificationManager } from "react-notifications";

class VerifyOTP extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      enteredOtp: "",
      wrongOtp: false,
      sendNewOtp: false,
    };
  }

  componentDidMount = () => {
    const { history } = this.props;
    if (this.props.auth.otpEmail === undefined) {
      history.push("/");
    }
  };

  componentWillReceiveProps = (nextProps) => {
    const { history } = this.props;

    if (nextProps.auth.otpVerified) {
      history.push("/save-browser");
    } else if (
      nextProps.auth.resendOtp == true &&
      nextProps.auth.otpVerified == false
    ) {
      this.setState({ wrongOtp: false, enteredOtp: "", sendNewOtp: true });
    } else if (
      nextProps.auth.resendOtp == true &&
      nextProps.auth.otpVerified == false
    ) {
      this.setState({ wrongOtp: true, sendNewOtp: false });
    } else if (nextProps.auth.otpVerified == false) {
      this.setState({ wrongOtp: true, enteredOtp: "", sendNewOtp: false });
    } else if (
      nextProps.auth.resendOtp == true &&
      nextProps.auth.otpVerified == null
    ) {
      this.setState({ wrongOtp: false, enteredOtp: "", sendNewOtp: true });
    } else {
      this.setState({ wrongOtp: false, enteredOtp: "", sendNewOtp: false });
    }
  };

  newOtpSend = () => {
    const { dispatch } = this.props;
    dispatch(resendOtp(this.props.auth.otpEmail));
  };

  wrongOtpSend = () => {
    this.setState({ enteredOtp: "", wrongOtp: true });
  };

  onSubmit = () => {
    const { dispatch } = this.props;
    if (this.state.enteredOtp.length < 6) {
      NotificationManager.warning("OTP must be 6 digit", "", 2000);
    } else if (this.state.enteredOtp.length == 6) {
      dispatch(verifyOtp(this.state.enteredOtp, this.props.auth.otpEmail));
    }
  };

  onFocus = () => {
    const { dispatch } = this.props;
    this.setState({
      wrongOtp: false,
      sendNewOtp: false,
    });
    dispatch(resetOtpReset());
  };

  render() {
    return (
      <div>
        <nav
          className="navbar navbar-expand navbar-white navbar-light main-nav"
          style={{ position: "fixed", top: 0, right: 0, left: 0 }}
        >
          <ul className="navbar-nav">
            <li className="nav-item">
              <img src="./images/logo.svg" alt="" />
              <a className="text-center">
                <span className="navigation-title title">HCBS</span>
              </a>
            </li>
          </ul>
        </nav>
        <div className="login">
          <div className="row">
            <div
              className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-12 text-center title"
              style={{ height: "auto" }}
            >
              <span>Enter Verification Code</span>
            </div>
          </div>
          {/* <OtpInputForm /> */}

          <Form>
            <div className="row">
              <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-12 form-container text-center">
                <div className="form">
                  {this.state.wrongOtp && (
                    <div
                      className="col-12 reset-token-note"
                      style={{
                        backgroundColor: "#FA7B6F",
                        color: "#FAFBFC",
                      }}
                    >
                      The verification code entered is incorrect. Please try
                      again or request a new code by clicking Resend link below.
                    </div>
                  )}

                  {this.state.sendNewOtp && (
                    <div
                      className="col-12 reset-token-note"
                      style={{
                        backgroundColor: "rgba(53,204,98,0.13)",
                        color: "#35CC62",
                      }}
                    >
                      A new verification code has been sent.
                    </div>
                  )}

                  <div className="col-12 reset-token-note">
                    <span>For your security we sent an SMS with a</span>
                    <br />
                    <span>6-digit code to your phone.</span>
                  </div>

                  <div className="col-12 input-box text-left">
                    <div
                      className="label text-center"
                      style={{
                        color: "#333333",
                        fontSize: 16,
                        marginTop: 16,
                        marginBottom: 16,
                      }}
                    >
                      This code is valid only for 10 minutes.
                    </div>
                    <div
                      onFocus={this.onFocus}
                      style={{
                        display: "flex",
                        paddingTop: 20,
                        paddingBottom: 20,
                        justifyContent: "center",
                        textAlign: "center",
                        alignSelf: "center",
                        alignItems: "center",
                      }}
                    >
                      <OtpInput
                        isInputNum={true}
                        inputStyle={{
                          height: 50,
                          width: 50,
                        }}
                        value={this.state.enteredOtp}
                        onChange={(otp) => {
                          this.setState({ enteredOtp: otp });
                        }}
                        numInputs={6}
                        separator={<div style={{ paddingRight: 10 }}></div>}
                      />
                    </div>
                  </div>

                  <br />
                  <div className="row">
                    <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1 col-sm-12">
                      <button
                        onClick={this.onSubmit}
                        type="button"
                        className="button submit"
                      >
                        <span className="text">VERIFY</span>
                      </button>
                    </div>
                  </div>

                  <div className="col-12 reset-token-note">
                    Didn’t receive a code?
                    <span
                      className="click-here"
                      style={{ cursor: "pointer" }}
                      onClick={this.newOtpSend}
                    >
                      &nbsp;Resend&nbsp;
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </div>
        <Footer />
      </div>
    );
  }
}

const EnterOtpForm = reduxForm({
  form: "otpInputForm",
})(VerifyOTP);

const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default withRouter(connect(mapStateToProps)(EnterOtpForm));
