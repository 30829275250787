import moment from "moment-timezone";
import { geoCodeAddress } from "../api/maps";
import { get, isEmpty } from "lodash";
import CryptoJS from "crypto-js";

export function trucateStringLength(value) {
  if (value.length > 10) {
    value = value.substring(0, 10) + "...";
  }
  return value;
}

export function getUserStatus(status) {
  if (status === 4) {
    return "Active";
  } else if (status === 5) {
    return "Inactive";
  }
}

export function getTimeZone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export function formatDateTime(dateObj, format) {
  return moment(dateObj).tz(getTimeZone()).format(format);
}

export function formatSSN(v) {
  let ssnValue = v;
  ssnValue = ssnValue.replace(/\D/g, "");
  ssnValue = ssnValue.replace(/^(\d{3})/, "$1-");
  ssnValue = ssnValue.replace(/-(\d{2})/, "-$1-");
  ssnValue = ssnValue.replace(/(\d)-(\d{4}).*/, "$1-$2");
  return ssnValue;
}

export function getGeoCodes(address) {
  geoCodeAddress(address)
    .then((res) => {
      const geoLocation = !isEmpty(res)
        ? get(res[0], "geometry.location", null)
        : null;
      if (geoLocation) {
        return {
          lat: geoLocation.lat,
          lng: geoLocation.lng,
        };
      }
    })
    .catch((err) => {
      console.log("Geo coding error ", err);
    });
}

export function base64url(source) {
  // Encode in classical base64
  let encodedSource = CryptoJS.enc.Base64.stringify(source);

  // Remove padding equal characters
  encodedSource = encodedSource.replace(/=+$/, "");

  // Replace characters according to base64url specifications
  encodedSource = encodedSource.replace(/\+/g, "-");
  encodedSource = encodedSource.replace(/\//g, "_");

  return encodedSource;
}

export function creteJwtToken(email, userId, browserName, channel) {
  var header = {
    alg: "HS256",
    typ: "JWT",
  };
  var stringifiedHeader = CryptoJS.enc.Utf8.parse(JSON.stringify(header));
  var encodedHeader = base64url(stringifiedHeader);
  var data = {
    id: email,
    username: userId,
    browserName: browserName,
    channel: channel,
    createdAt: new Date().getMilliseconds,
  };
  var stringifiedData = CryptoJS.enc.Utf8.parse(JSON.stringify(data));
  var encodedData = base64url(stringifiedData);
  var unsignedToken = encodedHeader + "." + encodedData;
  var secret = "My very confidential secret!";
  var signature = CryptoJS.HmacSHA256(unsignedToken, secret);
  var signature = base64url(signature);
  var signedToken = unsignedToken + "." + signature;
  return signedToken;
}
