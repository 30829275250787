import * as React from 'react';

class DataTableReportHeader extends React.Component {

    render() {
        return (
            <div className='custom-report-header overflow'>
                <span>{this.props.header}</span>
            </div>
        );
    }

}

export default DataTableReportHeader;
