import React, { useEffect, useState } from "react";
import moment from "moment";
import { DATE_TIME_FORMAT } from "../../../constants/appConstants";
import Pagination from "./pagination/pagination";
import "./table.scss";
function Table(props) {
  const { data, pageNumber } = props;
  let processedData = data.map((row) => {
    return { ...row, isVisible: false };
  });
  const [newData, setData] = useState([...processedData]);
  useEffect(() => {
    let processedData = data.map((row) => {
      return { ...row, isVisible: false };
    });
    setData([...processedData]);
  }, data);
  function handleClick(index) {
    let data = newData;
    data[index].isVisible = !data[index].isVisible;

    setData([...data]);
  }
  function onPageSizeChange(pageSize) {
    props.onPageSizeChange(parseInt(pageSize));
  }
  return (
    <>
      <div className="Table" style={{ border: 0 }}>
        <div
          className="custom-header"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div style={{ width: 150 }}>Date</div>
          <div style={{ width: 125 }}>Client</div>
          <div style={{ width: 120 }}>Service Code</div>
          <div style={{ width: 150 }}>Start</div>
          <div style={{ width: 150 }}>End</div>
          <div style={{ width: 50 }}>Pay</div>
          <div
            style={{
              width: 100,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <span>Notes</span>
          </div>
        </div>
        {newData.map((row, index) => {
          return (
            <>
              <div
                className="cell rt-tr-group"
                key={`key-${index}`}
                style={{
                  backgroundColor:
                    index % 2 === 0
                      ? "rgb(250, 250, 250)"
                      : "rgb(255, 255, 255)",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  // whiteSpace: "nowrap",
                }}
              >
                <div style={{ width: 150 }}>
                  {moment(row.createdDate).format("MM/DD/YYYY")}
                </div>
                <div style={{ width: 125 }}>{row.clientName}</div>
                <div style={{ width: 100 }}>{row.serviceCode}</div>
                <div style={{ width: 150 }}>
                  {moment(row.startTime).format(DATE_TIME_FORMAT).toUpperCase()}
                </div>
                <div style={{ width: 150 }}>
                  {moment(row.endTime).format(DATE_TIME_FORMAT).toUpperCase()}
                </div>
                <div style={{ width: 100 }}>{`${row.netAmount.toFixed(
                  2
                )}`}</div>
                <div
                  onClick={() => handleClick(index)}
                  style={{
                    width: 50,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    {/* {!row.isVisible && row.notes !== "" && (
                      <div
                        style={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {row.notes}
                      </div>
                    )} */}
                    <div>
                      {row.isVisible && row.notes !== "" && (
                        <img
                          src="./images/sort-up.png"
                          style={{ height: 10 }}
                          alt=""
                        />
                      )}
                      {!row.isVisible && row.notes !== "" && (
                        <img
                          src="./images/sort-down.png"
                          style={{ height: 10 }}
                          alt=""
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {row.isVisible && (
                <div
                  className=""
                  key={`key-${index}`}
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <div>{row.notes}</div>
                </div>
              )}
            </>
          );
        })}
        <div className="row pagination pagination-align">
          <div className="col-12">
            {props.showPagination && (
              <Pagination
                onPageSizeChange={onPageSizeChange}
                label={props.paginationLabel}
                onPageNumberChange={props.onPageNumberChange}
                total={props.total}
                pageNumber={pageNumber}
                pageSize={props.pageSize}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
export default Table;
