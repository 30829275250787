import {USER} from "../../constants/actionTypes";

export function createUser(user) {
    return {
        type: USER.CREATE_USER,
        user
    };
}

export function createUserSuccess(response) {
    return {
        type: USER.CREATE_USER_SUCCESS,
        response
    };
}

export function createUserFail(error) {
    return {
        type: USER.CREATE_USER_FAIL,
        error
    };
}

export function resetCreateUser() {
    return {
        type: USER.RESET_CREATE_USER
    };
}

export function updateUser(user) {
    return {
        type: USER.UPDATE_USER,
        user
    };
}

export function updateUserSuccess(response) {
    return {
        type: USER.UPDATE_USER_SUCCESS,
        response
    };
}

export function updateUserFail(error) {
    return {
        type: USER.UPDATE_USER_FAIL,
        error
    };
}

export function resetUpdateUser() {
    return {
        type: USER.RESET_UPDATE_USER
    };
}

export function getAllUsers(pageNumber, pageSize, filter, searchParams, userType, status) {
    return {
        type: USER.GET_USERS,
        pageNumber,
        pageSize,
        filter,
        searchParams,
        userType,
        status
    };
}

export function getAllUsersSuccess(response) {
    return {
        type: USER.GET_USERS_SUCCESS,
        response
    };
}

export function getAllUsersFail(error) {
    return {
        type: USER.GET_USERS_FAIL,
        error
    };
}

export function resetGetAllUsers() {
    return {
        type: USER.RESET_GET_USERS
    };
}

export function getAllUsersByType(pageNumber, type, searchParams) {
    return {
        type: USER.GET_USERS_BY_TYPE,
        type,
        searchParams
    };
}

export function getAllUsersByTypeSuccess(response) {
    return {
        type: USER.GET_USERS_BY_TYPE_SUCCESS,
        response
    };
}

export function getAllUsersByTypeFail(error) {
    return {
        type: USER.GET_USERS_BY_TYPE_FAIL,
        error
    };
}

export function resetGetAllUsersByType() {
    return {
        type: USER.RESET_GET_USERS_BY_TYPE
    };
}

export function getSCs(pageNumber, pageSize, filter, searchParams, status) {
    return {
        type: USER.GET_SCS,
        pageNumber,
        pageSize,
        filter,
        searchParams,
        status
    };
}


export function getSCsSuccess(response) {
    return {
        type: USER.GET_SCS_SUCCESS,
        response
    };
}

export function getSCsFail(error) {
    return {
        type: USER.GET_SCS_FAIL,
        error
    };
}

export function resetGetSCs() {
    return {
        type: USER.RESET_SCS
    };
}

export function createSC(user) {
    return {
        type: USER.CREATE_SC,
        user
    };
}

export function createSCSuccess(response) {
    return {
        type: USER.CREATE_SC_SUCCESS,
        response
    };
}

export function createSCFail(error) {
    return {
        type: USER.CREATE_SC_FAIL,
        error
    };
}

export function resetCreateSC() {
    return {
        type: USER.RESET_SC_USER
    };
}

export function updateSC(user) {
    return {
        type: USER.UPDATE_SC,
        user
    };
}

export function updateSCSuccess(response) {
    return {
        type: USER.UPDATE_SC_SUCCESS,
        response
    };
}

export function updateSCFail(error) {
    return {
        type: USER.UPDATE_SC_FAIL,
        error
    };
}

export function resetUpdateSC() {
    return {
        type: USER.RESET_UPDATE_USER
    };
}
