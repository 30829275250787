import React from "react";
import {VISIT_STATUS} from "../../../../constants/appConstants";
import {isMobileDevice, isTablet} from "../../../../util/deviceType";
import * as ClassNames from 'classnames'

class StatusCell extends React.Component {

    render() {
        const isMobile = (!isTablet() && !isMobileDevice())
        return (
            <div className='status-cell text-center'>

                {
                    this.props.status === VISIT_STATUS.ABANDONED &&
                    <div className={ClassNames('abandoned-status', {'mobile-device-status': !isMobile})}>
                        <img src="./images/abandoned.svg" alt=""/>&nbsp;
                        {isMobile &&
                        <span>Abandoned</span>
                        }

                    </div>
                }

                {
                    this.props.status === VISIT_STATUS.COMPLETED &&
                    <div className={ClassNames('completed-status', {'mobile-device-status': !isMobile})}>
                        <img src="./images/completed-circle.svg" alt=""/>&nbsp;
                        {isMobile &&
                        <span>Completed</span>
                        }
                    </div>
                }

                {
                    this.props.status === VISIT_STATUS.IN_PROGRESS &&
                    <div className={ClassNames('progress-status', {'mobile-device-status': !isMobile})}>
                        <img src="./images/inprogress.svg" alt=""/>&nbsp;
                        {isMobile &&
                        <span>In Progress</span>}
                    </div>
                }
            </div>
        );
    }
}

export default StatusCell;